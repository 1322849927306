import Slider from '@mui/material/Slider';
import * as Styled from './FilterRangeSliderMarks.styled';
import { FilterRangeSliderMarksProps } from './FilterRangeSliderMarks.types';
import { useAppDispatch } from '../../../../../../../../hooks/storeHooks';

export function FilterRangeSliderMarks({
  title,
  minValue,
  maxValue,
  marks,
  value,
  setStateValueAction,
  step,
}: FilterRangeSliderMarksProps) {
  const dispatch = useAppDispatch();

  function onChangeSetStateValueHandler(event: Event, newValue: number | number[]) {
    if (typeof newValue !== 'number') {
      dispatch(setStateValueAction(newValue));
    }
  }

  return (
    <Styled.RangeSliderMarks>
      <Styled.RangeSliderMarksTitle>
        {title}:
      </Styled.RangeSliderMarksTitle>
      <Styled.RangeSliderMarksWrapper>
        <Slider
          getAriaLabel={() => 'Temperature range'}
          value={value}
          onChange={onChangeSetStateValueHandler}
          size="small"
          min={minValue}
          step={step}
          max={maxValue}
          marks={marks}
          valueLabelDisplay="off"
          aria-labelledby="non-linear-slider"
        />
      </Styled.RangeSliderMarksWrapper>
    </Styled.RangeSliderMarks>
  );
}