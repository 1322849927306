import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import * as Styled from './CellWithColors.styled';
import { CellWithColorsProps } from './CellWithColors.types';
import { CellContainer } from '../../ui/CellContainer';

export function CellWithColors({
  value,
  paddingVertical,
  isBorderBottom,
  colorBorder,
  color,
  size,
  onClick,
}: CellWithColorsProps) {
  const {
    colors: {
      carminePink,
      lightGray,
      lightGreen,
      lightYellow,
    },
  } = useTheme();

  const backgroundInfo = useMemo(() => {
    switch(value) {
      case '1':
        return { color: carminePink, text: 'Обновить' };
      case '2':
        return  { color: lightYellow, text: 'Проверить!' };
      case '3':
        return  { color: lightGreen, text: 'Актуал' };
      default:
        return  { color: lightGray, text: 'Н/Д' };
    }
  }, [value]);

  return (
    <CellContainer
      background={backgroundInfo.color}
      paddingVertical={paddingVertical}
      isBorderBottom={isBorderBottom}
      colorBorder={colorBorder}
      onClick={onClick}
    >
      <Styled.CellText
        color={color}
        size={size}
      >
        {backgroundInfo.text}
      </Styled.CellText>
    </CellContainer>
  );
}