import * as Styled from './Container.styled';
import { ContainerProps } from './Container.types';

export function Container({
  children,
}: ContainerProps) {
  return(
    <Styled.Wrapper>
      <Styled.Container>
        {children}
      </Styled.Container>
    </Styled.Wrapper>
  );
}