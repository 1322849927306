import styled, { css } from 'styled-components';
import { color, compose, layout, position, space } from 'styled-system';
import { ButtonStyled, ButtonType } from './Button.types';
import { Text } from '../styledComponents';

type ButtonText = {
  size: string,
  colors: ButtonStyled,
  indent: number,
  isImageLeft: boolean,
  isDisabled: boolean,
}

export const ButtonText = styled(Text).attrs<ButtonText>(({
  size,
  colors,
  indent,
  isImageLeft,
}) => ({
  variant: size,
  color: colors.textDefault,
  ml: isImageLeft ? indent : 0,
  mr: !isImageLeft ? indent : 0,
}))<ButtonText>`
  order: ${({ isImageLeft }) => isImageLeft ? 1 : -1};
  text-align: center;
  user-select: none;

  ${({
    colors: {
      textDisabled,
      textHover,
      textActive,
    },
    isDisabled,
  }) => {
    if (isDisabled) {
      return css`
        color: ${textDisabled};

        &:hover {
          color: ${textDisabled};
        }

        &:active {
          color: ${textDisabled};
        }
      `;
    }

    return css`
      &:hover {
        color: ${textHover};
      }

      &:active {
        color: ${textActive};
      }
    `;
  }};
`;

type ButtonWrapperProps = {
  width: number | string,
  height: number | string,
  colors: ButtonStyled,
  typeButton: ButtonType,
  borderRadius: number,
  isDisabled: boolean,
  isSelected: boolean,
}

export const ButtonWrapper = styled('button').attrs<ButtonWrapperProps>(({
  width,
  height,
  colors: { colorDefault },
  typeButton,
}) => ({
  width,
  height,
  bg: typeButton !== 'border' ? colorDefault : 'transparent',
}))<ButtonWrapperProps>`
  ${compose(color, layout, position, space)};

  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .35s all;
  cursor: pointer;
  border-radius: ${({ borderRadius }) => borderRadius}px;
  border: none;

  ${({
    colors: {
      colorDisabled,
      colorDefault,
      colorHover,
      colorActive,
      textHover,
      textActive,
      textDefault,
    },
    typeButton,
    isDisabled,
    isSelected,
  }) => {
    if (isDisabled && typeButton === 'border') {
      return css`
        cursor: auto;
        border: 1px solid ${colorDisabled};

        &:hover,
        &:active {
          border-color: ${colorDisabled};
        }
      `;
    }

    if (isDisabled) {
      return css`
        cursor: auto;
        background-color: ${colorDisabled};

        &:hover,
        &:active {
          background-color: ${colorDisabled};
        }
      `;
    }

    if (isSelected) {
      return css`
        cursor: auto;
        background-color: ${colorActive};
        ${ButtonText} {
          color: ${textActive};
        }

        &:hover,
        &:active {
          background-color: ${colorActive};
          ${ButtonText} {
            color: ${textActive};
          }
        }
      `;
    }

    if (typeButton === 'border') {
      return css`
        border: 1px solid ${colorDefault};

        &:hover {
          border-color: ${colorHover};
          transition: .35s border;
        }

        &:active {
          background-color: ${colorActive};
          transition: .35s border;
          ${ButtonText} {
            color: ${textActive};
          }
        }
      `;
    }

    if (typeButton === 'blue2') {
      return css`
        border: 1px solid ${colorDefault};
        background-color: transparent;
        transition: .35s all;

        &:hover {
          background-color: ${colorHover};
          transition: .35s all;
        }

        &:active {
          background-color: ${colorActive};
          transition: .35s all;
        }
      `;
    }

    if (typeButton === 'white2') {
      return css`
        border: 1px solid ${textDefault};
        background-color: transparent;
        transition: .35s all;

        &:hover {
          border-color: ${colorDefault};
          background-color: ${colorHover};
          transition: .35s all;
          ${ButtonText} {
            color: ${textHover};
          }
        }

        &:active {
          background-color: ${colorActive};
          transition: .35s all;
          ${ButtonText} {
            color: ${textActive};
          }
        }
      `;
    }

    return css`
      &:hover {
        background-color: ${colorHover};
        transition: .35s background;
        ${ButtonText} {
          color: ${textHover};
        }
      }

      &:active {
        background-color: ${colorActive};
        transition: .35s background;
        ${ButtonText} {
          color: ${textActive};
        }
      }
    `;
  }}
`;