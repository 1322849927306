import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { INITIAL_VALUE } from './constants';
import * as Styled from './SignIn.styled';
import { AuthenticationPageWrapper } from '../../../components/AuthenticationPageWrapper';
import { PATH } from '../../../constants/constants';
import { useAppDispatch } from '../../../hooks/storeHooks';
import { useAuthenticationError } from '../../../hooks/useAuthenticationError';
import { useInput } from '../../../hooks/useInput';
import { logIn } from '../../../redux/reducers/auth/async';
import { FieldInput } from '../../../ui/FieldInput';
import { InputType } from '../../../ui/FieldInput/FieldInput.types';

export function SignIn() {
  const { t } = useTranslation();
  const login = useInput({ initialValue: INITIAL_VALUE.login });
  const password = useInput({ initialValue: INITIAL_VALUE.password });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    isValid,
    error,
    serverError,
  } = useAuthenticationError({
    login: login.value,
    password: password.value,
  });

  const {
    colors: {
      whiteLight,
      blueAzure,
      oliveGreen,
      lightGray,
    },
  } = useTheme();

  function onClickShapeChangeHandler() {
    navigate(PATH.signUp);
  }

  function onClickSetNewPasswordHandler() {
    navigate(PATH.passwordRecovery);
  }

  function onClickLogInOrSignInHandler() {
    if (!isValid) return;

    dispatch(logIn({
      login: login.value,
      password: password.value,
    }));

    password.setValueInField(INITIAL_VALUE.password);
  }

  return(
    <AuthenticationPageWrapper
      onClickShapeChange={onClickShapeChangeHandler}
      onClickLogInOrSignIn={onClickLogInOrSignInHandler}
      error={error}
      serverError={serverError}
      isValid={isValid}
      textButtonSubmit="authentication.signIn"
      textHelpInForm="authentication.createAccount"
      textNavigateButton="authentication.shapeChange"
      textSelectedForm="authentication.noAccount"
      tabIndexButtonSubmit={5}
    >
      <FieldInput
        { ...login }
        placeholder={t('authentication.emailPlaceholder')}
        backgroundColor={whiteLight}
        outlineColor={blueAzure}
        borderColor={lightGray}
        color={oliveGreen}
        type={InputType.Email}
        tabIndex={2}
      />

      <Styled.PasswordRecoveryWrapper>
        <Styled.PasswordRecovery
          onClick={onClickSetNewPasswordHandler}
          tabIndex={3}
        >
          {t('authentication.passwordRecovery')}
        </Styled.PasswordRecovery>
      </Styled.PasswordRecoveryWrapper>

      <FieldInput
        { ...password }
        placeholder={t('authentication.passwordPlaceholder')}
        backgroundColor={whiteLight}
        outlineColor={blueAzure}
        borderColor={lightGray}
        color={oliveGreen}
        type={InputType.Password}
        marginTop={2}
        tabIndex={4}
      />
    </AuthenticationPageWrapper>
  );
}