import { INPUT_MASKS } from '../../constants/constants';

export function parsingNumber(string: string): number {
  if (!INPUT_MASKS.realNonNegativeNumbers.test(string))
    throw new Error('Invalid input string');

  if (string.endsWith('.') || !string) {
    string += '0';
  }

  return parseFloat(string);
}