import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getCourses = createAsyncThunk(
  'courses/getCourses',
  async ({
    authKey,
    numberPage,
  }: {
    authKey: string,
    numberPage: number,
  }) => {
    const { data } = await axios.get(`/courses?current_page=${numberPage}`, {
      headers: {
        key: authKey,
      },
    });

    return data;
  },
);