import { useState } from 'react';

export function useSlider<T extends number | number[]>(
  initialValue: T,
) {
  const [value, setValue] = useState<T>(initialValue);

  const onChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as T);
  };

  return {
    value,
    onChange,
  };
}