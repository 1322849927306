export enum PlatformSharpRadius {
  maximum = 0.3,
  minimumMultiplier = 5,
  scaledValue = 0.025,
}

export type CheckboxGraphData = {
  key: string,
  hint: string,
  label: string,
  isChecked: boolean,
  onChangeSetIsCheckedHandler: () => void,
  disabled?: boolean,
}