import { useEffect } from 'react';
import * as Styled from './ModalContainer.styled';
import { ModalContainerProps } from './ModalContainer.types';
import { Button } from '../../components/Button';
import { ButtonType } from '../../components/Button/Button.types';
import { TooltipWithElement } from '../../components/TooltipWithElement';
import { TooltipPlacement } from '../../components/TooltipWithElement/TooltipWithElement.types';

export function ModalContainer({
  onClose,
  onActive,
  children,
  title,
  isDisabled = false,
  helpHint = '',
}: ModalContainerProps) {

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return(
    <>
      <Styled.Substrate
        onClick={onClose}
      />

      <Styled.Container>
        <Styled.TitleContainer>
          {title}
        </Styled.TitleContainer>

        <Styled.ContentContainer>
          {children}
        </Styled.ContentContainer>

        <Styled.ButtonsContainer>
          <Button
            width="100%"
            height={36}
            onClick={onClose}
            title="Отменить"
            size="regular14"
            type={ButtonType.TypeBorder}
          />

          <TooltipWithElement
            hint={isDisabled ? helpHint : ''}
            placement={TooltipPlacement.bottom}
            timeout={300}
            enterDelay={100}
            leaveDelay={100}
            element={
              <Button
                width="100%"
                height={36}
                onClick={onActive}
                title="Сохранить"
                size="regular14"
                isDisabled={isDisabled}
              />
            }
          />
        </Styled.ButtonsContainer>
      </Styled.Container>
    </>
  );
}