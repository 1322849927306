import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RegisterData, VerificationData } from './types';

export const logIn = createAsyncThunk(
  'auth/login',
  async ({ login, password }: { login: string, password: string }) => {
    const { data } = await axios.post('/login', { login, password });
  
    return data;
  },
);

export const signIn = createAsyncThunk(
  'auth/signIn',
  async ({ email, password }: RegisterData) => {
    const { data } = await axios.post('/register', { email, password });
    
    return data;
  },
);

export const passwordRecovery = createAsyncThunk(
  'auth/passwordRecovery',
  async ({ email, password }: RegisterData) => {
    const { data } = await axios.post('/password_reset', { email, password });

    return data;
  },
);

export const verificationIn = createAsyncThunk(
  'auth/verificationIn',
  async ({ email, password, codeFromEmail }: VerificationData) => {
    const dataRegistration = {
      email,
      password,
      code_from_email: codeFromEmail,
    };
    const { data } = await axios.post('/register_after_code_email', { ...dataRegistration });
    return data;
  },
);

export const verificationRecovery = createAsyncThunk(
  'auth/verificationRecovery',
  async ({ email, password, codeFromEmail }: VerificationData) => {
    const dataRegistration = {
      email,
      password,
      code_from_email: codeFromEmail,
    };
    const { data } = await axios.post('/password_reset_after_code_email', { ...dataRegistration });
    return data;
  },
);