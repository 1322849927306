import { useId } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as Styled from './Header.styled';
import { HeaderProps } from './Header.types';
import bell from '../../../../assets/icons/bell.svg';
import arrow from '../../../../assets/icons/paginationArrow.svg';
import star from '../../../../assets/icons/star.svg';
import { Button } from '../../../../components/Button';
import { ButtonType } from '../../../../components/Button/Button.types';
import { ImageContainer } from '../../../../components/ImageContainer';
import { PATH } from '../../../../constants/constants';
import { useAppDispatch } from '../../../../hooks/storeHooks';
import { setActiveElementAdminPanelAct } from '../../../../redux/reducers/navigation';
import { ActiveElement } from '../../../Admin/Admin.types';

export function Header({
  id,
  name,
}: HeaderProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    colors: {
      snowWhite,
      grayBlue,
    },
  } = useTheme();

  function onCLickSetActivePathAdminPanelHandler() {
    dispatch(setActiveElementAdminPanelAct(ActiveElement.Platforms));
    navigate(PATH.adminPanel);
  }

  const dataNameHeader = [
    {
      id: useId(),
      name: '_platform.name',
      title: name,
    },
    {
      id: useId(),
      name: '_platform.platformId',
      title: id,
    },
  ];

  return(
    <Styled.Container>
      <Button
        width={140}
        height={32}
        onClick={onCLickSetActivePathAdminPanelHandler}
        type={ButtonType.TypeBorder}
        title={t('_platform.allPlatform')}
        image={arrow}
        imageHeight={14}
        imageWidth={14}
        isImageLeft
        indent={2}
        fill={snowWhite}
        fillHover={snowWhite}
        fillActive={grayBlue}
        size="regular14"
      />

      <Styled.InfoContainer>
        {dataNameHeader.map(({ id, name, title }) => (
          <Styled.InfoNameContainer key={id}>
            <Styled.InfoPointerToNamePlatform>
              {t(name)}
            </Styled.InfoPointerToNamePlatform>

            <Styled.InfoNamePlatform>
              {title}
            </Styled.InfoNamePlatform>
          </Styled.InfoNameContainer>
        ))}
      </Styled.InfoContainer>

      <Styled.ActionContainer>
        <ImageContainer
          width={15}
          height={15}
          image={star}
        />

        <ImageContainer
          width={15}
          height={15}
          image={bell}
        />
      </Styled.ActionContainer>
    </Styled.Container>
  );
}