import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PinField } from 'react-pin-field';
import { INITIAL_VALUE } from './constants';
import * as Styled from './PinCodeForm.styled';
import { PinCodeFormProps } from './PinCodeForm.types';
import { INPUT_MASKS } from '../../../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { setIsTransitionPinCodeInFalseAction } from '../../../../redux/reducers/auth';
import { logIn, verificationIn, verificationRecovery } from '../../../../redux/reducers/auth/async';
import { ButtonContainer } from '../../../../ui/ButtonContainer';
import { Button } from '../../../Button';
import { ButtonType } from '../../../Button/Button.types';

export function PinCodeForm({
  login,
  password,
  serverError,
}: PinCodeFormProps) {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const pinCodeRef = useRef<HTMLInputElement[] | null>(null);

  const [isCompleted, setIsCompleted] = useState(INITIAL_VALUE.isCompleted);
  const [pinCodeValue, setPinCodeValue] = useState(INITIAL_VALUE.pinCodeValue);

  const {
    isRegister,
    isValidPinCode,
  } = useAppSelector((state) => state.auth);

  // Метод, записывающий данные о введенных значениях в поля пин-кода
  function onChangeSetPinCodeValueHandler(pinCode: string) {
    setPinCodeValue(pinCode);
  }

  // Метод, блокирующий ввод в поле пин-кода (Используется только когда все поля заполнены)
  function setCompleted() {
    setIsCompleted((prev) => !prev);
  }

  // Метод, установки ввода данных в поля пин-кода в одном формате
  function setFormat(pinCodeSymbol: string) {
    return pinCodeSymbol.toUpperCase();
  }

  // Метод, сброса отображаемых данных в пин-коде
  function resetFieldVerification() {
    if (pinCodeRef && pinCodeRef.current) {
      pinCodeRef.current.forEach(input => (input.value = ''));
      setPinCodeValue(INITIAL_VALUE.pinCodeValue);
      setIsCompleted((prev) => !prev);
    }
  }

  // Метод, запрашивающий ключ и отчищающий данные формы
  function getLogIn() {
    dispatch(logIn({ login, password }));

    resetFieldVerification();
  }

  // Метод, для входа на сайт при нажатии на кнопку входа
  function onClickSubmitPinCodeHandler() {
    getLogIn();
  }

  // Если все поля пин-кода заполнены запрашиваем спрашиваем с сервера, введен ли верный пин-код и отчищаем поля ввода
  useEffect(() => {
    if (isCompleted) {
      const dataVerification = {
        password,
        email: login,
        codeFromEmail: pinCodeValue,
      };

      dispatch(isRegister
        ? verificationIn(dataVerification)
        : verificationRecovery(dataVerification),
      );

      resetFieldVerification();
    }
  }, [isCompleted]);

  // Если пользователь ввел верный пин-код, запрашиваем данные и входим на сайт
  useEffect(() => {
    if (isValidPinCode) {
      getLogIn();
      dispatch(setIsTransitionPinCodeInFalseAction());
    }
  }, [isValidPinCode]);

  // Если нет данных о логине и пароле, пользователь не сможет войти на сайт.
  // Так как данные хранятся в useState, при обновлении страницы или любых размонтирующих компонент действиях,
  // данные страницы будут утеряны, следовательно нет смысла показывать ему страницу с верификацией.
  // Данные не хранятся в storage в целях безопасности.
  useEffect(() => {
    if (!login || !password) {
      dispatch(setIsTransitionPinCodeInFalseAction());
    }
  }, [login, password]);

  return(
    <Styled.Container>
      <Styled.PinCode isCompleted={isCompleted}>
        <PinField
          ref={pinCodeRef}
          className='test'
          onComplete={setCompleted}
          format={setFormat}
          autoFocus
          disabled={isCompleted}
          autoComplete="one-time-password"
          length={6}
          onChange={onChangeSetPinCodeValueHandler}
          validate={INPUT_MASKS.lettersAndNumbers}
        />
      </Styled.PinCode>

      <ButtonContainer
        marginTop={8}
        marginBottom={2}
      >
        <Button
          width="100%"
          height={40}
          onClick={onClickSubmitPinCodeHandler}
          isDisabled={pinCodeValue.length < 6}
          title={t('authentication.verificationCode')}
          size="bold24"
          type={ButtonType.TypeHeavenlyActive}
        />
      </ButtonContainer>

      <Styled.ErrorWrapper>
        <Styled.ErrorMessage>
          {t(serverError)}
        </Styled.ErrorMessage>
      </Styled.ErrorWrapper>
    </Styled.Container>
  );
}