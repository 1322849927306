import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import * as Styled from './CellWithHint.styled';

import { CellWithHintProps } from './CellWithHint.types';


export function CellWithHint({
  cellData,
  color,
  size,
  background,
  paddingVertical,
  isBorderBottom,
  colorBorder,
  onClick,
}: CellWithHintProps) {
  const [trimmedText, setTrimmedText] = useState<string[]>(cellData);

  const divRef = useRef<HTMLDivElement>(null);

  // Максимаьная высота = lineHeight (fontSize + 2) + paddingVertical
  // Регулярное выражерния достает размер шрифта "regular14" -> ['14']
  // Так как поддинги кратны 4, то их нужно увеличить в 4 раза
  const maxHeight = +`${size.match(/\d+/g)?.[0]}` + paddingVertical * 4 + 2;

  useEffect(() => {
    if (divRef.current) {
      const div = divRef.current;

      div.textContent = cellData.join(' ');

      // let - так как нам на данном этапе нужно ВСЕГДА актуальное значение и не больше одной линии
      let lines = [...cellData];
      let height = div.getBoundingClientRect().height;

      while (lines.length > 1 && height > maxHeight) {
        lines = lines.slice(0, -1);

        div.textContent = lines.join(' ');
        height = div.getBoundingClientRect().height;
      }

      setTrimmedText(lines);
    }
  }, [cellData]);

  function setNodeHint() {
    const amountTrimmedNode = cellData.length - trimmedText.length;

    if (!amountTrimmedNode) return '';

    const arrayWithHint = cellData.slice(trimmedText.length, cellData.length).join(' ');

    // 4 - так как надпись "еще n" по размерам примерно такая же, как и 3 буквы ячейки
    if (amountTrimmedNode === 1 && arrayWithHint.length < 4) {
      return (
        <Styled.CellHintText size={size} color={color}>
          {arrayWithHint}
        </Styled.CellHintText>
      );
    }

    // Метод, который запрещает навигакию при клике на подсказку
    function onClickStopPropagationNavigationHandler(event: MouseEvent<HTMLDivElement>) {
      event.stopPropagation();
    }

    return (
      <Styled.CellHintWrapper size={size} color={color}>
        {`еще ${amountTrimmedNode}`}
        <Styled.CellHint
          background={background}
          onClick={onClickStopPropagationNavigationHandler}
        >
          <Styled.CellHintText size={size} color={color}>
            {arrayWithHint}
          </Styled.CellHintText>
        </Styled.CellHint>
      </Styled.CellHintWrapper>
    );
  }

  return (
    <Styled.CellContainer
      background={background}
      paddingVertical={paddingVertical}
      isBorderBottom={isBorderBottom}
      colorBorder={colorBorder}
      onClick={onClick}
    >
      <Styled.CellWrapper>
        <Styled.CellText size={size} color={color} ref={divRef}/>
        {setNodeHint()}
      </Styled.CellWrapper>
    </Styled.CellContainer>
  );
}