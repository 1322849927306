import styled from 'styled-components';
import { Box } from '../../../../../../components/styledComponents';

export const Container = styled(Box).attrs({
  width: '100%',
  height: '100%',
  px: 1,
})`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`;

export const ButtonList = styled(Box).attrs({
  width: '100%',
  mt: 'auto',
  mb: 1,
  pt: 2,
})`
  display: flex;
  justify-content: space-between;
`;