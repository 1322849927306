import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FreeInformation } from './componetns/FreeInformation';
import { Header } from './componetns/Header';
import { Tabs } from './componetns/Tabs';
import { ActiveTab } from './componetns/Tabs/Tabs.types';
import { INITIAL_STATE } from './constants';
import * as Styled from './Platform.styled';
import { Loading } from '../../components/Loading';
import { PATH } from '../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { getDataPlatform } from '../../redux/reducers/tabels/async';

export function Platform() {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const {
    authKey = '',
  } = useAppSelector((state) => state.auth);
  const {
    loadingStatePlatform,
    platformData = INITIAL_STATE.platformData,
  } = useAppSelector((state) => state.tabels);

  const [platformId, setPlatformId] = useState<string>('');
  const [activeTab, setActiveTab] = useState<ActiveTab>(INITIAL_STATE.activeTab);

  useEffect(() => {
    if (platformId) {
      dispatch(getDataPlatform({ authKey, platformId }));
    }
  }, [platformId]);

  useEffect(() => {
    setPlatformId(pathname.split(PATH.platforms)[1]);
  }, [pathname]);

  function selectedTabContent() {
    switch (activeTab) {
      case ActiveTab.FreeInformation:
        return <FreeInformation platformData={platformData}/>;
      default:
        return;
    }
  }

  return(
    <Styled.HeaderContainer>
      <Styled.Container>
        {loadingStatePlatform === 'loading'
          ? <Loading/>
          : (
            <>
              <Header
                name={platformData.platformName}
                id={platformData.platformId}
              />

              <Tabs
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />

              {selectedTabContent()}
            </>
          )}
      </Styled.Container>
    </Styled.HeaderContainer>
  );
}