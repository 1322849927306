import styled from 'styled-components';
import { Box, Text } from '../../../../../styledComponents';

export const Container = styled(Box).attrs({
  width: '100%',
  height: '100%',
})`
  display: flex;
  align-items: center;
  column-gap: 5px;
`;

type CellTextProps = {
  size: string,
  color: string,
}

export const CellText = styled(Text).attrs<CellTextProps>(({
  color,
  size,
}) => ({
  color,
  variant: size,
}))<CellTextProps>``;