import { DefaultTheme } from 'styled-components';

export enum DropSide {
  Left = 'left',
  Right = 'right',
  Bottom = 'bottom',
}

export interface SeriesItem {
  name: string;
  type: string;
  smooth: boolean;
  symbol: string;
  data: number[][];
  yAxisIndex: number;
}

export enum GraphState {
  Initial = 'initial',
  Dragging = 'dragging',
  Graph = 'graph',
}

