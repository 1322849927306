import React, {
  useState,
  useEffect,
} from 'react';

import styles from './DataModalDeleteChains.module.scss';
import { useAppSelector } from '../../../../../../../../hooks/storeHooks';

import { DataModalDeleteChainsProp } from '../../DeleleChainsModal.types';

export function DataModalDeleteChains({
  notErrorReqDeleteChains,
}: DataModalDeleteChainsProp) {
  const {
    isResponseOK,
    errorMessageOK,
  } = useAppSelector((state) => state.algorithms);

  const [dataModal, setDataModal] = useState('');

  useEffect(() => {
    setDataModal(notErrorReqDeleteChains);
  }, [notErrorReqDeleteChains]);

  return (
    <div>
      {isResponseOK
        ? (
          <>
            <p className={styles.course_modal_title}>
              {`Колличество цепочек: ${dataModal}`}
            </p>
            <p>Вы точно хотите удалить ?</p>
          </>
        )
        : (
          <div className={styles.course_modal_title_error}>
            {errorMessageOK}
          </div>
        )}
    </div>
  );
}
