import { useRef } from 'react';
import { useTheme } from 'styled-components';
import * as Styled from './Button.styled';
import { ButtonProps, ButtonType } from './Button.types';
import { useActive } from '../../hooks/useActive';
import { useHover } from '../../hooks/useHover';
import { ImageContainer } from '../ImageContainer';

export function Button({
  width,
  height,
  onClick,
  title = '',
  image = '',
  isDisabled = false,
  type = ButtonType.TypeBlue,
  indent = 0,
  size = 'regular12',
  imageWidth = 0,
  imageHeight = 0,
  isImageLeft = false,
  borderRadius = 4,
  isSelected = false,
  fill,
  fillHover,
  fillActive,
  tabIndex,
}: ButtonProps) {
  const {
    colors: {
      greenCobalt,
      lightGrayBlue,
      greenBlue,
      snowWhite,
      white,
      carminePink,
      carminePinkLight,
      carminePinkDark,
      grayBlue,
      lightGray,
      slateGray,
      heavenly,
      blueAzure,
      blueGray,
      cornflowerBlue,
    },
  } = useTheme();

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const isHover = useHover(buttonRef);
  const isActive = useActive(buttonRef);

  function getStyleByTypeButton() {
    switch (type) {
      case ButtonType.TypeBorder:
        return {
          colorDefault: snowWhite,
          colorHover: white,
          colorActive: white,
          colorDisabled: lightGray,
          textDefault: white,
          textHover: white,
          textActive: grayBlue,
          textDisabled: lightGray,
        };
      case ButtonType.TypeWhite:
        return {
          colorDefault: snowWhite,
          colorHover: white,
          colorActive: greenCobalt,
          colorDisabled: lightGray,
          textDefault: grayBlue,
          textHover: grayBlue,
          textActive: snowWhite,
          textDisabled: slateGray,
        };
      case ButtonType.TypeRed:
        return {
          colorDefault: carminePink,
          colorHover: carminePinkLight,
          colorActive: carminePinkDark,
          colorDisabled: lightGray,
          textDefault: grayBlue,
          textHover: grayBlue,
          textActive: grayBlue,
          textDisabled: slateGray,
        };
      case ButtonType.TypeHeavenly:
        return {
          colorDefault: heavenly,
          colorHover: blueAzure,
          colorActive: blueAzure,
          colorDisabled: lightGray,
          textDefault: blueGray,
          textHover: snowWhite,
          textActive: snowWhite,
          textDisabled: slateGray,
        };
      case ButtonType.TypeHeavenlyActive:
        return {
          colorDefault: blueAzure,
          colorHover: cornflowerBlue,
          colorActive: cornflowerBlue,
          colorDisabled: lightGray,
          textDefault: snowWhite,
          textHover: snowWhite,
          textActive: snowWhite,
          textDisabled: snowWhite,
        };
      case ButtonType.TypeBlueTwo:
        return {
          colorDefault: greenCobalt,
          colorHover: greenCobalt,
          colorActive: greenCobalt,
          colorDisabled: lightGray,
          textDefault: snowWhite,
          textHover: snowWhite,
          textActive: snowWhite,
          textDisabled: lightGray,
        };
      case ButtonType.TypeWhiteTwo:
        return {
          colorDefault: greenCobalt,
          colorHover: snowWhite,
          colorActive: snowWhite,
          colorDisabled: lightGray,
          textDefault: snowWhite,
          textHover: greenCobalt,
          textActive: greenCobalt,
          textDisabled: lightGray,
        };
      default:
        return {
          colorDefault: greenCobalt,
          colorHover: lightGrayBlue,
          colorActive: greenBlue,
          colorDisabled: lightGray,
          textDefault: snowWhite,
          textHover: snowWhite,
          textActive: snowWhite,
          textDisabled: slateGray,
        };
    }
  }

  const colorsButton = getStyleByTypeButton();

  return (
    <Styled.ButtonWrapper
      width={width}
      height={height}
      onClick={!isDisabled && !isSelected ? onClick : undefined}
      colors={colorsButton}
      typeButton={type}
      borderRadius={borderRadius}
      isDisabled={isDisabled}
      isSelected={isSelected}
      tabIndex={!isDisabled ? tabIndex : undefined}
      ref={buttonRef}
    >
      {title && (
        <Styled.ButtonText
          size={size}
          colors={colorsButton}
          indent={indent}
          isImageLeft={isImageLeft}
          isDisabled={isDisabled}
        >
          {title}
        </Styled.ButtonText>
      )}

      {image && (
        <ImageContainer
          width={imageWidth}
          height={imageHeight}
          image={image}
          fill={isHover ? isActive ? fillActive : fillHover : fill}
        />
      )}
    </Styled.ButtonWrapper>
  );
}