import { textContent } from './constants';
import * as Styled from './Footer.styled';
import { Container } from '../../../../ui/Container';

export function Footer() {
  return(
    <Container>
      <Styled.Footer>
        <Styled.Container>
          <Styled.NavigationList>
            {textContent.сontacts}
          </Styled.NavigationList>
          <Styled.NavigationList>
            {textContent.questions}
          </Styled.NavigationList>
          <Styled.NavigationList>
            {textContent.review}
          </Styled.NavigationList>
        </Styled.Container>
      </Styled.Footer>
    </Container>
  );
}