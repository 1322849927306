import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';

import { theadName } from './constants';
import { ExchangeListProps } from './ExchangeList.types';
import logo from '../../../../assets/icons/logo.svg';
import tab from '../../../../assets/icons/tab.svg';

import arrow from '../../../../assets/icons/tab_icon.svg';
import '../../../../ui/AdminPage.scss';

import './ExchangeList.scss';
import { ImageContainer } from '../../../../components/ImageContainer';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { setStartCurrencyChainAct } from '../../../../redux/reducers/onlineParsing';
import { IOnlineParsingDataChains, StateButtonParsing } from '../../../../redux/reducers/onlineParsing/types';
import { updateStartCurrencyOrSetMarginalProfitChainAction } from '../../../../redux/reducers/tabels';
import { IChainSteps } from '../../../../redux/reducers/tabels/types';
import { parsingNumber } from '../../../../utils/parsingNumber';

export function ExchangeList({
  idChains,
  setActiveModal,
  setSelectCourse,
  valueTransaction,
}: ExchangeListProps) {
  const dispatch = useAppDispatch();
  const {
    chainSteps,
    marginalProfit,
  } = useAppSelector((state) => state.tabels);
  const {
    onlineParsing,
    resultAmount,
    resultProfit,
    onlineParsingDataChains,
  } = useAppSelector((state) => state.parsing);

  const [isNotActiveOnlineParser] = useState(
    onlineParsing?.[idChains]?.stateButton !== StateButtonParsing.Stop,
  );

  const [dataChainSteps, setDataChainSteps] = useState<IChainSteps[] | IOnlineParsingDataChains[]>(chainSteps);

  const marginCalculation = useMemo(() => {
    const transactionAmount = parsingNumber(valueTransaction);

    if (!transactionAmount) return 0;

    const marginality = ((marginalProfit - transactionAmount) / transactionAmount) * 100;

    return Math.trunc(marginality * 100) / 100;
  }, [marginalProfit, valueTransaction]);

  const amountAtTheEnd = useMemo(() => {
    return valueTransaction ? marginalProfit : 0;
  }, [marginalProfit]);

  const setDataInModal = (data: any) => {
    if (isNotActiveOnlineParser) {
      setActiveModal(true);
      setSelectCourse(data);
    }
  };

  useEffect(() => {
    dispatch(updateStartCurrencyOrSetMarginalProfitChainAction({
      presentValue: parsingNumber(valueTransaction),
    }));
  }, [valueTransaction]);

  useEffect(() => {
    if (isNotActiveOnlineParser) {
      setDataChainSteps(chainSteps);
    } else {
      setDataChainSteps(onlineParsingDataChains);
    }
  }, [chainSteps, onlineParsing]);

  function validatorVolume(value: number) {
    return !value || value < 0 ? 0 : value;
  }

  function onClickOpenModalFullInformationHandler(item: IChainSteps | IOnlineParsingDataChains) {
    setDataInModal(item);
  }

  return (
    <section className="admin_exchange">
      <table className="admin_exchange_table">
        <thead className="admin_exchange_thead">
          <tr className="admin_exchange_tr">
            <td className="admin_td"/>
            {theadName.map((item) => (
              <th
                key={item.id}
                className="admin_exchange_th"
              >
                <span className="admin_exchange_th_span">{item.title}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="admin_exchange_tbody">
          {dataChainSteps?.map((item, index) => (
            <tr
              key={item.rule_number}
              className="admin_exchange_tr"
              onClick={() => onClickOpenModalFullInformationHandler(item)}
            >
              <td
                key={item.rule_number}
                className="admin_exchange_td"
                onClick={(e) => {
                  e.stopPropagation();
                  if (isNotActiveOnlineParser) {
                    dispatch(setStartCurrencyChainAct({
                      idChains,
                      currency: item.currency_from,
                    }));
                    dispatch(updateStartCurrencyOrSetMarginalProfitChainAction({
                      presentValue: parsingNumber('' + valueTransaction),
                      indexCurrency: index,
                    }));
                  }
                }}
              >
                <img
                  className="admin_exchange_img"
                  src={tab}
                  alt="Flag"
                />
              </td>
              {theadName.map((header) => {
                switch (header.id) {
                  case 'rate':
                    return (
                      <td
                        key={header.id}
                        className="admin_exchange_td"
                      >
                        <span className="admin_exchange_td_span">
                          {item[header.id] !== 'Нет предложений' ? `${item[header.id]}`.slice(0, 10) : item[header.id]}
                        </span>
                      </td>
                    );
                  case 'method':
                    return (
                      <td
                        key={header.id}
                        className="admin_exchange_td admin_exchange_td-mehod"
                      >
                        <span className="admin_exchange_th_span">
                          {item[header.id]}
                        </span>
                        <ImageContainer
                          width={75}
                          height={30}
                          image={arrow}
                        />
                      </td>
                    );
                  case 'sumStart':
                    return (
                      <td
                        key={header.id}
                        className="admin_exchange_td"
                      >
                        <span className="admin_exchange_td_span">
                          {validatorVolume(item[header.id] || 0)}
                        </span>
                      </td>
                    );
                  case 'sumEnd':
                    return (
                      <td
                        key={header.id}
                        className="admin_exchange_td"
                      >
                        <span className="admin_exchange_td_span">
                          {validatorVolume(item[header.id] || 0)}
                        </span>
                      </td>
                    );
                  default:
                    return (
                      <td
                        key={header.id}
                        className="admin_exchange_td"
                      >
                        <span className="admin_exchange_td_span">
                          {item[header.id]}
                        </span>
                      </td>
                    );
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>

      <div className="admin_exchange_results">
        <div className="admin_exchange_results_logo">
          <ImageContainer
            width="100%"
            height="100%"
            image={logo}
            isBaseColor
          />
        </div>
        <div className="admin_exchange_results_wrapper">
          <div className="admin_exchange_result">
            <div className="admin_exchange_result_text">Сумма на конец:</div>
            <div className="admin_exchange_results_value">
              {isNotActiveOnlineParser
                ? amountAtTheEnd
                : resultAmount}
            </div>
          </div>
          <div className="admin_exchange_result">
            <div className="admin_exchange_result_text">Маржинальность:</div>
            <div className="admin_exchange_results_value">
              {isNotActiveOnlineParser
                ? `${marginCalculation}%`
                : `${resultProfit}%`}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
