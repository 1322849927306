import styled, { css } from 'styled-components';
import { color, compose, position, space } from 'styled-system';
import { Box, Text } from '../../../styledComponents';

export const CellWrapper = styled(Box).attrs({
  width: '100%',
  height: '100%',
})`
  display: flex;
  justify-content: center;
  align-items: center;
`;

type CellElementTextProps = {
  size: string,
  color: string,
}

export const CellElementText = styled(Text).attrs<CellElementTextProps>(({
  size,
  color,
}) => ({
  color,
  variant: size,
}))<CellElementTextProps>`
  user-select: none;
`;

export const CellImageSorting = styled(Box)``;

type CellContainerProps = {
  background: string,
  isBorder: boolean,
  isActiveSortingUp?: boolean,
}

export const CellContainer = styled('th').attrs<CellContainerProps>(({ background }) => ({
  position: 'sticky',
  zIndex: 2,
  py: 2,
  px: 1,
  bg: background,
}))<CellContainerProps>`
  ${compose(space, position, color)};

  box-sizing: border-box;
  border: ${({ theme, isBorder }) => isBorder ? `1px solid ${theme.colors.lightGray}` : 'none'};

  ${({ isActiveSortingUp }) => {
    if (isActiveSortingUp === undefined) {
      return css`
        ${CellImageSorting} {
          opacity: 0.15;
        }

        &:hover,
        &:active {
          ${CellImageSorting} {
            opacity: 1;
          }
        }
      `;
    }

    if (isActiveSortingUp) {
      return css`
        ${CellImageSorting} {
          opacity: 1;
        }
      `;
    }

    return css`
      &:hover,
      &:active {
        ${CellImageSorting} {
          opacity: 1;
        }
      }
    `;
  }};
`;

export const HintContainer = styled(Box).attrs({
  position: 'absolute',
  top: '-1px',
  right: '-1px',
  zIndex: 1,
  width: 11,
  height: 11,
  bg: 'lightGray',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 2px;
  transition: .25s all;

  &:hover {
    background-color: ${({ theme }) => theme.colors.snowWhite};
    transition: .25s all;
  }
`;

export const HintSymbol = styled(Text).attrs({
  variant: 'regular12',
  color: 'oliveGreen',
})`
  user-select: none;
`;

export const HintWrapper = styled(Box).attrs({
  position: 'absolute',
  top: 0,
  right: 0,
})`

`;