import { useTranslation } from 'react-i18next';
import * as Styled from './Slide.styled';
import { SlideProps } from './Slide.types';

export function Slide({
  title,
  text,
}: SlideProps) {
  const { t } = useTranslation();

  return(
    <Styled.Container>
      <Styled.SlideTitle>
        {t(title)}
      </Styled.SlideTitle>

      <Styled.SlideText>
        {t(text)}
      </Styled.SlideText>
    </Styled.Container>
  );
}