import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { InfoRow } from './components/InfoRow';
import { FreeInformationProps } from './FreeInformation.types';
import * as Styled from './FreeInformationProps.styled';
import start from '../../../../assets/icons/start.svg';
import { Button } from '../../../../components/Button';
import { useInput } from '../../../../hooks/useInput';
import { ParserData } from '../../../../redux/reducers/tabels/types';
import { FieldInput } from '../../../../ui/FieldInput';
import { FieldSelect } from '../../../../ui/FieldSelect';

export function FreeInformation({
  platformData,
}: FreeInformationProps) {
  const { t } = useTranslation();
  const {
    colors: {
      slateGray,
      lime,
      carminePink,
    },
  } = useTheme();
  const synonyms = useInput({ initialValue: '' });
  const initialStateParserData = platformData.parserData?.[0] ? platformData.parserData[0] : null;
  const [parser, setParser] = useState<ParserData | null>(initialStateParserData);
  const [defaultValue, setDefaultValue] = useState(
    { ...initialStateParserData, label: initialStateParserData?.parser || '', value: initialStateParserData?.parser || '' },
  );

  useEffect(() => {
    setParser(initialStateParserData);
    setDefaultValue({ ...initialStateParserData, label: initialStateParserData?.parser || '', value: initialStateParserData?.parser || '' });
  }, [platformData]);

  const dataInfoSecondColumn = [
    {
      label: '_platform.infoPlatform.numberOfCourses',
      value: parser?.allCourses,
    },
    {
      label: '_platform.infoPlatform.numberOfCurrencies',
      value: parser?.currency,
    },
    {
      label: '_platform.infoPlatform.numberOfIncomingCourses',
      value: parser?.incomingCourses,
    },
    {
      label: '_platform.infoPlatform.numberOfOutgoingCourses',
      value: parser?.outgoingCourses,
    },
    {
      label: '_platform.infoPlatform.numberOfInternalCourses',
      value: parser?.internalCourses,
    },
  ];


  function onClockStartParserHandler() {
    //
  }

  function getReactNodeRowWithActiveCourses() {
    const activeCourses = parser?.loops || '';
    const courses =activeCourses.split('/');

    return(
      <>
        <Styled.InfoLabelContainer>
          <Styled.InfoLabel>{t('_platform.infoPlatform.parser')}</Styled.InfoLabel>
        </Styled.InfoLabelContainer>

        <Styled.InfoLabelContainer>
          <Styled.InfoValueColor color={lime}>
            {`${courses[0]} `}
          </Styled.InfoValueColor>
          <Styled.InfoValue>
            /
          </Styled.InfoValue>
          <Styled.InfoValueColor color={carminePink}>
            {courses[1]}
          </Styled.InfoValueColor>
        </Styled.InfoLabelContainer>
      </>
    );
  }

  return(
    <Styled.Container>
      <Styled.InfoContainer>
        <Styled.InfoColumn>
          <Styled.InfoLabelContainer>
            <Styled.InfoLabel>{t('_platform.infoPlatform.parser')}</Styled.InfoLabel>
          </Styled.InfoLabelContainer>
          <FieldSelect
            options={platformData.parserData}
            keyToReplaceWithLabel="parser"
            setStateField={setParser}
            // @ts-ignore
            defaultValue={defaultValue}
            width={250}
            placeholder={t('_platform.infoPlatform.choice')}
            paddingY={0}
            isFullObject
            isBorder
            isSearchable
            image={start}
          />

          <InfoRow
            label={t('_platform.infoPlatform.source')}
            value={parser?.link}
            isLink
          />

          <InfoRow
            label={t('_platform.infoPlatform.parserStatus')}
            value={parser?.parserActive === 'True'
              ? t('_platform.infoPlatform.active')
              : t('_platform.infoPlatform.notActive')}
            isActive={parser?.parserActive === 'True'}
            isColor
          />

          <InfoRow
            label={t('_platform.infoPlatform.lastUpdateDate')}
            value={parser?.lastChange}
          />

          <Styled.ButtonContainer>
            <Styled.ButtonWrapper>
              <Button
                width="100%"
                height="100%"
                onClick={onClockStartParserHandler}
                title="Запустить"
                size="regular14"
                isDisabled
              />
            </Styled.ButtonWrapper>
          </Styled.ButtonContainer>
        </Styled.InfoColumn>

        <Styled.InfoColumn>
          {dataInfoSecondColumn.map(({ label, value }, index) => (
            <Fragment key={index}>
              <InfoRow label={t(label)} value={value}/>
            </Fragment>
          ))}
          {getReactNodeRowWithActiveCourses()}
        </Styled.InfoColumn>
      </Styled.InfoContainer>

      <Styled.AdditionalInformation>
        <Styled.Synonyms>
          <FieldInput
            { ...synonyms }
            backgroundColor={slateGray}
            placeholder={t('_platform.enterSynonymsSeparatedByCommas')}
            title={t('_platform.synonyms')}
            height={32}
          />

          <Styled.SynonymsList>
            {!!platformData.platformSynonyms.length && platformData.platformSynonyms.map((synonym, index) => (
              <Styled.SynonymsItem key={index}>
                <Styled.SynonymsText>
                  {synonym}
                </Styled.SynonymsText>
              </Styled.SynonymsItem>
            ))}

            {!platformData.platformSynonyms.length && (
              <Styled.SynonymsItem>
                <Styled.SynonymsText>
                  {t('_platform.nonSynonyms')}
                </Styled.SynonymsText>
              </Styled.SynonymsItem>
            )}
          </Styled.SynonymsList>
        </Styled.Synonyms>
      </Styled.AdditionalInformation>
    </Styled.Container>
  );
}