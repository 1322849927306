import React from 'react';

import { PropagateLoader } from 'react-spinners';
import { useTheme } from 'styled-components';

import * as Styled from './Loading.styled';

export function Loading() {
  const theme = useTheme();

  return (
    <Styled.Container>
      <PropagateLoader
        size="30px"
        color={theme.colors.greenCobalt}
        speedMultiplier={1}
      />
    </Styled.Container>
  );
}