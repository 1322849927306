import Slider from '@mui/material/Slider';
import * as Styled from './SliderMaterialUi.styled';
import { SliderProps } from './SliderMaterialUi.types';

export function SliderMaterialUi({
  title,
  defaultValue,
  value,
  onChange,
  min,
  max,
  step,
  marginTop = 4,
}: SliderProps) {
  return(
    <Styled.Container marginTop={marginTop}>
      <Styled.Title>
        {title}
      </Styled.Title>

      <Styled.SliderContainer>
        <Slider
          aria-label="Temperature range"
          defaultValue={defaultValue}
          valueLabelDisplay="auto"
          value={value}
          onChange={onChange}
          step={step}
          min={min}
          max={max}
          size="small"
          marks
        />
      </Styled.SliderContainer>
    </Styled.Container>
  );
}