import * as Styled from './StoppingProcesses.styled';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { getStoppingProcesses } from '../../../../redux/reducers/tabels/async';

export function StoppingProcesses() {
  const dispatch = useAppDispatch();
  const {
    authKey = '',
  } = useAppSelector((state) => state.auth);

  function onClickStoppingProcessesHandler() {
    dispatch(getStoppingProcesses({ authKey }));
  }

  return (
    <Styled.StoppingProcesses>
      <Styled.StoppingProcessesContainer>
        <Styled.StoppingProcessesContainerTextTitle>
          Информация по запуску алгоритма:
        </Styled.StoppingProcessesContainerTextTitle>
        <Styled.StoppingProcessesContainerText>
          Остановка процессов необходима для того чтобы прервать процесс, 
          который работает слишком долго и задерживает работу других процессов в БД
        </Styled.StoppingProcessesContainerText>
        <Styled.StoppingProcessesContainerText>
          Необходимо использовать, когда зависает БД и перестает реагировать на запросы к ней
        </Styled.StoppingProcessesContainerText>
      </Styled.StoppingProcessesContainer>
      <Styled.StoppingProcessesButton
        onClick={onClickStoppingProcessesHandler}
      >
        Остановить запросы к базе данных
      </Styled.StoppingProcessesButton>
    </Styled.StoppingProcesses>
  );
}