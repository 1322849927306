import React, {
  useEffect,
} from 'react';
  
  
import { useTranslation } from 'react-i18next';
import styles from './SubscribeModal.module.scss';
import { SubscribeModalProps } from './SubscribeModal.types';
import iconButton from '../../../../assets/icons/telegram_icon_130816.svg';
import { ImageContainer } from '../../../../components/ImageContainer';

export function SubscribeModal({
  isActiveSubscribeModal,
  onExitModalHandler,
} :SubscribeModalProps) {

  const { t } = useTranslation();
  const blockedScroll = () => {
    const getHTML = document.querySelector('html');
  
    if (isActiveSubscribeModal && getHTML !== null) {
      getHTML.style.overflowY = 'hidden';
    } else if (getHTML !== null) {
      getHTML.style.overflowY = '';
    }
  };
  
  useEffect(() => {
    blockedScroll();
  }, [isActiveSubscribeModal]);
  
  return (
    <>
      <div
        className={isActiveSubscribeModal
          ? styles.course_modal__substrate
          : styles.course_modal}
        onClick={onExitModalHandler}
      />
      <div className={isActiveSubscribeModal
        ? styles.course_modal && styles.course_modal_active_modal_hidden 
        : styles.course_modal}
      >
        <h3 className={styles.course_modal_title}>
            Перейти на подписку Premium
        </h3>
        <div className={styles.course_modal_paragraph}>
          {t('_navMenu.subscriptionDiscount')}
        </div>
        <div className={styles.course_modal_paragraph}>
          {t('_navMenu.subscribeRules')}
        </div>
        <div className={styles.course_modal_paragraph}>
          {t('_navMenu.subscribeLink')}
        </div>
        <div className={styles.course_modal_container}>
          <a
            type="submit"
            className={styles.scripts_btnStart}
            onClick={onExitModalHandler}
            href='https://t.me/ARBITORING'
          >
            <div className={styles.course_modal_container}>
              <ImageContainer width={100} height={30} image={iconButton} fill={'#FFF'}/>
            </div>
          </a>
        </div>
      </div>
    </>
  );
}
  