import styled from 'styled-components';
import { Box, Text } from '../../../../components/styledComponents';

type ContainerProps = {
  width: number | string,
  height: number | string,
  backgroundColor: string,
  hint: string,
}

export const Container = styled(Box).attrs<ContainerProps>(({
  width,
  height,
  backgroundColor,
}) => ({
  width,
  height: height ? height : 'auto',
  backgroundColor: backgroundColor ? backgroundColor : 'blackBlue',
  px: 2,
  py: 3,
}))<ContainerProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: ${({ hint }) => hint ? 'pointer' : 'auto'};
`;

type InformationWindowTitleProps = {
  size: string,
  color?: string,
}

export const InformationWindowTitle = styled(Text).attrs<InformationWindowTitleProps>(({
  size,
  color,
}) => ({
  variant: size,
  color: color ? color : 'snowWhite',
}))<InformationWindowTitleProps>``;

type InformationWindowLabelProps = {
  size: string,
  marginTopLabel: number,
  color?: string,
}

export const InformationWindowLabel = styled(Text).attrs<InformationWindowLabelProps>(({
  size,
  marginTopLabel,
  color,
}) => ({
  variant: size,
  color: color ? color : 'snowWhite',
  mt: marginTopLabel,
}))<InformationWindowLabelProps>``;