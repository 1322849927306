import styled from 'styled-components';
import { Box } from '../../components/styledComponents';

export const Container = styled(Box)`
  .mySwiper {
    overflow-x: hidden;
  }

  .swiper-wrapper {
    display: flex;
  }
`;