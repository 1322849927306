import { useTheme } from 'styled-components';
import * as Styled from './ModalVolume.styled';
import { ModalVolumeProps } from './ModalVolume.types';
import { FieldInput } from '../../../../ui/FieldInput';

export function ModalVolume({
  controllerFailMinQtyCase,
  controllerFixVolume,
  controllerMaxVolume,
}: ModalVolumeProps) {
  const {
    colors: {
      arsenic,
    },
  } = useTheme();

  return(
    <Styled.Container>
      <FieldInput
        title="Случаев с ошибкой"
        { ...controllerFailMinQtyCase }
        backgroundColor={arsenic}
        isDisabled
      />
      <FieldInput
        title="Фикс. Объем"
        { ...controllerFixVolume }
      />
      <FieldInput
        title="Макс. объем"
        { ...controllerMaxVolume }
      />
    </Styled.Container>
  );
}