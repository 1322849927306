import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { Features } from './components/Features';
import { initialDataCourses, tableMetaData } from './constants';
import * as Styled from './Courses.styled';
import { AdminCourseModal } from '../../components/AdminCourseModal';
import { MetaTags } from '../../components/MetaTags';
import { Paginate } from '../../components/Paginate';
import { Table } from '../../components/Table';
import { DataBody } from '../../components/Table/Table.types';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { setNumberPageCoursesAction } from '../../redux/reducers/courses';
import { getCourses } from '../../redux/reducers/courses/async';
import { CourseItem } from '../../types/CourseItem';
import { Skeleton } from '../../ui/Skeleton';

export function Courses() {
  const {
    colors: {
      blackBlue,
      graniteGray,
      oliveGreen,
    },
  } = useTheme();
  const dispatch = useAppDispatch();

  const {
    authKey = '',
  } = useAppSelector((state) => state.auth);
  const {
    loadingState,
    activePageCourses,
    coursesDataRequest = initialDataCourses,
  } = useAppSelector((state) => state.courses);
  const {
    loadingState: loadingStateFilters,
  } = useAppSelector((state) => state.filters);

  const [isActiveModal, setIsActiveModal] = useState(false);
  const [selectCourse, setSelectCourse] = useState<CourseItem>();

  useEffect(() => {
    dispatch(getCourses({
      authKey,
      numberPage: activePageCourses,
    }));
  }, [activePageCourses]);

  function onClickSetActiveAndDataModalHandler(rowData?: DataBody) {
    setSelectCourse(rowData as CourseItem);
    setIsActiveModal((prev) => !prev);
  }

  return(
    <Styled.Container>
      <MetaTags
        title="Arbitoring - Courses"
        description="Arbitoring - the best currency arbitrage in the world"
        name="Arbitoring"
        type="article"
      />

      <AdminCourseModal
        activeCourseModal={isActiveModal}
        setActiveCourseModal={setIsActiveModal}
        selectCourse={selectCourse}
      />

      <Features/>

      {loadingState === 'loading' || loadingStateFilters === 'loading'
        ? (
          <Skeleton
            width="100%"
            height="100%"
            background={blackBlue}
            borderRadius={4}
          />
        )
        : (
          <Table
            width="100%"
            height="100%"
            dataHeaders={tableMetaData}
            dataBody={coursesDataRequest.data}
            colorBodyColumnHover={graniteGray}
            colorBodyColumn={blackBlue}
            colorBodyColumnEven={oliveGreen}
            colorSubstrate={blackBlue}
            isBorderHeader
            sizeHeaderCell="regular14"
            sizeBodyCell="regular14"
            setActionByRow={onClickSetActiveAndDataModalHandler}
          />
        )}

      <Styled.PaginateContainer>
        <Paginate
          totalPages={coursesDataRequest.total_pages}
          setActivePageAction={setNumberPageCoursesAction}
          loadingState={loadingState || loadingStateFilters}
          forcePage={activePageCourses}
          width="50%"
          height={45}
          pageRangeDisplayed={4}
        />
      </Styled.PaginateContainer>
    </Styled.Container>
  );
}