import { AnyArray, AnyObject } from './СonvertSnakeToCamel.types';
import { Masks } from '../../constants/constants';

function toCamelCase(str: string): string {
  return str.replace(Masks.snakeCase, matches => matches[1].toUpperCase());
}

/**
 * Преобразует ключи объекта из snake_case в camelCase.
 * Использует generics, чтобы возвращать правильный тип на выходе.
 *
 * ПРИМЕЧАНИЕ: относительно приведения типов `as unknown as T`:
 * Эта функция предназначена для использования при получении данных с сервера,
 * где структура входных данных будет в соответствии с ожидаемой структурой на выходе.
 * Таким образом, в контексте этой функции приведение типов считается безопасным.
 * ОДНАКО при использовании функции в другом контексте следует быть осторожным и
 * убедиться, что входные данные соответствуют ожидаемой структуре выходных данных.
 */
export function convertSnakeToCamel<T>(input: AnyObject | AnyArray): T {
  if (typeof input !== 'object' || input === null) {
    return input as unknown as T;
  }

  if (Array.isArray(input)) {
    return input.map(item => convertSnakeToCamel(item)) as unknown as T;
  }

  return Object.entries(input).reduce((acc: AnyObject, [key, value]) => {
    acc[toCamelCase(key)] = typeof value === 'object' ? convertSnakeToCamel(value) : value;
    return acc;
  }, {}) as T;
}