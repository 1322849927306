import styled from 'styled-components';
import { Box, Text } from '../../components/styledComponents';

export const Substrate = styled(Box).attrs({
  width: '100vw',
  height: '100vh',
  bg: 'rgba(19, 20, 26, 0.3)',
})`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`;

export const Container = styled(Box).attrs({
  py: 4,
  px: 3,
  bg: 'slateGray',
})`
  border: 1px solid ${({ theme }) => theme.colors.snowWhite};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 8px;
  border-radius: 4px;
  max-height: 80vh;
`;

export const TitleContainer = styled(Text).attrs({
  color: 'snowWhite',
  variant: 'regular18',
})``;

export const ContentContainer = styled(Box).attrs({
  height: '100%',
})`
  overflow-y: auto;
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
`;
