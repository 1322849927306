import styled from 'styled-components';
import { Box, Text } from '../../../../../styledComponents';

export const Container = styled(Box).attrs({
  width: '100%',
  height: '100%',
  mr: 1,
})`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

type CellTitleProps = {
  size: string,
  color: string,
}

export const CellTitle = styled(Text).attrs<CellTitleProps>(({
  size,
  color,
}) => ({
  color,
  variant: size,
}))<CellTitleProps>`
  user-select: none;
`;

export const CellSubTitleContainer = styled(Box).attrs({
  mt: 1,
})`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 4px;
`;

type CellSubTitleWrapperProps = {
  background: string,
}

export const CellSubTitleWrapper = styled(Box).attrs<CellSubTitleWrapperProps>(({ background }) => ({
  bg: background,
  py: 1,
  px: 1,
  minWidth: 70,
  width: '100%',
  height: '100%',
}))<CellSubTitleWrapperProps>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

type CellSubTitle = {
  size: string,
  color: string,
}

export const CellSubTitle = styled(Text).attrs<CellSubTitle>(({
  size,
  color,
}) => ({
  color,
  variant: size,
}))<CellSubTitle>`
  user-select: none;
`;