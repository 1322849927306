import styled from 'styled-components';
import { Box, Text } from '../../../../../../components/styledComponents';

type AllFiltersChainsProps = {
  isActive: boolean;
}
export const AllFilters = styled(Box).attrs({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 100,
  width: '100vw',
  height: '100vh',
})<AllFiltersChainsProps>`
  visibility: ${({ isActive }) => isActive ? 'visible' : 'hidden'};
  opacity: ${({ isActive }) => isActive ? 1 : 0};
`;

export const ModalSubstrate = styled(Box).attrs({
  width: '100%',
  height: '100%',
  bg: 'oliveGreen',
})`
  opacity: 0.3;
`;

export const AllFiltersWrapper = styled(Box).attrs({
  position: 'absolute',
  top: '50%',
  left: '50%',
  zIndex: 101,
  width: 750,
  height: '95%',
  bg: 'slateGray',
  py: 6,
  px: 8,
})`
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  border: 1px ${({ theme }) => theme.colors.snowWhite} solid;

  @media (max-width: 768px) {
    width: 98%;
  }

  @media (max-width: 510px) {
    padding: 12px 12px;
  }
`;

export const AllFiltersHeader = styled(Box).attrs({
  display: 'flex',
  pb: 6,
})`
  justify-content: space-between;
  border-bottom: 1px ${({ theme }) => theme.colors.snowWhite} solid;

  @media (max-width: 510px) {
    padding-bottom: 4px;
  }
`;

export const AllFiltersTitle = styled(Text).attrs({
  variant: 'medium24',
  color: 'snowWhite',
})``;

export const AllFiltersBody = styled(Box).attrs({
  height: 'calc(100% - 118px)',
  pt: 5,
  px: 1,
})`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(min-content, max-content);
  gap: 28px 12px;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;

  @media (max-width: 510px) {
    height: calc(100% - 116px);
    grid-template-columns: 1fr;
    row-gap: 10px;
    padding-top: 0;
  }
`;

export const AllFiltersFooter = styled(Box).attrs({
  mt: 3,
})`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 374px) {
    flex-direction: column;
  }
`;

export const AllFiltersReset = styled(Text).attrs({
  variant: 'regular14',
  color: 'lightGray',
})`
  border-bottom: 1px ${({ theme }) => theme.colors.lightGray} solid;
  cursor: pointer;
  user-select: none;
  transition: .25s all;

  &:active {
    border-bottom: 1px ${({ theme }) => theme.colors.snowWhite} solid;
    color: ${({ theme }) => theme.colors.snowWhite};
    transition: .25s all;
  }

  @media (max-width: 374px) {
    margin-bottom: 16px;
  }
`;

export const AllFiltersButtonWrapper = styled(Box).attrs({
  py: 2,
  px: 3,
})`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px ${({ theme }) => theme.colors.snowWhite} solid;
  border-radius: 4px;
  cursor: pointer;
`;

export const AllFiltersImage = styled('img').attrs({
  width: 17,
  height: 17,
})`
  user-select: none;
`;

export const AllFiltersButtonText = styled(Text).attrs({
  variant: 'regular14',
  color: 'snowWhite',
  ml: 3,
})`
  user-select: none;
`;
