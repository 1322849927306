import styled from 'styled-components';
import { Box } from '../../components/styledComponents';

export const AdminPanel = styled(Box).attrs({
  width: '100vw',
  height: 'calc(100vh - 78px)',
})`
    box-sizing: border-box;
    display: flex;

    nav {
      box-sizing: border-box;
      height: 100%;
    }
  `;

export const AdminPanelRight = styled(Box).attrs({
  width: '100%',
  height: 'calc(100vh - 78px)',
})`
    position: relative;
`;

export const AdminPanelLoading = styled(Box).attrs({
  top: '50%',
  left: '50%',
})`
    position: absolute;
    transform: translate(-50%, -50%);
`;
  
