import styled from 'styled-components';
import { Box, Text } from '../../../../../../components/styledComponents';

export const ServiceList = styled(Box).attrs({
  as: 'ul',
  pl: 0,
})`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

export const ServiceItem = styled(Box).attrs({
  as: 'li',
})`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

export const ServiceText = styled(Text).attrs({
  variant: 'regular18',
  color: 'blackBlue',
  as: 'h3',
  my: 0,
})``;