import * as Styled from './Close.styled';
import { CloseProps } from './Close.types';
import close from '../../assets/icons/admin-close-filter.svg';

export function Close({
  width,
  height,
  onClick,
}: CloseProps) {
  return (
    <Styled.CloseWrapper
      width={width}
      height={height}
      onClick={onClick}
    >
      <Styled.CloseImage src={close}/>
    </Styled.CloseWrapper>
  );
}