import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Navigation } from './components/Navigation';
import { SubscribeModal } from './components/SubscribeModal';
import { NAVIGATION_ADMIN_USER, NAVIGATION_PREMIUM_USER } from './constants';
import * as Styled from './Navmenu.styled';
import exit from '../../assets/icons/exit-white.svg';
import logo from '../../assets/icons/logo.svg';
import { Button } from '../../components/Button';
import { Close } from '../../components/Close';
import { ImageContainer } from '../../components/ImageContainer';
import { PATH } from '../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { logout } from '../../redux/reducers/auth';
import { getStateFilters } from '../../redux/reducers/filters/async';
import { UserStatus } from '../../types/CourseItem';
import { ButtonContainer } from '../ButtonContainer';


function Navmenu() {
  const dispatch = useAppDispatch();
  const {
    userStatus,
    isActiveScreen,
  } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    colors: {
      snowWhite,
    },
  } = useTheme();

  const [activeSelect, setActiveSelect] = useState<boolean>(false);
  const [isActiveHamburger, setIsActiveHamburger] = useState(false);
  const [isActiveSubscribeModal, setIsActiveSubscribeModal] = useState<boolean>(false);

  function onExitModalHandler() {
    setIsActiveSubscribeModal(false);
  }

  function onClickOpenSubscribeModal() {
    if (userStatus !== UserStatus.premiumUser && userStatus !== UserStatus.adminUser) {
      setIsActiveSubscribeModal((prev) => !prev);
    }
  }

  function onClickSetActiveSelectHandler() {
    setActiveSelect((prev) => !prev);
  }

  function onClickSetLogOutHandler() {
    dispatch(logout());
    navigate(PATH.signIn);
  }

  function onClickSetActionHamburgerHandler() {
    setIsActiveHamburger((prev) => !prev);
  }

  function getStatusUserText() {
    if (userStatus === UserStatus.premiumUser) {
      return t('_navMenu.ratePremium');
    }
    if (userStatus === UserStatus.adminUser) {
      return t(isActiveScreen ? '_navMenu.ratePremium' : '_navMenu.rateAdmin');
    }

    return t('_navMenu.rateBasic');
  }

  return (
    <Styled.Header>
      <Styled.Container>
        <Styled.MainPageLink to="/chains">
          <ImageContainer
            width={200}
            height={60}
            image={logo}
            isBaseColor
          />
        </Styled.MainPageLink>

        {userStatus === UserStatus.adminUser && (
          <Navigation navigationListUser={NAVIGATION_ADMIN_USER}/>
        )}

        {userStatus === UserStatus.premiumUser && (
          <Navigation navigationListUser={NAVIGATION_PREMIUM_USER}/>
        )}

        <Styled.HamburgerWrapper onClick={onClickSetActionHamburgerHandler}>
          <Styled.HamburgerLayer
            isActive={isActiveHamburger}
            translateX={-30}
          />
          <Styled.HamburgerLayer
            isActive={isActiveHamburger}
            isLong
          />
          <Styled.HamburgerLayer
            isActive={isActiveHamburger}
            translateX={20}
          />
        </Styled.HamburgerWrapper>
        <Styled.OptionsWrapper>
          <Styled.OptionsSubstrate
            isActive={isActiveHamburger}
            onClick={onClickSetActionHamburgerHandler}
          />
          <Styled.OptionsContainer isActive={isActiveHamburger}>
            <Styled.HamburgerExit isActive={isActiveHamburger}>
              <Close
                width={30}
                height={30}
                onClick={onClickSetActionHamburgerHandler}
              />
              <Styled.OptionsRate
                isViewPhone
                isActive={isActiveHamburger}
                onClick={onClickOpenSubscribeModal}
              >
                {getStatusUserText()}
              </Styled.OptionsRate>
            </Styled.HamburgerExit>
            <Styled.OptionsRate
              onClick={onClickOpenSubscribeModal}
            >
              {getStatusUserText()}
            </Styled.OptionsRate>

            <ButtonContainer marginLeft={4}>
              <Button
                width={120}
                height={24}
                onClick={onClickOpenSubscribeModal}
                title={t('_navMenu.subscribe')}
              />
            </ButtonContainer>

            <Styled.OptionsProfile
              onClick={onClickSetActiveSelectHandler}
            >
              <Styled.OptionsText>{t('_navMenu.profile')}</Styled.OptionsText>
              <Styled.OptionsSelect isActive={activeSelect}/>
            </Styled.OptionsProfile>
            
            <Styled.OptionsLogout onClick={onClickSetLogOutHandler}>
              <ButtonContainer marginLeft={2}>
                <ImageContainer
                  width={25}
                  height={25}
                  image={exit}
                  fill={snowWhite}
                />
              </ButtonContainer>
              <Styled.OptionsText>{t('_navMenu.exit')}</Styled.OptionsText>
            </Styled.OptionsLogout>
          </Styled.OptionsContainer>
        </Styled.OptionsWrapper>
        <SubscribeModal
          isActiveSubscribeModal={isActiveSubscribeModal}
          onExitModalHandler={onExitModalHandler}
        />
      </Styled.Container>
    </Styled.Header>
  );
}

export default Navmenu;
