import { Tooltip, Zoom } from '@mui/material';

import { TooltipPlacement, TooltipProps } from './TooltipWithElement.types';


export function TooltipWithElement({
  hint,
  element,
  timeout = 600,
  enterDelay = 650,
  leaveDelay = 300,
  placement = TooltipPlacement.top,
  isArrow = true,
}: TooltipProps) {
  return (
    <Tooltip
      title={hint}
      arrow={isArrow}
      TransitionComponent={Zoom}
      TransitionProps={{ timeout: timeout }}
      enterDelay={enterDelay}
      leaveDelay={leaveDelay}
      placement={placement}
    >
      <div style={{ width: '100%' }}>
        {element}
      </div>
    </Tooltip>
  );
}