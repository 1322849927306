import { ActiveTimeGraph, GraphTime } from './LoopInfo.types';

export const graphTime: GraphTime[] = [
  {
    id: ActiveTimeGraph.Second,
    title: '3c',
    isSelected: false,
    isDisabled: true,
  },
  {
    id: ActiveTimeGraph.Minute,
    title: 'Мин',
    isSelected: true,
    isDisabled: false,
  },
  {
    id: ActiveTimeGraph.Hour,
    title: 'Час',
    isSelected: false,
    isDisabled: false,
  },
  {
    id: ActiveTimeGraph.Month,
    title: 'День',
    isSelected: false,
    isDisabled: false,
  },
];

export const isoDatetimeRegular = /^(?<year>[0-9]{2,4})-(?<month>[0-9]{2})-(?<day>[0-9]{2})\s(?<hours>[0-9]{2}):(?<minutes>[0-9]{2}):(?<seconds>[0-9]{2})\.(?<microseconds>[0-9]{6})\s*/;
