import {
  Keyboard,
  Pagination,
  Navigation,
  FreeMode,
  Autoplay,
} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import * as Styled from './SlidesPerView.styled';
import { SlidesPerViewProps } from './SlidesPerView.types';

export function SlidesPerView({
  slides,
}: SlidesPerViewProps) {
  return(
    <Styled.Container>
      <Swiper
        slidesPerView={4}
        spaceBetween={30}
        className="mySwiper"
        freeMode={true}
        keyboard={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Keyboard, Pagination, Navigation, FreeMode, Autoplay]}
      >
        {slides && slides.map((slide, index) => (
          <SwiperSlide key={`${index}zz`}>
            {slide}
          </SwiperSlide>
        ))}
      </Swiper>
    </Styled.Container>
  );
}