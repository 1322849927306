import * as Styled from './AlgorithmVersion.styled';
import { ALGORITHM_VERSION_DATA } from './constants';
import { Button } from '../../../../../../components/Button';
import { ButtonType } from '../../../../../../components/Button/Button.types';
import { TooltipWithElement } from '../../../../../../components/TooltipWithElement';
import { TooltipPlacement } from '../../../../../../components/TooltipWithElement/TooltipWithElement.types';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/storeHooks';
import { setActiveAlgorithmVersionAction } from '../../../../../../redux/reducers/algorithms';
import { ButtonContainer } from '../../../../../../ui/ButtonContainer';

export function AlgorithmVersion() {
  const dispatch = useAppDispatch();
  const {
    activeAlgorithmVersion,
  } = useAppSelector((state) => state.algorithms);

  // Метод для выбора версии алгоритма, которая будет запущена
  function onClickSetActiveAlgorithmVersionHandler(id: number) {
    if(activeAlgorithmVersion !== id) {
      dispatch(setActiveAlgorithmVersionAction(id));
    }
  }

  return(
    <Styled.Container>
      {ALGORITHM_VERSION_DATA.map(({ id, title, marginBottom, hint }) => (
        <ButtonContainer
          key={id}
          marginBottom={marginBottom}
        >
          <TooltipWithElement
            hint={hint}
            placement={TooltipPlacement.left}
            element={(
              <Button
                width={200}
                height={60}
                onClick={() => onClickSetActiveAlgorithmVersionHandler(id)}
                title={title}
                size='regular16'
                type={ButtonType.TypeWhite}
                isSelected={activeAlgorithmVersion === id}
              />
            )}
          />
        </ButtonContainer>
      ))}
    </Styled.Container>
  );
}