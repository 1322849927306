import possibilities1 from '../../../../assets/img/possibilities-1.jpg';
import possibilities2 from '../../../../assets/img/possibilities-2.jpg';
import possibilities3 from '../../../../assets/img/possibilities-3.jpg';
import possibilities4 from '../../../../assets/img/possibilities-4.jpg';

export const SLIDES = [
  possibilities1,
  possibilities2,
  possibilities3,
  possibilities4,
];