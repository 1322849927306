import { PATH } from '../../../../constants/constants';

export const NAVIGATION_LIST = [
  {
    value: '1q',
    label: '_header.navigationList.li1',
    link: '/',
  },
  {
    value: '2q',
    label: '_header.navigationList.li2',
    link: '/',
  },
  {
    value: '3q',
    label: '_header.navigationList.li3',
    link: PATH.signUp,
  },
];