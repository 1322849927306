import { useTheme } from 'styled-components';

import * as Styled from './TableHeaderCell.styled';

import { TableHeaderCellProps } from './TableHeaderCell.types';

import sortMountDown from '../../../../assets/icons/sortMountDown.svg';
import sortMountUp from '../../../../assets/icons/sortMountUp.svg';

import { ImageContainer } from '../../../ImageContainer';
import { TooltipWithElement } from '../../../TooltipWithElement';

export function TableHeaderCell({
  stringOrElement,
  isActiveSortingUp,
  backgroundHeaderCell,
  isBorder,
  colSpan = 1,
  isSorting = false,
  imageWidth = 20,
  imageHeight = 20,
  hint = '',
  tooltipElement,
  size = 'light12',
  textColor = '',
  onClick,
}: TableHeaderCellProps) {
  const {
    colors: {
      snowWhite,
      greenCobalt,
    },
  } = useTheme();

  return (
    <Styled.CellContainer
      background={backgroundHeaderCell}
      colSpan={colSpan}
      isActiveSortingUp={isActiveSortingUp}
      onClick={onClick}
      isBorder={isBorder}
    >
      <Styled.CellWrapper>
        {typeof stringOrElement === 'string'
          ? (
            <Styled.CellElementText
              size={size}
              color={textColor ? textColor : snowWhite}
            >
              {stringOrElement}
            </Styled.CellElementText>
          )
          : stringOrElement}

        {isSorting && (
          <Styled.CellImageSorting>
            <ImageContainer
              width={imageWidth}
              height={imageHeight}
              image={isActiveSortingUp ? sortMountUp : sortMountDown}
              fill={isActiveSortingUp !== undefined ? greenCobalt : snowWhite}
            />
          </Styled.CellImageSorting>
        )}

      </Styled.CellWrapper>


      {hint && (
        <Styled.HintWrapper>
          <TooltipWithElement
            hint={hint}
            element={
              tooltipElement
                ? tooltipElement
                : (
                  <Styled.HintContainer>
                    <Styled.HintSymbol>i</Styled.HintSymbol>
                  </Styled.HintContainer>
                )
            }
          />
        </Styled.HintWrapper>
      )}
    </Styled.CellContainer>
  );
}