import React, { ChangeEvent, useMemo } from 'react';
import * as Styled from './UpdateMetrics.styled';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { updateLoopIdAction } from '../../../../redux/reducers/createData';
import { postUpdateMetrics } from '../../../../redux/reducers/createData/async';

export function UpdateMetrics() {
  const dispatch = useAppDispatch();
  const {
    updateLoopId,
    updateMetricsStatus = 0,
    updateMetricsMessage = '',
  } = useAppSelector((state) => state.createData);
  const {
    authKey = '',
  } = useAppSelector((state) => state.auth);

  function onChangeSetLoopIdHandler(e: ChangeEvent<HTMLInputElement>) {
    dispatch(updateLoopIdAction(e.target.value));
  }

  function onClickUpdateMetricsRequestHandel() {
    dispatch(postUpdateMetrics({ authKey }));
  }

  const statusMetrics = useMemo(() => {
    return updateMetricsMessage
      ? updateMetricsMessage
      : 'Состояние неизвестно';
  }, [updateMetricsMessage]);

  const isDisabledMetric = useMemo(() => {
    return updateMetricsStatus === 200 && updateLoopId;
  }, [updateMetricsStatus, updateLoopId]);

  const isDisabledMetrics = useMemo(() => {
    return updateMetricsStatus === 200;
  }, [updateMetricsStatus]);

  return (
    <Styled.UpdateMetrics>
      <Styled.Container>
        <Styled.StatusContainer>
          <Styled.StatusTitle>Информация по запуску алгоритма:</Styled.StatusTitle>
          <Styled.StatusText>
            Обновление метрик необходимо для того, чтобы задать начальное состояние метрик для цепочек
          </Styled.StatusText>
          <Styled.StatusText>
            Необходимо использовать, когда в цепочках сбились метрики
          </Styled.StatusText>
          <Styled.StatusText>Статус: {statusMetrics}</Styled.StatusText>
        </Styled.StatusContainer>
        <Styled.MetricIdInput
          onChange={onChangeSetLoopIdHandler}
          value={updateLoopId}
          placeholder="1"
        />
        <Styled.ButtonContainer>
          <Styled.ButtonUpdateMetrics
            isDisabled={!isDisabledMetrics}
            onClick={onClickUpdateMetricsRequestHandel}
          >
            Обновить метрики всех цепочек
          </Styled.ButtonUpdateMetrics>
          <Styled.ButtonUpdateMetrics
            isDisabled={!isDisabledMetric}
            onClick={onClickUpdateMetricsRequestHandel}
          >
            Обновить метрики одной цепочки
          </Styled.ButtonUpdateMetrics>
        </Styled.ButtonContainer>
      </Styled.Container>
    </Styled.UpdateMetrics>
  );
}