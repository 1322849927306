import { Services } from './components/Services';
import { Title } from './components/Title';
import { SLIDES } from './constants';
import * as Styled from './Possibilities.styled';
import { Container } from '../../../../ui/Container';
import { SliderSwipperOneSlide } from '../../../../ui/SliderSwipperOneSlide';

export function Possibilities() {
  return(
    <Styled.Wrapper>
      <Container>
        <Styled.Container>
          <Styled.ServicesContainer>
            <Title/>
            <Services/>
          </Styled.ServicesContainer>

          <div>
            <SliderSwipperOneSlide
              slides={
                SLIDES.map((slider, index) => (
                  <Styled.SliderImageContainer>
                    <Styled.SliderImage
                      key={`${index}t`}
                      src={slider}
                      alt=""
                    />
                  </Styled.SliderImageContainer>
                ))
              }
            />
          </div>
        </Styled.Container>
      </Container>
    </Styled.Wrapper>
  );
}