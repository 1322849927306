import React from 'react';

import { AllFilterChains } from './components/AllFilterChains';
import { TableFilters } from './components/TableFilters';
import { TableState } from './components/TableState';
import { TableTypeTimeBtn } from './components/TableTypeTimeBtn';
import styles from './TableFeatures.module.scss';

export function TableFeatures() {
  return (
    <section className={styles.chains_selection}>
      <AllFilterChains />

      <TableState />

      <div className={styles.chains_wrapper}>
        <TableTypeTimeBtn />

        <TableFilters />
      </div>
    </section>
  );
}
