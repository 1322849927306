import ReactPaginate from 'react-paginate';
import { useTheme } from 'styled-components';
import * as Styled from './Paginate.styled';
import { PaginationMainProps } from './Paginate.types';
import arrow from '../../assets/icons/paginationArrow.svg';
import { useAppDispatch } from '../../hooks/storeHooks';
import { Skeleton } from '../../ui/Skeleton';
import { ImageContainer } from '../ImageContainer';

export function Paginate({
  totalPages,
  loadingState,
  setActivePageAction,
  forcePage,
  width = '100%',
  height = 'auto',
  pageRangeDisplayed = 3,
}: PaginationMainProps) {
  const dispatch = useAppDispatch();
  const {
    colors: {
      snowWhite,
      blackBlue,
    },
  } = useTheme();

  const handlePageClick = (event: { selected: number }) => {
    if (loadingState !== 'loading') {
      dispatch(setActivePageAction(event.selected + 1));
    }
  };

  return (
    <Styled.PaginateContainer width={width}>
      {loadingState === 'loading'
        ? (
          <Skeleton
            width="100%"
            height={height}
            background={blackBlue}
            borderRadius={4}
            marginTop={3}
          />
        )
        : (
          <>
            {totalPages > 1 && (
              <ReactPaginate
                onPageChange={handlePageClick}
                pageRangeDisplayed={pageRangeDisplayed}
                pageCount={totalPages}
                containerClassName="container"
                pageClassName="paginate_item"
                pageLinkClassName="paginate_item__link"
                activeClassName="paginate_item__active"
                breakClassName="paginate_item__break"
                forcePage={forcePage - 1}
                breakLabel="..."
                previousLabel={
                  <Styled.PaginateImage totalPages={totalPages}>
                    <ImageContainer
                      width={14}
                      height={14}
                      image={arrow}
                      fill={snowWhite}
                      marginRight={2}
                    />
                  </Styled.PaginateImage>
                }
                nextLabel={
                  <Styled.PaginateImage totalPages={totalPages}>
                    <ImageContainer
                      width={14}
                      height={14}
                      image={arrow}
                      fill={snowWhite}
                      rotate={180}
                      marginLeft={2}
                    />
                  </Styled.PaginateImage>
                }
                renderOnZeroPageCount={null}
                marginPagesDisplayed={4}
              />
            )}
          </>
        )}
    </Styled.PaginateContainer>
  );
}