import { useTheme } from 'styled-components';
import * as Styled from './FilterWithDropDown.styled';
import { FilterWithDropDownProps } from './FilterWithDropDown.types';
import { useAppDispatch } from '../../hooks/storeHooks';
import { DataMetric } from '../../redux/reducers/filters/types';
import { DropDownList } from '../DropDownList';

export function FilterWithDropDown({
  title,
  dataList,
  placeholder,
  selectedItems,
  setStateValueAction,
  deleteItemsAction,
  mainElementsList,
  isDropDownBottom = false,
  isLightBackground = false,
}: FilterWithDropDownProps) {
  const dispatch = useAppDispatch();
  const {
    colors: {
      greenCobalt,
      snowWhite,
      grayBlue,
      lightGray,
    },
  } = useTheme();

  function onClickDeleteItemHandler(item: DataMetric) {
    dispatch(deleteItemsAction(item));
  }

  function onClickSetStateActionHandler(item: DataMetric) {
    dispatch(setStateValueAction(item));
  }

  const elements = new Set(mainElementsList);
  const mainElements = dataList?.filter(({ name }) => elements.has(name));

  return (
    <Styled.Container>
      <Styled.FilterHeader>
        {title && (
          <Styled.FilterTitle>
            {title}:
          </Styled.FilterTitle>
        )}
      </Styled.FilterHeader>
      <Styled.FilterBody isDropDownBottom={isDropDownBottom}>
        <Styled.FilterWrapper>
          <Styled.FilterSubTitle isLightBackground={isLightBackground}>
            Выбранные:
          </Styled.FilterSubTitle>
          <Styled.FilterSelected>
            {selectedItems.map((item) => (
              <Styled.FilterSelectedItemWrapper
                key={item.id}
                background={isLightBackground ? greenCobalt : snowWhite}
                onClick={() => onClickDeleteItemHandler(item)}
              >
                <Styled.FilterSelectedItem
                  color={isLightBackground ? snowWhite : grayBlue}
                >
                  {item.name}
                </Styled.FilterSelectedItem>
              </Styled.FilterSelectedItemWrapper>
            ))}
          </Styled.FilterSelected>
          {mainElementsList && (
            <>
              <Styled.FilterSubTitle isLightBackground={isLightBackground}>
                Основные в использовании:
              </Styled.FilterSubTitle>
              <Styled.FilterSelected>
                {mainElements.map((item) => (
                  <Styled.FilterSelectedItemWrapper
                    key={item?.id}
                    background={grayBlue}
                    onClick={() => onClickSetStateActionHandler(item)}
                  >
                    <Styled.FilterSelectedItem color={lightGray}>
                      {item?.name}
                    </Styled.FilterSelectedItem>
                  </Styled.FilterSelectedItemWrapper>
                ))}
              </Styled.FilterSelected>
            </>
          )}
        </Styled.FilterWrapper>
        <DropDownList
          dataList={dataList}
          placeholder={placeholder}
          setStateValueAction={setStateValueAction}
        />
      </Styled.FilterBody>
    </Styled.Container>
  );
}