import { SetFormatStringProps } from './SetFormatString.types';
import { setFormatTime } from '../SetFormatTime';
import { setStatus } from '../SetStatus';
import { calculatePercentage } from '../СalculatePercentage';

// Метод, форматирующий строку в нужный формат уже с вычислениями
export function setFormatString({
  value,
  options,
  helpValue = '',
}: SetFormatStringProps): string | [string, string] {
  function checkValidate(value: string | string[]) {
    return value === '-1' || value === 'NaN' || value === 'null' || value === 'undefined' || value ==='None';
  }

  // Н/Д - нет данных
  if (helpValue && (checkValidate(value) || checkValidate(helpValue))) return ['Н/Д', 'Н/Д'];
  if (checkValidate(value)) return 'Н/Д';

  switch (true) {
    case (helpValue && options?.isCalculatePercent && options.isDeviation):
      return [
        setFormatTime(typeof value === 'string' ? value : ''),
        +value <= 0 ? 'Н/Д' : `±${calculatePercentage(+value, +helpValue)}`,
      ];
    case (helpValue && options?.isPercent && options.isDeviation):
      return [`${value}%`, `±${helpValue}%`];
    case options?.isPercent:
      return `${value}%`;
    case options?.isTime:
      return setFormatTime(typeof value === 'string' ? value : '');
    case options?.isStatus:
      return setStatus(+value);
    case options?.isDeviation:
      return `±${value}%`;
    default:
      return `${value}`;
  }
}