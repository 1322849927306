import { MouseEventHandler } from 'react';

export enum ButtonType {
  // Голубая кнопка
  TypeBlue = 'blue',
  // Кнопка с прозрачным цветом и рамкой
  TypeBorder = 'border',
  // Белая кнопка
  TypeWhite = 'white',
  // Красная кнопка
  TypeRed = 'red',
  // Небесная кнопка
  TypeHeavenly = 'heavenly',
  // Активная небесная кнопка
  TypeHeavenlyActive = 'TypeHeavenlyActive',
  TypeBlueTwo = 'blue2',
  TypeWhiteTwo = 'white2',
}

export type ButtonProps = {
  // Ширина кнопки
  width: number | string,
  // Высота кнопки
  height: number | string,
  // Функция срабатывающая при нажатии на кнопку
  onClick: MouseEventHandler<HTMLButtonElement>,
  // Заблокирована ли кнопка
  isDisabled?: boolean,
  // Подпись кнопка
  title?: string,
  // Изображение
  image?: string,
  // Тип кнопки, по умолчанию голубая
  type?: ButtonType,
  // Выбрана ли кнопка
  isSelected?: boolean,
  // Размер шрифта
  size?: string,
  // Ширина изображения
  imageWidth?: number | string,
  // Высота изображения
  imageHeight?: number | string,
  // Отспут между текстом и изображением
  indent?: number,
  // Будет ли изображение находиться слева
  isImageLeft?: boolean,
  // Скругление кнопки, по умолчанию 4
  borderRadius?: number,
  // Цвет изображения
  fill?: string,
  // Цвет изображения при наведении
  fillHover?: string,
  // Цвет изображения при активации
  fillActive?: string,
  // Номер выделения элемента по счету
  tabIndex?: number,
}

export type ButtonStyled = {
  colorDefault?: string,
  colorHover?: string,
  colorActive?: string,
  colorDisabled?: string,
  textDefault?: string,
  textHover?: string,
  textActive?: string,
  textDisabled?: string,
}