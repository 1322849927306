import * as Styled from './InfoRow.styled';
import { InfoRowProps } from './InfoRow.types';

export function InfoRow({
  label,
  value,
  isColor = false,
  isActive = false,
  isLink = false,
}: InfoRowProps) {
  return(
    <>
      <Styled.Container>
        <Styled.InfoLabel>{label}</Styled.InfoLabel>
      </Styled.Container>
      {!isColor && (
        <Styled.Container>
          {isLink
            ? (
              <Styled.InfoValueLink href={value || ''} target="_blank">
                {value ? value : 'Н/Д'}
              </Styled.InfoValueLink>
            ) : (
              <Styled.InfoValue>
                {value ? value : 'Н/Д'}
              </Styled.InfoValue>
            )}
        </Styled.Container>
      )}

      {isColor && (
        <Styled.Container>
          <Styled.InfoValueWithColor isActive={isActive}>
            {value ? value : 'Н/Д'}
          </Styled.InfoValueWithColor>
        </Styled.Container>
      )}
    </>
  );
}