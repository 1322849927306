import { useEffect, useState } from 'react';
import { sortingField, sortingType } from './constants';
import * as Styled from './Filters.styled';
import { FiltersProps } from './Filters.types';
import { Button } from '../../../../../../components/Button';
import { INPUT_MASKS } from '../../../../../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/storeHooks';
import { useInput } from '../../../../../../hooks/useInput';
import { setNumberPageCoursesAction } from '../../../../../../redux/reducers/courses';
import { getCourses } from '../../../../../../redux/reducers/courses/async';
import { coursesClearFilterAction, setFilterCourseAction } from '../../../../../../redux/reducers/filters';
import { getClearFilters, setCoursesFilters } from '../../../../../../redux/reducers/filters/async';
import { getMetrics } from '../../../../../../redux/reducers/tabels/async';
import { FieldInput } from '../../../../../../ui/FieldInput';
import { FieldSelect } from '../../../../../../ui/FieldSelect';
import { parsingNumber } from '../../../../../../utils/parsingNumber';

export function Filters({
  setIsActive,
  countReset,
  isLoadingDataCourses,
}: FiltersProps) {
  const dispatch = useAppDispatch();

  const {
    coursesActiveFilters,
  } = useAppSelector((state) => state.filters);
  const {
    authKey = '',
  } = useAppSelector((state) => state.auth);
  const {
    allPlatforms,
    allMethods,
    allCurrencies,
    loadingState: loadingStateTable,
  } = useAppSelector((state) => state.tabels);
  const {
    activePageCourses,
  } = useAppSelector((state) => state.courses);

  const {
    coursesRuleNumber = 0,
    coursesRateFirst = 0,
    coursesRateSecond = 0,
    coursesPlatform = '',
    coursesMethod = '',
    coursesCurrency = '',
    coursesSortField,
    coursesSortDirection,
  } = coursesActiveFilters;

  const ruleNumbers = useInput({
    initialValue: coursesRuleNumber ? coursesRuleNumber : '',
    mask: INPUT_MASKS.number,
  });
  const leftRageRate = useInput({
    initialValue: coursesRateFirst ? coursesRateFirst : '',
    mask: INPUT_MASKS.realNonNegativeNumbers,
  });
  const rightRageRate = useInput({
    initialValue: coursesRateSecond ? coursesRateSecond : '',
    mask: INPUT_MASKS.realNonNegativeNumbers,
  });

  const [platform, setPlatform] = useState(coursesPlatform);
  const [method, setMethod] = useState(coursesMethod);
  const [currency, setCurrency] = useState(coursesCurrency);
  const [fieldSorting, setFieldSorting] = useState(coursesSortField);
  const [typeSorting, setTypeSorting] = useState(coursesSortDirection);

  // Запрашиваем метрики, если их до этого не было
  useEffect(() => {
    if (!allPlatforms.length || !allMethods.length || !allCurrencies.length) {
      dispatch(getMetrics({ authKey }));
    }
  }, []);

  // Присваиваем фильтрам в компоненте активные фильтры, когда они меняются (Чаще работает для сброса, учитывая асинхроность)
  useEffect(() => {
    ruleNumbers.setValueInField(coursesRuleNumber);
    leftRageRate.setValueInField(coursesRateFirst);
    rightRageRate.setValueInField(coursesRateSecond);
    setPlatform(coursesPlatform);
    setMethod(coursesMethod);
    setCurrency(coursesCurrency);
    setFieldSorting(coursesSortField);
    setTypeSorting(coursesSortDirection);
  }, [coursesActiveFilters]);

  // Срабатывает, когда происходит нажатие на кнопку сброса фильтров уровнем выше
  // Можно убрать переместив стейты на уровень выше, но это не очень элегантное решение
  // Да, это костыль!
  useEffect(() => {
    if (countReset !== 0) {
      dispatch(setNumberPageCoursesAction(1));
      dispatch(coursesClearFilterAction());
    }
  }, [countReset]);

  // Метод, отчищающий фильтры и активные фильтры
  function onClickResetActiveFiltersHandler() {
    // Отчищаем активные фильтры локально
    dispatch(coursesClearFilterAction());

    // Отчищаем активные фильтры на сервере и запрашиваем новые табличные данные
    dispatch(getClearFilters({ authKey }));
    dispatch(setNumberPageCoursesAction(1));
    dispatch(getCourses({ authKey, numberPage: activePageCourses }));

    // Скрываем панель с фильтрами
    setIsActive((prev) => !prev);
  }

  // Метод, устанавливающий автивные фильтры
  function onClickSetActiveFiltersHandler() {
    // Устанавливаем активные фильтры локально
    dispatch(setFilterCourseAction({
      coursesRuleNumber: parsingNumber(ruleNumbers.value),
      coursesPlatform: platform,
      coursesCurrency: currency,
      coursesRateFirst: parsingNumber(leftRageRate.value),
      coursesRateSecond: parsingNumber(rightRageRate.value),
      coursesMethod: method,
      coursesSortField: fieldSorting,
      coursesSortDirection: typeSorting,
    }));

    // Устанавливаем активные фильтры на сервере и запрашиваем новые табличные данные
    dispatch(setCoursesFilters({ authKey }));
    dispatch(getCourses({ authKey, numberPage: activePageCourses }));

    // Устанавливаем первую страницу
    dispatch(setNumberPageCoursesAction(1));

    // Скрываем панель с фильтрами
    setIsActive((prev) => !prev);
  }

  return(
    <Styled.Container>
      <FieldInput { ...ruleNumbers } title="Номер правила"/>
      <FieldInput { ...leftRageRate } title="Диапозон курса"/>
      <FieldInput { ...rightRageRate }/>

      <FieldSelect
        options={allPlatforms}
        title="Платформы"
        isLoading={loadingStateTable === 'loading'}
        isClearable
        isSearchable
        placeholder="Введите назнание платформы"
        keyToReplaceWithLabel={'name'}
        setStateField={setPlatform}
      />

      <FieldSelect
        options={allMethods}
        title="Методы"
        isLoading={loadingStateTable === 'loading'}
        isClearable
        isSearchable
        placeholder="Введите назнание метода"
        keyToReplaceWithLabel={'name'}
        setStateField={setMethod}
      />

      <FieldSelect
        options={allCurrencies}
        title="Валюты"
        isLoading={loadingStateTable === 'loading'}
        isClearable
        isSearchable
        placeholder="Введите назнание валюты"
        keyToReplaceWithLabel={'name'}
        setStateField={setCurrency}
      />

      <FieldSelect
        options={sortingField}
        title="Сортировка по"
        placeholder="Выберите поле для сортировки"
        keyToReplaceWithLabel={null}
        defaultValue={sortingField[0]}
        setStateField={setFieldSorting}
      />

      <FieldSelect
        options={sortingType}
        placeholder="Выберите тип сортировки"
        keyToReplaceWithLabel={null}
        defaultValue={sortingType[0]}
        setStateField={setTypeSorting}
      />

      <Styled.ButtonList>
        <Button
          width="45%"
          height={35}
          onClick={onClickResetActiveFiltersHandler}
          isDisabled={isLoadingDataCourses}
          title="Сбросить"
          size="regular16"
        />
        <Button
          width="45%"
          height={35}
          onClick={onClickSetActiveFiltersHandler}
          isDisabled={isLoadingDataCourses}
          title="Применить"
          size="regular16"
        />
      </Styled.ButtonList>
    </Styled.Container>
  );
}