import { SetColorInNodesAtActivateProps } from './setColorInNodesAtActivate.types';

export function setColorInNodesAtActivate({
  isHighlighted,
  selectedNodesFullList,
  hoveredNode,
  selectedNodesWithoutDependencies,
  node,
  colors,
}: SetColorInNodesAtActivateProps) {
  switch (true) {
    case !isHighlighted && (!selectedNodesFullList.length && !hoveredNode):
      return colors.hovering;
    case isHighlighted && selectedNodesWithoutDependencies.includes(node):
      return colors.selected;
    case isHighlighted:
      return colors.hovering;
    default:
      return colors.default;
  }
}