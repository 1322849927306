import { useMemo } from 'react';
import { CellStrategyParams } from './components/CellStrategyParams';
import { StrategyParams } from './components/CellStrategyParams/CellStrategyParams.types';
import { CellVolumeParams } from './components/CellVolumeParams';
import { VolumeProps } from './components/CellVolumeParams/CellVolumeParams.types';
import { CellWithArrow } from './components/CellWithArrow';
import { CellWithBackgroundText } from './components/CellWithBackgroundText';
import { CellWithCheckbox } from './components/CellWithCheckbox';
import { CellWithColors } from './components/CellWithColors';
import { CellWithDeviation } from './components/CellWithDeviation';
import { CellWithHint } from './components/CellWithHint';

import * as Styled from './TableBodyCell.styled';

import { TableBodyCellProps } from './TableBodyCell.types';
import { CellContainer } from './ui/CellContainer';
import { setFormatString } from './utils/SetFormatString';

export function TableBodyCell({
  value,
  options,
  color,
  size,
  background,
  subValue = '',
  paddingVertical = 2,
  backgroundText = '',
  isBorderBottom,
  colorBorder,
  onClick,
}: TableBodyCellProps) {
  const commonParameters = {
    background,
    paddingVertical,
    isBorderBottom,
    colorBorder,
    size,
    color,
    onClick,
  };

  return useMemo(() => {
    switch (true) {
      case options?.isFieldWithDeviation:
        return (
          <CellWithDeviation
            value={'' + value}
            subValue={subValue}
            options={options}
            { ...commonParameters }
          />
        );
      case options?.isHintCellBody:
        return (
          <CellWithHint
            cellData={Array.isArray(value) ? value : []}
            { ...commonParameters }
          />
        );
      case options?.isTextWithArrow:
        return (
          <CellWithArrow
            text={typeof value === 'string' ? value : ''}
            { ...commonParameters }
          />
        );
      case options?.isTextWithBackground:
        return (
          <CellWithBackgroundText
            text={typeof value === 'string' ? value : ''}
            backgroundText={backgroundText}
            { ...commonParameters }
          />
        );
      case options?.isCellColor:
        return (
          <CellWithColors
            value={typeof value === 'string' ? value : ''}
            { ...commonParameters }
          />
        );
      case options?.isCheckbox:
        return (
          <CellWithCheckbox
            value={typeof value === 'string' ? value : ''}
            options={options}
            { ...commonParameters }
          />
        );
      case options?.isStrategyParams:
        return (
          <CellStrategyParams
            // todo убрать временный костыль
            value={value as unknown as StrategyParams}
            options={options}
            { ...commonParameters }
          />
        );
      case options?.isVolumeParams:
        return (
          <CellVolumeParams
            // todo убрать временный костыль
            value={value as unknown as VolumeProps}
            options={options}
            { ...commonParameters }
          />
        );
      default:
        return (
          <CellContainer { ...commonParameters }>
            <Styled.CellText size={size} color={color}>
              {setFormatString({
                value: '' + value,
                options: options,
              })}
            </Styled.CellText>
          </CellContainer>
        );
    }
  }, [value, subValue, background]);
}