import { useTheme } from 'styled-components';
import * as Styled from './Features.styled';
import refresh from '../../../../assets/icons/refresh.svg';
import { Button } from '../../../../components/Button';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { getChainsRobots } from '../../../../redux/reducers/tabels/async';

export function Features() {
  const {
    colors: {
      snowWhite,
    },
  } = useTheme();

  const {
    authKey = '',
  } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  function onClickUpdateChainsHandler() {
    dispatch(getChainsRobots({ authKey: authKey }));
  }

  return(
    <Styled.Container>
      <Button
        width={120}
        height={32}
        onClick={onClickUpdateChainsHandler}
        image={refresh}
        imageWidth={18}
        imageHeight={18}
        fill={snowWhite}
        fillHover={snowWhite}
        fillActive={snowWhite}
        indent={1}
        isImageLeft
        title='Обновить'
        size='regular14'
      />
    </Styled.Container>
  );
}