import styled, { css } from 'styled-components';
import { color, compose, layout, position, space } from 'styled-system';
import { Box, Text } from '../../../../../styledComponents';

type CellContainerProps = {
  background: string,
  paddingVertical: number,
  isBorderBottom: boolean,
  colorBorder: string,
}

export const CellContainer = styled('td').attrs<CellContainerProps>(({
  background,
  paddingVertical,
}) => ({
  bg: background,
  py: paddingVertical,
  position: 'relative',
}))<CellContainerProps>`
  ${compose(color, layout, space)};

  box-sizing: border-box;

  ${({
    isBorderBottom,
    colorBorder,
  }) => isBorderBottom && css`
    border-bottom: 1px solid ${colorBorder};
  `}
`;

export const CellWrapper = styled(Box).attrs({
  width: '100%',
  height: '100%',
})`
  display: flex;
  justify-content: center;
  align-items: center;
`;

type CellTextProps = {
  size: string,
  color: string,
}

export const CellText = styled(Text).attrs<CellTextProps>(({
  color,
  size,
}) => ({
  color,
  variant: size,
  height: '100%',
}))<CellTextProps>`
  ${layout};

  //white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type CellHintProps = {
  background: string,
}

export const CellHint = styled(Box).attrs<CellHintProps>(({ background }) => ({
  position: 'absolute',
  top: '50%',
  right: '-5px',
  zIndex: 1,
  bg: background,
  minWidth: 70,
  maxHeight: 32,
  py: 2,
}))<CellHintProps>`
  transform: translate(100%, -50%);
  visibility: hidden;
  opacity: 0;
  border: 1px solid ${({ theme }) => theme.colors.snowWhite};
  border-radius: 5px;
  transition: .25s all;
`;

type CellHintTextProps = {
  color: string,
  size: string,
}

export const CellHintText = styled(Text).attrs<CellHintTextProps>(({
  size,
  color,
}) => ({
  color,
  variant: size,
  px: 1,
}))<CellHintTextProps>`
  white-space: nowrap;
`;

type CellHintWrapperProps = {
  size: string,
  color: string,
}

export const CellHintWrapper = styled(Text).attrs<CellHintWrapperProps>(({
  color,
  size,
}) => ({
  color,
  variant: size,
  ml: 1,
  position: 'relative',
}))<CellHintWrapperProps>`
  ${position};

  border-bottom: 1px solid ${({ color }) => color};
  white-space: nowrap;

  &:hover {
    ${CellHint} {
      transition: .25s all;
      visibility: visible;
      opacity: 1;
    }
  }
`;