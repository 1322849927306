import styled, { css } from 'styled-components';
import { Box, Text } from '../../../../../../components/styledComponents';
import { theme } from '../../../../../../utils/theme';

export const FiltersAllText = styled(Text).attrs({
  variant: 'medium14',
  ml: 3,
  color: 'snowWhite',
})``;

export const FiltersAllWrapper = styled(Box).attrs({
  position: 'relative',
  display: 'flex',
  py: 2,
  px: 3,
  transition: '.25s all',
})`
  user-select: none;
  cursor: pointer;
  border-radius: 5px;
  
  @media (max-width: 690px) {
    padding: 8px 32px;
    margin-top: 20px;
  }
  
  ${({ theme: { colors: { snowWhite, grayBlue } } }) => theme && css`
    border: 1px ${snowWhite} solid;

    &:active {
      background: ${snowWhite};
      transition: .25s all;

      path {
        fill: ${grayBlue};
        transition: .25s all;
      }

      ${FiltersAllText} {
        color: ${grayBlue};
        transition: .25s all;
      }
    }
  `}
`;