import {
  Fade,
  Tooltip,
  Typography,
} from '@mui/material';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { useTheme } from 'styled-components';

import { SwitchFromMaterialUi } from './components/SwitchFromMaterialUi';

import * as Styled from './FilterSwitch.styled';
import { FilterSwitchProps } from './FilterSwitch.types';
import { useAppDispatch } from '../../../../../../../../hooks/storeHooks';


export function FilterSwitch({
  hint,
  textSwitch,
  isChecked,
  setStateAction,
}: FilterSwitchProps) {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  function onChangeSetStateFilterHandler() {
    dispatch(setStateAction(undefined));
  }

  return (
    <Styled.FilterSwitch>
      <Tooltip
        title={hint}
        arrow
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        enterDelay={400}
        leaveDelay={300}
        placement="top-end"
      >
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="start"
              control={(
                <SwitchFromMaterialUi
                  checked={isChecked}
                  // () => function() because prop spreading is forbidden (material ui)
                  onChange={() => onChangeSetStateFilterHandler()}
                  sx={{ m: 1 }}
                />
              )}
              label={(
                <Typography sx={{ color: theme.colors.white }}>
                  {textSwitch}:
                </Typography>
              )}
              labelPlacement="start"
            />
          </FormGroup>
        </FormControl>
      </Tooltip>
    </Styled.FilterSwitch>
  );
}