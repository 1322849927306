import styled from 'styled-components';
import { Box } from '../../../../components/styledComponents';

export const Container = styled(Box).attrs({
  as: 'section',
  width: '100%',
  my: 7,
  position: 'relative',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableActionWithFilters = styled(Box).attrs({
  display: 'flex',
})``;