import styled, { css } from 'styled-components';
import { Box, Text } from '../../../../components/styledComponents';

export const GraphContainer = styled(Box).attrs({
  height: 'calc(100vh - 78px)',
})``;

export const GraphSettingsWrapper = styled(Box).attrs({
})`
  display: flex;
  flex-direction: column;
`;

export const GraphActionsWithNodes = styled(Box)`
  display: flex;
  justify-content: space-between;
`;