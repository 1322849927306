import styled from 'styled-components';
import { Box, Text } from '../../../../components/styledComponents';

export const Rates = styled(Box).attrs({
  minHeight: 634,
  height: 634,
  pt: 10,
  pb: 6,
  as: 'section',
})`
  box-sizing: border-box;
  background-image: url('./assets/img/Arbitrage_preview.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-attachment: fixed;
`;

export const RatesContainer = styled(Box).attrs({
  pt: 4,
  width: '100%',
})`
  display: flex;
  column-gap: 12px;
`;

export const RatesRow = styled(Box).attrs({
  pt: 14,
  width: '40%',
})``;

export const RatesRowCard = styled(Box).attrs({
  width: '60%',
})`
  display: flex;
  gap: 24px;
`;

export const RatesTitle = styled(Text).attrs({
  color:'snowWhite',
  variant: 'bold40',
  as: 'h2',
})``;

export const RatesText = styled(Text).attrs({
  color: 'snowWhite',
  variant: 'medium18',
  mb: 5,
})`
  display: flex;
`;

export const RatesTextContent = styled(Text).attrs({
  color: 'snowWhite',
  variant: 'medium18',
  mt: 3,
})`
  max-width: 475px;
  text-align: left;
  display: flex;
`;

export const RatesTextColor = styled(Text).attrs({
  color: 'mainBlue',
  variant: 'bold18',
  ml: 1,
})``;

export const RatesTextContentColor = styled(Text).attrs({
  color: 'mainBlue',
  variant: 'bold18',
})`
  display: flex;
  white-space: nowrap;
`;