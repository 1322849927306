import styled from 'styled-components';
import { Box } from '../../../../components/styledComponents';

export const Container = styled(Box).attrs({
  height: 'calc(100vh - 78px)',
  px: 5,
  py: 5,
})`
  box-sizing: border-box;
`;

export const ButtonContainer = styled(Box).attrs({
  mt: 5,
})`
  display: flex;
  justify-content: space-between;
`;