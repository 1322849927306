import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';
import { compose, layout, space } from 'styled-system';
import { Box, Text } from '../../../../components/styledComponents';
import { SCREEN } from '../../../../constants/constants';

export const NavigationContainer = styled(Box).attrs({
  position: 'relative',
  width: '100%',
  height: 57,
  pl: 5,
  pr: 3,
})`
  display: flex;
  align-items: center;
`;

export const NavigationMenuImage = styled('img').attrs({
  width: 25,
  height: 25,
})`
  ${layout};
  display: none;

  @media (max-width: ${SCREEN.desktops}) {
    display: block;
  }
`;

type NavigationProps = {
  isView: boolean;
}

export const Navigation = styled('nav').attrs({
  width: '100%',
  height: '100%',
})<NavigationProps>`
  ${compose(layout, space)};
  display: flex;
  align-items: center;

  @media (max-width: ${SCREEN.desktops}) {
    position: fixed;
    left: 50%;
    top: 78px;
    transform: translateX(-50%);
    z-index: 102;
    width: 95%;
    visibility: ${({ isView }) => isView ? 'visible' : 'hidden'};
    opacity: ${({ isView }) => isView ? 1 : 0};
    background: ${({ theme }) => theme.colors.arsenic};
    box-shadow: 0 0 10px 2px ${({ theme }) => theme.colors.blackShadow};
    flex-wrap: wrap;
    height: auto;
  }
`;

export const NavigationLink = styled(Link).attrs({
  height: '100%',
  mr: 2,
})`
  ${compose(layout, space)};
  display: flex;
  align-items: center;
  text-decoration: none;

  @media (max-width: ${SCREEN.desktops}) {
    margin-right: 0;
    padding-left: 8px;
  }
`;

type NavigationLinkTextProps = {
  isActive?: boolean;
}

export const NavigationLinkText = styled(Text).attrs<NavigationLinkTextProps>(({ isActive }) => ({
  variant: 'regular16',
  color: isActive ? 'greenCobalt' : 'snowWhite',
  height: '100%',
}))<NavigationLinkTextProps>`
  display: flex;
  align-items: center;
  transition: .2s all;
  user-select: none;

  &:hover {
    transition: .2s all;
    color: ${({
    theme,
    isActive,
  }) => !isActive ? theme.colors.snowWhite : theme.colors.greenCobalt};
  }

  &:active {
    transition: .2s all;
    color: ${({
    theme,
    isActive,
  }) => !isActive ? theme.colors.greenCobalt : theme.colors.greenCobalt};
  }

  @media (max-width: ${SCREEN.desktops}) {
    padding: 24px 0;
  }
`;




