import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import * as Styled from './DeleteLoopsByCsv.styled';
import { Button } from '../../../../components/Button';
import { PopupWindow } from '../../../../components/PopupWindow';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import {
  onClearListFilesDeleteLoopsCsvAction,
  onDownloadFilesDeleteLoopsCsvAction,
} from '../../../../redux/reducers/createData';
import { getStateDeleteLoops, setStateDeleteLoopsCsv } from '../../../../redux/reducers/createData/async';
import { DragAndDrop } from '../../ui/DragAndDrop';
import { InformationWindow } from '../../ui/InformationWindow';

export function DeleteLoopsByCsv() {
  const [isErrorInFiles, setIsErrorTypeInFiles] = useState(false);
  const [isResultInfoModal, setIsResultInfoModal] = useState(false);
  const [isActiveRes, setIsActiveRes] = useState(false);

  const { colors } = useTheme();
  const dispatch = useAppDispatch();
  const {
    authKey = '',
  } = useAppSelector((state) => state.auth);
  const {
    deleteLoopsCsv,
    deleteLoopsStatusCsvResponse,
    deleteLoopsStatus,
    loadingState,
  } = useAppSelector((state) => state.createData);

  function onClickCheckStateDeleteLoopsHandler() {
    dispatch(getStateDeleteLoops({ authKey }));
  }

  function onClickDownloadFileCSVForDeleteLoopsHandler() {
    dispatch(setStateDeleteLoopsCsv({ authKey }));
    setIsActiveRes((prev) => !prev);
  }

  useEffect(() => {
    if (isActiveRes) {
      setIsResultInfoModal(true);
      setIsActiveRes((prev) => !prev);
      dispatch(getStateDeleteLoops({ authKey }));
    }
  }, [deleteLoopsStatusCsvResponse]);

  return (
    <Styled.Container>
      <InformationWindow
        width="100%"
        title="Статус работы алгоритма"
        label={deleteLoopsStatus?.deletion_status || 'Информация не получена'}
        hint="Одновременно может работать только один алгоритм удаления"
      />

      <DragAndDrop
        width="100%"
        height="75%"
        fileList={deleteLoopsCsv}
        setValueFilesAction={onDownloadFilesDeleteLoopsCsvAction}
        clearValueFilesAction={onClearListFilesDeleteLoopsCsvAction}
        setIsErrorState={setIsErrorTypeInFiles}
        fileTypes={['text/csv']}
        imageFolderFill={colors.arsenic}
        isOneFile
      />

      <Styled.ButtonContainer>
        <Button
          width="45%"
          height={50}
          onClick={onClickCheckStateDeleteLoopsHandler}
          title="Обновить (узнать) статус работы алгоритма"
          size="regular16"
        />
        <Button
          width="45%"
          height={50}
          onClick={onClickDownloadFileCSVForDeleteLoopsHandler}
          title={deleteLoopsCsv.length ? 'Загрузить файл' : 'Сперва выберите файл'}
          size="regular16"
          isDisabled={isErrorInFiles || deleteLoopsStatus?.is_working}
        />
      </Styled.ButtonContainer>

      {loadingState !== 'loading' && (
        <PopupWindow
          message={deleteLoopsStatusCsvResponse?.message || ''}
          isResultInfoModal={isResultInfoModal}
          setIsResultInfoModal={setIsResultInfoModal}
        />
      )}
    </Styled.Container>
  );
}