import styled from 'styled-components';
import { compose, layout, position } from 'styled-system';
import { SCREEN } from '../../constants/constants';
import { Box, Text } from '../styledComponents';

export const Container = styled(Box).attrs({
  as: 'form',
  padding: '2.5rem',
  width: '42%',
})`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: ${SCREEN.desktops}) {
    width: 50%;
  }

  @media (max-width: ${SCREEN.tablets}) {
    width: 100%;
  }

  @media (max-width: ${SCREEN.landscapePhones}) {
    width: 80%;
    padding: 0;
    margin-top: 16px;
  }
`;

export const LogoContainer = styled(Box).attrs({
  width: '100%',
  height: 60,
})``;

export const Logo = styled('img').attrs({
  width: '100%',
  height: '100%',
})``;

export const Title = styled(Text).attrs({
  as: 'h1',
  color: 'blueAzure',
  variant: 'OpenSansBold26',
  mt: 1,
})`
  text-align: center;

  @media (max-width: ${SCREEN.mobileM}) {
    margin: 0;
    font-size: 20px;
  }
`;

type ShapeChangeProps = {
  isPinCode: boolean,
}

export const ShapeChange = styled(Box).attrs<ShapeChangeProps>(({
  isPinCode,
}) => ({
  width: '100%',
  mt: isPinCode ? 2 : 6,
  mb: isPinCode ? 6 : 8,
}))<ShapeChangeProps>`
  display: flex;
  align-items: center;

  @media (max-width: ${SCREEN.mobileM}) {
    margin: 4px 0;
  }
`;

export const ShapeChangeLine = styled(Box).attrs({
  position: 'relative',
  width: '100%',
})`
  &::before {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.blueAzure};
    transform: translateY(-50%);
    z-index: 1;
  }

  @media (max-width: ${SCREEN.mobileM}) {
    display: none;
  }
`;

type ShapeChangeTextProps = {
  isPinCode: boolean,
}

export const ShapeChangeText = styled(Text).attrs({
  variant: 'OpenSansBold18',
  position: 'relative',
  color: 'blueAzure',
  mx: 1,
})<ShapeChangeTextProps>`
  ${compose(layout, position)};
  
  text-align: center;
  white-space: nowrap;
  cursor: ${({ isPinCode }) => isPinCode ? 'pointer' : 'auto'};

  @media (max-width: ${SCREEN.mobileM}) {
    white-space: normal;
    width: 100%;
    text-align: center;
    font-size: 16px;
  }
`;

export const Content = styled(Box).attrs({
  width: '100%',
})``;

export const InformationWrapper = styled(Box).attrs({
  px: 4,
})``;


export const InformationText = styled(Text).attrs({
  variant: 'OpenSansBold18',
  color: 'blueAzure',
})`
  text-align: center;

  @media (max-width: ${SCREEN.mobileM}) {
    font-size: 16px;
  }
`;

export const ErrorWrapper = styled(Box).attrs({
  width: '100%',
  height: 16,
  my: 1,
})`
  box-sizing: border-box;
`;

type ErrorMessageProps = {
  isValidErrorPassword: boolean,
}

export const ErrorMessage = styled(Text).attrs({
  variant: 'regular14',
  color: 'carminePink',
})<ErrorMessageProps>`
  text-align: center;
  cursor: ${({ isValidErrorPassword }) => isValidErrorPassword ? 'pointer' : 'auto'};
`;

export const PrivacyPolicy = styled(Text).attrs({
  color: 'darkGray',
  variant: 'light12',
  mt: 2,
  mb: 1,
})`
  text-align: center;
`;