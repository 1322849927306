import { DataHeaders } from '../../components/Table/Table.types';

export const metaDataTablePlatform: DataHeaders[] = [
  {
    id: 13,
    header: 'relevance',
    name: 'Актуальность',
    options: {
      isCellColor: true,
    },
  },
  {
    id: 1,
    header: 'name',
    name: 'Название платформы',
  },
  {
    id: 2,
    header: 'id',
    name: 'ID платформы',
  },
  {
    id: 3,
    header: 'type_platform',
    name: 'Вид платформы',
  },
  {
    id: 4,
    header: 'parser',
    name: 'Парсер',
  },
  {
    id: 5,
    header: 'parser_active',
    name: 'Статус парсера',
  },
  {
    id: 6,
    header: 'last_change',
    name: 'Последняя дата актуализации',
  },
  {
    id: 7,
    header: 'all_courses',
    name: 'Кол-во курсов',
  },
  {
    id: 8,
    header: 'currency',
    name: 'Кол-во валют',
  },
  {
    id: 9,
    header: 'incoming_courses',
    name: 'Кол-во входящих курсов',
  },
  {
    id: 10,
    header: 'outgoing_courses',
    name: 'Кол-во исходящих курсов',
  },
  {
    id: 11,
    header: 'internal_courses',
    name: 'Кол-во внутренних курсов',
  },
  {
    id: 12,
    header: 'loops',
    name: 'Кол-во цепочек с этой платформой',
  },
];

export const adminTableMetaData: DataHeaders[] = [
  {
    id: '1',
    header: 'id',
    name: 'Id',
  },
  {
    id: '2',
    header: 'name',
    name: 'Название',
  },
  {
    id: '3',
    header: 'comment',
    name: 'Комментарий',
  },
];

export const adminTableMetaDataCountry: DataHeaders[] = [
  {
    id: '1',
    header: 'id',
    name: 'Id',
  },
  {
    id: '2',
    header: 'fullname',
    name: 'Полное название',
  },
  {
    id: '3',
    header: 'shortname',
    name: 'Сокращение',
  },
];
