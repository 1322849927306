export const calculateDataTimeLoopSpeed = (value: number) => {
  switch (value) {
    case 1: {
      return 86400;
    }
    case 2: {
      return 259200;
    }
    case 3: {
      return 864000;
    }
    default:
      return null;
  }
};