import styled from 'styled-components';
import { position } from 'styled-system';
import { Box, Text } from '../../../../components/styledComponents';

export const Container = styled(Box).attrs({
  as: 'section',
  width: '100%',
  px: 8,
  py: 5,
  position: 'relative',
})`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 0 ${({ theme }) => theme.colors.blackShadow};
`;

export const ActionsContainer = styled(Box)`
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const Title = styled(Text).attrs({
  variant: 'bold24',
  color: 'snowWhite',
  position: 'absolute',
  top: '50%',
  left: '50%',
})`
  ${position};

  transform: translate(-50%, -50%);
`;