import styled from 'styled-components';
import { Box } from '../../../../components/styledComponents';

export const Container = styled(Box).attrs({
  width: 400,
  mt: 3,
  px: 1,
})`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;