import {
  Checkbox,
  Fade,
  FormControlLabel,
  Tooltip,
} from '@mui/material';
import { useTheme } from 'styled-components';
import { FilterCheckboxProps } from './FilterCheckbox.types';
import { useAppDispatch } from '../../../../../../../../hooks/storeHooks';

export function FilterCheckbox({
  label,
  state,
  setStateStatusAction,
  setStatus,
  hint = '',
}: FilterCheckboxProps) {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  function onChangeSetStateStatusActionHandler(status: number[]) {
    dispatch(setStateStatusAction(status));
  }

  return (
    <Tooltip
      title={hint}
      arrow
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      enterDelay={400}
      leaveDelay={300}
      placement="right-start"
    >
      <FormControlLabel
        control={
          <Checkbox
            sx={{ color: theme.colors.greenCobalt }}
            color="default"
            onChange={() => onChangeSetStateStatusActionHandler(setStatus)}
            checked={state.includes(setStatus?.[0])}
          />
        }
        label={label}
        sx={{ color: theme.colors.snowWhite }}
      />
    </Tooltip>
  );
}