import styled from 'styled-components';
import { Box, Text } from '../../../../components/styledComponents';

export const Container = styled(Box).attrs({
  width: '100%',
})``;

export const InfoContainer = styled(Box).attrs({
  bg: 'grayBlue',
  py: 7,
  mt: 4,
})`
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
`;

export const InfoColumn = styled(Box).attrs({

})`
  display: grid;
  grid-template: repeat(6, 32px) / repeat(2, minmax(min-content, max-content));
  column-gap: 52px;
  row-gap: 16px;
`;

export const ButtonContainer = styled(Box)`
  grid-row: 6 / 7;
  grid-column: 2 / 3;
`;

export const ButtonWrapper = styled(Box).attrs({
  width: 140,
  height: '100%',
  ml: 'auto',
})``;

export const InfoLabelContainer = styled(Box).attrs({
  height: '100%',
})`
  display: flex;
  align-items: center;
`;

export const InfoLabel = styled(Text).attrs({
  color: 'lightGray',
  variant: 'light16',
})``;

export const InfoValue = styled(Text).attrs({
  color: 'snowWhite',
  variant: 'medium20',
  mx: 1,
})``;

type InfoValueColorProps = {
  color: string,
}

export const InfoValueColor = styled(Text).attrs<InfoValueColorProps>(({ color }) => ({
  color,
  variant: 'medium20',
}))<InfoValueColorProps>``;

export const AdditionalInformation = styled(Box).attrs({
  mt: 8,
})`
  display: flex;
  justify-content: space-between;
`;

export const Synonyms = styled(Box).attrs({
  minWidth: 200,
})`
  flex-basis: 35%;
`;

export const SynonymsList = styled(Box).attrs({
  as: 'ul',
  mt: 4,
  pl: 0,
})`
  display: flex;
  column-gap: 8px;
`;

export const SynonymsItem = styled(Box).attrs({
  as: 'li',
  py: 1,
  px: 3,
  bg: 'snowWhite',
})`
  border-radius: 4px;
  list-style-type: none;
`;

export const SynonymsText = styled(Text).attrs({
  as: 'span',
  color: 'grayBlue',
  variant: 'medium14',
})`
  white-space: nowrap;
`;