import styled from 'styled-components';
import { Box } from '../../../../components/styledComponents';

export const Wrapper = styled(Box).attrs({
  as: 'section',
  bg: 'white',
  py: 22,
})``;

export const Container = styled(Box)`
  display: grid;
  grid-template: minmax(min-content, max-content) / 32% 68%;
  column-gap: 90px;
`;

export const ServicesContainer = styled(Box).attrs({
  height: '100%',
})`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SliderImageContainer = styled(Box).attrs({
  width: 760,
  height: '100%',
})``;

export const SliderImage = styled('img').attrs({
  width: '100%',
  height: '99%',
})`
  object-fit: contain;
`;