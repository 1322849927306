import styled, { css } from 'styled-components';
import { position } from 'styled-system';
import { Box, Text } from '../../../../components/styledComponents';

type ContainerProps = {
  width: number | string,
  height: number | string,
}

export const Container = styled(Box).attrs<ContainerProps>(({
  width,
  height,
}) => ({
  width,
  height,
  mt: 5,
  position: 'relative',
}))<ContainerProps>`
  box-sizing: border-box;
  border: 2px dashed ${({ theme }) => theme.colors.snowWhite};
  border-radius: 4px;
`;

export const DragAndDropMain = styled(Box).attrs({
  height: '100%',
})`
  display: flex;
  justify-content: center;
  align-items: center;
`;

type DragAndDropMainTextProps = {
  size: string,
  isVisible: boolean,
}

export const DragAndDropMainText = styled(Text).attrs<DragAndDropMainTextProps>(({ size }) => ({
  variant: size,
  color: 'lightGray',
}))<DragAndDropMainTextProps>`
  display: ${({ isVisible }) => isVisible ? 'none' : 'block'};
  user-select: none;
`;

type SelectedFilesLabelProps = {
  isVisible: boolean,
}

export const SelectedFilesLabel = styled('label')<SelectedFilesLabelProps>`
  display: ${({ isVisible }) => isVisible ? 'none' : 'block'};
  cursor: pointer;
`;

type SelectedFilesLabelTextProps = {
  size: string,
}

export const SelectedFilesLabelText = styled(Text).attrs<SelectedFilesLabelTextProps>(({ size }) => ({
  variant: size,
  color: 'snowWhite',
  mx: 1,
}))<SelectedFilesLabelTextProps>`
  border-bottom: 1px solid ${({ theme }) => theme.colors.snowWhite};
  user-select: none;
`;

export const SelectedFilesInput = styled('input')`
  display: none;
`;

type DragAndDropImageContainerProps = {
  isVisible: boolean,
}

export const DragAndDropImageContainer = styled(Box).attrs({
  position: 'absolute',
  top: '50%',
  left: '50%',
  zIndex: -1,
  width: '45%',
  height: '45%',
})<DragAndDropImageContainerProps>`
  transform: translate(-50%, -50%);
  display: ${({ isVisible }) => isVisible ? 'none' : 'block'};
`;

type DragAndDropFileOrFilesContainerProps = {
  listFileLength: number,
  isVisible: boolean,
}

export const DragAndDropFileOrFilesContainer = styled(Box).attrs({
  width: '100%',
  height: '100%',
})<DragAndDropFileOrFilesContainerProps>`
  display: ${({ isVisible }) => isVisible ? 'flex' : 'none'};

  ${({ listFileLength }) => listFileLength === 1 ? css`
    justify-content: center;
    align-items: center;
  ` : css`
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  `};
`;

type DragAndDropFileContainerProps = {
  width: number | string,
  height: number | string,
  listFileLength: number,
}

export const DragAndDropFileContainer = styled(Box).attrs<DragAndDropFileContainerProps>(({
  width,
  height,
  listFileLength,
}) => ({
  width: listFileLength === 1 ? '15%' : width,
  maxWidth: 140,
  height: listFileLength === 1 ? '40%' : height,
  position: 'relative',
}))<DragAndDropFileContainerProps>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

type DragAndDropFileTextProps = {
  color: string
}

export const DragAndDropFileText = styled(Text).attrs<DragAndDropFileTextProps>(({ color }) => ({
  variant: 'light12',
  color: color ? color : 'snowWhite',
}))<DragAndDropFileTextProps>`
  text-align: center;
`;

export const DragAndDropFileType = styled(Text).attrs({
  variant: 'regular14',
  color: 'snowWhite',
  position: 'absolute',
  top: '20%',
  left: '50%',
})`
  ${position};

  transform: translateX(-50%);
  user-select: none;
`;

export const DeleteFileContainer = styled(Box).attrs({
  width: 15,
  height: 15,
  position: 'absolute',
  top: '3%',
  right: '3%',
  bg: 'lightGray',
})`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;