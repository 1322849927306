import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { StartSrearAlgorithmRequest } from './types';
import {
  deleteFieldWithNull,
} from '../../../utils/deleteFieldWithNull/deleteFieldWithNull';
import { extractValues } from '../../../utils/ExtractValues';
import { RootState } from '../../store';

export const postStartSrearAlgorithm = createAsyncThunk(
  'algorithms/postStartSrearAlgorithm',
  async ({
    authKey,
    waitingTime,
    searchDepth,
    numberOfChains,
    numberOfStepsInsideExchangers,
    numberOfRules,
    numberOfStepsInsideThePlatform,
    chainMarginRange,
    platforms,
    methods,
    currency,
    startCurrency,
    activeAlgorithmVersion,
  }: StartSrearAlgorithmRequest,
  { getState },
  ) => {
    const {
      algorithms: {
        emailUser,
        timeActionBtn,
      },
    } = getState() as RootState;
    

    const REQUEST_DATA = {
      ver: activeAlgorithmVersion,
      email: emailUser,

      platforms: extractValues(platforms),
      tran_methods: extractValues(methods),
      currencies: extractValues(currency),
      max_time: waitingTime ? +waitingTime : 100,
      search_depth: searchDepth ? +searchDepth : null,
      max_loop_length: numberOfRules,
      start_currency: startCurrency ? +startCurrency.value : null,
      time: timeActionBtn,
      max_steps_per_platform: numberOfStepsInsideThePlatform,
      min_spread: chainMarginRange[0],
      max_spread: chainMarginRange[1],
      max_loops_count: numberOfChains ? +numberOfChains : 500,
      max_steps_exchange_poin: numberOfStepsInsideExchangers ? +numberOfStepsInsideExchangers : null, 
    };

    const { data } = await axios.post('/analyze-graph/', { ...REQUEST_DATA }, {
      headers: {
        key: authKey,
      },
    });

    return data;
  },
);

export const getAnalisisiGrachLog = createAsyncThunk(
  'algorithms/getAnalisisiGrachLog',
  async ({
    authKey,
  }: {
    authKey: string,
  }) => {
    const { data } = await axios.get('/get-last-algorithm-log/', {
      headers: {
        key: authKey,
      },
    });
    return data;
  },
);

export const getStatusAlgorithmLog = createAsyncThunk(
  'algorithms/getStatusAlgorithmLog',
  async ({
    authKey,
  }: {
    authKey: string,
  }) => {
    const { data } = await axios.get('/endpoint', {
      headers: {
        key: authKey,
      },
    });
    return data;
  },
);

export const getChainsDeleteCount = createAsyncThunk(
  'algorithms/getChainsDeleteCount',
  async (
    { authKey }: { authKey: string },
    { getState },
  ) => {
    const { algorithms } = getState() as RootState;
    const {
      selectedCoursesDelete,
      selectedPlarformsDeleteChain,
      valueRangeRegulation,
      valueRangeTime,
      valueRangeLoopSpeed,
      valueRangeRule,
      valueRangeRuleWeek,
      valueRangeRuleMonth,
      valueRangeTimeProfit,
      valueRangeTimeProfitPercent,
      valueRangeTimeMonthProfitPercent,
      strictModeDelete,
    } = algorithms;

    const createArrListID = algorithms.currencyIdSctiprs.trim().split(' ');

    function checkingTheFirstElement() {
      return createArrListID[0] ? createArrListID : [];
    }

    function parseDataRangeTime(value: number) {
      switch (value) {
        case 1:
          return 60;
        case 2:
          return 600;
        case 3:
          return 3600;
        case 4:
          return 10800;
        case 5:
          return 86400;
        case 6:
          return 172800;
        case 7:
          return null;
        default:
          return 0;
      }
    }

    function translateInMinutes(time: number) {
      return time * 60;
    }

    const chainsSettingDelete = deleteFieldWithNull({
      id_list: checkingTheFirstElement(),
      mode: strictModeDelete ? strictModeDelete : false,
      currencies: selectedCoursesDelete,
      platforms: selectedPlarformsDeleteChain,
      min_loop_length: valueRangeRegulation[0],
      max_loop_length: valueRangeRegulation[1] !== 10 ? valueRangeRegulation[1] : null,
      min_execution_time: parseDataRangeTime(valueRangeTime[0]),
      max_execution_time: parseDataRangeTime(valueRangeTime[1]) ? parseDataRangeTime(valueRangeTime[1]) : null,
      min_existence_time: parseDataRangeTime(valueRangeLoopSpeed[0]),
      max_existence_time: parseDataRangeTime(valueRangeLoopSpeed[1]) ? parseDataRangeTime(valueRangeLoopSpeed[1]) : null,
      min_mean_spread_day: valueRangeRule[0] !== -30 ? valueRangeRule[0] : null,
      max_mean_spread_day: valueRangeRule[1] !== 30 ? valueRangeRule[1] : null,
      min_mean_spread_week: valueRangeRuleWeek[0] !== -30 ? valueRangeRuleWeek[0] : null,
      max_mean_spread_week: valueRangeRuleWeek[1] !== 30 ? valueRangeRuleWeek[1] : null,
      min_mean_spread_month: valueRangeRuleMonth[0] !== -30 ? valueRangeRuleMonth[0] : null,
      max_mean_spread_month: valueRangeRuleMonth[1] !== 30 ? valueRangeRuleMonth[1] : null,
      min_profit_time_week: valueRangeTimeProfitPercent[0] !== 0 ? valueRangeTimeProfitPercent[0] : null,
      max_profit_time_week: valueRangeTimeProfitPercent[1],
      min_profit_time_month: valueRangeTimeMonthProfitPercent[0] !== 0 ? valueRangeTimeMonthProfitPercent[0] : null,
      max_profit_time_month: valueRangeTimeMonthProfitPercent[1],
      min_profit_duration_day: translateInMinutes(valueRangeTimeProfit[0]) !== 0 ? translateInMinutes(valueRangeTimeProfit[0]) : null,
      max_profit_duration_day: translateInMinutes(valueRangeTimeProfit[1]) !== 7200 ? translateInMinutes(valueRangeTimeProfit[1]) : null,
    });

    const { data } = await axios.post('/check-loops-for-marking/', { ...chainsSettingDelete }, {
      headers: {
        key: authKey,
      },
    });
    return data;
  },
);

export const deleteChainsDeleteCount = createAsyncThunk(
  'algorithms/deleteChainsDeleteCount',
  async (
    { authKey }: { authKey: string },
    { getState },
  ) => {
    const { algorithms } = getState() as RootState;
    const {
      responseDeleteChains,
    } = algorithms;

    const responseDeleteChainsData = {
      id_list: responseDeleteChains?.id_list,
    };

    const { data } = await axios.post('/delete-loops-filters', { ...responseDeleteChainsData }, {
      headers: {
        key: authKey,
      },
    });

    return data;
  },
);

export const getEmail = createAsyncThunk(
  'algorithms/getEmail',
  async ({
    authKey,
  }: {
    authKey: string,
  }) => {
    const { data } = await axios.get('/get-user-email', {
      headers: {
        key: authKey,
      },
    });

    return data;
  },
);
