import styled from 'styled-components';
import { Box } from '../styledComponents';

export const Container = styled(Box).attrs({
  width: '100vw',
  height: '100vh',
})`
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.oliveGreen};
  display: flex;
  justify-content: center;
  align-items: center;
`;