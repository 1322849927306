export const ADVANTAGES = [
  {
    strong: '_advantages.advantages.string1',
    text: '_advantages.advantages.text1',
  },
  {
    strong: '_advantages.advantages.string2',
    text: '_advantages.advantages.text2',
  },
  {
    strong: '_advantages.advantages.string3',
    text: '_advantages.advantages.text3',
  },
];

export const SERVICES = [
  '_advantages.advantages.services1',
  '_advantages.advantages.services2',
  '_advantages.advantages.services3',
];

export const SLIDERS = [
  {
    title: '_advantages.slider.slide1.title',
    text: '_advantages.slider.slide1.text',
  },
  {
    title: '_advantages.slider.slide2.title',
    text: '_advantages.slider.slide2.text',
  },
  {
    title: '_advantages.slider.slide3.title',
    text: '_advantages.slider.slide3.text',
  },
  {
    title: '_advantages.slider.slide4.title',
    text: '_advantages.slider.slide4.text',
  },
  {
    title: '_advantages.slider.slide5.title',
    text: '_advantages.slider.slide5.text',
  },
];