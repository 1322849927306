import { PayloadAction } from '@reduxjs/toolkit';
import { NavState } from '.';
import { DiscoveryMenu } from '../../../pages/Admin/Admin.types';

export const setActivePath = (
  state: NavState,
  { payload }: PayloadAction<string>,
) => {
  state.activePath = payload;
};

export const setActiveId = (
  state: NavState,
  { payload } : PayloadAction<number | undefined>,
) => {
  state.activeId = payload;
};

export const setLoopID = (
  state: NavState,
  { payload } : PayloadAction<string | null>,
) => {
  state.loopId = payload;
};

export const setActiveElementAdminPanel = (
  state: NavState,
  { payload } : PayloadAction<string>,
) => {
  state.activeElementAdminPanel = payload;
};

export const setActivePageScripts = (
  state: NavState,
  { payload }: PayloadAction<number>,
) => {
  state.activePageScripts = payload;
};

export const setOpenMenu = (
  state: NavState,
  { payload }: PayloadAction<DiscoveryMenu>,
) => {
  // Проверка наличия ключа в объекте `openMenuItems`
  if (payload in state.openMenuItems) {
    // Инвертируем текущее значение флага в объекте
    state.openMenuItems[payload] = !state.openMenuItems[payload];
  } else {
    state.openMenuItems = { ...state.openMenuItems, [payload]: false };
  }
};

export const setChoisePageDirectories = (
  state: NavState,
  { payload }: PayloadAction<number>,
) => {
  const activeElement = state.activeElementAdminPanel;
  
  if (activeElement && state.choisePageDirectories.hasOwnProperty(activeElement)) {
    //@ts-ignore
    state.choisePageDirectories[activeElement] = payload;
  }
};

export const setOpenModalDeleteChains = (
  state: NavState,
) => {
  state.openModalDeleteChains = !state.openModalDeleteChains;
};

export const setIsOpenModalFilters = (
  state: NavState,
) => {
  state.isOpenModalFilters = !state.isOpenModalFilters;
};
