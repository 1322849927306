import {
  useState,
  useEffect,
  useRef,
  DragEvent,
  ChangeEvent,
} from 'react';

import styles from './DownloadCsv.module.scss';
import { PopupWindow } from '../../../../components/PopupWindow';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import {
  deleteListFilesActions,
  setNewListFilesActions,
} from '../../../../redux/reducers/createData';
import { postDownloadCSV } from '../../../../redux/reducers/createData/async';

export function DownloadCsv() {
  const dispatch = useAppDispatch();
  const {
    authKey = '',
  } = useAppSelector((state) => state.auth);
  const {
    fileCSV,
    resDownloadCsv,
    loadingState,
  } = useAppSelector((state) => state.createData);

  const [isDrag, setIsDrag] = useState(false);
  const [isDownloadFileTypeError, setIsDownloadFileTypeError] = useState(false);
  const [fileCsvForDownload, setFileCsvForDownload] = useState<File>();
  const [isResultInfoModal, setIsResultInfoModal] = useState(false);
  const [isActiveRes, setIsActiveRes] = useState(false);

  function onDragStartHandler(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    setIsDrag(true);
  }

  function onDragLeaveHandler(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    setIsDrag(false);
  }

  function onDropHandler(e: DragEvent<HTMLDivElement>, fullFiles: FileList) {
    e.preventDefault();

    dispatch(setNewListFilesActions([...fullFiles]));

    setIsDrag(false);
  }

  const inputFileRef = useRef<HTMLInputElement | null>(null);

  function onChangeInputHandler(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();

    if (inputFileRef.current && inputFileRef.current.files) {
      const firstFile = [inputFileRef.current.files[0]];
      dispatch(setNewListFilesActions(firstFile));
    }
  }

  function onFileDeleteHandler() {
    if (fileCSV.length && inputFileRef.current) {
      inputFileRef.current.value = '';
      dispatch(deleteListFilesActions());
    }
  }

  function onFileDownloadHandler() {
    if (!isDownloadFileTypeError && inputFileRef.current) {
      inputFileRef.current.value = '';
      dispatch(postDownloadCSV({ authKey }));
      dispatch(deleteListFilesActions());
      setIsActiveRes((prev) => !prev);
    }
  }

  useEffect(() => {
    setFileCsvForDownload(fileCSV[0]);
  }, [fileCSV]);

  useEffect(() => {
    setIsDownloadFileTypeError(() => fileCsvForDownload?.type !== 'text/csv');
  }, [fileCsvForDownload]);

  useEffect(() => {
    if (isActiveRes) {
      setIsResultInfoModal(true);
      setIsActiveRes((prev) => !prev);
    }
  }, [resDownloadCsv]);

  return (
    <section className={styles.root}>
      <h2 className={styles.csv_title}>
        Загрузка CSV файла
      </h2>

      <div className={styles.csv_drop}>
        {isDrag
          ? (
            <div
              className={styles.csv_drop_area}
              onDragStart={onDragStartHandler}
              onDragLeave={onDragLeaveHandler}
              onDragOver={onDragStartHandler}
              onDrop={(e) => onDropHandler(e, e.dataTransfer.files)}
            >
              Отпустите файл, чтобы загрузить его
            </div>
          )
          : (
            <div
              className={styles.csv_drop_area}
              onDragStart={onDragStartHandler}
              onDragLeave={onDragLeaveHandler}
              onDragOver={onDragStartHandler}
            >
              Перетащите файл, чтобы загрузить его
            </div>
          )}
        {fileCSV.length ? (
          <div className={styles.csv_drop_file}>
            {fileCsvForDownload?.name}
          </div>
        ) : null}
      </div>

      <div className={styles.csv_or}>
        Или
      </div>

      <form
        action="post"
        className={styles.input_wrapper}
      >
        <label
          htmlFor="file"
          className={styles.input_file}
        >
          <span className={styles.input_file_text}>
            {fileCsvForDownload?.name}
          </span>
          <input
            type="file"
            id="file"
            className={styles.input_file_download}
            ref={inputFileRef}
            onChange={onChangeInputHandler}
          />
          <span className={styles.input_file_btn}>
            Выберите файл
          </span>
        </label>
      </form>

      <div
        className={`
            ${styles.csv_reset}
            ${fileCSV.length !== 0 ? styles.csv_reset_error : ''}
        `}
        onClick={onFileDeleteHandler}
      >
        Удалить файлы
      </div>

      <button
        className={`
          ${styles.csv_btn}
          ${isDownloadFileTypeError ? styles.csv_btn_error : ''}
        `}
        type="submit"
        onClick={onFileDownloadHandler}
      >
        Загрузить CSV файл
      </button>

      {loadingState !== 'loading'
        ? (
          <PopupWindow
            message={resDownloadCsv?.message || ''}
            isResultInfoModal={isResultInfoModal}
            setIsResultInfoModal={setIsResultInfoModal}
            bottom="0%"
            left="50%"
            translateX="-50%"
            translateY="-50%"
          />
        )
        : null}
    </section>
  );
}
