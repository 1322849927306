import '@react-sigma/core/lib/react-sigma.min.css';
import { useEffect, useState, useId } from 'react';
import {
  SigmaContainer,
  ControlsContainer,
  ZoomControl,
  FullScreenControl,
  SearchControl,
} from '@react-sigma/core';

// import { LayoutForceAtlas2Control } from '@react-sigma/layout-forceatlas2';
import { MultiDirectedGraph } from 'graphology';
import { CheckboxGraph } from './components/CheckboxGraph';
import { SampleGraph } from './components/SampleGraph';
import { mainCurrency } from './constants';
import * as Styled from './PlatformGraph.styled';
import { CheckboxGraphData, PlatformSharpRadius } from './PlatformGraph.types';
import { Button } from '../../../../components/Button';
import { FilterCheckboxWrapper } from '../../../../components/FilterCheckboxWrapper';
import { FilterWithDropDown } from '../../../../components/FilterWithDropDown';
import { Loading } from '../../../../components/Loading';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';

import { useSet } from '../../../../hooks/useSet';
import {
  onDeleteSelectedCurrencyGraphAction,
  onSelectedCurrencyGraphAction, setIsCurrencyPairStrictAction,
  setIsEqualCurrenciesAction,
  setIsRelatedPlatformsAction,
} from '../../../../redux/reducers/tabels';

import { getPlatformGraph } from '../../../../redux/reducers/tabels/async';

export function PlatformGraph() {
  const dispatch = useAppDispatch();

  const {
    authKey = '',
  } = useAppSelector((state) => state.auth);

  const {
    loadingState,
    platformEdges,
    allCurrencies,
    selectedCurrencyGraph,
    isEqualCurrencies,
    isCurrencyPairStrict,
    isRelatedPlatforms,
    platformsSharp = [],
    platformsNonSharp = [],
  } = useAppSelector((state) => state.tabels);

  const [platformsSharpRadius, setPlatformsSharpRadius] = useState(0);
  const [selectedNodes, setSelectedNodes] = useState<string[]>([]);
  const [childNodesOfSelectedNodes, { reset, add }] = useSet<string>();

  useEffect(() => {
    const platformsSharpLength = platformsSharp.length;

    setPlatformsSharpRadius(
      Math.min(Math.max(
        PlatformSharpRadius.minimumMultiplier, platformsSharpLength) * PlatformSharpRadius.scaledValue,
      PlatformSharpRadius.maximum),
    );
  }, [platformsSharp]);

  function onClickGetPlatformGraphHandler() {
    dispatch(getPlatformGraph({ authKey }));
  }

  function onClickSetIsEqualCurrenciesHandler() {
    dispatch(setIsEqualCurrenciesAction());
  }

  function onClickSetIsRelatedPlatformsHandler() {
    dispatch(setIsRelatedPlatformsAction());
  }

  function onClickSetIsStrictCouplePlatformsHandler() {
    dispatch(setIsCurrencyPairStrictAction());
  }

  function onClickSetFullListSelectedNodeHandler() {
    setSelectedNodes(platformsSharp.concat(platformsNonSharp));
  }

  function onClickDeleteAllSelectedNodeHandler() {
    setSelectedNodes([]);
    reset();
  }

  const checkboxGraphData: CheckboxGraphData[] = [
    {
      key: useId(),
      hint: 'Рассматривать только те курсы, в которых начальная валюта равна конечной',
      label: 'Сохранение валюты перевода',
      isChecked: isEqualCurrencies,
      onChangeSetIsCheckedHandler: onClickSetIsEqualCurrenciesHandler,
    },
    {
      key: useId(),
      hint: 'Исключить из графа платформы, с которым не связан ни один курс',
      label: 'Исключить несвязные',
      isChecked: isRelatedPlatforms,
      onChangeSetIsCheckedHandler: onClickSetIsRelatedPlatformsHandler,
    },
    {
      key: useId(),
      hint: 'Рассматривать только те курсы, в которых и начальная, и конечная валюта присутствует в выбранных валютах (доступно только при снятой галочке в "Сохранение валюты перевода")',
      label: 'Строгая пара',
      isChecked: isCurrencyPairStrict,
      onChangeSetIsCheckedHandler: onClickSetIsStrictCouplePlatformsHandler,
      disabled: isEqualCurrencies,
    },
  ];

  const dataButtonWithSelectedNode = [
    {
      key: useId(),
      title: 'Выбрать все',
      onClick: onClickSetFullListSelectedNodeHandler,
    },
    {
      key: useId(),
      title: 'Удалить все',
      onClick: onClickDeleteAllSelectedNodeHandler,
    },
  ];

  return (
    <Styled.GraphContainer>
      {loadingState === 'loading'
        ? <Loading/>
        : (
          <SigmaContainer
            graph={MultiDirectedGraph}
            settings={{
              renderEdgeLabels: true,
            }}
          >
            <SampleGraph
              graphEdges={platformEdges}
              platformsSharp={platformsSharp}
              platformsNonSharp={platformsNonSharp}
              platformsSharpRadius={platformsSharpRadius}
              selectedNodes={selectedNodes}
              setSelectedNodes={setSelectedNodes}
              childNodesOfSelectedNodes={childNodesOfSelectedNodes}
              resetChildNodes={reset}
              addChildNodes={add}
            />
            <ControlsContainer position={'bottom-right'}>
              <ZoomControl/>
              <FullScreenControl/>
              {/*<LayoutForceAtlas2Control settings={{ settings: { slowDown: 10 } }}/>*/}
            </ControlsContainer>
            <ControlsContainer position={'top-right'}>
              <SearchControl style={{ width: 200 }}/>
            </ControlsContainer>
            <ControlsContainer position={'top-left'} style={{ width: 300 }}>
              <FilterWithDropDown
                dataList={allCurrencies}
                placeholder="Введите название валюты"
                selectedItems={selectedCurrencyGraph}
                setStateValueAction={onSelectedCurrencyGraphAction}
                deleteItemsAction={onDeleteSelectedCurrencyGraphAction}
                mainElementsList={mainCurrency}
                isDropDownBottom
                isLightBackground
              />
            </ControlsContainer>
            <ControlsContainer position={'bottom-left'}>
              <Styled.GraphActionsWithNodes>
                {dataButtonWithSelectedNode.map((button) => (
                  <Button
                    width="48%"
                    height={32}
                    size="regular16"
                    { ...button }
                  />
                ))}
              </Styled.GraphActionsWithNodes>
              <Styled.GraphSettingsWrapper>
                <FilterCheckboxWrapper
                  formElements={
                    <>
                      {checkboxGraphData.map((graphData) => (
                        <CheckboxGraph {...graphData}/>
                      ))}
                    </>
                  }
                />
                <Button
                  width="100%"
                  height={35}
                  onClick={onClickGetPlatformGraphHandler}
                  title="Обновить"
                  size="regular16"
                />
              </Styled.GraphSettingsWrapper>
            </ControlsContainer>
          </SigmaContainer>
        )}
    </Styled.GraphContainer>
  );
}