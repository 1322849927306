import styled from 'styled-components';
import { Box } from '../../../../../../../../components/styledComponents';

export const FilterSwitch = styled(Box).attrs({
  height: '100%',
  mr: 2,
})`
  display: flex;
  justify-content: end;
  align-items: end;

  @media (max-width: 510px) {
    grid-row: 1 / 2;
    margin-right: 12px;
  }
`;