import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Filters } from './components/Filters';
import * as Styled from './Features.styled';
import refresh from '../../../../assets/icons/refresh.svg';
import { Button } from '../../../../components/Button';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { setNumberPageCoursesAction } from '../../../../redux/reducers/courses';
import { getCourses } from '../../../../redux/reducers/courses/async';
import { getClearFilters } from '../../../../redux/reducers/filters/async';
import { ButtonContainer } from '../../../../ui/ButtonContainer';
import { RetractableSidePanel } from '../../../../ui/RetractableSidePanel';

export function Features() {
  const {
    colors: {
      snowWhite,
    },
  } = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    authKey = '',
  } = useAppSelector((state) => state.auth);
  const {
    loadingState: loadingStateFilters,
  } = useAppSelector((state) => state.filters);
  const {
    loadingState: loadingStateTable,
  } = useAppSelector((state) => state.tabels);
  const {
    activePageCourses,
    loadingState: loadingStateCourses,
  } = useAppSelector((state) => state.courses);

  const [isActive, setIsActive] = useState(false);
  const [countReset, setCountRest] = useState(0);

  const isLoadingDataCourses =
    loadingStateFilters === 'loading'
    || loadingStateTable === 'loading'
    || loadingStateCourses === 'loading';

  function onClickUpdateCoursesHandler() {
    dispatch(getCourses({ authKey, numberPage: activePageCourses }));
    dispatch(setNumberPageCoursesAction(1));
    setIsActive(false);
  }

  function onClickResetCoursesHandler() {
    dispatch(getClearFilters({ authKey }));
    dispatch(setNumberPageCoursesAction(1));
    dispatch(getCourses({ authKey, numberPage: activePageCourses }));
    setIsActive(false);
    setCountRest((prev) => prev + 1);
  }

  return(
    <Styled.Container>
      <Styled.TableActionWithFilters>
        <Button
          width={120}
          height={32}
          onClick={onClickUpdateCoursesHandler}
          title={t('_features.update')}
          image={refresh}
          imageWidth={18}
          imageHeight={18}
          fill={snowWhite}
          indent={1}
          isImageLeft
          size="regular14"
        />

        <ButtonContainer marginRight={2}>
          <Button
            width={150}
            height={32}
            onClick={onClickResetCoursesHandler}
            title={t('_features.resetFilters')}
            size="regular14"
            isDisabled={isLoadingDataCourses}
          />
        </ButtonContainer>
      </Styled.TableActionWithFilters>

      <RetractableSidePanel
        title={t('_features.filters')}
        width={400}
        marginTopSidePanel={7}
        isActive={isActive}
        setIsActive={setIsActive}
      >
        <Filters
          setIsActive={setIsActive}
          countReset={countReset}
          isLoadingDataCourses={isLoadingDataCourses}
        />
      </RetractableSidePanel>
    </Styled.Container>
  );
}