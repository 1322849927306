import React, { useEffect, useState } from 'react';

import { btnDataSearch } from './constants';
import { BtnDataSearch } from './TableTypeTimeBtn.types';
import * as Styled from './TableTypeTimeButton.styled';
import { Button } from '../../../../../../components/Button';
import { ButtonType } from '../../../../../../components/Button/Button.types';
import { TooltipWithElement } from '../../../../../../components/TooltipWithElement';
import { TooltipPlacement } from '../../../../../../components/TooltipWithElement/TooltipWithElement.types';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/storeHooks';
import { loopBadStatusAct, loopWithTimeIntervalAct } from '../../../../../../redux/reducers/filters';
import { setActiveTypeTimeAction } from '../../../../../../redux/reducers/tabels';

export function TableTypeTimeBtn() {
  const dispatch = useAppDispatch();
  const {
    chains,
    statusChains,
  } = useAppSelector((state) => state.filters);

  const [selectedChains, setSelectedChains] = useState<number>(1);
  const [activeBtn, setActiveBtn] = useState<BtnDataSearch[]>(
    btnDataSearch.map((item) => ({
      ...item,
    })),
  );

  useEffect(() => {
    if (statusChains !== 404) {
      dispatch(loopWithTimeIntervalAct(selectedChains));
    } else {
      dispatch(loopBadStatusAct());
    }
  }, [chains, selectedChains]);

  const setActiveIdBtnTypeTime = (id: number) => {
    const newObj = activeBtn.map((item) => {
      if (item.id !== id) {
        return {
          ...item,
          selected: false,
        };
      }
      setSelectedChains(id);
      dispatch(setActiveTypeTimeAction(item.time));
      return {
        ...item,
        selected: true,
      };
    });

    setActiveBtn(newObj);
  };

  function onClickActiveIdBtnProfitTimeHandler(id: number) {
    setActiveIdBtnTypeTime(id);
  }

  return (
    <Styled.TypeTimeButtonContainer>
      <TooltipWithElement
        hint="Прибыльность за период"
        placement={TooltipPlacement.left}
        element={
          <Styled.ButtonListContainer>
            {activeBtn.map(({
              id,
              title,
              selected,
            }) => (
              <Styled.ButtonContainer key={id}>
                <Button
                  width={110}
                  height={32}
                  onClick={() => onClickActiveIdBtnProfitTimeHandler(id)}
                  title={title}
                  isSelected={selected}
                  type={ButtonType.TypeWhite}
                  size="regular14"
                />
              </Styled.ButtonContainer>
            ))}
          </Styled.ButtonListContainer>
        }
      />
    </Styled.TypeTimeButtonContainer>
  );
}
