import { useMemo } from 'react';
import * as Styled from './ActionsWithMetric.styled';
import { ActionsWithMetricProps, ActionsWithMetricText } from './ActionsWithMetric.types';
import { useAppDispatch } from '../../../../../../../../../../hooks/storeHooks';

export function ActionsWithMetric({
  allMetrics,
  setStateAllMetric,
  isSelectedMetrics,
  setIsSelectedMetric,
  isShowMetrics,
  setIsShowMetric,
  isShowAll,
  isRightActions,
}: ActionsWithMetricProps) {
  const dispatch = useAppDispatch();

  function onClickSetStateAllMetricHandler() {
    dispatch(setStateAllMetric({
      data: allMetrics,
      isActive: isSelectedMetrics,
    }));
    setIsSelectedMetric((prev) => !prev);
  }

  function onClickSetStateShowMetricHandler() {
    setIsShowMetric((prev) => !prev);
  }

  return (
    <Styled.ActionsWithMetric
      isRightActions={isRightActions}
    >
      <Styled.SelectOrDeleteAllMetrics
        onClick={onClickSetStateAllMetricHandler}
      >
        {isSelectedMetrics
          ? ActionsWithMetricText.SelectedMetrics
          : ActionsWithMetricText.DeleteMetrics}
      </Styled.SelectOrDeleteAllMetrics>
      {isShowAll && (
        <Styled.ShowOrHideMetrics
          onClick={onClickSetStateShowMetricHandler}
        >
          {isShowMetrics
            ? ActionsWithMetricText.ShowMetrics
            : ActionsWithMetricText.HideMetrics}
        </Styled.ShowOrHideMetrics>
      )}
    </Styled.ActionsWithMetric>
  );
}