import { Checkbox, Fade, FormControlLabel, Tooltip } from '@mui/material';
import { useTheme } from 'styled-components';
import { CheckboxGraphProps } from './CheckboxGraph.types';

export function CheckboxGraph({
  hint,
  label,
  isChecked,
  onChangeSetIsCheckedHandler,
  disabled,
}: CheckboxGraphProps) {
  const {
    colors: {
      grayBlue,
      greenCobalt,
    },
  } = useTheme();

  return (
    <Tooltip
      title={hint}
      arrow
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      enterDelay={400}
      leaveDelay={300}
      placement="right-start"
    >
      <FormControlLabel
        control={
          <Checkbox
            sx={{ color: greenCobalt }}
            color="default"
            onChange={onChangeSetIsCheckedHandler}
            checked={isChecked}
            disabled={disabled || undefined}
          />
        }
        label={label}
        sx={{ color: grayBlue }}
      />
    </Tooltip>
  );
}