import { useTranslation } from 'react-i18next';
import * as Styled from './Title.styled';

export function Title() {
  const { t } = useTranslation();

  return(
    <Styled.Title>
      {t('_possibilities.title1')}
      <Styled.TitleStrong>
        {t('_possibilities.title2')}
      </Styled.TitleStrong>
      {t('_possibilities.title3')}
    </Styled.Title>
  );
}