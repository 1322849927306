import styled from 'styled-components';
import { Box } from '../../../../../../components/styledComponents';

export const Container = styled(Box).attrs({
  width: '100%',
  height: 60,
  bg: 'snowWhite',
})`
  position: relative;
  border-radius: 4px;
  display: flex;
  align-items: center;
`;