import styled from 'styled-components';
import { Box, Text } from '../../../../components/styledComponents';


export const Container = styled(Box).attrs({
  as: 'section',
  width: '100%',
  height: '78px',
  mt: 5,
})`
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;

export const TypeTimeButtonContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


export const ButtonListContainer = styled(Box).attrs({
  ml: 1,
  mr: 2,
})`
  display: flex;
`;

export const ButtonContainer = styled(Box).attrs({
  mx: 1,
})``;