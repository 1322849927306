import styled, { css } from 'styled-components';
import { color, compose, layout, space } from 'styled-system';
import { Box, Text } from '../../components/styledComponents';

type ContainerProps = {
  marginTop: number,
  marginLeft: number
  width: number | string,
}

export const Container = styled(Box).attrs<ContainerProps>(({
  marginTop,
  marginLeft,
  width,
}) => ({
  width,
  position: 'relative',
  py: 1,
  mt: marginTop,
  ml: marginLeft,
}))<ContainerProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

type InputProps = {
  color: string,
  backgroundColor: string,
  borderRadius: number,
  borderColor: string,
  outlineColor: string,
  height: number | string,
}

export const Input = styled('input').attrs<InputProps>(({
  backgroundColor,
  height,
}) => ({
  height,
  width: '100%',
  bg: backgroundColor,
  px: 2,
  py: 1,
}))<InputProps>`
  ${compose(color, space, layout)};
  box-sizing: border-box;
  border: none;
  
  ${({
    borderRadius,
    borderColor,
    outlineColor,
  }) => css`
    border-radius: ${borderRadius}px;
    border-width: ${borderColor ? 1 : 0}px;
    border-style: solid;
    border-color: ${borderColor};
    &:focus {
      outline: ${outlineColor} solid 1px;
      border-width: 0;
    }
  `}
`;

type TitleInputProps = {
  color: string,
}

export const TitleInput = styled(Text).attrs<TitleInputProps>(({
  color,
}) => ({
  color,
  variant: 'regular16',
  mb: 2,
}))<TitleInputProps>`
  white-space: nowrap;
`;

export const TitleContainr = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const ActionsContainer = styled(Box).attrs({
  pb: 2,
})`
  display: flex;
  column-gap: 10px;
`;