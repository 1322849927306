import styled from 'styled-components';
import { Box, Text } from '../../../../../styledComponents';

type CellTextProps = {
  color: string,
  size: string,
}

export const CellText = styled(Text).attrs<CellTextProps>(({
  color,
  size,
}) => ({
  color,
  variant: size,
}))<CellTextProps>`
  white-space: nowrap;
`;

type CellBackgroundTextProps = {
  background: string,
}

export const CellBackgroundText = styled(Box).attrs<CellBackgroundTextProps>(({ background }) => ({
  bg: background,
  py: 1,
}))<CellBackgroundTextProps>`
  display: flex;
  justify-content: center;
  align-items: center;
`;