import { useTranslation } from 'react-i18next';
import { NAVIGATION_LIST } from './constants';
import * as Styled from './Header.styled';
import logo from '../../../../assets/icons/logo.svg';
import { Button } from '../../../../components/Button';
import { ButtonType } from '../../../../components/Button/Button.types';
import { ImageContainer } from '../../../../components/ImageContainer';
import { usePathLogged } from '../../../../hooks/usePathLogged';
import { Container } from '../../../../ui/Container';

export function Header() {
  const { t } = useTranslation();
  const { setPathAuntification } = usePathLogged();

  function onClickSetPathSingInHandler() {
    setPathAuntification('signIn');
  }

  function onClickSetPathSignUpHandler() {
    setPathAuntification('signUp');
  }

  return(
    <Styled.Header>
      <Container>
        <Styled.Wrapper>
          <ImageContainer
            width={150}
            height={30}
            image={logo}
            isBaseColor
          />

          <Styled.NavigationWrapper>
            <Styled.NavigationList>
              {NAVIGATION_LIST.map(({ value, label, link }) => (
                <Styled.NavigationItem key={value}>
                  <Styled.NavigationLink to={link}>
                    {t(label)}
                  </Styled.NavigationLink>
                </Styled.NavigationItem>
              ))}
            </Styled.NavigationList>
          </Styled.NavigationWrapper>

          <Styled.ButtonContainer>
            <Button
              width={162.5}
              height={49}
              onClick={onClickSetPathSignUpHandler}
              title={t('_header.signUp')}
              size='medium14'
              borderRadius={6}
              type={ButtonType.TypeBlueTwo}
            />

            <Button
              width={121.4}
              height={49}
              onClick={onClickSetPathSingInHandler}
              title={t('_header.signIn')}
              size='medium14'
              borderRadius={6}
              type={ButtonType.TypeWhiteTwo}
            />
          </Styled.ButtonContainer>
        </Styled.Wrapper>
      </Container>
    </Styled.Header>
  );
}