import styled from 'styled-components';
import { Box, Text } from '../../../../components/styledComponents';

export const Wrapper = styled(Box).attrs({
  minHeight: 764,
  height: 764,
  pt: 10,
  pb: 6,
  as: 'section',
})`
  box-sizing: border-box;
  background-image: url('./assets/img/Arbitrage_preview.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-attachment: fixed;
`;

export const PreviewContainer = styled(Box).attrs({
  pt: 35,
  width: '100%',
})`
  display: flex;
  column-gap: 12px;
`;

export const PreviewRow = styled(Box).attrs({
  width: '50%',
})``;

export const PreviewImage = styled('img').attrs({
  width: '100%',
  height: '100%',
})`
  object-fit: contain;
  transition: .25s all;

  &:hover {
    transition: .25s all;
    scale: 1.05;
  }
`;

export const PreviewTitle = styled(Text).attrs({
  color:'snowWhite',
  variant: 'medium22',
  as: 'h1',
})``;

export const PreviewTag = styled(Text).attrs({
  pl: 0,
  as: 'ul',
  mt: 11,
  mb: 14,
})`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

export const PreviewItem = styled(Text).attrs({
  as: 'li',
})`
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  column-gap: 12px;
`;

export const PreviewText = styled(Text).attrs({
  color: 'lightGray',
  variant: 'medium18',
})``;
