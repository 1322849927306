import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AddGraph } from './components/AddGraph/AddGraph';
import * as Styled from './Graph.styled';

export function Graph() {
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <AddGraph />
      </DndProvider>
    </>
  );
}
