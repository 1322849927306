import styled from 'styled-components';
import { Box } from '../../../../components/styledComponents';

export const Container = styled(Box).attrs({
  height: 'calc(100vh - 78px)',
  p: 5,
})`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ElementWrapper = styled(Box).attrs({
  width: '100%',
  mb: 3,
})``;

export const ButtonContainer = styled(Box).attrs({
  width: '100%',
})`
  display: flex;
  justify-content: space-between;
`;