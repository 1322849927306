import styled from 'styled-components';
import { Box } from '../../../../components/styledComponents';

export const Container = styled(Box).attrs({
  as: 'section',
  width: '100%',
  height: '78px',
  mt: 5,
})`
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;