import { Dispatch, SetStateAction } from 'react';

export enum ActiveTab {
  FreeInformation = 0,
  Currencies = 1,
  IncomingCourses = 2,
  OutgoingCourses = 3,
  InternalCourses = 4,
  CommissionData = 5,
}

export type TabsProps = {
  activeTab: ActiveTab,
  setActiveTab: Dispatch<SetStateAction<ActiveTab>>,
}