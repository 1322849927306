import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './AuthenticationWrapper.styled';
import { AuthenticationWrapperProps } from './AuthenticationWrapper.types';
import logo from '../../assets/icons/logo_dark.svg';
import { ERROR_VALID_MESSAGE } from '../../hooks/useAuthenticationError/constants';
import { ButtonContainer } from '../../ui/ButtonContainer';
import { Button } from '../Button';
import { ButtonType } from '../Button/Button.types';

export function AuthenticationWrapper({
  children,
  onClickShapeChange,
  onClickLogInOrSignIn,
  error = '',
  serverError = '',
  isValid,
  textButtonSubmit = '',
  textHelpInForm = '',
  textNavigateButton = '',
  textSelectedForm = '',
  tabIndexButtonSubmit,
  isPinCode = false,
  onClickRequestCodeAgain,
}: AuthenticationWrapperProps) {
  const { t } = useTranslation();

  const isValidErrorPassword = error === ERROR_VALID_MESSAGE.password || error === ERROR_VALID_MESSAGE.passwordShort;

  return(
    <Styled.Container>
      <Styled.LogoContainer>
        <Styled.Logo src={logo}/>
      </Styled.LogoContainer>
      <Styled.Title>
        {t(textSelectedForm)}
      </Styled.Title>

      {onClickShapeChange && (
        <ButtonContainer
          marginTop={2}
          marginBottom={2}
          width="100%"
        >
          <Button
            width="100%"
            height={40}
            onClick={onClickShapeChange}
            title={t(textHelpInForm)}
            size="bold24"
            type={ButtonType.TypeHeavenly}
            tabIndex={1}
          />
        </ButtonContainer>
      )}

      <Styled.ShapeChange isPinCode={isPinCode}>
        <Styled.ShapeChangeLine/>
        <Styled.ShapeChangeText
          isPinCode={isPinCode}
          onClick={onClickRequestCodeAgain}
        >
          {t(textNavigateButton)}
        </Styled.ShapeChangeText>
        <Styled.ShapeChangeLine/>
      </Styled.ShapeChange>

      <Styled.Content>
        {children}
      </Styled.Content>

      {!isPinCode && onClickLogInOrSignIn && (
        <ButtonContainer
          marginTop={5}
          width="100%"
        >
          <Button
            width="100%"
            height={40}
            onClick={onClickLogInOrSignIn}
            title={t(textButtonSubmit)}
            size="bold24"
            type={ButtonType.TypeHeavenlyActive}
            isDisabled={!isValid}
            tabIndex={tabIndexButtonSubmit}
          />
        </ButtonContainer>
      )}

      {!isPinCode && (
        <Styled.ErrorWrapper>
          <Styled.ErrorMessage isValidErrorPassword={isValidErrorPassword}>
            {t(serverError ? serverError : error)}
          </Styled.ErrorMessage>
        </Styled.ErrorWrapper>
      )}

      <Styled.InformationWrapper>
        <Styled.InformationText>
          {t('authentication.problemsAccess')}
        </Styled.InformationText>
        <Styled.InformationText>
          {t('authentication.support')}
        </Styled.InformationText>
        <Styled.PrivacyPolicy>
          {t('authentication.privatePolicy')}
        </Styled.PrivacyPolicy>
      </Styled.InformationWrapper>
    </Styled.Container>
  );
}