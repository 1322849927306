import Slider from '@mui/material/Slider';
import * as Styled from './SliderDoubleMaterialUi.styled';
import { SliderDoubleMaterialUiProps } from './SliderDoubleMaterialUi.types';

export function SliderDoubleMaterialUi({
  step,
  min,
  max,
  value,
  onChange,
  title,
  marginTop = 4,
}: SliderDoubleMaterialUiProps) {
  const ACTION_DATA = [
    {
      key: 1,
      label: 'от',
      value: value?.[0] || 'Н/Д',
    },
    {
      key: 2,
      label: 'до',
      value: value?.[1] || 'Н/Д',
    },
  ];

  return(
    <Styled.Container marginTop={marginTop}>
      <Styled.Title>
        {title}
      </Styled.Title>

      <Styled.SliderContainer>
        <Slider
          getAriaLabel={() => 'Temperature range'}
          value={value}
          onChange={onChange}
          size="small"
          valueLabelDisplay="auto"
          step={step}
          min={min}
          max={max}
        />

        <Styled.ActionsWrapper>
          {ACTION_DATA.map(({ key, label, value }) => (
            <Styled.ActionBlock key={key}>
              <Styled.ActionTitle>
                {label}
              </Styled.ActionTitle>

              <Styled.Action>
                <Styled.ActionValue>
                  {value}
                </Styled.ActionValue>
              </Styled.Action>
            </Styled.ActionBlock>
          ))}
        </Styled.ActionsWrapper>
      </Styled.SliderContainer>

    </Styled.Container>
  );
}