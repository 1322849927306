import styled from 'styled-components';
import { compose, layout, position } from 'styled-system';
import { Box, Text } from '../../../components/styledComponents';
import { SCREEN } from '../../../constants/constants';

export const ShapeChange = styled(Box).attrs({
  width: '100%',
  my: 1,
})`
  display: flex;
  align-items: center;
`;

export const ShapeChangeLine = styled(Box).attrs({
  position: 'relative',
  width: '100%',
})`
  &::before {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.blueAzure};
    transform: translateY(-50%);
    z-index: 1;
  }
`;

export const ShapeChangeText = styled(Text).attrs({
  variant: 'OpenSansBold18',
  position: 'relative',
  color: 'blueAzure',
  mx: 1,
})`
  ${compose(layout, position)};
  
  text-align: center;
  white-space: nowrap;

  @media (max-width: ${SCREEN.mobileM}) {
    font-size: 16px;
  }
`;
