import styled from 'styled-components';
import { Box, Text } from '../styledComponents';

export const CheckboxWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const CheckboxWrapperTitle = styled(Text).attrs({
  variant: 'regular16',
  color: 'snowWhite',
})``;

export const CheckboxWrapperBody = styled(Box)`
  display: flex;
  flex-direction: column;
`;