import styled, { css } from 'styled-components';
import { Box, Text } from '../../components/styledComponents';

type ContainerProps = {
  isLeft: boolean,
  width: number | string,
}

export const Container = styled(Box).attrs<ContainerProps>(({
  isLeft,
  width,
}) => ({
  width: `calc(120px + ${typeof width === 'number' ? width + 'px' : width})`,
  height: 40,
  bg: 'greenCobalt',
  position: 'fixed',
  left: isLeft ? 0 : 'initial',
  right: !isLeft ? 0 : 'initial',
  zIndex: 10,
}))<ContainerProps>`
  display: flex;
  align-items: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  cursor: pointer;
  user-select: none;
  transition: .25s all;
  
  ${({ theme: { colors: { lightGrayBlue, greenBlue } } }) => css`
    &:hover {
      transition: .25s all;
      background-color: ${lightGrayBlue};
    }
    &:active {
      transition: .25s all;
      background-color: ${greenBlue};
    }
  `}
  
`;

export const ButtonActiveSidePanel = styled(Box).attrs({
  width: '100%',
  height: '100%',
  ml: 2,
})`
  display: flex;
  align-items: center;
`;

export const TileSidePanel = styled(Text).attrs({
  variant: 'regular14',
  color: 'snowWhite',
  ml: 2,
})``;

type SidePanelContainerProps = {
  width: number | string,
  marginTop: number,
  isActive: boolean,
}

export const SidePanelContainer = styled(Box).attrs<SidePanelContainerProps>(({
  width,
  marginTop,
  isActive,
}) => ({
  width,
  as: 'aside',
  height: 'calc(100vh - 78px)',
  bg: 'grayAzure',
  position: 'absolute',
  right: 0,
  top: -marginTop * 4 + 4,
  mt: 0,
  px: isActive ? 3 : 0,
  py: 3,
}))<SidePanelContainerProps>`
  box-sizing: border-box;
  transition: .25s width, padding-left, padding-right ease-out;
`;