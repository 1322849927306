import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { INITIAL_VALUE } from './constants';
import * as Styled from './PasswordRecovery.styled';
import { AuthenticationPageWrapper } from '../../../components/AuthenticationPageWrapper';
import { VerificationCode } from '../../../components/VerificationCode';
import { INPUT_MASKS, PATH } from '../../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { useAuthenticationError } from '../../../hooks/useAuthenticationError';
import { useInput } from '../../../hooks/useInput';
import { setIsRegisterStataAction } from '../../../redux/reducers/auth';
import { passwordRecovery } from '../../../redux/reducers/auth/async';
import { FieldInput } from '../../../ui/FieldInput';
import { InputType } from '../../../ui/FieldInput/FieldInput.types';

export function PasswordRecovery() {
  const { t } = useTranslation();

  const userLogin = useInput({ initialValue: INITIAL_VALUE.login });
  const userPassword = useInput({ initialValue: INITIAL_VALUE.password, mask: INPUT_MASKS.password });
  const passwordRepeat = useInput({ initialValue: INITIAL_VALUE.passwordRepeat, mask: INPUT_MASKS.password });
  const navigate = useNavigate();

  const {
    isValid,
    error,
    serverError,
  } = useAuthenticationError({
    login: userLogin.value,
    password: userPassword.value,
    passwordRepeat: passwordRepeat.value,
    isPasswordRepeat: true,
  });
  const {
    isTransitionPinCodeRecovery,
  } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const {
    colors: {
      whiteLight,
      blueAzure,
      oliveGreen,
      lightGray,
    },
  } = useTheme();

  function onClickShapeChangeHandler() {
    navigate(PATH.signIn);
  }

  function onClickLogInOrSignInHandler() {
    if (!isValid) return;

    dispatch(passwordRecovery({
      email: userLogin.value,
      password: userPassword.value,
    }));
    dispatch(setIsRegisterStataAction(false));
  }

  return(
    <>
      {isTransitionPinCodeRecovery
        ? (
          <VerificationCode
            login={userLogin.value}
            password={userPassword.value}
            serverError={serverError}
          />
        )
        : (
          <AuthenticationPageWrapper
            onClickShapeChange={onClickShapeChangeHandler}
            onClickLogInOrSignIn={onClickLogInOrSignInHandler}
            error={error}
            serverError={serverError}
            isValid={isValid}
            textButtonSubmit="authentication.passwordRecoveryButton"
            textHelpInForm="authentication.signIn"
            textNavigateButton="authentication.passwordRecoveryByEmail"
            textSelectedForm="authentication.passwordRemembered"
          >
            <FieldInput
              { ...userLogin }
              placeholder={t('authentication.emailPlaceholder')}
              backgroundColor={whiteLight}
              outlineColor={blueAzure}
              borderColor={lightGray}
              color={oliveGreen}
              type={InputType.Email}
            />

            <Styled.ShapeChange>
              <Styled.ShapeChangeLine/>
              <Styled.ShapeChangeText>
                {t('authentication.enterNewPassword')}
              </Styled.ShapeChangeText>
              <Styled.ShapeChangeLine/>
            </Styled.ShapeChange>

            <FieldInput
              { ...userPassword }
              placeholder={t('authentication.newPassword')}
              backgroundColor={whiteLight}
              outlineColor={blueAzure}
              borderColor={lightGray}
              color={oliveGreen}
              type={InputType.Password}
              marginTop={2}
            />

            <FieldInput
              { ...passwordRepeat }
              placeholder={t('authentication.newPasswordRecovery')}
              backgroundColor={whiteLight}
              outlineColor={blueAzure}
              borderColor={lightGray}
              color={oliveGreen}
              type={InputType.Password}
              marginTop={2}
            />
          </AuthenticationPageWrapper>
        )}
    </>
  );
}