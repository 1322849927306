import { CreateEdgesProps } from './createEdges.types';

export function createEdges({
  graph,
  dataEdges,
  color,
}: CreateEdgesProps) {
  dataEdges?.forEach((graphEdge, index) => {
    graph.addEdgeWithKey(index, graphEdge.node1, graphEdge.node2, {
      color,
      label: graphEdge.currencies,
    });
  });
}