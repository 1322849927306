import React, { useEffect, useState } from 'react';

import { HandySvg } from 'handy-svg';

import styles from './AddingChains.module.scss';
import { createChainsHeaders, IDataMetrics } from './constants';
import arrow from '../../../../assets/icons/arrowNew.svg';

import { PopupWindow } from '../../../../components/PopupWindow';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';

import {
  checkForEmptyValuesAct,
  onUpdateItemCurrencyAct,
  onUpdateItemMethodAct,
  onUpdateItemPlatformAct,
  setChainsLengthAct,
  setNewListChainsAct,
} from '../../../../redux/reducers/createData';

import { postCreateChains } from '../../../../redux/reducers/createData/async';
import { CreateChainData } from '../../../../redux/types';

export function AddingChains() {
  const [isResultInfoModal, setIsResultInfoModal] = useState(false);
  const [isActiveRes, setIsActiveRes] = useState(false);

  const dispatch = useAppDispatch();
  const {
    chainsLength,
    cheinData,
    validBtn,
    loadingState,
    createChainResponse,
  } = useAppSelector((state) => state.createData);
  const {
    allPlatforms,
    allCurrencies,
    allMethods,
  } = useAppSelector((state) => state.tabels);
  const {
    authKey = '',
  } = useAppSelector((state) => state.auth);

  const dataMetrics: IDataMetrics[] = [
    {
      id: 'length',
      title: 'Длина цепочки:',
      actions: (value: number) => setChainsLengthAct(value),
      maxValue: 20,
      valueField: chainsLength,
      setNewMap: () => setNewListChainsAct(),
    },
  ];

  const handleChange = (
    value: string,
    actions: (value: number) => {
      payload: number;
      type: string;
    },
    maxValue?: number,
  ) => {
    if (+value >= 0 && (+value <= (maxValue || +value))) {
      dispatch(actions(+value));
    } else if (+value >= 0 && (+value >= (maxValue || +value))) {
      dispatch(actions(maxValue || +value));
    }
  };

  const setNewMapAddingChains = (
    action?: () => {
      payload: undefined;
      type: string;
    },
  ) => {
    if (action) {
      dispatch(action());
    }
  };

  const setValueInFieldStepsCreateChains = (
    valueField: string,
    index: number,
    action: any,
  ) => {
    dispatch(action({
      propField: valueField,
      index,
    }));
  };

  const indexFiled = (
    index: number,
    arrData: CreateChainData[] = cheinData,
  ) => {
    if (arrData.length - 1 === index) {
      return 0;
    }
    return index + 1;
  };

  function onClickCreateChainHandler() {
    if (validBtn && chainsLength > 1) {
      setIsActiveRes((prev) => !prev);
      dispatch(postCreateChains({ authKey }));
    }
  }

  useEffect(() => {
    dispatch(setNewListChainsAct());
  }, []);

  useEffect(() => {
    dispatch(checkForEmptyValuesAct());
  }, [cheinData]);

  useEffect(() => {
    if (isActiveRes) {
      setIsResultInfoModal(true);
      setIsActiveRes((prev) => !prev);
    }
  }, [createChainResponse]);

  return (
    <section className={styles.root}>
      <h2 className={styles.create_title}>Создание цепочки</h2>

      <div className={styles.metrics}>
        {dataMetrics.map((item) => (
          <label htmlFor={item.id} className={styles.metrics_default} key={item.id}>
            <div className={styles.metrics_default_title}>{item.title}</div>
            <input
              type="number"
              id={item.id}
              placeholder="1"
              value={!item.valueField ? '' : item.valueField}
              className={styles.metrics_default_field}
              onChange={(e) => {
                handleChange(e.target.value, item.actions, item?.maxValue);
                setNewMapAddingChains(item?.setNewMap);
              }}
            />
          </label>
        ))}
      </div>

      <div className={styles.create_chains}>
        {chainsLength ? (createChainsHeaders.map((item) => (
          <div
            key={item.id}
            className={styles.create_chains_header}
          >
            {item.title}
          </div>
        ))) : null}

        {cheinData.map((item, i) => (
          <div className={styles.create_wrapper} key={item.id}>
            <label htmlFor={`chain-0${item.id}${i}`} className={styles.create_label}>
              <input
                list={`chain-0${item.id}${i}`}
                type="search"
                className={styles.create_field}
                value={cheinData[i].platform}
                onChange={(e) => {
                  setValueInFieldStepsCreateChains(
                    e.target.value,
                    i,
                    onUpdateItemPlatformAct,
                  );
                }}
              />
              <datalist id={`chain-0${item.id}${i}`}>
                {allPlatforms.map((platform, index) => (
                  <option value={platform.name} id={`${platform.id}`} key={`chain-0${item.id}${i}${index}`}/>
                ))}
              </datalist>
            </label>

            <label htmlFor={`chain-1${item.id}${i}`} className={styles.create_label}>
              <input
                list={`chain-1${item.id}${i}`}
                type="search"
                className={styles.create_field}
                value={cheinData[i].currency}
                onChange={(e) => {
                  setValueInFieldStepsCreateChains(
                    e.target.value,
                    i,
                    onUpdateItemCurrencyAct,
                  );
                }}
              />
              <datalist id={`chain-1${item.id}${i}`}>
                {allCurrencies.map((currency, index) => (
                  <option value={currency.name} id={`${currency.id}`} key={`chain-1${item.id}${i}${index}`}/>
                ))}
              </datalist>
            </label>

            <HandySvg
              src={arrow}
              className={styles.icon}
            />

            <label htmlFor={`chain-2${item.id}${i}`} className={styles.create_label}>
              <input
                list={`chain-2${item.id}${i}`}
                type="search"
                className={styles.create_field}
                value={cheinData[i].method}
                onChange={(e) => {
                  setValueInFieldStepsCreateChains(
                    e.target.value,
                    i,
                    onUpdateItemMethodAct,
                  );
                }}
              />
              <datalist id={`chain-2${item.id}${i}`}>
                {allMethods.map((method, index) => (
                  <option value={method.name} id={`${method.id}`} key={`chain-2${item.id}${i}${index}`}/>
                ))}
              </datalist>
            </label>

            <HandySvg
              src={arrow}
              className={styles.icon}
            />

            <label htmlFor={`chain-3${item.id}${i}`} className={styles.create_label}>
              <input
                list={`chain-3${item.id}${i}`}
                type="search"
                className={styles.create_field}
                value={cheinData[indexFiled(i)]?.currency}
                onChange={(e) => {
                  setValueInFieldStepsCreateChains(
                    e.target.value,
                    indexFiled(i),
                    onUpdateItemCurrencyAct,
                  );
                }}
              />
              <datalist id={`chain-3${item.id}${i}`}>
                {allCurrencies.map((currency, index) => (
                  <option value={currency.name} id={`${currency.id}`} key={`chain-3${item.id}${i}${index}`}/>
                ))}
              </datalist>
            </label>

            <label htmlFor={`chain-4${item.id}${i}`} className={styles.create_label}>
              <input
                list={`chain-4${item.id}${i}`}
                type="search"
                className={styles.create_field}
                value={cheinData[indexFiled(i)]?.platform}
                onChange={(e) => {
                  setValueInFieldStepsCreateChains(
                    e.target.value,
                    indexFiled(i),
                    onUpdateItemPlatformAct,
                  );
                }}
              />
              <datalist id={`chain-4${item.id}${i}`}>
                {allPlatforms.map((platform, index) => (
                  <option value={platform.name} id={`${platform.id}`} key={`chain-4${item.id}${i}${index}`}/>
                ))}
              </datalist>
            </label>
          </div>
        ))}
      </div>

      {chainsLength ? (
        <button
          type="submit"
          className={`
            ${styles.create_submit}
            ${!validBtn || chainsLength <= 1 ? styles.create_submit_error : ''}
          `}
          onClick={onClickCreateChainHandler}
        >
          Создать цепочку
        </button>
      ) : null}

      {loadingState !== 'loading'
        ? (
          <PopupWindow
            message={createChainResponse?.message || ''}
            isResultInfoModal={isResultInfoModal}
            setIsResultInfoModal={setIsResultInfoModal}
            bottom="0%"
            left="50%"
            translateX="-50%"
            translateY="-50%"
          />
        )
        : null}
    </section>
  );
}
