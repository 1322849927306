import styled, { css } from 'styled-components';
import { Box, Text } from '../styledComponents';

type PaginateContainerProps = {
  width: number | string,
}

export const PaginateContainer = styled(Box).attrs<PaginateContainerProps>(({ width }) => ({
  width,
}))<PaginateContainerProps>`
  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style-type: none;
    padding: 0;
  }

  ${({
    theme: {
      colors: {
        greenCobalt,
        snowWhite,
      },
    },
  }) => css`
    .paginate_item {
      padding: 8px 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: ${snowWhite};
      user-select: none;

      &__link {
        width: 100%;
        text-align: center;
      }

      &__active {
        border-radius: 4px;
        border: 1px solid ${greenCobalt};
        color: ${greenCobalt};
      }

      &__break {
        color: ${snowWhite};
        user-select: none;
      }
    }
  `};
`;

type PaginateImageProps = {
  totalPages: number,
}

export const PaginateImage = styled(Box).attrs({
  height: '100%',
})<PaginateImageProps>`
  display: ${({ totalPages }) => totalPages ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const PaginateImageText = styled(Text).attrs({
  variant: 'light14',
  color: 'snowWhite',
})`
  user-select: none;
`;