import styled from 'styled-components';
import { Box, Text } from '../../../../../../components/styledComponents';

export const RatesContainer = styled(Box).attrs({
  width: '100%',
})`
  display: flex;
`;

export const Card = styled(Box).attrs({
  width: '360px',
  height: '455px',
})`
  border: 2px solid #65B8FF;
  border-radius: 22px;
  display: flex;
  overflow: hidden;
  flex-direction: column; 
  box-sizing: border-box;
  background-image: url('./assets/img/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`;

export const CardBox = styled(Box).attrs({
  width: '100%',
  maxHeight: '65px',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #65B8FF;
`;

export const CardTitle = styled(Text).attrs({
  color: 'snowWhite',
  variant: 'bold24',
  margin: '20px 0',
  as: 'h2',
})`
  text-transform: uppercase;
`;

export const CardBoxContentButton = styled(Box).attrs({
  width: '100%',
})`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const CardList = styled(Box).attrs({
  pr: 6,
  pl: 6,
  pt: 6,
  margin: 0,
  as: 'ul',
})`
  text-align: left;
  list-style-type: none;
`;

export const CardItem = styled(Text).attrs({
  padding: 0,
  mb: 3,
  mt: 3,
  color: 'black',
  variant: 'medium18',
  as: 'li',
})`
  list-style-type: none;
`;

export const CardItemBlackPremium = styled(Text).attrs({
  pb: 5,
  mb: 4,
  mt: 4,
  color: 'black',
  variant: 'medium18',
  as: 'li',
})`
  list-style-type: none;
  border-bottom: 1px solid #E0E0E0;
`;

export const CardItemGrayOpacity = styled(Text).attrs({
  padding: 0,
  mb: 4,
  mt: 4,
  color: 'opacityGray',
  variant: 'medium18',
  as: 'li',
})`
  max-width: 290px;
  list-style-type: none;
`;

export const CardItemGray = styled(Text).attrs({
  pb: 5,
  mb: 4,
  mt: 4,
  color: 'grey5',
  variant: 'medium14',
  as: 'li',
})`
  list-style-type: none;
  border-bottom: 1px solid #E0E0E0;
`;

export const CardBoxContent = styled(Box).attrs({
  width: '100%',
})`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const CardBoxContentPremium = styled(Box).attrs({
  width: '100%',
  pl: 0,
})`
  display: flex;
`;

export const CardContentPremium = styled(Text).attrs({
  color: 'black',
  variant: 'medium24',
  margin: 0,

  pt: 3,
  as: 'h3',
})`
  text-align: left;
  display: flex;
  justify-content: flex-start;
  text-transform: uppercase;
`;

export const CardContent = styled(Text).attrs({
  color: 'black',
  variant: 'medium24',
  margin: 0,
  pr: 6,
  pl: 6,
  pt: 3,
  as: 'h3',
})`
  text-transform: uppercase;
`;
