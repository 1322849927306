export const theadName: {
  id: string;
  title: string;
}[] = [
  {
    id: 'platform_from',
    title: 'Откуда',
  },
  {
    id: 'currency_from',
    title: 'Валюта',
  },
  {
    id: 'sumStart',
    title: 'Объем из',
  },
  {
    id: 'method',
    title: 'Метод',
  },
  {
    id: 'sumEnd',
    title: 'Объем в',
  },
  {
    id: 'currency_to',
    title: 'Валюта',
  },
  {
    id: 'platform_to',
    title: 'Куда',
  },
  {
    id: 'rate',
    title: 'Курсы',
  },
  {
    id: 'tax',
    title: 'Комиссия',
  },
];
