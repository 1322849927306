import styled from 'styled-components';
import { Box } from '../../components/styledComponents';

export const HeaderContainer = styled(Box).attrs({
  width: '100vw',
  height: 'calc(100dvh - 78px)',
})``;

export const Container = styled(Box).attrs({
  width: '98%',
  height: '100%',
  mx: 'auto',
})``;