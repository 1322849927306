import styled from 'styled-components';
import { Box } from '../../../components/styledComponents/Box';

export const DropContainer = styled(Box).attrs({
  as: 'section',
  width: '35vw',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed gray;
  padding: 10px;
  margin: 5px;
  height: 30vh;
  margin-top: -350px;
`;
