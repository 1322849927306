export enum TooltipPlacement {
  bottomEnd = 'bottom-end',
  bottomStart = 'bottom-start',
  bottom = 'bottom',
  leftEnd = 'left-end',
  leftStart = 'left-start',
  left = 'left',
  rightEnd = 'right-end',
  rightStart = 'right-start',
  right = 'right',
  topEnd = 'top-end',
  topStart = 'top-start',
  top = 'top',
}

export type TooltipProps = {
  hint: string,
  element: JSX.Element,
  timeout?: number,
  enterDelay?: number,
  leaveDelay?: number,
  placement?: TooltipPlacement,
  isArrow?: boolean,
}