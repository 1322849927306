import { Dispatch, SetStateAction } from 'react';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { DataMetric } from '../../../../../../../../../../redux/reducers/filters/types';

export type ActionsWithMetricProps = {
  // Все данные метриков
  allMetrics: DataMetric[],
  // Действие по выбору всех элементов (action в redux)
  setStateAllMetric: ActionCreatorWithPayload<{ data: DataMetric[], isActive: boolean }, string>,
  // Определяет, активна ли кнопка выбрать все/удалить все
  isSelectedMetrics: boolean,
  // Действие по смене активности кнопки выбрать все/удалить все
  setIsSelectedMetric: Dispatch<SetStateAction<boolean>>,
  // Определяет, развернуты ли сейчас элементы
  isShowMetrics: boolean,
  // Действие по смене активности кнопки скрыть/показать
  setIsShowMetric: Dispatch<SetStateAction<boolean>>,
  // Определяет, есть ли кнопка показать/скрыть элементы
  isShowAll?: boolean,
  // Определяет, будет ли кнопка по выбору всех элементов находится справо
  isRightActions?: boolean,
}

export enum ActionsWithMetricText {
  SelectedMetrics = 'Выбрать все',
  DeleteMetrics = 'Удалить все',
  ShowMetrics = 'Показать',
  HideMetrics = 'Скрыть',
}