import { ActiveTab } from './componetns/Tabs/Tabs.types';
import { InitialState } from './Platform.types';

export const INITIAL_STATE: InitialState = {
  activeTab: ActiveTab.FreeInformation,
  platformData: {
    parserData: [],
    platformId: 0,
    platformName: '',
    platformSynonyms: [],
  },
};