import { ChangeEvent, useMemo, useState } from 'react';
import * as Styled from './DropDownList.styled';
import { DropDownListProps } from './DropDownList.types';
import { useAppDispatch } from '../../hooks/storeHooks';
import { DataMetric } from '../../redux/reducers/filters/types';

export function DropDownList({
  dataList,
  placeholder,
  setStateValueAction,
}: DropDownListProps) {
  const dispatch = useAppDispatch();

  const [state, setState] = useState<DataMetric[]>(dataList);
  const [isShow, setIsShow] = useState(false);

  function onClickSetStateActionHandler(item: DataMetric) {
    dispatch(setStateValueAction(item));
    setIsShow((prev) => !prev);
  }

  function onClickStateHandler() {
    setIsShow((prev) => !prev);
  }

  function onChangeFilterHandler(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value.toUpperCase();

    setState(dataList.filter(({ name }) => {
      return name.toUpperCase().includes(value);
    }));
  }

  const elementList = useMemo(() => {
    return state.map((item) => (
      <Styled.DropDownItemWrapper
        key={item.id}
        onClick={() => onClickSetStateActionHandler(item)}
      >
        <Styled.DropDownItem>
          {item.name}
        </Styled.DropDownItem>
      </Styled.DropDownItemWrapper>
    ));
  }, [state, dataList]);

  return (
    <Styled.DropDown>
      <Styled.Container>
        <Styled.DropDownSubstrate
          isShow={isShow}
          onClick={onClickStateHandler}
        />
        <Styled.DropDownInputWrapper>
          <Styled.DropDownInput
            placeholder={placeholder}
            onChange={onChangeFilterHandler}
            onClick={onClickStateHandler}
          />
        </Styled.DropDownInputWrapper>
        <Styled.DropDownListWrapper isShow={isShow}>
          {elementList}
        </Styled.DropDownListWrapper>
      </Styled.Container>
    </Styled.DropDown>
  );
}