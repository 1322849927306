import styled from 'styled-components';
import { color, compose, fontSize, space, variant } from 'styled-system';

export const Text = styled('div')(
  compose(
    color,
    space,
  ),
  {
    fontFamily: 'Roboto-Regular, sans-serif',
    fontWeight: 400,
    color: 'snowWhite',
  },
  variant({
    variants: {
      light10: {
        fontFamily: 'Roboto-Light, sans-serif',
        fontSize: 10,
        lineHeight: '12px',
        fontWeight: 300,
      },
      light12: {
        fontFamily: 'Roboto-Light, sans-serif',
        fontSize: 12,
        lineHeight: '14px',
        fontWeight: 300,
      },
      light14: {
        fontFamily: 'Roboto-Light, sans-serif',
        fontSize: 14,
        lineHeight: '16px',
        fontWeight: 300,
      },
      light16: {
        fontFamily: 'Roboto-Light, sans-serif',
        fontSize: 16,
        lineHeight: '18px',
        fontWeight: 300,
      },
      light20: {
        fontFamily: 'Roboto-Light, sans-serif',
        fontSize: 20,
        lineHeight: '22px',
        fontWeight: 300,
      },
      regular10: {
        fontFamily: 'Roboto-Regular, sans-serif',
        fontSize: 10,
        lineHeight: '12px',
        fontWeight: 400,
      },
      regular12: {
        fontFamily: 'Roboto-Regular, sans-serif',
        fontSize: 12,
        lineHeight: '14px',
        fontWeight: 400,
      },
      regular14: {
        fontFamily: 'Roboto-Regular, sans-serif',
        fontSize: 14,
        lineHeight: '16px',
        fontWeight: 400,
      },
      regular16: {
        fontFamily: 'Roboto-Regular, sans-serif',
        fontSize: 16,
        lineHeight: '18px',
        fontWeight: 400,
      },
      regular18: {
        fontFamily: 'Roboto-Regular, sans-serif',
        fontSize: 18,
        lineHeight: '20px',
        fontWeight: 400,
      },
      regular20: {
        fontFamily: 'Roboto-Regular, sans-serif',
        fontSize: 20,
        lineHeight: '22px',
        fontWeight: 400,
      },
      regular24: {
        fontFamily: 'Roboto-Regular, sans-serif',
        fontSize: 24,
        lineHeight: '26px',
        fontWeight: 400,
      },
      medium10: {
        fontFamily: 'Roboto-Medium, sans-serif',
        fontSize: 10,
        lineHeight: '12px',
        fontWeight: 500,
      },
      medium14: {
        fontFamily: 'Roboto-Medium, sans-serif',
        fontSize: 14,
        lineHeight: '16px',
        fontWeight: 500,
      },
      medium16: {
        fontFamily: 'Roboto-Medium, sans-serif',
        fontSize: 16,
        lineHeight: '18px',
        fontWeight: 500,
      },
      medium18: {
        fontFamily: 'Roboto-Medium, sans-serif',
        fontSize: 18,
        lineHeight: '20px',
        fontWeight: 500,
      },
      medium20: {
        fontFamily: 'Roboto-Medium, sans-serif',
        fontSize: 20,
        lineHeight: '22px',
        fontWeight: 500,
      },
      medium22: {
        fontFamily: 'Roboto-Medium, sans-serif',
        fontSize: 20,
        lineHeight: '28px',
        fontWeight: 500,
      },
      medium24: {
        fontFamily: 'Roboto-Medium, sans-serif',
        fontSize: 24,
        lineHeight: '26px',
        fontWeight: 500,
      },
      bold18: {
        fontFamily: 'Roboto-Bold, sans-serif',
        fontSize: 18,
        lineHeight: '20px',
        fontWeight: 700,
      },
      bold24: {
        fontFamily: 'Roboto-Bold, sans-serif',
        fontSize: 24,
        lineHeight: '26px',
        fontWeight: 700,
      },
      bold40: {
        fontFamily: 'Roboto-Bold, sans-serif',
        fontSize: 40,
        lineHeight: '40px',
        fontWeight: 700,
      },
      OpenSansBold14: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 14,
        lineHeight: '14px',
        fontWeight: 800,
      },
      OpenSansMedium16: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
        lineHeight: '18px',
        fontWeight: 500,
      },
      OpenSansBold16: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
        lineHeight: '18px',
        fontWeight: 700,
      },
      OpenSansBold18: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 18,
        lineHeight: '28px',
        fontWeight: 600,
      },
      OpenSansBold28: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 28,
        lineHeight: '30px',
        fontWeight: 600,
      },
      OpenSansBold26: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 24,
        lineHeight: '24px',
        fontWeight: 700,
      },
      OpenSansBold34: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 34,
        lineHeight: '34px',
        fontWeight: 800,
      },
    },
  }),
);
