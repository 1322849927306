import * as Styled from './CellContainer.styled';
import { CellContainerProps } from './CellContainer.types';

export function CellContainer({
  background,
  paddingVertical,
  children,
  isRow = false,
  isBorderBottom = false,
  colorBorder = '',
  onClick,
}: CellContainerProps) {
  return (
    <Styled.CellContainer
      paddingVertical={paddingVertical}
      background={background}
      isBorderBottom={isBorderBottom}
      colorBorder={colorBorder}
      onClick={onClick}
    >
      <Styled.CellWrapper isRow={isRow}>
        {children}
      </Styled.CellWrapper>
    </Styled.CellContainer>
  );
}