import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { dataTabs } from './constants';
import * as Styled from './Tabs.styled';
import { TabsProps } from './Tabs.types';
import { Button } from '../../../../components/Button';
import { ButtonType } from '../../../../components/Button/Button.types';

export function Tabs({
  activeTab,
  setActiveTab,
}: TabsProps) {
  const { t } = useTranslation();

  const onClickSetActiveTabHandler = useCallback((activeTabId: number) => {
    setActiveTab(activeTabId);
  }, []);

  return(
    <Styled.Container>
      {dataTabs.map(({ id, label }) => (
        <Button
          key={id}
          width={200}
          height={32}
          onClick={() => onClickSetActiveTabHandler(id)}
          title={t(label)}
          type={ButtonType.TypeBorder}
          isSelected={id === activeTab}
          size="regular14"
        />
      ))}
    </Styled.Container>
  );
}