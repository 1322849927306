export const dataTabs = [
  {
    id: 0,
    label: '_platform.tabs.freeInformation',
  },
  {
    id: 1,
    label: '_platform.tabs.currencies',
  },
  {
    id: 2,
    label: '_platform.tabs.incomingCourses',
  },
  {
    id: 3,
    label: '_platform.tabs.outgoingCourses',
  },
  {
    id: 4,
    label: '_platform.tabs.internalCourses',
  },
  {
    id: 5,
    label: '_platform.tabs.commissionData',
  },
];