import styled from 'styled-components';
import { Text } from '../../../../../../components/styledComponents';

export const Title = styled(Text).attrs({
  variant: 'bold40',
  color: 'blackBlue',
  as: 'h3',
  mb: 7,
  mt: 0,
})``;

export const TitleStrong = styled(Text).attrs({
  variant: 'bold40',
  color: 'greenCobalt',
  as: 'strong',
  mx: 2,
})``;