import styled from 'styled-components';
import { Text } from '../../../../../styledComponents';

type CellTextProps = {
  size: string,
  color: string,
}

export const CellText = styled(Text).attrs<CellTextProps>(({
  size,
  color,
}) => ({
  color,
  variant: size,
}))<CellTextProps>``;