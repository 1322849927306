import { Tooltip, Fade } from '@mui/material';
import * as Styled from './InformationWindow.styled';

import { InformationWindowProps } from './InformationWindow.types';

export function InformationWindow({
  width,
  title,
  label,
  color,
  height = '',
  hint = '',
  backgroundColor = '',
  sizeTitle = 'regular18',
  sizeLabel = 'regular14',
  marginTopLabel = 2,
}: InformationWindowProps) {
  return (
    <Tooltip
      title={hint}
      arrow
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      enterDelay={400}
      leaveDelay={300}
      placement="bottom"
    >
      <Styled.Container
        width={width}
        height={height}
        backgroundColor={backgroundColor}
        hint={hint}
      >
        <Styled.InformationWindowTitle
          size={sizeTitle}
          color={color}
        >
          {title}
        </Styled.InformationWindowTitle>
        <Styled.InformationWindowLabel
          size={sizeLabel}
          color={color}
          marginTopLabel={marginTopLabel}
        >
          {label}
        </Styled.InformationWindowLabel>
      </Styled.Container>
    </Tooltip>
  );
}