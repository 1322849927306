import { Route, Routes } from 'react-router-dom';
import {
  ROUTES_USER_ADMIN,
  ROUTES_USER_FREE,
  ROUTES_USER_PREMIUM,
  ROUTES_AUTHENTICATION,
} from './constants';
import { PATH } from '../../../constants/constants';
import { useAppSelector } from '../../../hooks/storeHooks';
import Page404 from '../../../pages/404/404';
import { Home } from '../../../pages/Home';
import { UserStatus } from '../../../types/CourseItem';

export function UserPages() {
  const {
    isLogged,
    userStatus = 0,
  } = useAppSelector((state) => state.auth);

  function availablePage() {
    if (!isLogged) return;

    switch (userStatus) {
      // Страницы пользователя АДМИН
      case UserStatus.adminUser:
        return ROUTES_USER_ADMIN.map((route) => (<Route { ...route }/>));
      // Страницы пользователя ПРЕМИУМ
      case UserStatus.premiumUser:
        return ROUTES_USER_PREMIUM.map((route) => (<Route { ...route }/>));
      // Страницы пользователя БЕСПЛАТНЫЙ
      case UserStatus.freeUser:
        return ROUTES_USER_FREE.map((route) => (<Route { ...route }/>));
      default:
        return;
    }
  }

  return(
    <Routes>
      {availablePage()}
      {/* Страницы Авторизации */}
      {ROUTES_AUTHENTICATION.map((route) => (<Route { ...route }/>))}
      {/* Стартовая страница */}
      <Route path={PATH.default} element={<Home/>}/>
      {/* При неправильной странице */}
      <Route path="*" element={<Page404/>}/>
    </Routes>
  );
}