import { useEffect } from 'react';
import Select from 'react-select';
import * as Styled from './MultiSelect.styled';
import { MultiSelectProps } from './MultiSelect.types';
import { Button } from '../../components/Button';

export function MultiSelect<T>({
  title,
  options,
  value,
  onChange,
  isLoading = false,
  isDisabled = false,
  isClearable = true,
  isMulti = true,
  name = 'select',
  isDependsOnAnother = false,
  isButtonSelectedAll = true,
  isDark = false,
}: MultiSelectProps<T>) {
  function onClickSetAllOptionsHandler() {
    onChange(options);
  }

  // Если поле зависимо от другого и в зависимом поле есть данные,
  // которых нет в списке опций, то отщищаем поле
  useEffect(() => {
    if (isDependsOnAnother && !Array.isArray(value) && !options.includes(value)) {
      onChange([]);
    }
  }, [options]);

  return(
    <Styled.Container>
      <Styled.TitleContainer>
        <Styled.Title>
          {title}
        </Styled.Title>

        {isMulti && isButtonSelectedAll && (
          <Button
            width={120}
            height={20}
            onClick={onClickSetAllOptionsHandler}
            title="Выбрать все"
            size='light16'
          />
        )}
      </Styled.TitleContainer>

      <Styled.SelectContainer>
        <Select
          isMulti={isMulti}
          name={name}
          options={options}
          value={value}
          onChange={onChange}
          closeMenuOnSelect={!isMulti}
          isLoading={isLoading}
          isDisabled={isLoading || isDisabled}
          isClearable={isClearable}
          classNamePrefix={isDark ? 'dark' : 'light'}
        />
      </Styled.SelectContainer>
    </Styled.Container>
  );
}