import { ViewCurrencyFilter } from './AllFilterChains.types';

export interface IMarksData {
  value: number;
  label: string;
}

export const marks: IMarksData[] = [
  {
    value: 0,
    label: '<1 мин',
  },
  {
    value: 1,
    label: '1 мин',
  },
  {
    value: 2,
    label: '10 мин',
  },
  {
    value: 3,
    label: '1ч',
  },
  {
    value: 4,
    label: '3 ч',
  },
  {
    value: 5,
    label: '24ч',
  },
  {
    value: 6,
    label: '48ч',
  },
  {
    value: 7,
    label: '>48ч',
  },
];

export const marksTimeExistence: IMarksData[] = [
  {
    value: 0,
    label: '>1 мин',
  },
  {
    value: 1,
    label: '> 1 дня',
  },
  {
    value: 2,
    label: '> 3 дней',
  },
  {
    value: 3,
    label: '> 10 дней',
  },
  {
    value: 4,
    label: '>30 дней',
  },

];

export const marksRule: IMarksData[] = [
  {
    value: 2,
    label: '<2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: '>10',
  },
];

export const viewCurrencyFilter: ViewCurrencyFilter[] = [
  {
    id: 1,
    name: 'Фиат нал.',
    title: 'fiat_cash',
  },
  {
    id: 2,
    name: 'Фиат безнал.',
    title: 'fiat_non_cash',
  },
  {
    id: 3,
    name: 'Крипто',
    title: 'crypto',
  },
];

export const methodMainElements = [
  'swift',
  'Trade',
  'Binance P2P',
  'Exchange',
  'Bybit P2P',
  'Cash out',
  'Cash in',
  'TRC20',
  'ERC20',
];
