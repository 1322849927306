import styled, { keyframes, css } from 'styled-components';
import { Box } from '../../components/styledComponents';

const loading = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

type ContainerProps = {
  width: number | string,
  height: number | string,
  background: string,
  borderRadius: number | string,
  marginTop: number,
  marginRight: number,
  marginLeft: number,
  marginBottom: number,
}

export const Container = styled(Box).attrs<ContainerProps>(({
  width,
  height,
  background,
  marginTop,
  marginRight,
  marginLeft,
  marginBottom,
}) => ({
  width,
  height,
  bg: background,
  position: 'relative',
  mt: marginTop,
  mr: marginRight,
  ml: marginLeft,
  mb: marginBottom,
}))<ContainerProps>`
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    animation: ${loading} 1.4s infinite;
  }

  ${({
    borderRadius,
    theme: {
      colors: {
        monochromaticBlue,
        lightGrayGhostly,
      },
    },
  }) => css`
    border-radius: ${borderRadius}px;
    box-shadow: 0 4px 12px ${monochromaticBlue};

    &::after {
      background: linear-gradient(90deg, transparent, ${lightGrayGhostly}, transparent);
    }
  `}
`;