import { useState, useEffect } from 'react';
import { useNodesHoverProps } from './useNodesHover.types';

export function useNodesHover({
  nodes,
  colorHover,
  colorDefault,
  colorDefaultEven,
}: useNodesHoverProps) {
  const [nodesHovering, setNodesHovering] = useState(new Map());

  function getColor(index: number) {
    return colorDefaultEven && index % 2
      ? colorDefaultEven
      : colorDefault;
  }

  function onMouseEnterOrMoveHandler(index: number) {
    setNodesHovering((prev) => {
      return new Map(prev.set(index, colorHover));
    });
  }

  function onMouseLeaveHandler(index: number) {
    setNodesHovering((prev) => {
      return new Map(prev.set(index, getColor(index)));
    });
  }

  useEffect(() => {
    if (!nodes) return;

    nodes.forEach((node, index) => {
      if (node) {
        node.addEventListener('mouseenter', () => onMouseEnterOrMoveHandler(index));
        node.addEventListener('mouseleave', () => onMouseLeaveHandler(index));
      }
    });

    return function () {
      nodes.forEach((node, index) => {
        if (node) {
          node.removeEventListener('mouseenter', () => onMouseEnterOrMoveHandler(index));
          node.removeEventListener('mouseleave', () => onMouseLeaveHandler(index));
        }
      });

      nodesHovering.clear();
    };
  }, []);

  return {
    nodesHovering,
    getColor,
  };
}