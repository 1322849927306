import { useMemo, useState } from 'react';
import { ActionsWithMetric } from './components/ActionsWithMetric';
import * as Styled from './FilterMetric.styled';
import { FilterMetricProps } from './FilterMetric.types';
import { useAppDispatch } from '../../../../../../../../hooks/storeHooks';
import { DataMetric } from '../../../../../../../../redux/reducers/filters/types';

export function FilterMetric({
  title,
  allMetrics,
  selectedItems,
  setStateMetric,
  setStateAllMetric,
  isShowAll = true,
  isRightActions = false,
}: FilterMetricProps) {
  const dispatch = useAppDispatch();

  const [isSelectedMetrics, setIsSelectedMetric] = useState(true);
  const [isShowMetrics, setIsShowMetric] = useState(true);

  function onClickSelectedMetricHandler(metric: DataMetric) {
    dispatch(setStateMetric(metric));
  }

  const dataList = useMemo(() => {
    return allMetrics.map((item) => (
      <Styled.MetricItem
        key={item.id}
        isActive={selectedItems.includes(item)}
        isRightActions={isRightActions}
        onClick={() => onClickSelectedMetricHandler(item)}
      >
        {item.name}
      </Styled.MetricItem>
    ));
  }, [allMetrics, selectedItems]);

  return (
    <Styled.MetricContainer>
      <Styled.MetricTitle>{title}:</Styled.MetricTitle>
      <Styled.MetricWrapper
        isRightActions={isRightActions}
      >
        <Styled.MetricList
          isScroll={isShowMetrics}
          isRightActions={isRightActions}
        >
          {dataList}
        </Styled.MetricList>
        <ActionsWithMetric
          allMetrics={allMetrics}
          setStateAllMetric={setStateAllMetric}
          isSelectedMetrics={isSelectedMetrics}
          setIsSelectedMetric={setIsSelectedMetric}
          isShowMetrics={isShowMetrics}
          setIsShowMetric={setIsShowMetric}
          isShowAll={isShowAll}
          isRightActions={isRightActions}
        />
      </Styled.MetricWrapper>
    </Styled.MetricContainer>
  );
}