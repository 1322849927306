import * as Styled from './ButtonContainer.styled';
import { ButtonContainerProps } from './ButtonContainer.types';

export function ButtonContainer({
  children,
  marginLeft = 0,
  marginRight = 0,
  marginTop = 0,
  marginBottom = 0,
  width,
}: ButtonContainerProps) {
  return (
    <Styled.Container
      marginRight={marginLeft}
      marginLeft={marginRight}
      marginTop={marginTop}
      marginBottom={marginBottom}
      width={width}
    >
      {children}
    </Styled.Container>
  );
}