import React from 'react';
import { Advantages } from './components/Advantages';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { Possibilities } from './components/Possibilities';
import { Preview } from './components/Preview';
import { Rates } from './components/Rates';
import { MetaTags } from '../../components/MetaTags';

export function Home() {
  return(
    <>
      <MetaTags
        title="Arbitoring - P2P, спотовая торговля, фьючерсы"
        description="Сканер связок для торговли и арбитража на различных платформах"
        name="Arbitoring"
        type="article"
      />
      <Header/>

      <main>
        <Preview/>
        <Advantages/>
        <Possibilities/>
        <Rates/>
      </main>

      <Footer/>
    </>
  );
}