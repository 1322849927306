import { useEffect, useState } from 'react';
import { StrategyParams } from '../../../../components/Table/components/TableBodyCell/components/CellStrategyParams/CellStrategyParams.types';
import { INPUT_MASKS } from '../../../../constants/constants';
import { useInput } from '../../../../hooks/useInput';
import { useModal } from '../../../../hooks/useModal';
import { INITIAL_VALUE } from '../../constants';

// Модуль, который отвечат за ячейку параметров откр/закр
export function useStrategy() {
  // Помещаем данные ячейки, на которыю кликнул пользователь
  const [dataCellStrategy, setDataCellStrategy] = useState<StrategyParams>(INITIAL_VALUE.dataCellStrategy);

  const modalStrategy = useModal();

  // Контроллеры полей
  const controllerBidAskDiff = useInput({ initialValue: dataCellStrategy.bidAskDiff, mask: INPUT_MASKS.number });
  const controllerDateDiff = useInput({ initialValue: dataCellStrategy.dateDiff, mask: INPUT_MASKS.number });
  const controllerDiff1 = useInput({ initialValue: dataCellStrategy.diff1, mask: INPUT_MASKS.floatNumbers });
  const controllerDiff2 = useInput({ initialValue: dataCellStrategy.diff2, mask: INPUT_MASKS.floatNumbers });
  const controllerLossLimit = useInput({ initialValue: dataCellStrategy.lossLimit, mask: INPUT_MASKS.floatNumbers });
  const controllerProfitLimit = useInput({ initialValue: dataCellStrategy.profitLimit, mask: INPUT_MASKS.floatNumbers });
  const [listCurrency, setListCurrency] = useState(dataCellStrategy.currencyList);

  function onClickSetDataStrategyParamsHandler() {
    // Бэк пока не предоставил роут
  }

  // Метод закритыя модалки и сброс не сохраненных значений
  function onClickResetDataStrategyHandler() {
    modalStrategy.onClick();

    controllerBidAskDiff.setValueInField(dataCellStrategy.bidAskDiff);
    controllerDateDiff.setValueInField(dataCellStrategy.dateDiff);
    controllerDiff1.setValueInField(dataCellStrategy.diff1);
    controllerDiff2.setValueInField(dataCellStrategy.diff2);
    controllerLossLimit.setValueInField(dataCellStrategy.lossLimit);
    controllerProfitLimit.setValueInField(dataCellStrategy.profitLimit);
  }

  // Обновляем данные в полях
  useEffect(() => {
    controllerBidAskDiff.setValueInField(dataCellStrategy.bidAskDiff);
    controllerDateDiff.setValueInField(dataCellStrategy.dateDiff);
    controllerDiff1.setValueInField(dataCellStrategy.diff1);
    controllerDiff2.setValueInField(dataCellStrategy.diff2);
    controllerLossLimit.setValueInField(dataCellStrategy.lossLimit);
    controllerProfitLimit.setValueInField(dataCellStrategy.profitLimit);
   
    setListCurrency(dataCellStrategy.currencyList);
  }, [dataCellStrategy]);

  return {
    modalStrategy,
    setDataCellStrategy,
    onClickResetDataStrategyHandler,
    controllerBidAskDiff,
    controllerDateDiff,
    controllerDiff1,
    controllerDiff2,
    controllerLossLimit,
    controllerProfitLimit,
    listCurrency,
    onClickSetDataStrategyParamsHandler,
  };
}