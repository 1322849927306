import { useTheme } from 'styled-components';
import * as Styled from './ModalStrategy.styled';
import { ModalStrategyProps } from './ModalStrategy.types';
import { FieldInput } from '../../../../ui/FieldInput';
import { MultiSelect } from '../../../../ui/MultiSelect';
import { getTimeFormating } from '../../../../utils/getTimeFormating';

export function ModalStrategy({
  controllerBidAskDiff,
  controllerDateDiff,
  controllerDiff1,
  controllerDiff2,
  controllerLossLimit,
  controllerProfitLimit,
  listCurrency,
  metricCurrency,
}: ModalStrategyProps) {
  const {
    colors: {
      arsenic,
    },
  } = useTheme();

  return(
    <Styled.Container>
      <FieldInput
        title='Bid Ask Diff'
        { ...controllerBidAskDiff }
      />
      <MultiSelect
        title='Валюты'
        value={listCurrency}
        onChange={() => {
          // Бэк предоставил данные не в том виде
        }}
        options={metricCurrency}
        isButtonSelectedAll={false}
        isDisabled
        isDark
      />
      <FieldInput
        title='Дата разница'
        value={getTimeFormating({ date: Number(controllerDateDiff.value), compressionDate: true })}
        onChange={controllerDateDiff.onChange}
        backgroundColor={arsenic}
        isDisabled
      />
      <FieldInput
        title='Разница 1'
        { ...controllerDiff1 }
      />
      <FieldInput
        title='Разница 2'
        { ...controllerDiff2 }
      />
      <FieldInput
        title='Лимит потерь'
        { ...controllerLossLimit }
      />
      <FieldInput
        title='Лимит прибыли'
        { ...controllerProfitLimit }
      />
    </Styled.Container>
  );
}