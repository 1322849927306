import styled from 'styled-components';
import { SCREEN } from '../../constants/constants';
import { Box } from '../styledComponents';

export const AuthenticationForm = styled(Box).attrs({
  width: '100%',
  padding: '0',
  bg: 'grayAzure',
  height: '100dvh',
})`
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  overflow-x: hidden;
  scrollbar-width: none;
`;

export const Container = styled(Box).attrs({
  padding: '0',
  mx: 'auto',
  px: 2,
})`
  display: flex;
  justify-content: center;
`;

export const FormWrapper = styled(Box).attrs({
  bg: 'white',
  width: '100%',
  padding: '0',
  my: 0,
  mx: 'auto',
})`
  box-sizing: border-box;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%),
  0 1px 2px 0 rgb(0 0 0 / 6%);
  max-width: 1280px;
  display: flex;
  justify-content: center;
  flex: 1 1 0;
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0;
`;

export const FormContentImage = styled(Box).attrs({
  width: '100%',
  padding: '0',
})`
  display: flex;
  text-align: center;
  flex: 1 1 0;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;

  @media (max-width: ${SCREEN.tablets}) {
    display: none;
  }
`;

export const FormImage = styled('img').attrs({
  width: '100%',
  height: '100%',
})`
  background-size: contain;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  object-fit: contain;
  user-select: none;
  pointer-events: none;
`;