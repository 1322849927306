import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { INITIAL_VALUE } from './constants';
import { AuthenticationPageWrapper } from '../../../components/AuthenticationPageWrapper';
import { VerificationCode } from '../../../components/VerificationCode';
import { INPUT_MASKS, PATH } from '../../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { useAuthenticationError } from '../../../hooks/useAuthenticationError';
import { useInput } from '../../../hooks/useInput';
import { setIsRegisterStataAction } from '../../../redux/reducers/auth';
import { signIn } from '../../../redux/reducers/auth/async';
import { FieldInput } from '../../../ui/FieldInput';
import { InputType } from '../../../ui/FieldInput/FieldInput.types';

export function SignUp() {
  const { t } = useTranslation();
  const login = useInput({ initialValue: INITIAL_VALUE.login });
  const password = useInput({ initialValue: INITIAL_VALUE.password, mask: INPUT_MASKS.password });
  const passwordRepeat = useInput({ initialValue: INITIAL_VALUE.passwordRepeat, mask: INPUT_MASKS.password });
  const navigate = useNavigate();
  const {
    isValid,
    error,
    serverError,
  } = useAuthenticationError({
    login: login.value,
    password: password.value,
    passwordRepeat: passwordRepeat.value,
    isPasswordRepeat: true,
  });

  const {
    isTransitionPinCode,
  } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const {
    colors: {
      whiteLight,
      blueAzure,
      oliveGreen,
      lightGray,
    },
  } = useTheme();

  function onClickShapeChangeHandler() {
    navigate(PATH.signIn);
  }

  function onClickLogInOrSignInHandler() {
    if (!isValid) return;

    dispatch(signIn({
      email: login.value,
      password: password.value,
    }));
    dispatch(setIsRegisterStataAction(true));
  }

  return(
    <>
      {isTransitionPinCode
        ? (
          <VerificationCode
            login={login.value}
            password={password.value}
            serverError={serverError}
          />
        )
        : (
          <AuthenticationPageWrapper
            onClickShapeChange={onClickShapeChangeHandler}
            onClickLogInOrSignIn={onClickLogInOrSignInHandler}
            error={error}
            serverError={serverError}
            isValid={isValid}
            textButtonSubmit="authentication.createAccount"
            textHelpInForm="authentication.signIn"
            textNavigateButton="authentication.shapeChangeSignIn"
            textSelectedForm="authentication.alreadyAccount"
          >
            <FieldInput
              { ...login }
              placeholder={t('authentication.emailPlaceholder')}
              backgroundColor={whiteLight}
              outlineColor={blueAzure}
              borderColor={lightGray}
              color={oliveGreen}
              type={InputType.Email}
            />

            <FieldInput
              { ...password }
              placeholder={t('authentication.passwordPlaceholder')}
              backgroundColor={whiteLight}
              outlineColor={blueAzure}
              borderColor={lightGray}
              color={oliveGreen}
              type={InputType.Password}
              marginTop={2}
            />

            <FieldInput
              { ...passwordRepeat }
              placeholder={t('authentication.passwordRepeatPlaceholder')}
              backgroundColor={whiteLight}
              outlineColor={blueAzure}
              borderColor={lightGray}
              color={oliveGreen}
              type={InputType.Password}
              marginTop={2}
            />
          </AuthenticationPageWrapper>
        )}
    </>
  );
}