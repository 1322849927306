import styled from 'styled-components';
import { Box } from '../../components/styledComponents';

export const Container = styled(Box).attrs({
  width: 760,
})`
  .mySwiper {
    overflow-x: hidden;
  }

  .swiper-wrapper {
    display: flex;
  }

  .swiper-pagination {
    display: flex;
    justify-content: center;
    column-gap: 12px;
    padding-top: 16px;
  }
  
  .swiper-pagination-bullet {
    display: block;
    width: 9px;
    height: 9px;
    background-color: ${({ theme }) => theme.colors.grey4};
    border-radius: 50%;
  }

  .swiper-pagination-bullet-active {
    background-color: ${({ theme }) => theme.colors.greenCobalt};
  }
`;