import { useNavigate } from 'react-router-dom';
import { PATH } from '../../constants/constants';
import { useAppSelector } from '../storeHooks';

export function usePathLogged() {
  const { isLogged } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  function setPathAuntification(path: 'signIn' | 'signUp') {
    if (!isLogged) {
      navigate(path === 'signIn' ? PATH.signIn : PATH.signUp);
    } else {
      navigate(PATH.chains);
    }
  }

  return {
    setPathAuntification,
  };
}