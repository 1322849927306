import * as Styled from './AuthenticationPageWrapper.styled';
import { AuthenticationPageWrapperProps } from './AuthenticationPageWrapper.types';
import mainImage from '../../assets/icons/login-logo.jpg';
import { useAppSelector } from '../../hooks/storeHooks';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import { Loading } from '../Loading';

export function AuthenticationPageWrapper({
  children,
  onClickShapeChange,
  onClickLogInOrSignIn,
  error,
  textButtonSubmit,
  textHelpInForm,
  textNavigateButton,
  textSelectedForm,
  tabIndexButtonSubmit,
  isValid,
  isPinCode,
  onClickRequestCodeAgain,
  serverError,
}: AuthenticationPageWrapperProps) {
  const {
    loadingState,
  } = useAppSelector((state) => state.auth);

  return(
    <Styled.AuthenticationForm>
      {loadingState === 'loading'
        ? (
          <Loading/>
        )
        : (
          <Styled.Container>
            <Styled.FormWrapper>
              <AuthenticationWrapper
                onClickShapeChange={onClickShapeChange}
                onClickLogInOrSignIn={onClickLogInOrSignIn}
                error={error}
                serverError={serverError}
                textButtonSubmit={textButtonSubmit}
                textHelpInForm={textHelpInForm}
                textNavigateButton={textNavigateButton}
                textSelectedForm={textSelectedForm}
                tabIndexButtonSubmit={tabIndexButtonSubmit}
                isValid={isValid}
                isPinCode={isPinCode}
                onClickRequestCodeAgain={onClickRequestCodeAgain}
              >
                {children}
              </AuthenticationWrapper>
              <Styled.FormContentImage>
                <Styled.FormImage src={mainImage}/>
              </Styled.FormContentImage>
            </Styled.FormWrapper>
          </Styled.Container>
        )}
    </Styled.AuthenticationForm>
  );
}