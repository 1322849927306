export const cardRates = {
  exchangeRates: 'Курсы валют',
  bundles: 'Внутрибиржевые связки*',
  interExchangeBundles: 'Межбиржевые связки*',
  internationalConnections: 'Международные связки*',
  filters: 'Фильтры и сортировка',
  helps: 'Приоритетная поддержка',
  contentInfo: '* действует ограничение по количеству связок (3-5 шт.)',
  content: '14 дней бесплатно',
  price: '40 USDT в мес.',
};

export const cardRatesPremium = {
  exchangeRates: 'Курсы валют',
  bundles: 'Внутрибиржевые связки*',
  interExchangeBundles: 'Межбиржевые связки*',
  internationalConnections: 'Международные связки*',
  filters: 'Фильтры и сортировка',
  helps: 'Приоритетная поддержка',
  contentInfo: '* действует ограничение по количеству связок (3-5 шт.)',
  content: '14 дней бесплатно',
  price: '40 USDT в мес.',
};