import React, { useState, useEffect } from 'react';
import { IUserErrorMessage } from './UserError.types';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { postUserErrorMessage } from '../../../../redux/reducers/tabels/async';

import './UserError.scss';

export function UserError({
  idChains,
  isActive,
  setIsActive,
}: { idChains: string | null, isActive: boolean, setIsActive: (state: boolean) => void }) {
  const dispatch = useAppDispatch();
  const {
    authKey = '',
  } = useAppSelector((state) => state.auth);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [maxChars, setmaxChars] = useState<number>(0);
  const [userErrorMessage, setUserErrorMessage] = useState<IUserErrorMessage>({
    error_message: '',
    id_chains: idChains,
  });

  useEffect(() => {
    const newObj = {
      ...userErrorMessage,
      error_message: errorMessage,
    };
    setUserErrorMessage(newObj);
  }, [errorMessage]);

  const blockedScroll = () => {
    const getHTML = document.querySelector('html');

    if (isActive && getHTML !== null) {
      getHTML.style.overflowY = 'hidden';
    } else if (getHTML !== null) {
      getHTML.style.overflowY = '';
    }
  };

  useEffect(() => {
    blockedScroll();
  }, [isActive]);

  const classNames1 = 'user_error_substrate';
  const classNames2 = 'user_error';

  return (
    <>
      <div
        className={isActive ? `${classNames1} user_error_active` : classNames1}
        onClick={() => setIsActive(false)}
      />
      <div className={isActive ? `${classNames2} user_error_active` : classNames2}>
        <div className="user_error_exit" onClick={() => setIsActive(false)}/>
        <h2 className="user_error_title">Сообщить об ошибке</h2>
        <h3 className="user_error_subtitle">{`Цепочка № ${idChains}`}</h3>
        <textarea
          name="userError"
          id="userError"
          className="user_error_textarea"
          placeholder="Введите сообщение об ошибке"
          maxLength={500}
          value={errorMessage}
          onChange={(e) => {
            setErrorMessage(e.target.value);
            setmaxChars(e.target.value.length);
          }}
        />
        <div className="user_error_post">
          <div className="user_error_chars">{`${maxChars} / 500`}</div>
          <button
            type="submit"
            className="user_error_submit"
            onClick={() => {
              dispatch(postUserErrorMessage({
                authKey,
                dataObj: userErrorMessage,
              }));
              setUserErrorMessage({
                ...userErrorMessage,
                error_message: '',
              });
              setErrorMessage('');
              setmaxChars(0);
              setIsActive(false);
            }}
          >
            Отправить
          </button>
        </div>
      </div>
    </>
  );
}
