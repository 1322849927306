import styled from 'styled-components';
import { Box } from '../../components/styledComponents';

export const Container = styled(Box).attrs({
  height: 'calc(100vh - 78px)',
  width: '95%',
  mx: 'auto',
})`
  display: flex;
  flex-direction: column;
`;

export const TableContainer = styled(Box).attrs({
  height: '80%',
})``;