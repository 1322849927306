import styled from 'styled-components';
import { Box, Text } from '../../components/styledComponents';

export const NotFound = styled(Box).attrs({
  width: '100vw',
  height: 'calc(100vh - 97px)',
})`
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
`;

export const NotFoundContainer = styled(Box).attrs({
  my: 'auto',
  mx: 0,
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const NotFoundErrorText = styled(Text).attrs({
  color: 'snowWhite',
  variant: 'bold24',
  margin: 0,
})`
  text-align: center;
`;

export const NotFoundErrorTitle = styled(Text).attrs({
  color: 'snowWhite',
  variant: 'bold24',
  mt: '10px',
})`
  text-align: center;
`;
