import Select, { SingleValue } from 'react-select';
import * as Styled from './FieldSelect.styled';
import { FieldSelectProps } from './FieldSelect.types';

export function FieldSelect<T, K>({
  options,
  keyToReplaceWithLabel,
  setStateField,
  width = '100%',
  height = 'auto',
  defaultValue,
  isDisabled = false,
  isLoading = false,
  isSearchable = false,
  isClearable = false,
  placeholder = '',
  title = '',
  isFullObject = false,
  paddingY = 1,
  isBorder = false,
}: FieldSelectProps<T, K>) {
  function onChange(event: SingleValue<any>) {
    isFullObject
      ? setStateField(event)
      : setStateField(event?.value || '');
  }

  // Метод, предназначенный для того, чтобы заменить ключ по полю 'inputKey' на ключ 'label' (Для react-select)
  function transformObjectsArray(inputArray: Array<any>, inputKey: string | null) {
    if (!inputKey) return inputArray;

    return inputArray.map(object => {
      let transformedObject;
      if (typeof object === 'string') {
        transformedObject = {
          label: object,
        };
      } else {
        const { [inputKey]: labelValue, ...rest } = object;
        transformedObject = typeof labelValue === 'string'
          ? { label: labelValue, ...rest }
          : { label: labelValue[inputKey], ...rest };
      }

      return transformedObject;
    });
  }

  // Метод, предназначенный для того, чтобы добавить ключ value со значением из поля 'keyName' (Для react-select)
  function addValueToArrayObjects(objects: any[], keyName: string | null) {
    if (!keyName || (objects.length && objects[0].hasOwnProperty('value'))) return objects;

    return objects.map(object => {
      const value = object[keyName];
      return {
        ...object,
        value,
      };
    });
  }

  return(
    <Styled.Container
      height={height}
      width={width}
      paddingY={paddingY}
      isBorder={isBorder}
    >
      {title && (
        <Styled.SelectTitle>
          {title}
        </Styled.SelectTitle>
      )}
      <Select
        className="basic-single"
        classNamePrefix="select"
        defaultValue={defaultValue}
        isDisabled={isDisabled || isLoading}
        isLoading={isLoading}
        isClearable={isClearable}
        isSearchable={isSearchable}
        placeholder={placeholder}
        options={addValueToArrayObjects(transformObjectsArray(options, keyToReplaceWithLabel), 'label')}
        onChange={onChange}
      />
    </Styled.Container>
  );
}