export const INITIAL_VALUE = {
  error: '',
};

export const ERROR_VALID_MESSAGE = {
  email: 'authentication.email',
  password: 'authentication.password',
  passwordShort: 'authentication.passwordShort',
  response: 'authentication.response',
  passwordsDoNotMatch: 'authentication.passwordsDoNotMatch',

  serverEmailNoSuch: 'authentication.serverEmailNoSuch',
  serverIncorrectEmail: 'authentication.serverIncorrectEmail',

  serverPasswordDoesNotContainSpecialCharacters: 'authentication.serverPasswordDoesNotContainSpecialCharacters',
  serverPasswordDoesNotContainUpperLetters: 'authentication.serverPasswordDoesNotContainUpperLetters',
  serverPasswordDoesNotContainLowerLetters: 'authentication.serverPasswordDoesNotContainLowerLetters',
  serverPasswordDoesNotContainNumbers: 'authentication.serverPasswordDoesNotContainNumbers',
  serverPasswordShort: 'authentication.serverPasswordShort',
  serverPasswordBanSymbol: 'authentication.serverPasswordBanSymbol',

  serverNoSuchUser: 'authentication.serverNoSuchUser',
  serverCodesAreNotEqual: 'authentication.serverCodesAreNotEqual',
  serverUserAlreadyExists: 'authentication.serverUserAlreadyExists',
  errorSystem: 'authentication.errorSystem',
  okay: '',
};

export enum ServerValidMessage {
  ServerEmailNoSuch =  'no such email',
  ServerIncorrectEmail = 'incorrect email',
  ServerIncorrectPassword = 'incorrect password',

  ServerPasswordDoesNotContainSpecialCharacters = 'password does not contain special characters',
  ServerPasswordDoesNotContainUpperLetters = 'password does not contain upper letters',
  ServerPasswordDoesNotContainLowerLetters = 'password does not contain lower letters',
  ServerPasswordDoesNotContainNumbers = 'password does not contain numbers',
  ServerPasswordShort = 'short password',
  ServerPasswordBanSymbol = 'ban symbol',

  ServerNoSuchUser = 'no such user',
  ServerCodesAreNotEqual = 'codes are not equal',
  ServerUserAlreadyExists = 'user already exists',
  Okay = '',
}