import React, { useEffect } from 'react';
import * as Styled from './AdminCourseModal.styled';
import { AdminCourseModalProps } from './AdminCourseModal.types';
import adminCloseFilter from '../../assets/icons/admin-close-filter.svg';
import arrow from '../../assets/icons/tab_icon.svg';
import { DASH } from '../../constants/constants';

export function AdminCourseModal({
  activeCourseModal,
  setActiveCourseModal,
  selectCourse,
}: AdminCourseModalProps) {
  const blockedScroll = () => {
    const getHTML = document.querySelector('html');

    if (activeCourseModal && getHTML !== null) {
      getHTML.style.overflowY = 'hidden';
    } else if (getHTML !== null) {
      getHTML.style.overflowY = '';
    }
  };

  useEffect(() => {
    blockedScroll();
  }, [activeCourseModal]);

  function onClickOpenCourseModalHandler() {
    setActiveCourseModal((prev) => !prev);
  }

  return (
    <>
      <Styled.Background
        isActive={activeCourseModal}
        onClick={onClickOpenCourseModalHandler}
      />
      <Styled.BackgroundActive isActive={activeCourseModal}>
        <Styled.CloseButtonModal
          src={adminCloseFilter}
          alt="Close"
          onClick={onClickOpenCourseModalHandler}
        />
        <Styled.Title>
          {`ID: ${selectCourse?.id ? selectCourse.id : DASH} (Правило: ${selectCourse?.rule_number ? selectCourse.rule_number : DASH})`}
        </Styled.Title>

        <Styled.Step>
          <Styled.SelectedPlatforms>{selectCourse?.platform_from ? selectCourse.platform_from : DASH}</Styled.SelectedPlatforms>
          <Styled.SelectCurrency>{selectCourse?.currency_from ? selectCourse.currency_from : DASH}</Styled.SelectCurrency>
          <Styled.ArrowIcon src={arrow} alt="arrow"/>
          <Styled.SelectCurrency>{selectCourse?.currency_to ? selectCourse.currency_to : DASH}</Styled.SelectCurrency>
          <Styled.SelectedPlatforms>{selectCourse?.platform_to ? selectCourse.platform_to : DASH}</Styled.SelectedPlatforms>
        </Styled.Step>

        <Styled.LinesContainer>
          <Styled.LinesContent>
            <Styled.LinesText>Способ перевода:</Styled.LinesText>
            {selectCourse?.platform_link
              ? (
                <Styled.LinkSource href={selectCourse.platform_link}>
                  {selectCourse?.method ? selectCourse.method : DASH}
                </Styled.LinkSource>
              )
              : (
                <Styled.LinesText>
                  {selectCourse?.method ? selectCourse.method : DASH}
                </Styled.LinesText>
              )
            }
          </Styled.LinesContent>
          <Styled.LinesContent>
            <Styled.LinesText>Курс:</Styled.LinesText>
            <Styled.LinesText>{selectCourse?.rate ? selectCourse.rate : DASH}</Styled.LinesText>
          </Styled.LinesContent>
        </Styled.LinesContainer>

        <Styled.LinesContainer>
          <Styled.LinesContent>
            <Styled.LinesText>Комиссия (%):</Styled.LinesText>
            <Styled.LinesText>{selectCourse?.tax ? selectCourse.tax : DASH}</Styled.LinesText>
          </Styled.LinesContent>
          <Styled.LinesContent>
            <Styled.LinesText>Комиссия фикс:</Styled.LinesText>
            <Styled.LinesText>{selectCourse?.tax_const ? selectCourse.tax_const : DASH}</Styled.LinesText>
          </Styled.LinesContent>
        </Styled.LinesContainer>

        <Styled.LinesContainer>
          <Styled.LinesContent>
            <Styled.LinesText>Max комиссия:</Styled.LinesText>
            <Styled.LinesText>{selectCourse?.tax_max ? selectCourse.tax_max : DASH}</Styled.LinesText>
          </Styled.LinesContent>
          <Styled.LinesContent>
            <Styled.LinesText>Min комиссия:</Styled.LinesText>
            <Styled.LinesText>{selectCourse?.tax_min ? selectCourse.tax_min : DASH}</Styled.LinesText>
          </Styled.LinesContent>
        </Styled.LinesContainer>

        <Styled.LinesContainer>
          <Styled.LinesContent>
            <Styled.LinesText>Max сумма транзакции:</Styled.LinesText>
            <Styled.LinesText>{selectCourse?.tran_max ? selectCourse.tran_max : DASH}</Styled.LinesText>
          </Styled.LinesContent>
          <Styled.LinesContent>
            <Styled.LinesText>Min сумма транзакции:</Styled.LinesText>
            <Styled.LinesText>{selectCourse?.tran_min ? selectCourse.tran_min : DASH}</Styled.LinesText>
          </Styled.LinesContent>
        </Styled.LinesContainer>

        <Styled.LinesContainer>
          <Styled.LinesContent>
            <Styled.LinesText>Время транзакции (сек):</Styled.LinesText>
            <Styled.LinesText>{selectCourse?.tran_speed ? selectCourse.tran_speed : DASH}</Styled.LinesText>
          </Styled.LinesContent>
          <Styled.LinesContent>
            <Styled.LinesText>Периодичность обновления курса:</Styled.LinesText>
            <Styled.LinesText>{selectCourse?.update_rate ? selectCourse.update_rate : DASH}</Styled.LinesText>
          </Styled.LinesContent>
        </Styled.LinesContainer>

        <Styled.LinesContainer>
          <Styled.LinesContent>
            <Styled.LinesText>Дата курса:</Styled.LinesText>
            <Styled.LinesText>{selectCourse?.last_change ? selectCourse.last_change : DASH}</Styled.LinesText>
          </Styled.LinesContent>
          <Styled.LinesContent>
            <Styled.LinesText>Автор:</Styled.LinesText>
            <Styled.LinesText>{selectCourse?.author ? selectCourse.author : DASH}</Styled.LinesText>
          </Styled.LinesContent>
        </Styled.LinesContainer>
      </Styled.BackgroundActive>
    </>
  );
}

