import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';
import { compose, layout, space } from 'styled-system';
import { Box, Text } from '../../components/styledComponents';
import { SCREEN } from '../../constants/constants';

export const Header = styled(Box).attrs({
  position: 'relative',
  bg: 'arsenic',
  width: '100vw',
  height: 78,
})`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px 2px ${({ theme }) => theme.colors.blackShadow};
  overflow-x: hidden;
`;

export const Container = styled(Box).attrs({
  width: '98%',
  mx: 'auto',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MainPageLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  text-decoration: none;
`;

export const NavigationContainer = styled(Box).attrs({
  position: 'relative',
  width: '100%',
  height: 57,
  pl: 5,
  pr: 3,
})`
  display: flex;
  align-items: center;
`;

export const NavigationMenuImage = styled('img').attrs({
  width: 25,
  height: 25,
})`
  ${layout};
  display: none;

  @media (max-width: ${SCREEN.desktops}) {
    display: block;
  }
`;

type NavigationProps = {
  isView: boolean;
}

export const Navigation = styled('nav').attrs({
  width: '100%',
  height: '100%',
})<NavigationProps>`
  ${compose(layout, space)};
  display: flex;
  align-items: center;

  @media (max-width: ${SCREEN.desktops}) {
    position: fixed;
    left: 50%;
    top: 78px;
    transform: translateX(-50%);
    z-index: 102;
    width: 95%;
    visibility: ${({ isView }) => isView ? 'visible' : 'hidden'};
    opacity: ${({ isView }) => isView ? 1 : 0};
    background: ${({ theme }) => theme.colors.arsenic};
    box-shadow: 0 0 10px 2px ${({ theme }) => theme.colors.blackShadow};
    flex-wrap: wrap;
    height: auto;
  }
`;

export const NavigationLink = styled(Link).attrs({
  height: '100%',
  mr: 2,
})`
  ${compose(layout, space)};
  display: flex;
  align-items: center;
  text-decoration: none;

  @media (max-width: ${SCREEN.desktops}) {
    margin-right: 0;
    padding-left: 8px;
  }
`;

type NavigationLinkTextProps = {
  isActive?: boolean;
}

export const NavigationLinkText = styled(Text).attrs<NavigationLinkTextProps>(({ isActive }) => ({
  variant: 'regular16',
  color: isActive ? 'greenCobalt' : 'snowWhite',
  height: '100%',
}))<NavigationLinkTextProps>`
  display: flex;
  align-items: center;
  transition: .2s all;
  user-select: none;

  &:hover {
    transition: .2s all;
    color: ${({
    theme,
    isActive,
  }) => !isActive ? theme.colors.snowWhite : theme.colors.greenCobalt};
  }

  &:active {
    transition: .2s all;
    color: ${({
    theme,
    isActive,
  }) => !isActive ? theme.colors.greenCobalt : theme.colors.greenCobalt};
  }

  @media (max-width: ${SCREEN.desktops}) {
    padding: 24px 0;
  }
`;

export const OptionsWrapper = styled(Box).attrs({
  height: '100%',
})`
  display: flex;
  align-items: center;

  @media (max-width: ${SCREEN.tablets}) {
    position: fixed;
    z-index: 104;
  }
`;

type OptionsSubstrateProps = {
  isActive: boolean;
}

export const OptionsSubstrate = styled(Box).attrs({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 103,
  width: '100vw',
  height: '100vh',
  bg: 'monochromaticBlue',
})<OptionsSubstrateProps>`
  transition: .5s all;

  ${({ isActive }) => isActive
    ? css`
            display: block;
            visibility: visible;
            opacity: 1;
          `
    : css`
            display: none;
            visibility: hidden;
            opacity: 0;
          `};
`;

type OptionsContainerProps = {
  isActive: boolean;
}

export const OptionsContainer = styled(Box).attrs<OptionsContainerProps>(({ isActive }) => ({
  height: '100%',
  p: isActive ? 4 : 0,
}))<OptionsContainerProps>`
  box-sizing: border-box;
  display: flex;
  transition: .5s all;

  ${({ isActive }) => isActive
    ? css`
            justify-content: flex-start;
            align-items: flex-start;
          `
    : css`
            justify-content: space-between;
            align-items: center;
          `};

  @media (max-width: ${SCREEN.tablets}) {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 104;
    background: ${({ theme }) => theme.colors.arsenic};
    flex-direction: column;
    width: 250px;

    ${({ isActive }) => isActive
    ? css`
              transform: translateX(0);
              visibility: visible;
              opacity: 1;
            `
    : css`
              transform: translateX(100%);
              visibility: hidden;
              opacity: 0;
            `};
`;

type OptionsRateProps = {
  isViewPhone?: boolean,
  isActive?: boolean,
}

export const OptionsRate = styled(Text).attrs<OptionsRateProps>(({
  isViewPhone,
  isActive,
}) => ({
  variant: 'medium14',
  color: 'snowWhite',
  bg: 'greenCobalt',
  py: 1,
  px: 3,
  mr: isViewPhone && isActive ? 0 : 4,
}))<OptionsRateProps>`
  display: block;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;

  &:hover {
    transition: .2s background-color;
    background-color: ${({ theme }) => theme.colors.lightGrayBlue};
  }

  &:active {
    transition: .2s background-color;
    background-color: ${({ theme }) => theme.colors.greenBlue};
  }

  @media (max-width: ${SCREEN.tablets}) {
    display: ${({
    isViewPhone,
    isActive,
  }) => isViewPhone && isActive ? 'block' : 'none'};
  }
`;

export const OptionsProfile = styled(Box).attrs({
  mr: 6,
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;

  @media (max-width: ${SCREEN.tablets}) {
    margin-right: 0;
    margin-bottom: 12px;
  }
`;

export const OptionsText = styled(Text).attrs({
  variant: 'medium16',
  color: 'snowWhite',
  mr: 3,
})`
  user-select: none;
`;

type OptionsSelectProps = {
  isActive: boolean,
}

export const OptionsSelect = styled(Box).attrs({
  position: 'relative',
  width: 14,
  height: 9,
})<OptionsSelectProps>`
  transition: .4s all;
  transform: ${({ isActive }) => isActive ? 'rotate(180deg)' : 'rotate(0)'};

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-25%, -50%) rotate(45deg);
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.snowWhite};
    width: 10px;
    height: 2px;
  }

  &::before {
    left: 50%;
    width: 2px;
    height: 10px;
    transform: translate(25%, -50%) rotate(45deg);
  }
`;

export const OptionsLogout = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const HamburgerWrapper = styled(Box).attrs({
  height: 21,
})`
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  cursor: pointer;

  @media (max-width: ${SCREEN.tablets}) {
    display: flex;
  }
`;

type HamburgerLayerProps = {
  isActive: boolean;
  isLong?: boolean;
  translateX?: number;
}

export const HamburgerLayer = styled(Box).attrs<HamburgerLayerProps>(({ isLong }) => ({
  as: 'span',
  width: isLong ? 36 : 30,
  height: 3,
  bg: 'greenCobalt',
}))<HamburgerLayerProps>`
  transition: .5s all;

  ${({
    isActive,
    translateX,
  }) => {
    if (isActive && translateX) {
      return css`
        transform: translateX(${translateX + 'px'});
        opacity: 0;
      `;
    }
    if (isActive) {
      return css`
        opacity: 0;
      `;
    }
    return css`
      opacity: 1;
      transform: translateX(0);
    `;
  }}
`;

type HamburgerExitProps = {
  isActive: boolean;
}

export const HamburgerExit = styled(Box).attrs({
  width: '100%',
  mb: 3,
})<HamburgerExitProps>`
  box-sizing: border-box;
  display: ${({ isActive }) => isActive ? 'flex' : 'none'};
  justify-content: space-between;
  align-items: center;
`;