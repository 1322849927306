import * as Styled from './Substrate.styled';
import { SubstrateProps } from './Substrate.types';

export function Substrate({
  width,
  height,
  background,
  children,
  borderRadius = 0,
  marginTop = 0,
  element = 'div',
}: SubstrateProps) {
  return (
    <Styled.Container
      width={width}
      height={height}
      background={background}
      borderRadius={borderRadius}
      marginTop={marginTop}
      element={element}
    >
      {children}
    </Styled.Container>
  );
}