import * as Styled from './CellWithBackgroundText.styled';
import { CellWithBackgroundTextProps } from './CellWithBackgroundText.types';
import { CellContainer } from '../../ui/CellContainer';

export function CellWithBackgroundText({
  text,
  color,
  size,
  background,
  backgroundText,
  paddingVertical,
  isBorderBottom,
  colorBorder,
  onClick,
}: CellWithBackgroundTextProps) {
  return (
    <CellContainer
      background={background}
      paddingVertical={paddingVertical}
      isBorderBottom={isBorderBottom}
      colorBorder={colorBorder}
      onClick={onClick}
    >
      <Styled.CellBackgroundText background={backgroundText}>
        <Styled.CellText
          size={size}
          color={color}
        >
          {text}
        </Styled.CellText>
      </Styled.CellBackgroundText>
    </CellContainer>
  );
}