import { MouseEvent } from 'react';
import * as Styled from './Hint.styled';
import { HintProps } from './Hint.types';

export function Hint({
  data,
}: HintProps) {

  function onClickStopPropagationHandler(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
  }

  return(
    <Styled.Container onClick={onClickStopPropagationHandler}>
      {data.map(({ key, text, title }) => (
        <Styled.RowContainer key={key}>
          <Styled.HintText>
            {text}
          </Styled.HintText>

          <Styled.HintTitle>
            {title}
          </Styled.HintTitle>
        </Styled.RowContainer>
      ))}
    </Styled.Container>
  );
}