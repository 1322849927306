import styled from 'styled-components';
import { Box } from '../../components/styledComponents';

type ContainerProps = {
  marginLeft: number,
  marginRight: number,
  marginTop: number,
  marginBottom: number,
  width?: number | string,
}

export const Container = styled(Box).attrs<ContainerProps>(({
  marginLeft,
  marginRight,
  marginTop,
  marginBottom,
  width,
}) => ({
  ml: marginLeft,
  mr: marginRight,
  mt: marginTop,
  mb: marginBottom,
  width: width ? width : 'auto',
}))<ContainerProps>``;