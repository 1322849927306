import styled from 'styled-components';
import { Box, Text } from '../../components/styledComponents';

type ModalWrapperProps = {
  isActive: boolean;
}

export const ModalWrapper = styled(Box)<ModalWrapperProps>`
  opacity: ${({ isActive }) => isActive ? 1 : 0};
  visibility: ${({ isActive }) => isActive ? 'visible' : 'hidden'};
  transition: .25s all;
`;

export const ModalSubstrate = styled(Box).attrs({
  position: 'fixed',
  zIndex: 1000,
  width: '100vw',
  height: '100vh',
  bg: 'oliveGreen',
  top: '50%',
  left: '50%',
})`
  transform: translate(-50%, -50%);
  opacity: 0.3;
`;

export const Container = styled(Box).attrs({
  position: 'fixed',
  minWidth: 500,
  maxWidth: 996,
  zIndex: 1000,
  py: 4,
  px: 5,
  bg: 'graniteGray',
  top: '50%',
  left: '50%',
})`
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  border-radius: 4px;
`;

export const Title = styled(Text).attrs({
  variant: 'regular20',
  color: 'snowWhite',
})`
  text-align: center;
`;

export const MessageText = styled(Text).attrs({
  color: 'snowWhite',
  variant: 'regular16',
})``;

export const ContainerExit = styled(Box).attrs({
  position: 'absolute',
  width: 22,
  height: 22,
  top: '12px',
  right: '20px',
})`
  cursor: pointer;
  user-select: none;
`;

export const ExitImage = styled('img').attrs({
  width: '100%',
  height: '100%',
})``;