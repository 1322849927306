import { CreateNodesProps } from './createNodes.types';

export function createNodes({
  graph,
  dataForNode,
  colorNode,
  nodeRadius = 1,
}: CreateNodesProps) {
  dataForNode.reduce((angle, nodeName) => {
    graph.addNode(nodeName, {
      x: Math.sin(angle) * nodeRadius,
      y: Math.cos(angle) * nodeRadius,
      color: colorNode,
      label: nodeName,
      size: 10,
    });

    // 2 * Math.PI / dataForNode.length - alpha;
    return angle + 2 * Math.PI / dataForNode.length;
  }, 0);
}