import { NavigationList } from './Navmenu.types';
import { PATH } from '../../constants/constants';

export const NAVIGATION_ADMIN_USER: NavigationList[] = [
  {
    id: 6,
    title: '_navMenu.courses',
    path: PATH.courses,
  },
  {
    id: 2,
    title: '_navMenu.chains',
    path: PATH.chains,
  },
  {
    id: 3,
    title: '_navMenu.favoriteChains',
    path: PATH.myChains,
  },
  {
    id: 4,
    title: '_navMenu.onlineOperations',
    path: PATH.onlineOperation,
  },
  {
    id: 5,
    title: '_navMenu.adminPanel',
    path: PATH.adminPanel,
  },
  {
    id: 7,
    title: 'График',
    path: PATH.graph,
  },
];

export const NAVIGATION_PREMIUM_USER: NavigationList[] = [
  {
    id: 8,
    title: '_navMenu.chains',
    path: PATH.chains,
  },
  {
    id: 9,
    title: '_navMenu.favoriteChains',
    path: PATH.myChains,
  },
];
