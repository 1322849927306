import { GetTimeFormating } from './getTimeFormating.types';

export const getTimeFormating = ({
  date,
  compressionDate,
}: GetTimeFormating) => {
  if (date < 0) return 'Н/Д';

  // учитывает високосные годы
  const secondsPerYear = 365.25 * 24 * 60 * 60;
  const years = Math.floor(date / secondsPerYear);
  const days = Math.floor((date % secondsPerYear) / (60 * 60 * 24));
  const hours = Math.floor(((date / (60 * 60)) % 24));
  const minutes = Math.floor((date / 60) % 60);
  const seconds = Math.floor(date % 60);
  
  if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
    return '0';
  }
  
  if (days === -1 && hours === -1 && minutes === -1 && seconds === -1) {
    return '-1';
  }
  
  if (compressionDate) {
    if (years !== 0) {
      return `${years}г ${days}д`;
    }

    if (days > 20) {
      return `${days}д`;
    }
  
    if (days !== 0) {
      return `${days}д ${hours}ч`;
    }
  
    if (hours !== 0) {
      return `${hours}ч ${minutes}m`;
    }
  
    if (minutes !== 0) {
      return `${minutes}м ${seconds}с`;
    }
  
    if (seconds !== 0) {
      return `${seconds}с`;
    }
  }
  
  return `${days !== 0 ? `${days}д ` : ''}
            ${hours !== 0 ? `${hours}ч ` : ''}
            ${minutes !== 0 ? `${minutes}м ` : ''}
            ${seconds !== 0 ? `${seconds}с ` : ''}`;
};