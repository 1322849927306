import { AlgorithmVersionType } from './AlgorithmVersion.types';

export const ALGORITHM_VERSION_DATA = [
  {
    id: AlgorithmVersionType.AlgorithmOne,
    title: 'Aлгоритм 1',
    marginBottom: 4,
    tabIndex: 1,
    hint: 'Первый алгоритм поиска со стандартными подмножествами: ищет цепочки по всем валютам/платформам/методам,не ищет #CASH (зависит от parameter_settings),с любым количеством правил и шагов внутри платформ, без ограничения по времени, с максимальной глубиной поиска 100000, маржинальности строго > 0.0 и строго < 100.0, без приоритета валют начала поиска.',
  },
  {
    id: AlgorithmVersionType.AlgorithmTwo,
    title: 'Aлгоритм 2',
    marginBottom: 4,
    tabIndex: 2,
    hint: 'Второй алгоритм поиска не строгого соответствия по подмножествам валют/платформ: ищет цепочки, которые включают в себя одно или несколько условий из подмножеств валют и платформ, а также все остальные подмножества. Если не выбрана ни одна валюта/платформа/метод, возьмет данное подмножество целиком (равносильно выбору всех валют/платформ/методов).',
  },
  {
    id: AlgorithmVersionType.AlgorithmThree,
    title: 'Aлгоритм 3',
    marginBottom: 4,
    tabIndex: 3,
    hint: 'Третий алгоритм поиска строгого соответствия по подмножествам валют/платформ: ищет цепочки, которые состоят только из условий подмножеств валют и платформ, а также все остальные подмножества. Если не выбрана ни одна валюта/платформа/метод, возьмет данное подмножество целиком (равносильно выбору всех валют/платформ/методов).',
  },
  {
    id: AlgorithmVersionType.AlgorithmFour,
    title: 'Aлгоритм 4',
    marginBottom: 0,
    tabIndex: 4,
    hint: 'Сообщите описание',
  },
];