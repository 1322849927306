import { useState, ChangeEvent } from 'react';
import { useInputProps } from './useInput.types';

export function useInput({
  initialValue,
  mask,
  maxValue,
}: useInputProps) {
  const [value, setValue] = useState(`${initialValue}`);

  function setValueInput(value: string) {
    if (maxValue && !Number.isNaN(value) && +value > maxValue) {
      setValue(maxValue + '');
    } else {
      setValue(value);
    }
  }

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const getValue = event.target.value;
    if (!mask) {
      setValueInput(getValue);
    }

    if (mask?.test(getValue) || getValue === '') {
      setValueInput(getValue);
    }
  }

  function setValueInField(value: number | string) {
    setValueInput(`${value ? value : ''}`);
  }

  return {
    value,
    onChange,
    setValueInField,
  };
}