import { createSlice } from '@reduxjs/toolkit';
import {
  setChainsLength,
  setNewListChains,
  onUpdateItemPlatform,
  onUpdateItemCurrency,
  onUpdateItemMethod,
  checkForEmptyValues,
  setNewListFiles,
  deleteListFiles,
  updateLoopId,
  onDownloadFilesDeleteLoopsCsv,
  onClearListFilesDeleteLoopsCsv,
} from './actions';
import * as asyncActs from './async';
import { CreateChainsResponse, DeleteLoopsResponseStatus, DeleteLoopsStatus } from './types';
import {
  CreateChainData,
  ResDownloadCsv,
} from '../../types';

export interface ICreateData {
  loadingState: 'loading' | 'failed' | 'success';
  error?: string;
  requestStatus?: number;
  isOk?: boolean;
  chainsLength: number;
  cheinData: CreateChainData[];
  validBtn: boolean;
  fileCSV: File[];
  resDownloadCsv?: ResDownloadCsv;
  updateLoopId: string;
  updateMetricsStatus?: number;
  updateMetricsMessage?: string;
  messageCreateChain?: string;
  createChainResponse?: CreateChainsResponse;
  deleteLoopsCsv: File[];
  deleteLoopsStatus?: DeleteLoopsStatus;
  deleteLoopsStatusCsvResponse?: DeleteLoopsResponseStatus,
  isDeleteLoopsAuto: boolean,
}

const initialState: ICreateData = {
  loadingState: 'success',
  chainsLength: 2,
  cheinData: [],
  validBtn: false,
  fileCSV: [],
  updateLoopId: '',
  deleteLoopsCsv: [],
  isDeleteLoopsAuto: false,
};

export const createData = createSlice({
  name: 'createData',
  initialState,
  reducers: {
    setChainsLength,
    setNewListChains,
    onUpdateItemPlatform,
    onUpdateItemCurrency,
    onUpdateItemMethod,
    checkForEmptyValues,
    setNewListFiles,
    deleteListFiles,
    updateLoopId,
    onDownloadFilesDeleteLoopsCsv,
    onClearListFilesDeleteLoopsCsv,
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActs.postCreateChains.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.postCreateChains.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.code;
      })
      .addCase(asyncActs.postCreateChains.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';

        state.createChainResponse = payload;
      });

    builder
      .addCase(asyncActs.postDownloadCSV.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.postDownloadCSV.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.message;
        state.isOk = false;
      })
      .addCase(asyncActs.postDownloadCSV.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';

        state.resDownloadCsv = payload;
        state.requestStatus = payload.status;
        state.isOk = payload.isOk;
      });

    builder
      .addCase(asyncActs.postUpdateMetrics.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.postUpdateMetrics.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.message;
      })
      .addCase(asyncActs.postUpdateMetrics.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';

        state.requestStatus = payload.status;
      });

    builder
      .addCase(asyncActs.getUpdateMetricsStatus.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.getUpdateMetricsStatus.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.message;
      })
      .addCase(asyncActs.getUpdateMetricsStatus.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';

        state.updateMetricsStatus = payload.status;
        state.updateMetricsMessage = payload.message;
      });

    builder
      .addCase(asyncActs.getStateDeleteLoops.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.getStateDeleteLoops.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.message;
      })
      .addCase(asyncActs.getStateDeleteLoops.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';

        state.deleteLoopsStatus = payload.data;
      });

    builder
      .addCase(asyncActs.setStateDeleteLoopsCsv.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.setStateDeleteLoopsCsv.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.message;
      })
      .addCase(asyncActs.setStateDeleteLoopsCsv.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';
        state.deleteLoopsStatusCsvResponse = payload;
      });

    builder
      .addCase(asyncActs.getStateDeleteLoopsAuto.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.getStateDeleteLoopsAuto.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.message;
      })
      .addCase(asyncActs.getStateDeleteLoopsAuto.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';
        state.isDeleteLoopsAuto = payload.is_on;
      });
  },
});

export const {
  setChainsLength: setChainsLengthAct,
  setNewListChains: setNewListChainsAct,
  onUpdateItemPlatform: onUpdateItemPlatformAct,
  onUpdateItemCurrency: onUpdateItemCurrencyAct,
  onUpdateItemMethod: onUpdateItemMethodAct,
  checkForEmptyValues: checkForEmptyValuesAct,
  setNewListFiles: setNewListFilesActions,
  deleteListFiles: deleteListFilesActions,
  updateLoopId: updateLoopIdAction,
  onDownloadFilesDeleteLoopsCsv: onDownloadFilesDeleteLoopsCsvAction,
  onClearListFilesDeleteLoopsCsv: onClearListFilesDeleteLoopsCsvAction,
} = createData.actions;

export default createData.reducer;
