import { useTheme } from 'styled-components';
import * as Styled from './Skeleton.styled';
import { SkeletonProps } from './Skeleton.types';

export function Skeleton({
  background,
  borderRadius = 4,
  width = '100%',
  height = '100%',
  marginTop = 0,
  marginRight = 0,
  marginBottom = 0,
  marginLeft = 0,
}: SkeletonProps) {
  const { colors: { blackBlue } } = useTheme();

  return <Styled.Container
    height={height}
    width={width}
    background={background ? background : blackBlue}
    borderRadius={borderRadius}
    marginTop={marginTop}
    marginRight={marginRight}
    marginBottom={marginBottom}
    marginLeft={marginLeft}
  />;
}