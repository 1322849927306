import styled from 'styled-components';
import { Box, Text } from '../styledComponents';

type TableWrapperProps = {
  width: number | string,
  height: number | string,
}

export const TableWrapper = styled(Box).attrs<TableWrapperProps>(({
  width,
  height,
}) => ({
  width,
  height,
}))<TableWrapperProps>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

type TableEmptyFieldProps = {
  background: string,
}

export const TableEmptyField = styled(Box).attrs<TableEmptyFieldProps>(({ background }) => ({
  width: '100%',
  height: '100%',
  bg: background,
}))<TableEmptyFieldProps>``;

type TableTableProps = {
  borderSpacing: number | string,
}

export const TableTable = styled(Box).attrs({
  as: 'table',
})<TableTableProps>`
  border-spacing: ${({ borderSpacing }) => borderSpacing};
`;

export const TableHeaderWrapper = styled(Box).attrs({
  as: 'thead',
})``;

export const TableBodyWrapper = styled(Box).attrs({
  as: 'tbody',
})``;

export const TableRow = styled(Box).attrs({
  as: 'tr',
})`
  display: table-row;
  width: 100%;
  cursor: pointer;
`;

export const SubstrateText = styled(Text).attrs({
  variant: 'regular16',
  color: 'snowWhite',
})``;