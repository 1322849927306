import { useEffect } from 'react';
import * as Styled from './Modal.styled';
import { ModalProps } from './Modal.types';

export function Modal({
  onClick,
  text,
}: ModalProps) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return(
    <>
      <Styled.Substrate onClick={onClick}/>
      <Styled.Container>
        <Styled.Title>
          Алгоритм Запущен!
        </Styled.Title>

        <Styled.ModalText>
          {text}
        </Styled.ModalText>
      </Styled.Container>
    </>
  );
}