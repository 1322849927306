import styled from 'styled-components';
import { Box, Text } from '../../../../../../../../../../components/styledComponents';


type ActionsWithMetricProps = {
  isRightActions: boolean | undefined,
}
export const ActionsWithMetric = styled(Box).attrs<ActionsWithMetricProps>(({ isRightActions }) => ({
  mt: isRightActions ? 0 : 2,
}))<ActionsWithMetricProps>`
  display: flex;
  align-items: center;
`;

export const SelectOrDeleteAllMetrics = styled(Text).attrs({
  variant: 'regular14',
  color: 'snowWhite',
  bg: 'grayBlue',
  py: 1,
  px: 3,
  mr: 4,
})`
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
`;

export const ShowOrHideMetrics = styled(Text).attrs({
  variant: 'regular14',
  color: 'lightGray',
})`
  cursor: pointer;
  user-select: none;
`;