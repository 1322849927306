import styled, { css } from 'styled-components';
import { Box, Text } from '../../components/styledComponents';

export const Container = styled(Box).attrs({
  width: '100%',
})`
  display: flex;
  flex-direction: column;
  column-gap: 12px;
`;

export const TitleContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled(Text).attrs({
  color: 'snowWhite',
  variant: 'light16',
})``;

export const SelectContainer = styled(Box).attrs({
  mt: 3,
})`
  ${({ theme: { colors: { grayBlue, lightGray, snowWhite, graniteGray } } }) => css`
    .dark__control {
      background-color: ${grayBlue};
      border: none;
      border-radius: 4px;
      cursor: pointer;
      white-space: nowrap;
      box-shadow: none;
    }
  
    .dark__control--is-focused {
      border-color: ${lightGray} !important;
      box-shadow: 0 0 0 1px ${lightGray};
    }
    
    .dark__indicator-separator,
    .dark__option--is-focused {
      background-color: ${lightGray}
    }
    
    .dark__indicator {
      svg {
        fill: ${lightGray};
      }
    }
    
    .dark__menu,
    .dark__option--is-selected {
      background-color: ${grayBlue};
    }
    
    .dark__option {
      font-size: 16px;
      line-height: 18px;
      padding: 10px;
      color: ${snowWhite};
    }
    
    .dark__option--is-focused {
      background-color: ${graniteGray};
    }
    
    .dark__single-value,
    .dark__input-container {
      color: ${snowWhite};
    }
  `}
`;