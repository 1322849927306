import styled from 'styled-components';
import { Box } from '../styledComponents';

type CloseWrapperProps = {
  width: number,
  height: number,
}

export const CloseWrapper = styled(Box).attrs<CloseWrapperProps>(({
  width,
  height,
}) => ({
  width: width,
  height: height,
}))<CloseWrapperProps>`
  cursor: pointer;
`;

export const CloseImage = styled('img').attrs({
  width: '100%',
  height: '100%',
})``;
