import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { TableBodyCell } from './components/TableBodyCell';
import { TableHeaderCell } from './components/TableHeaderCell';
import { CellWithDeviation } from './components/TableHeaderCell/components/CellWithDeviation';
import { useNodesHover } from './hooks/useNodesHover';
import * as Styled from './Table.styled';
import { DataBody, TableProps } from './Table.types';
import { getActiveSorting } from './utils/GetActiveSorting';
import { getRefRow } from './utils/GetRefRow';
import { Substrate } from '../../ui/Substrate';

export function Table({
  width,
  height,
  dataHeaders,
  dataBody,
  borderSpacing = 0,
  subName = '',
  isBorderBottom = false,
  sizeBodyCell = 'regular14',
  sizeHeaderCell = 'light12',
  sizeSubHeaderCell = 'light10',
  colorBodyCell = '',
  backgroundHeaderCell = '',
  colorBodyColumnHover = '',
  colorBodyColumn = '',
  colorBodyColumnEven = '',
  colorBorder = '',
  activeTypeTime,
  setSorting,
  fillSorting,
  isActiveSort,
  setActionByRow,
  colorSubstrate = '',
  isBorderHeader = false,
  setActionByCell,
}: TableProps) {
  const { t } = useTranslation();
  const {
    colors: {
      snowWhite,
      oliveGreen,
    },
  } = useTheme();

  // Хранит в себе референсы всех строк тела таблицы
  const refsRowsBodyTable = useRef<HTMLDivElement[] | null[]>([]);
  const {
    nodesHovering,
    getColor,
  } = useNodesHover({
    nodes: refsRowsBodyTable.current,
    colorHover: colorBodyColumnHover,
    colorDefault: colorBodyColumn,
    colorDefaultEven: colorBodyColumnEven,
  });

  // Метод, предназначенный для сортировки
  function onClickSetSortingHandler(header: string, isFieldWithTypeTime?: boolean, isTypeTime?: boolean) {
    if (setSorting) {
      setSorting(header + (isFieldWithTypeTime || isTypeTime ? activeTypeTime : ''));
    }
  }

  // Метод, срабатывающий при клике на строку
  function onClickSetActionByRowHandler(rowData: DataBody, index: number) {
    if (setActionByRow) {
      setActionByRow(rowData, index);
    }
  }

  function onClickSetActionByCellHandler(rowData: DataBody, i: number, j: number) {
    if (setActionByCell) {
      setActionByCell(rowData, i, j);
    }
  }

  return (
    <Styled.TableWrapper width={width} height={height}>
      {dataBody.length
        ? (
          <>
            <Styled.TableTable borderSpacing={borderSpacing}>
              <Styled.TableHeaderWrapper>
                <Styled.TableRow>
                  {dataHeaders.map((header) => (
                    <TableHeaderCell
                      key={header.id}
                      stringOrElement={
                        header.options?.isFieldWithDeviation
                          ? (
                            <CellWithDeviation
                              title={header.name}
                              isTimeSubTitleOne={header.options.isTime}
                              sizeTitle={sizeHeaderCell}
                              sizeSubTitle={sizeSubHeaderCell}
                            />
                          )
                          : header.name}
                      colSpan={header.options?.colspan}
                      isSorting={header.options?.isSorting}
                      hint={header.hint}
                      backgroundHeaderCell={backgroundHeaderCell ? backgroundHeaderCell : oliveGreen}
                      size={sizeHeaderCell}
                      onClick={() => onClickSetSortingHandler(header.header, header.options?.isFieldWithDeviation, header.options?.isTypeTime)}
                      isActiveSortingUp={getActiveSorting({
                        header: header.header,
                        fillSorting: fillSorting,
                        isActive: isActiveSort,
                        activeTypeTime: activeTypeTime,
                        isFieldWithTypeTime: header.options?.isFieldWithDeviation || header.options?.isTypeTime,
                      })}
                      isBorder={isBorderHeader}
                    />
                  ))}
                </Styled.TableRow>
              </Styled.TableHeaderWrapper>

              <Styled.TableBodyWrapper>
                {dataBody.map((row, index) => (
                  <Styled.TableRow
                    key={'' + row.id}
                    ref={(node) => getRefRow({ node, index, refs: refsRowsBodyTable })}
                    onClick={() => onClickSetActionByRowHandler(row, index)}
                  >
                    {dataHeaders.map(({ header, options }, j) => (
                      <TableBodyCell
                        key={header}
                        value={row[header]}
                        subValue={'' + row[header + subName]}
                        options={options}
                        background={nodesHovering.get(index) || getColor(index)}
                        size={sizeBodyCell}
                        color={colorBodyCell ? colorBodyCell : snowWhite}
                        isBorderBottom={isBorderBottom}
                        colorBorder={colorBorder ? colorBorder : snowWhite}
                        onClick={() => onClickSetActionByCellHandler(row, index, j)}
                      />
                    ))}
                  </Styled.TableRow>
                ))}
              </Styled.TableBodyWrapper>
            </Styled.TableTable>
            <Styled.TableEmptyField background={colorSubstrate}/>
          </>
        )
        : (
          <Substrate
            width="100%"
            height="100%"
            background={colorSubstrate}
            borderRadius={4}
          >
            <Styled.SubstrateText>
              {t('_components.noElement')}
            </Styled.SubstrateText>
          </Substrate>
        )}
    </Styled.TableWrapper>
  );
}