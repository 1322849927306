import styled, { css } from 'styled-components';
import { Box, Text } from '../styledComponents';

export const Container = styled(Box)`
  grid-column: 1 / 3;
  display: flex;
  flex-direction: column;

  @media (max-width: 510px) {
    grid-column: 1 / 2;
  }
`;

export const FilterHeader = styled(Box)`
  display: flex;
  flex-direction: column;
`;

type FilterBodyProps = {
  isDropDownBottom?: boolean;
}

export const FilterBody = styled(Box).attrs({
  mt: 1,
})<FilterBodyProps>`
  display: grid;
  grid-template-columns: 3fr 2fr;
  column-gap: 15px;

  ${({ isDropDownBottom }) => isDropDownBottom ? css`
    grid-template: 2fr 1fr / 1fr;
  ` : css`
    grid-template-columns: 3fr 2fr;
  `};

  @media (max-width: 510px) {
    grid-template: 2fr 1fr / 1fr;
  }
`;

export const FilterTitle = styled(Text).attrs({
  variant: 'regular16',
  color: 'snowWhite',
})``;

type FilterSubTitleProps = {
  isLightBackground: boolean,
}

export const FilterSubTitle = styled(Text).attrs<FilterSubTitleProps>(({ isLightBackground }) => ({
  variant: 'regular14',
  color: isLightBackground ? 'oliveGreen' : 'snowWhite',
}))<FilterSubTitleProps>``;

export const FilterWrapper = styled(Box).attrs({
  px: 1,
  py: 1,
})`
  display: flex;
  flex-direction: column;
`;

export const FilterSelected = styled(Box).attrs({
  minHeight: 28,
  mt: 1,
})`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
`;

type FilterSelectedItemWrapperProps = {
  background: string,
}

export const FilterSelectedItemWrapper = styled(Box).attrs<FilterSelectedItemWrapperProps>(({
  background,
}) => ({
  bg: background,
  py: 1,
  px: 3,
  mb: 1,
  mr: 1,
}))<FilterSelectedItemWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
`;

type FilterSelectedItemProps = {
  color: string,
}

export const FilterSelectedItem = styled(Text).attrs<FilterSelectedItemProps>(({
  color,
}) => ({
  color,
  variant: 'regular14',
}))<FilterSelectedItemProps>``;
