import { useEffect, useState, useCallback } from 'react';
import { useLoadGraph, useRegisterEvents, useSetSettings, useSigma } from '@react-sigma/core';
import { MultiDirectedGraph } from 'graphology';
import { useTheme } from 'styled-components';
import { SampleGraphProps } from './SampleGraph.types';
import { createEdges } from './utils/createEdges';
import { createNodes } from './utils/createNodes';
import { setColorInNodesAtActivate } from './utils/setColorInNodesAtActivate';

export function SampleGraph({
  graphEdges,
  platformsNonSharp,
  platformsSharp,
  platformsSharpRadius,
  selectedNodes,
  setSelectedNodes,
  childNodesOfSelectedNodes,
  resetChildNodes,
  addChildNodes,
}: SampleGraphProps) {
  const {
    colors: {
      yellowPink,
      carminePink,
      darkWhite,
      greenCobalt,
    },
  } = useTheme();

  // Необходимые сервисы графа
  const sigma = useSigma();
  const registerEvents = useRegisterEvents();
  const setSettings = useSetSettings();
  const loadGraph = useLoadGraph();

  const [hoveredNode, setHoveredNode] = useState<string | null>(null);
  const platformsNonSharpOrderBy = [...platformsNonSharp].sort();

  const onClickSetOrDeleteNewNodeHandler = useCallback((node: string) => {
    setSelectedNodes((prev) => {
      return prev.includes(node)
        ? prev.filter((prev) => prev !== node)
        : [...prev, node];
    });
    resetChildNodes();
  }, [setSelectedNodes, resetChildNodes]);

  // Отвечает за обновление выбранных и дочерних узлов при изменении графа.
  // Он проверяет наличие каждого выбранного узла и его дочерних узлов в обновленном графе.
  // Если узел не существует, он удаляет его из выбранных или дочерних узлов.
  // В конце эффекта обновляется состояние выбранных и дочерних узлов.
  useEffect(() => {
    // Получаем текущий граф
    const graph = sigma.getGraph();

    // Создаем копии массивов выбранных и дочерних узлов
    let updatedSelectedNodes = [...selectedNodes];
    let updatedChildNodes = new Set([...childNodesOfSelectedNodes]);

    // Обходим все выбранные узлы
    selectedNodes.forEach((selectedNode) => {
      // Проверяем, существует ли узел в графе
      if(graph.hasNode(selectedNode)) {
        // Если узел существует, мы добавляем его соседей в список дочерних узлов
        graph.neighbors(selectedNode).forEach((item) => {
          updatedChildNodes.add(item);
        });
      } else {
        // Если узел не существует, мы удаляем его из списка выбранных узлов
        updatedSelectedNodes = updatedSelectedNodes.filter(node => node !== selectedNode);
      }
    });

    // Фильтруем список дочерних узлов, удаляя узлы, которые не существуют в графе
    updatedChildNodes = new Set(Array.from(updatedChildNodes).filter(node => graph.hasNode(node)));

    // Обновляем состояние выбранных и дочерних узлов
    setSelectedNodes(updatedSelectedNodes);
    resetChildNodes();
    Array.from(updatedChildNodes).forEach(addChildNodes);

    // Фильтруем список дочерних узлов, удаляя узлы, которые не имеют связи с выбранными узлами
    updatedChildNodes = new Set(Array.from(updatedChildNodes).filter(node => {
      return updatedSelectedNodes.some(selectedNode => graph.neighbors(selectedNode)?.includes(node));
    }));

    // Обновляем состояние дочерних узлов
    resetChildNodes();
    Array.from(updatedChildNodes).forEach(addChildNodes);
  }, [selectedNodes, sigma, addChildNodes, setSelectedNodes, resetChildNodes]);

  // Создание нод и ребер графа
  useEffect(() => {
    const graph = new MultiDirectedGraph();

    createNodes({
      graph,
      dataForNode: platformsNonSharpOrderBy,
      colorNode: yellowPink,
    });

    createNodes({
      graph,
      dataForNode: platformsSharp,
      colorNode: yellowPink,
      nodeRadius: platformsSharpRadius,
    });

    createEdges({
      graph,
      dataEdges: graphEdges,
      color: carminePink,
    });

    loadGraph(graph);

    registerEvents({
      enterNode: (event) => setHoveredNode(event.node),
      leaveNode: () => setHoveredNode(null),
      clickNode: (event) => onClickSetOrDeleteNewNodeHandler(event.node),
    });
  }, [
    loadGraph,
    registerEvents,
    platformsNonSharp,
    platformsSharp,
    graphEdges,
    platformsSharpRadius,
    onClickSetOrDeleteNewNodeHandler,
  ]);

  useEffect(() => {
    setSettings({
      nodeReducer: (node, data) => {
        const graph = sigma.getGraph();

        const selectedNodesFullList = [...selectedNodes, ...childNodesOfSelectedNodes];
        const isHighlighted =
          !!(hoveredNode && (node === hoveredNode || graph.neighbors(hoveredNode)?.includes(node))) ||
          selectedNodesFullList.includes(node);

        return {
          ...data,
          highlighted: isHighlighted,
          color: setColorInNodesAtActivate({
            isHighlighted,
            selectedNodesFullList,
            hoveredNode,
            node,
            selectedNodesWithoutDependencies: selectedNodes,
            colors: {
              default: darkWhite,
              selected: greenCobalt,
              hovering: carminePink,
            },
          }),
        };
      },
      edgeReducer: (edge, data) => {
        const graph = sigma.getGraph();

        const extremities = graph.extremities(edge);
        const isSelectedEdges = selectedNodes.some((selectedNode) => extremities.includes(selectedNode));
        const setHiddenEdge = () => {
          if (!hoveredNode && !selectedNodes.length) return false;
          if (!hoveredNode && !isSelectedEdges) return true;
          return !!(hoveredNode && !extremities.includes(hoveredNode) && !isSelectedEdges);
        };

        return {
          ...data,
          hidden: setHiddenEdge(),
        };
      },
    });
  }, [hoveredNode, setSettings, sigma, selectedNodes, childNodesOfSelectedNodes]);

  return null;
}
