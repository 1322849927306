import styled, { css } from 'styled-components';
import { color, compose, layout, space } from 'styled-system';
import { Box } from '../../../../../styledComponents';

type CellContainerProps = {
  background: string,
  paddingVertical: number,
  isBorderBottom: boolean,
  colorBorder: string,
}

export const CellContainer = styled('td').attrs<CellContainerProps>(({
  background,
  paddingVertical,
}) => ({
  bg: background,
  py: paddingVertical,
  position: 'relative',
}))<CellContainerProps>`
  ${compose(color, space, layout)};

  box-sizing: border-box;

  ${({
    isBorderBottom,
    colorBorder,
  }) => isBorderBottom && css`
    border-bottom: 1px solid ${colorBorder};
  `}
`;

type CellWrapperProps = {
  isRow: boolean,
}

export const CellWrapper = styled(Box).attrs({
  width: '100%',
  height: '100%',
})<CellWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ isRow }) => isRow ? 'row' : 'column'};
`;