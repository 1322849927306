import { useTheme } from 'styled-components';
import * as Styled from './CellWithDeviation.styled';
import { CellWithDeviationProps } from './CellWithDeviation.types';

export function CellWithDeviation({
  title,
  isTimeSubTitleOne = false,
  sizeTitle = 'light12',
  sizeSubTitle = 'light10',
  textColor = '',
}: CellWithDeviationProps) {
  const {
    colors: {
      snowWhite,
      lightGrayGhostly,
    },
  } = useTheme();

  const color = textColor ? textColor : snowWhite;

  const subTitleOne = isTimeSubTitleOne ? 'продолж.' : 'доход.';

  return (
    <Styled.Container>
      <Styled.CellTitle
        size={sizeTitle}
        color={color}
      >
        {title}
      </Styled.CellTitle>
      <Styled.CellSubTitleContainer>
        <Styled.CellSubTitleWrapper background={lightGrayGhostly}>
          <Styled.CellSubTitle
            size={sizeSubTitle}
            color={color}
          >
            {'Ср. ' + subTitleOne}
          </Styled.CellSubTitle>
        </Styled.CellSubTitleWrapper>

        <Styled.CellSubTitleWrapper background={lightGrayGhostly}>
          <Styled.CellSubTitle
            size={sizeSubTitle}
            color={color}
          >
            Откл.
          </Styled.CellSubTitle>
        </Styled.CellSubTitleWrapper>
      </Styled.CellSubTitleContainer>
    </Styled.Container>
  );
}