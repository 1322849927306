import { FC } from 'react';
import { useDrop, DropTargetMonitor } from 'react-dnd';
import * as Styled from '../DropZone.styled';

interface DropZoneProps {
  onDrop: (item: { type: string; data: any }) => void;
}

interface DropItem {
  type: string;
  data: any;
}

const DropZoneRight: FC<DropZoneProps> = ({ onDrop }) => {
  const [{ isOver }, drop] = useDrop<DropItem, void, { isOver: boolean }>({
    accept: ['graph-data', 'avg-rate-bid-data', 'avg-rate-ask-data', 'rate-diff', 'profit-long', 'profit-short'],
    drop: (item) => onDrop(item),
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <Styled.DropContainer ref={drop}>
      <p>Создать правую ось абсолютных величин</p>
    </Styled.DropContainer>
  );
};

export default DropZoneRight;
