import styled, { css } from 'styled-components';
import { compose, color, layout, space } from 'styled-system';
import { Box, Text } from '../../../../components/styledComponents';

export const UpdateMetrics = styled(Box).attrs({
  width: '100%',
  height: 'calc(100vh - 98px)',
})``;

export const Container = styled(Box).attrs({
  width: '60%',
  minWidth: 700,
  my: 5,
  mx: 'auto',
})``;

export const StatusContainer = styled(Box).attrs({
  py: 3,
  px: 4,
  mx: 'auto',
  bg: 'blackBlue',
})`
  border-radius: 4px;
`;

export const StatusTitle = styled(Text).attrs({
  color: 'snowWhite',
  variant: 'medium20',
})`
  text-align: center;
`;

export const StatusText = styled(Text).attrs({
  variant: 'regular16',
  color: 'snowWhite',
  mt: 4,
})`
  text-align: center;
`;

export const MetricIdInput = styled('input').attrs({
  width: '100%',
  bg: 'grayBlue',
  mt: 5,
  py: 2,
  px: 3,
  color: 'snowWhite',
})`
  ${compose(layout, color, space)};

  box-sizing: border-box;
  border-radius: 4px;
  border: none;

  &:focus {
    outline: ${({ theme }) => theme.colors.slateGray} solid 1px;
  }
`;

export const ButtonContainer = styled(Box).attrs({
  mt: 5,
})`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

type ButtonUpdateMetricsProps = {
  isDisabled: boolean,
}

export const ButtonUpdateMetrics = styled(Box).attrs({
  as: 'button',
  py: 6,
  px: 10,
  bg: 'greenCobalt',
  color: 'snowWhite',
})<ButtonUpdateMetricsProps>`
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: .25s all;

  &:hover {
    background: ${({ theme }) => theme.colors.lightGrayBlue};
    transition: .25s all;
  }

  &:active {
    background: ${({ theme }) => theme.colors.greenBlue};
    transition: .25s all;
  }

  ${({
    isDisabled,
    theme: { colors },
  }) => isDisabled && css`
    background: ${colors.lightGray};
    transition: .25s all;

    &:hover,
    &:active {
      background: ${colors.lightGray};
      cursor: auto;
    }
  `}
`;
