import { useTranslation } from 'react-i18next';
import { textVariables } from './constants';
import * as Styled from './Preview.styled';
import arrowSite from '../../../../assets/icons/arrowSite.svg';
import logo from '../../../../assets/icons/logo.svg';
import hostorySpread from '../../../../assets/img/Frame-2224361-1024x688.png';
import { Button } from '../../../../components/Button';
import { ImageContainer } from '../../../../components/ImageContainer';
import { usePathLogged } from '../../../../hooks/usePathLogged';
import { Container } from '../../../../ui/Container';

export function Preview() {
  const { t } = useTranslation();
  const { setPathAuntification } = usePathLogged();

  function onClickSetPathSignUpHandler() {
    setPathAuntification('signUp');
  }

  return(
    <Styled.Wrapper>
      <Container>
        <Styled.PreviewContainer>
          <Styled.PreviewRow>
            <ImageContainer
              height={105}
              width={540}
              image={logo}
              isBaseColor
            />
            <Styled.PreviewTitle>
              {textVariables.title}
            </Styled.PreviewTitle>

            <Styled.PreviewTag>
              <Styled.PreviewItem>
                <ImageContainer
                  height={28}
                  width={28}
                  image={arrowSite}
                  isBaseColor
                />
                <Styled.PreviewText>
                  {textVariables.wideSelectionOfCurrencies}
                </Styled.PreviewText>
              </Styled.PreviewItem>

              <Styled.PreviewItem>
                <ImageContainer
                  height={28}
                  width={28}
                  image={arrowSite}
                  isBaseColor
                />
                <Styled.PreviewText>
                  {textVariables.realTimeOperation}
                </Styled.PreviewText>
              </Styled.PreviewItem>

              <Styled.PreviewItem>
                <ImageContainer
                  height={28}
                  width={28}
                  image={arrowSite}
                  isBaseColor
                />
                <Styled.PreviewText>
                  {textVariables.largeNumberOfExchanges}
                </Styled.PreviewText>
              </Styled.PreviewItem>

              <Styled.PreviewItem>
                <ImageContainer
                  height={28}
                  width={28}
                  image={arrowSite}
                  isBaseColor
                />
                <Styled.PreviewText>
                  {textVariables.regularlyUpdatedDatabases}
                </Styled.PreviewText>
              </Styled.PreviewItem>
            </Styled.PreviewTag>

            <Button
              width={213}
              height={53}
              onClick={onClickSetPathSignUpHandler}
              title={t('_preview.singUp')}
              size='medium16'
              borderRadius={6}
            />
          </Styled.PreviewRow>

          <Styled.PreviewRow>
            <Styled.PreviewImage
              src={hostorySpread}
              alt="History spread"
            />
          </Styled.PreviewRow>
        </Styled.PreviewContainer>
      </Container>
    </Styled.Wrapper>
  );
}