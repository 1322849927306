import { useEffect, useState } from 'react';
import { AlgorithmVersion } from './components/AlgorithmVersion';
import { AlgorithmVersionType } from './components/AlgorithmVersion/AlgorithmVersion.types';
import { LoadingStatus } from './components/LoadingStatus';
import { Logs } from './components/Logs';
import { Modal } from './components/Modal';
import * as Styled from './SearchChains.styled';
import { Button } from '../../../../components/Button';
import { INPUT_MASKS } from '../../../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { useDebounce } from '../../../../hooks/useDebounce';
import { useInput } from '../../../../hooks/useInput';
import { useModal } from '../../../../hooks/useModal';
import { useMultiSelect } from '../../../../hooks/useMultiSelect/useMultiSelect';
import { useSlider } from '../../../../hooks/useSlider';
import { choiseAlgAct, onNewDateActiveBtnUserAct, setDefaultSearchAlgorithmStatusInUndefinedAction } from '../../../../redux/reducers/algorithms';
import { getStatusAlgorithmLog, postStartSrearAlgorithm } from '../../../../redux/reducers/algorithms/async';
import { ButtonContainer } from '../../../../ui/ButtonContainer';
import { FieldInput } from '../../../../ui/FieldInput';
import { MultiSelect } from '../../../../ui/MultiSelect';
import { SliderDoubleMaterialUi } from '../../../../ui/SliderDoubleMaterialUi';
import { SliderMaterialUi } from '../../../../ui/SliderMaterialUi';

export function SearchChains() {
  const dispatch = useAppDispatch();
  const {
    loadingState,
    activeAlgorithmVersion,
    searchAlgorithmStatus,
  } = useAppSelector((state) => state.algorithms);

  const {
    authKey = '',
  } = useAppSelector((state) => state.auth);

  const {
    metricPlatform,
    metricMethod,
    metricCurrency,
    loadingState: loadingStateTable,
  } = useAppSelector((state) => state.tabels);

  const waitingTime = useInput({ initialValue: 100, mask: INPUT_MASKS.number });
  const searchDepth = useInput({ initialValue: 100000, mask: INPUT_MASKS.number, maxValue: 10000000 });
  const numberOfChains = useInput({ initialValue: 500, mask: INPUT_MASKS.number, maxValue: 5000 });
  const numberOfStepsInsideExchangers = useInput({ initialValue: '', mask: INPUT_MASKS.number });

  const numberOfRules = useSlider(3);
  const numberOfStepsInsideThePlatform = useSlider(5);
  const chainMarginRange = useSlider([2.0, 100.0]);

  const [platforms, setPlatforms] = useMultiSelect();
  const [methods, setMethods] = useMultiSelect();
  const [currency, setCurrency] = useMultiSelect();
  const [startCurrency, setStartCurrency] = useMultiSelect();

  const { isActive, onClick } = useModal();
  const [modalMessage, setModalMessage] = useState('');

  // Скрывает модальное окно через 3 секунды, если не было закрыто вручную
  const debouncedCloseModal = useDebounce(() => {
    onClick();
  }, [3000, isActive]);

  // Метод, который проверяет статус алгоритма
  function onClickStartAlgorithmHandler() {
    dispatch(getStatusAlgorithmLog({ authKey }));
  }

  // Еффект, который запускает алгоритм поиска, после проверки его статуса
  // и открывает модальное окно сообщяющее об этом 
  useEffect(() => {
    if (searchAlgorithmStatus !== undefined && !searchAlgorithmStatus) {
      onClick();
      dispatch(onNewDateActiveBtnUserAct());
      dispatch(choiseAlgAct(activeAlgorithmVersion));
      dispatch(postStartSrearAlgorithm({
        authKey,
        activeAlgorithmVersion,
        waitingTime: waitingTime.value,
        searchDepth: searchDepth.value,
        numberOfChains: numberOfChains.value,
        numberOfStepsInsideExchangers: numberOfStepsInsideExchangers.value,
        numberOfRules: numberOfRules.value,
        numberOfStepsInsideThePlatform: numberOfStepsInsideThePlatform.value,
        chainMarginRange: chainMarginRange.value,
        platforms: platforms,
        methods: methods,
        currency: currency,
        // @ts-ignore
        startCurrency: startCurrency,
      }));
      setModalMessage('Алгоритм запущен! Поиск цепочек продолжится в фоне');
      dispatch(setDefaultSearchAlgorithmStatusInUndefinedAction());
    }

    if (searchAlgorithmStatus) {
      onClick();
      setModalMessage('Алгоритм уже запущен в фоне, продолжите позднее!');
      dispatch(setDefaultSearchAlgorithmStatusInUndefinedAction());
    }
  }, [searchAlgorithmStatus]);

  // Закрывает модалку со статусом запроса, после получения ответа с сервера
  useEffect(() => {
    if (isActive) {
      debouncedCloseModal();
    }
  }, [isActive]);

  // Данный полей ввода (inputs)
  const SETTINGS_ALGORITHM_INPUTS_DATA = [
    {
      key: 4,
      value: waitingTime.value,
      onChange: waitingTime.onChange,
      setValueInField: waitingTime.setValueInField,
      title: 'Максимальное время ожидания',
      placeholder: 'Время ожидания',
    },
    {
      key: 7,
      value: numberOfChains.value,
      onChange: numberOfChains.onChange,
      setValueInField: numberOfChains.setValueInField,
      title: 'Количество цепочек',
      placeholder: 'Максимальное количество цепочек',
    },
    {
      key: 6,
      value: searchDepth.value,
      onChange: searchDepth.onChange,
      setValueInField: searchDepth.setValueInField,
      title: 'Максимальная глубина поиска',
      placeholder: 'Глубина поиска',
    },
    {
      key: 8,
      value: numberOfStepsInsideExchangers.value,
      onChange: numberOfStepsInsideExchangers.onChange,
      setValueInField: numberOfStepsInsideExchangers.setValueInField,
      title: 'Количество шагов внутри обмеников',
      placeholder: 'Количество шагов',
    },
  ];

  // Данные полей с выпадающим списком (selects)
  const SETTINGS_ALGORITHM_METRIC_DATA = [
    {
      key: 10,
      title: 'Платформы',
      options: metricPlatform,
      value: platforms,
      onChange: setPlatforms,
      isMulti: true,
    },
    {
      key: 11,
      title: 'Методы',
      options: metricMethod,
      value: methods,
      onChange: setMethods,
      isMulti: true,
    },
    {
      key: 12,
      title: 'Валюты',
      options: metricCurrency,
      value: currency,
      onChange: setCurrency,
      isMulti: true,
    },
    {
      key: 13,
      title: 'Валюта начала поиска',
      options: currency,
      value: startCurrency,
      onChange: setStartCurrency,
      isMulti: false,
      isDependsOnAnother: true,
    },
  ];

  return(
    <Styled.Container>
      <Styled.LogsAndAlgorithms>
        <Logs/>
        <AlgorithmVersion/>
      </Styled.LogsAndAlgorithms>

      {/* На первой версии алгоритма выбор параметров отсутствует */}
      {activeAlgorithmVersion !== AlgorithmVersionType.AlgorithmOne && (
        <>
          <Styled.InputsGrid>
            {SETTINGS_ALGORITHM_INPUTS_DATA.map((input) => (
              <Styled.InputContainer key={input.key}>
                <FieldInput
                  { ...input }
                  marginTop={3}
                  isActionButton
                />
              </Styled.InputContainer>
            ))}
          </Styled.InputsGrid>

          <Styled.SelectContainer>
            {SETTINGS_ALGORITHM_METRIC_DATA.map((metric) => (
              <MultiSelect { ...metric} isLoading={loadingStateTable === 'loading'}/>
            ))}
          </Styled.SelectContainer>

          <SliderMaterialUi
            { ...numberOfRules }
            title="Количество правил"
            defaultValue={3}
            step={1}
            min={3}
            max={12}
          />

          <SliderMaterialUi
            { ...numberOfStepsInsideThePlatform }
            title="Количество шагов внутри платформы"
            defaultValue={1}
            step={1}
            min={1}
            max={9}
          />

          <SliderDoubleMaterialUi
            { ...chainMarginRange }
            title="Диапазон маржинальности цепочки"
            step={0.1}
            min={-2}
            max={100}
          />
        </>
      )}

      <ButtonContainer marginTop={4}>
        {loadingState === 'loading'
          ? <LoadingStatus/>
          : (
            <Button
              width="100%"
              height={60}
              onClick={onClickStartAlgorithmHandler}
              title="Запусить алгоритм"
              size="light20"
            />
          )}
      </ButtonContainer>

      {isActive && (
        <Modal
          onClick={onClick}
          text={modalMessage}
        />
      )}
    </Styled.Container>
  );
}