import styled from 'styled-components';
import { Box, Text } from '../../../../../../components/styledComponents';

export const TypeTimeButtonContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TypeTimeButtonText = styled(Text).attrs({
  variant: 'regular16',
  color: 'snowWhite',
})`
  user-select: none;
`;

export const ButtonListContainer = styled(Box).attrs({
  ml: 1,
  mr: 2,
})`
  display: flex;
`;

export const ButtonContainer = styled(Box).attrs({
  mx: 1,
})``;