import { truncate } from '../../../../../utils/Truncate';
import { CreateNewValue } from '../../types';
import { calculatePercentageAfterCommission } from '../CalculatePercentageAfterCommission';

export function createUpdatedStepValue({
  presentValue,
  rate,
  tax,
  constTax,
  significant,
}: CreateNewValue) {
  const absoluteConstTax = constTax === -1 ? 0 : Math.abs(constTax);
  const updatedValue = presentValue * rate * calculatePercentageAfterCommission(tax) - absoluteConstTax;

  if (updatedValue < 0) {
    return {
      updatedValue: 0,
      updatedViewValue: 0,
    };
  }

  return {
    updatedValue,
    updatedViewValue: truncate(updatedValue, significant),
  };
}