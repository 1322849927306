import { ChangeEvent } from 'react';
import * as Styled from './FilterInput.styled';
import { FilterInputProps } from './FilterInput.types';
import { useAppDispatch } from '../../../../../../../../hooks/storeHooks';

export function FilterInput({
  title,
  value,
  setStateValueAction,
  placeholder,
}: FilterInputProps) {
  const dispatch = useAppDispatch();

  function onChangeSetValueHandler(e: ChangeEvent<HTMLInputElement>) {
    dispatch(setStateValueAction(e.target.value));
  }

  return (
    <Styled.FilterInput>
      <Styled.FilterTitle>{title}</Styled.FilterTitle>
      <Styled.FilterInputInput
        placeholder={placeholder}
        value={value}
        onChange={onChangeSetValueHandler}
      />
    </Styled.FilterInput>
  );
}