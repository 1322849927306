/* eslint-disable no-console */
import React, { Dispatch, SetStateAction, useEffect, useRef, useState, FormEvent } from 'react';
import * as echarts from 'echarts';
import { ECharts } from 'echarts';
import { useTheme } from 'styled-components';
import * as Styled from './AddGraph.styled';
import { DropSide, SeriesItem, GraphState } from './AddGraph.types';
import { INPUT_MASKS } from '../../../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { useInput } from '../../../../hooks/useInput';
import { getDealRates } from '../../../../redux/reducers/graph/async';
import { setDealNumberAction } from '../../../../redux/reducers/graph/index';
import { RootState } from '../../../../redux/store';
import DropZoneBottom from '../../DropZones/DropZoneBottom/DropZoneBottom';
import DropZoneLeft from '../../DropZones/DropZoneLeft/DropZoneLeft';
import DropZoneRight from '../../DropZones/DropZoneRight/DropZoneRight';
import { AvgRateAskItemComponent } from '../DragItem/AvgRateAskItemComponent';
import { AvgRateBidItemComponent } from '../DragItem/AvgRateBidItemComponent';
import { DragItemComponent } from '../DragItem/DragItem';
import { ProfitActualLong } from '../DragItem/ProfitActualLong';
import { ProfitActualShort } from '../DragItem/ProfitActualShort';
import { RateDiffAverage } from '../DragItem/RateDiffAverage';
import Group from '../Group/Group';


function AddGraph() {
  const chartRef = useRef<HTMLDivElement>(null);
  const chartRefBottom = useRef<HTMLDivElement>(null);
  const chartInstance = useRef<ECharts>();
  const chartInstanceBottom = useRef<ECharts>();
  const [series, setSeries] = useState<SeriesItem[]>([]);
  const [isDraggingGlobal, setIsDraggingGlobal] = useState(false);
  const [hasDropped, setHasDropped] = useState<DropSide | null>(null);
  const [currentState, setCurrentState] = useState<GraphState>(GraphState.Initial);
  const dealNumber = useInput({ initialValue: '', mask: INPUT_MASKS.number });
  const [firstArray, setFirstArray] = useState<number[]>([]);
  const [secondArray, setSecondArray] = useState<number[]>([]);
  const [thirdArray, setThirdArray] = useState<number[]>([]);
  const [avgRateBidArray, setAvgRateBidArray] = useState<number[]>([]);
  const [avgRateAskArray, setAvgRateAskArray] = useState<number[]>([]);
  const [rateDiffAverageArray, setRateDiffAverageArray] = useState<number[]>([]);
  const [profitLimitActualLongArray, setProfitLimitActualLongArray] = useState<number[]>([]);
  const [profitLimitActualShortArray, setProfitLimitActualShortArray] = useState<number[]>([]);
  const [displayedSeries, setDisplayedSeries] = useState<SeriesItem[]>([]);
  const [seriesBottom, setSeriesBottom] = useState<SeriesItem[]>([]);
  const [activeTab, setActiveTab] = useState('absolute');
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const {
    isDealValid,
    loadingState,
    error,
    closeDate,
    date,
    ratesArray,
    slidingWindowSize,
    rate1,
    rate2,
    platform1,
    platform2,
    currencyFrom,
    currencyTo,
    profitVolume,
    closeFee1,
    closeFee2,
    correlation,
  } = useAppSelector((state: RootState) => state.graph);

  const handleTabChange = (tab: React.SetStateAction<string>) => {
    setActiveTab(tab);
  };

  const handleDealNumberSubmit = async (event: FormEvent) => {
    event.preventDefault();
    dispatch(setDealNumberAction(dealNumber.value));
    try {
      await dispatch(getDealRates({
        dealNumber: dealNumber.value,
        authKey: '',
      })).unwrap();
    } catch (error) {
      console.error('Ошибка при получении данных сделки:', error);
    }
  };

  useEffect(() => {
    console.log('closeDate:', closeDate);
    console.log('date:', date);
    console.log('ratesArray:', ratesArray);
    console.log('slidingWindowSize:', slidingWindowSize);
    console.log('rate1:', rate1);
    console.log('rate2:', rate2);
    console.log('pl1', platform1);
    console.log('pl2', platform2);
    console.log('curFrom', currencyFrom);
    console.log('curTo', currencyTo);
    console.log('volumeProf', profitVolume);
    console.log('close_fee1', closeFee1);
    console.log('close_fee2', closeFee2);
    console.log('corr', correlation);

    if (ratesArray.length > 0) {
      const newFirstArray = ratesArray.map(rate => +new Date(rate[0]));
      const newSecondArray = ratesArray.map(rate => Number(rate[1]));
      const newThirdArray = ratesArray.map(rate => Number(rate[2]));

      setFirstArray(newFirstArray);
      setSecondArray(newSecondArray);
      setThirdArray(newThirdArray);

      console.log('firstArray:', newFirstArray);
      console.log('secondArray:', newSecondArray);
      console.log('thirdArray:', newThirdArray);

      calculateSlidingWindowAverageRateBid();
      calculateSlidingWindowAverageRateAsk();
      calculateRateDiffAverage();
      if (rate1 !== null && rate1 !== undefined && rate2 !== null && rate2 !== undefined) {
        const profitLimitActualLong = calculateProfitLimitActualLong(rate1, rate2);
        const profitLimitActualShort = calculateProfitLimitActualShort(rate1, rate2);

        setProfitLimitActualLongArray(profitLimitActualLong as number[]);
        setProfitLimitActualShortArray(profitLimitActualShort as number[]);

        console.log('profitLimitActualLong:', profitLimitActualLong);
        console.log('profitLimitActualShort:', profitLimitActualShort);
      } else {
        console.error('Invalid rates:', { rate1, rate2 });
      }
    }
  }, [ratesArray, slidingWindowSize, series, seriesBottom]);

  const calculateSlidingWindowAverageRateBid = () => {
    const windowSize = Number(slidingWindowSize);
    if (isNaN(windowSize) || windowSize <= 0) {
      console.error('Invalid sliding window size:', slidingWindowSize);
      return;
    }

    console.log('Calculating sliding window average for RateBid with windowSize:', windowSize);

    const rateBidData = thirdArray;
    const timestamps = firstArray;

    if (rateBidData.length !== timestamps.length) {
      console.error('Data length mismatch:', { rateBidDataLength: rateBidData.length, timestampsLength: timestamps.length });
      return;
    }

    const averages = rateBidData.map((currentValue, index) => {
      const currentTime = timestamps[index];
      const windowStartTime = currentTime - windowSize * 60 * 1000;
      const windowValues = rateBidData.filter((_, idx) => {
        const time = timestamps[idx];
        return time >= windowStartTime && time < currentTime;
      });

      if (windowValues.length === 0) return null;

      const average = windowValues.reduce((acc, val) => acc + val, 0) / windowValues.length;
      return average;
    });

    console.log('RateBid Averages:', averages);

    setAvgRateBidArray(averages as number[]);
  };

  const calculateSlidingWindowAverageRateAsk = () => {
    const windowSize = Number(slidingWindowSize);
    if (isNaN(windowSize) || windowSize <= 0) {
      console.error('Invalid sliding window size:', slidingWindowSize);
      return;
    }

    console.log('Calculating sliding window average for RateAsk with windowSize:', windowSize);

    const rateAskData = secondArray;
    const timestamps = firstArray;

    if (rateAskData.length !== timestamps.length) {
      console.error('Data length mismatch:', { rateAskDataLength: rateAskData.length, timestampsLength: timestamps.length });
      return;
    }

    const averages = rateAskData.map((currentValue, index) => {
      const currentTime = timestamps[index];
      const windowStartTime = currentTime - windowSize * 60 * 1000;
      const windowValues = rateAskData.filter((_, idx) => {
        const time = timestamps[idx];
        return time >= windowStartTime && time < currentTime;
      });

      if (windowValues.length === 0) return null;

      const average = windowValues.reduce((acc, val) => acc + val, 0) / windowValues.length;
      return average;
    });

    console.log('RateAsk Averages:', averages);

    setAvgRateAskArray(averages as number[]);
  };

  const calculateRateDiffAverage = () => {
    if (avgRateBidArray.length === 0 || avgRateAskArray.length === 0 || secondArray.length === 0 || thirdArray.length === 0) {
      console.error('Missing data for rate_diff_average calculation');
      return;
    }

    const rateDiffAverages = firstArray.map((_, index) => {
      const bestAskAvgRateBid = avgRateBidArray[index];
      const bestAskRateAsk = secondArray[index];
      const bestBidRateBid = thirdArray[index];
      const bestBidAvgRateAsk = avgRateAskArray[index];

      if (
        bestAskAvgRateBid == null ||
        bestAskRateAsk == null ||
        bestBidRateBid == null ||
        bestBidAvgRateAsk == null
      ) {
        return null;
      }

      const rateDiffAverage =
        ((bestAskAvgRateBid - bestAskRateAsk) / bestAskRateAsk +
          (bestBidRateBid - bestBidAvgRateAsk) / bestBidRateBid) *
        100;

      return rateDiffAverage;
    });

    console.log('RateDiffAverages:', rateDiffAverages);

    setRateDiffAverageArray(rateDiffAverages as number[]);
  };

  const calculateProfitLimitActualLong = (rate1: number, rate2: number) => {
    if (
      avgRateBidArray.length === 0 ||
      avgRateAskArray.length === 0 ||
      secondArray.length === 0 ||
      thirdArray.length === 0
    ) {
      console.error('Missing data for profit_limit_actual_long calculation');
      return [];
    }

    return firstArray.map((_, index) => {
      const bestAskRateBid = avgRateBidArray[index];
      const bestAskRateAsk = secondArray[index];
      const bestBidRateBid = thirdArray[index];
      const bestBidRateAsk = avgRateAskArray[index];

      if (
        bestAskRateBid == null ||
        bestAskRateAsk == null ||
        bestBidRateBid == null ||
        bestBidRateAsk == null
      ) {
        return null;
      }

      const profitLimitActualLong =
        (bestAskRateAsk + bestBidRateBid + rate1 - rate2) / 2;

      return profitLimitActualLong;
    });
  };

  const calculateProfitLimitActualShort = (rate1: number, rate2: number) => {
    if (
      avgRateBidArray.length === 0 ||
      avgRateAskArray.length === 0 ||
      secondArray.length === 0 ||
      thirdArray.length === 0
    ) {
      console.error('Missing data for profit_limit_actual_short calculation');
      return [];
    }

    return firstArray.map((_, index) => {
      const bestAskRateBid = avgRateBidArray[index];
      const bestAskRateAsk = secondArray[index];
      const bestBidRateBid = thirdArray[index];
      const bestBidRateAsk = avgRateAskArray[index];

      if (
        bestAskRateBid == null ||
        bestAskRateAsk == null ||
        bestBidRateBid == null ||
        bestBidRateAsk == null
      ) {
        return null;
      }

      const profitLimitActualShort =
        (bestAskRateAsk + bestBidRateBid + rate2 - rate1) / 2;

      return profitLimitActualShort;
    });
  };

  useEffect(() => {
    if (chartRef.current) {
      // eslint-disable-next-line import/namespace
      const chart = echarts.init(chartRef.current);
      chartInstance.current = chart;

      const option: echarts.EChartsOption = {
        tooltip: {
          trigger: 'axis',
          position: function (pt) {
            return [pt[0], '10%'];
          },
        },
        title: {
          left: 'center',
          text: 'Абсолютный график',
          textStyle: {
            color: theme.colors.white,
            fontSize: '25px',
          },
        },
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: 'none',
            },
            restore: {},
            saveAsImage: {},
          },
        },
        xAxis: {
          type: 'time',
          axisLine: {
            show: true,
            lineStyle: {
              color: theme.colors.lightGray,
            },
          },
          axisLabel: {
            color: theme.colors.lightGray,
          },
        },
        yAxis: [
          {
            type: 'value',
            boundaryGap: [0, '100%'],
            splitLine: { show: true },
            axisLine: {
              show: true,
              lineStyle: {
                color: theme.colors.lightGray,
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: theme.colors.lightGray,
              formatter: function (value: number) {
                return value.toFixed(3);
              },
            },
            min: (value: { min: number }) => Math.floor(value.min * 1000) / 1000,
            max: (value: { max: number }) => Math.ceil(value.max * 1000) / 1000,
            splitNumber: 10,
          },
          {
            type: 'value',
            boundaryGap: [0, '100%'],
            splitLine: { show: true },
            axisLine: {
              show: true,
              lineStyle: {
                color: theme.colors.lightGray,
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: theme.colors.lightGray,
              formatter: function (value: number) {
                return value.toFixed(3);
              },
            },
            min: (value: { min: number }) => Math.floor(value.min * 1000) / 1000,
            max: (value: { max: number }) => Math.ceil(value.max * 1000) / 1000,
            splitNumber: 10,
          },
        ],
        series: [],
      };

      chart.setOption(option);
    }

    if (chartRefBottom.current) {
      // eslint-disable-next-line import/namespace
      const chartBottom = echarts.init(chartRefBottom.current);
      chartInstanceBottom.current = chartBottom;

      const optionBottom: echarts.EChartsOption = {
        tooltip: {
          trigger: 'axis',
          position: function (pt) {
            return [pt[0], '10%'];
          },
        },
        title: {
          left: 'center',
          text: 'Процентный график',
          textStyle: {
            color: theme.colors.white,
            fontSize: '25px',
          },
        },
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: 'none',
            },
            restore: {},
            saveAsImage: {},
          },
        },
        xAxis: {
          type: 'time',
          axisLine: {
            show: true,
            lineStyle: {
              color: theme.colors.lightGray,
            },
          },
          axisLabel: {
            color: theme.colors.lightGray,
          },
        },
        yAxis: [
          {
            type: 'value',
            boundaryGap: [0, '100%'],
            splitLine: { show: true },
            axisLine: {
              show: true,
              lineStyle: {
                color: theme.colors.lightGray,
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: theme.colors.lightGray,
              formatter: function (value: number) {
                return value.toFixed(6);
              },
            },
            min: (value: { min: number }) => Math.floor(value.min * 1000000) / 1000000,
            max: (value: { max: number }) => Math.floor(value.max * 1000000) / 1000000,
            splitNumber: 10,
          },
        ],
        series: [],
        dataZoom: [
          {
            type: 'slider',
            xAxisIndex: 0,
            start: 0,
            end: 100,
          },
          {
            type: 'inside',
            xAxisIndex: 0,
            start: 0,
            end: 100,
          },
          {
            type: 'slider',
            yAxisIndex: 0,
            start: 0,
            end: 100,
          },
          {
            type: 'inside',
            yAxisIndex: 0,
            start: 0,
            end: 100,
          },
        ],
      };

      chartBottom.setOption(optionBottom);
    }
  }, [series, seriesBottom]);

  const handleLeftDrop = (item: { type: string, data: any }) => {
    handleDrop(DropSide.Left, item);
  };

  const handleRightDrop = (item: { type: string, data: any }) => {
    handleDrop(DropSide.Right, item);
  };

  const handleBottomDrop = (item: { type: string, data: any }) => {
    handleDrop(DropSide.Bottom, item);
  };

  const handleDrop = (side: DropSide, item: { type: string, data: any }) => {
    setHasDropped(side);
    let newData: SeriesItem;

    if (item.type === 'avg-rate-bid-data') {
      newData = {
        name: 'AvgRateBid',
        type: 'line',
        smooth: true,
        symbol: 'none',
        data: firstArray.map((timestamp, index) => [timestamp, item.data[index] || null]),
        yAxisIndex: side === DropSide.Left || DropSide.Bottom ? 0 : 1,
      };
    } else if (item.type === 'avg-rate-ask-data') {
      newData = {
        name: 'AvgRateAsk',
        type: 'line',
        smooth: true,
        symbol: 'none',
        data: firstArray.map((timestamp, index) => [timestamp, item.data[index] || null]),
        yAxisIndex: side === DropSide.Left || DropSide.Bottom ? 0 : 1,
      };
    } else if (item.type === 'rate-diff') {
      newData = {
        name: 'RateDiff',
        type: 'line',
        smooth: true,
        symbol: 'none',
        data: firstArray.map((timestamp, index) => [timestamp, item.data[index] || null]),
        yAxisIndex: side === DropSide.Left || DropSide.Bottom ? 0 : 1,
      };
    } else if (item.type === 'profit-long') {
      newData = {
        name: 'Profit Actual Long',
        type: 'line',
        smooth: true,
        symbol: 'none',
        data: firstArray.map((timestamp, index) => [timestamp, item.data[index] || null]),
        yAxisIndex: side === DropSide.Left || DropSide.Bottom ? 0 : 1,
      };
    } else if (item.type === 'profit-short') {
      newData = {
        name: 'Profit Actual Short',
        type: 'line',
        smooth: true,
        symbol: 'none',
        data: firstArray.map((timestamp, index) => [timestamp, item.data[index] || null]),
        yAxisIndex: side === DropSide.Left || DropSide.Bottom ? 0 : 1,
      };
    } else {
      switch (side) {
        case DropSide.Left:
          newData = {
            name: 'RateAsk',
            type: 'line',
            smooth: true,
            symbol: 'none',
            data: secondArray.map((value, index) => [firstArray[index], value]),
            yAxisIndex: 0,
          };
          break;
        case DropSide.Right:
          newData = {
            name: 'RateBid',
            type: 'line',
            smooth: true,
            symbol: 'none',
            data: thirdArray.map((value, index) => [firstArray[index], value]),
            yAxisIndex: 1,
          };
          break;
        default:
          newData = {
            name: 'Data Default',
            type: 'line',
            smooth: true,
            symbol: 'none',
            data: [],
            yAxisIndex: 0,
          };
          break;
      }
    }

    if (side === DropSide.Bottom) {
      setSeriesBottom(prevSeries => [...prevSeries, newData]);
    } else {
      setSeries(prevSeries => {
        const updatedSeries = prevSeries.filter(s => s.yAxisIndex !== newData.yAxisIndex);
        return [...updatedSeries, newData];
      });
    }

    setCurrentState(GraphState.Graph);
  };


  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.setOption({ series: series.map(s => ({ ...s, lineStyle: { color: s.yAxisIndex === 0 ? theme.colors.primary : theme.colors.secondary } })) });
    }
    if (chartInstanceBottom.current) {
      chartInstanceBottom.current.setOption({ series: seriesBottom.map(s => ({ ...s, lineStyle: { color: s.yAxisIndex === 0 ? theme.colors.primary : theme.colors.secondary } })) });
    }
  }, [series, seriesBottom, theme]);

  useEffect(() => {
    console.log(avgRateBidArray);
    console.log(avgRateAskArray);
    console.log(rateDiffAverageArray);
  }, [avgRateBidArray, avgRateAskArray, rateDiffAverageArray]);

  useEffect(() => {
    if (isDraggingGlobal) {
      setCurrentState(GraphState.Dragging);
    } else if (!isDraggingGlobal && series.length > 0) {
      setCurrentState(GraphState.Graph);
    } else {
      setCurrentState(GraphState.Initial);
    }
  }, [isDraggingGlobal, series.length]);

  return (
    <Styled.Container>
      {!isDealValid ? (
        <Styled.DealNumberWrapper>
          <Styled.DealNumberForm onSubmit={handleDealNumberSubmit}>
            <Styled.Label>
              Введите номер сделки
              <Styled.Input
                type="text"
                {...dealNumber}
                required
              />
            </Styled.Label>
            {loadingState === 'loading' && <div>Загрузка...</div>}
            {loadingState === 'failed' && <div>Неверный номер сделки</div>}
            <Styled.Button type="submit" disabled={loadingState === 'loading'}>Подтвердить</Styled.Button>
          </Styled.DealNumberForm>
        </Styled.DealNumberWrapper>
      ) : (
        <Styled.Container>
          <Styled.DragItemsWrapper>
            <div>
              <Styled.TabsContainer>
                <Styled.TabButton
                  isActive={activeTab === 'absolute'}
                  onClick={() => handleTabChange('absolute')}
                >
                  Абсолютные
                </Styled.TabButton>
                <Styled.TabButton
                  isActive={activeTab === 'relative'}
                  onClick={() => handleTabChange('relative')}
                >
                  Процентные
                </Styled.TabButton>
              </Styled.TabsContainer>
              <Styled.TabsContent>
                {activeTab === 'absolute' ? (
                  <>
                    <DragItemComponent label="RateBid" data={[1630457200000, 1.3452, 1.3451]} setIsDragging={setIsDraggingGlobal} />
                    <DragItemComponent label="RateAsk" data={[1630457300000, 1.3458, 1.3452]} setIsDragging={setIsDraggingGlobal} /></>
                ) : (
                  <>
                    <AvgRateBidItemComponent data={avgRateBidArray} setIsDragging={setIsDraggingGlobal} label="Avg Rate Bid" />
                    <AvgRateAskItemComponent data={avgRateAskArray} setIsDragging={setIsDraggingGlobal} label="Avg Rate Ask" />
                    <RateDiffAverage data={rateDiffAverageArray} setIsDragging={setIsDraggingGlobal} label="Rate Diff Average" />
                    <ProfitActualLong data={profitLimitActualLongArray} setIsDragging={setIsDraggingGlobal} label="Profit Limit Actual Long" />
                    <ProfitActualShort data={profitLimitActualShortArray} setIsDragging={setIsDraggingGlobal} label="Profit Limit Actual Short" />
                  </>
                )}
              </Styled.TabsContent>
            </div>


          </Styled.DragItemsWrapper>
          <Styled.GraphContainer>
            {currentState === 'initial' && (
              <p>Перетащите интересующие компоненты</p>
            )}
            {currentState === GraphState.Dragging && (
              <Styled.DropZones>
                <Styled.Row>
                  <DropZoneLeft onDrop={handleLeftDrop} />
                  <DropZoneRight onDrop={handleRightDrop} />
                </Styled.Row>
                <DropZoneBottom onDrop={handleBottomDrop} />
              </Styled.DropZones>
            )}
            {currentState === GraphState.Graph && !isDraggingGlobal && (
              <Styled.GraphContainers>
                <Styled.infoContainer>
                  <Styled.infoItem>Platform From: {platform1}</Styled.infoItem>
                  <Styled.infoItem>Platform To: {platform2}</Styled.infoItem>
                  <Styled.infoItem>Currency From: {currencyFrom}</Styled.infoItem>
                  <Styled.infoItem>Currency To: {currencyTo}</Styled.infoItem>
                  <Styled.infoItem>Profit: {profitVolume}</Styled.infoItem>
                  <Styled.infoItem>Open date: {date}</Styled.infoItem>
                  <Styled.infoItem>Close date: {closeDate}</Styled.infoItem>
                  <Styled.infoItem>Close fee 1: {closeFee1}</Styled.infoItem>
                  <Styled.infoItem>Close fee 2: {closeFee2}</Styled.infoItem>
                  <Styled.infoItem>Correlation: {correlation}</Styled.infoItem>
                </Styled.infoContainer>
                <Styled.GraphWrapper ref={chartRef} />
                <Styled.GraphWrapper ref={chartRefBottom} style={{ marginTop: '20px' }} />
              </Styled.GraphContainers>
            )}
            {currentState === GraphState.Initial && isDraggingGlobal && (
              <Styled.DropZones>
                <DropZoneLeft onDrop={handleLeftDrop} />
                <DropZoneRight onDrop={handleRightDrop} />
                <DropZoneBottom onDrop={handleBottomDrop} />
              </Styled.DropZones>
            )}
          </Styled.GraphContainer>
        </Styled.Container>
      )}
    </Styled.Container>
  );
}

export { AddGraph };
