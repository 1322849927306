import styled from 'styled-components';
import { Box } from '../../../../components/styledComponents';

export const SampleTableContainer = styled(Box).attrs({
  width: '100%',
  mt: 5,
})`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PaginateContainer = styled(Box).attrs({
  width: '100%',
  mt: 5,
})`
  display: flex;
  flex-direction: column;
  align-items: center;
`;