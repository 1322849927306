import * as Styled from './Log.styled';
import { LogProps } from './Log.types';

export function Log({
  label,
  value,
}: LogProps) {
  return (
    <Styled.Container>
      <Styled.LogLabel>
        {label}
      </Styled.LogLabel>

      <Styled.LogValueContainer>
        <Styled.LogValue>
          {value}
        </Styled.LogValue>
      </Styled.LogValueContainer>
    </Styled.Container>
  );
}