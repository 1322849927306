import { useMemo } from 'react';

import * as Styled from './ModalMaintenancePeriod.styled';

import { ModalMaintenancePeriodProps } from './ModalMaintenancePeriod.types';
import exit from '../../assets/icons/admin-close-filter.svg';
import { useStorageState } from '../../hooks/useStorageState';
import { StorageType } from '../../hooks/useStorageState/useStorageState.types';

export function ModalMaintenancePeriod({
  text,
}: ModalMaintenancePeriodProps) {
  const [engineeringWorks, setEngineeringWorks] = useStorageState({
    storageType: StorageType.session,
    key: 'engineeringWorks',
    initialState: {
      isActive: true,
      isRequest: true,
      message: text,
    },
  });

  const {
    isActive,
    message,
  } = engineeringWorks;

  const title = useMemo(() => {
    const usersClock = new Date().getHours();

    return `Добрый ${usersClock > 17 || usersClock < 7 ? 'вечер' : 'день'}!`;
  }, [engineeringWorks]);

  function onClickSetActiveHandler() {
    setEngineeringWorks({
      ...engineeringWorks,
      isActive: !isActive,
    });
  }

  return (
    <Styled.ModalWrapper isActive={isActive}>
      <Styled.ModalSubstrate/>
      <Styled.Container>
        <Styled.Title>{title}</Styled.Title>
        <Styled.ContainerExit onClick={onClickSetActiveHandler}>
          <Styled.ExitImage src={exit} alt="Exit"/>
        </Styled.ContainerExit>
        <Styled.MessageText>{message}</Styled.MessageText>
      </Styled.Container>
    </Styled.ModalWrapper>
  );
}