export type CourseItem = {
  [key: string]: string | number | boolean | undefined,
  id: number,
  author: string,
  currency_from: string,
  currency_to: string,
  last_change: string,
  method: string,
  platform_link: string,
  platform_from: string,
  platform_to: string,
  rate: number | string,
  rule_number: number,
  tax: number,
  tax_const: number,
  tax_max: number,
  tax_min: number,
  tran_max: number,
  tran_min: number,
  tran_speed: number,
  update_rate: number,
  comment?: string,
  bot?: boolean,
  sum_start?: number,
  sum_end?: number,
};

export enum UserStatus {
  premiumUser = 1,
  adminUser = 2,
  freeUser = 3,
  undefined = 0,
}