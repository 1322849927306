import styled from 'styled-components';
import { Box } from '../../../../components/styledComponents';

export const DragItem = styled(Box)`
  display: flex;
  width: 85%;
  background-color: #13141a;
  color: white;
  border-radius: 5px;
  outline: none;
  padding: 10px;
  margin: 10px;
  transition: background-color 0.35s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.hoverBackground};
  }
`;
