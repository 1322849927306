import styled from 'styled-components';
import { Box } from '../../components/styledComponents';

export const Container = styled(Box).attrs({
  width: '98%',
  height: 'calc(100vh - 108px)',
  mx: 'auto',
})`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const PaginateContainer = styled(Box).attrs({
  minHeight: 57,
})`
  display: flex;
  justify-content: center;
`;