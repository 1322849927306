import { useTranslation } from 'react-i18next';
import { PinCodeForm } from './components/PinCodeForm';
import { VerificationCodeProps } from './VerificationCode.types';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { passwordRecovery, signIn } from '../../redux/reducers/auth/async';
import { AuthenticationPageWrapper } from '../AuthenticationPageWrapper';

export function VerificationCode({
  login,
  password,
  serverError,
}: VerificationCodeProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    isRegister,
  } = useAppSelector((state) => state.auth);

  function onClickRequestCodeAgainHandler() {
    const requestVerificationCodeData = {
      password,
      email: login,
    };

    dispatch(isRegister
      ? signIn(requestVerificationCodeData)
      : passwordRecovery(requestVerificationCodeData));
  }

  return(
    <AuthenticationPageWrapper
      textButtonSubmit={t('authentication.verificationCode')}
      textSelectedForm={t('authentication.codeSentToEmail')}
      textNavigateButton={t('authentication.requestUnderAgain')}
      isPinCode
      onClickRequestCodeAgain={onClickRequestCodeAgainHandler}
    >
      <PinCodeForm
        login={login}
        password={password}
        serverError={serverError}
      />
    </AuthenticationPageWrapper>
  );
}