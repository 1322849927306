import { useId } from 'react';
import { useTheme } from 'styled-components';
import * as Styled from './FieldInput.styled';
import { FieldInputProps, InputType } from './FieldInput.types';
import { Button } from '../../components/Button';

export function FieldInput({
  value,
  onChange,
  title,
  backgroundColor,
  outlineColor,
  borderColor = 'transparent',
  color,
  placeholder = '',
  borderRadius = 4,
  type = InputType.Text,
  marginTop = 0,
  marginLeft = 0,
  tabIndex,
  isReadOnly = false,
  isNotVisible = false,
  isDisabled = false,
  width = '100%',
  height = 38,
  isActionButton = false,
  setValueInField,
}: FieldInputProps) {
  const {
    colors: {
      grayBlue,
      snowWhite,
      lightGray,
    },
  } = useTheme();
  const textColor = color ? color : snowWhite;

  const ACTIONS_BUTTON_DATA = [
    {
      key: useId(),
      title: '-100',
      value: -100,
    },
    {
      key: useId(),
      title: '-1000',
      value: -1000,
    },
    {
      key: useId(),
      title: '-10000',
      value: -10000,
    },
    {
      key: useId(),
      title: '+100',
      value: 100,
    },
    {
      key: useId(),
      title: '+1000',
      value: 1000,
    },
    {
      key: useId(),
      title: '+10000',
      value: 10000,
    },
  ];

  function onClickAddValueField(plusValue: number) {
    if (setValueInField
      && value !== undefined
      && (typeof value === 'number' || typeof value === 'string')
      && !Number.isNaN(+value)
      && +value + plusValue >= 0) {
      setValueInField(+value + plusValue);
    }
  }

  return(
    <>
      {!isNotVisible && (
        <Styled.Container
          marginTop={marginTop}
          marginLeft={marginLeft}
          width={width}
        >
          <Styled.TitleContainr>
            {title && (
              <Styled.TitleInput color={textColor}>
                {title}
              </Styled.TitleInput>
            )}

            {isActionButton && (
              <Styled.ActionsContainer>
                {ACTIONS_BUTTON_DATA.map(({ key, title, value }) => (
                  <Button
                    width={50}
                    height={16}
                    key={key}
                    title={title}
                    onClick={() => onClickAddValueField(value)}
                    size="light14"
                  />
                ))}
              </Styled.ActionsContainer>
            )}
          </Styled.TitleContainr>

          <Styled.Input
            type={type}
            value={value}
            onChange={onChange}
            color={textColor}
            backgroundColor={backgroundColor ? backgroundColor : grayBlue}
            outlineColor={outlineColor ? outlineColor: lightGray}
            borderColor={borderColor}
            borderRadius={borderRadius}
            placeholder={placeholder}
            tabIndex={tabIndex}
            readOnly={isReadOnly}
            disabled={isDisabled}
            height={height}
          />
        </Styled.Container>
      )}
    </>
  );
}