import { createSlice } from '@reduxjs/toolkit';
import { setCloseFee1, setCloseFee2, setCorrelation, setDealNumber, setDealValid, setCloseDate, setDate, setRatesArray, setSlidingWindowSize, setRate1, setRate2, setPlatform1, setPlatform2, setCurrencyFrom, setCurrencyTo, setProfitVolume } from './actions';
import { getDealRates } from './async';
import { DealStore } from './types';

const initialState: DealStore = {
  dealNumber: '',
  isDealValid: false,
  loadingState: 'success',
  error: undefined,
  avgRateAsk1: null,
  rateAsk1: null,
  avgRateBid2: null,
  rateBid2: null,
  closeDate: '',
  date: '',
  ratesArray: [],
  slidingWindowSize: null,
  rate1: null,  
  rate2: null,
  platform1: null, 
  platform2: null, 
  currencyFrom: null, 
  currencyTo: null, 
  profitVolume: null,
  closeFee1: null, 
  closeFee2: null, 
  correlation: null,  
};

export const deal = createSlice({
  name: 'deal',
  initialState,
  reducers: {
    setDealNumber,
    setDealValid,
    setCloseDate,
    setDate,
    setRatesArray,
    setSlidingWindowSize,
    setRate1, 
    setRate2, 
    setPlatform1, 
    setPlatform2, 
    setCurrencyFrom, 
    setCurrencyTo, 
    setProfitVolume, 
    setCloseFee1, 
    setCloseFee2, 
    setCorrelation, 
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDealRates.pending, (state) => {
        state.loadingState = 'loading';
        state.error = undefined;
      })
      .addCase(getDealRates.rejected, (state, { payload }) => {
        state.loadingState = 'failed';
        state.error = payload as string;
      })
      .addCase(getDealRates.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';
        state.isDealValid = payload.ok;
        if (payload.ok) {
          state.closeDate = payload.data.close_date;
          state.date = payload.data.date;
          state.ratesArray = payload.data.rates_array;
          state.slidingWindowSize = payload.data.sliding_window_size;
          state.rate1 = payload.data.rate1;  
          state.rate2 = payload.data.rate2;  
          state.platform1 = payload.data.platform1; 
          state.platform2 = payload.data.platform2; 
          state.currencyFrom = payload.data.currency_from; 
          state.currencyTo = payload.data.currency_to; 
          state.profitVolume = payload.data.profit_volume; 
          state.closeFee1 = payload.data.close_fee1; 
          state.closeFee2 = payload.data.close_fee2; 
          state.correlation = payload.data.correlation; 
          state.error = undefined;
        }
      });
  },
});

export const {
  setDealNumber: setDealNumberAction,
  setDealValid: setDealValidAction,
  setCloseDate: setCloseDateAction,
  setDate: setDateAction,
  setRatesArray: setRatesArrayAction,
  setSlidingWindowSize: setSlidingWindowSizeAction,
  setRate1: setRate1Action, 
  setRate2: setRate2Action, 
  setPlatform1: setPlatform1Action,
  setPlatform2: setPlatform2Action,
  setCurrencyFrom: setCurrencyFromAction,
  setCurrencyTo: setCurrencyToAction,
  setProfitVolume: setProfitVolumeAction,
  setCloseFee1: setCloseFee1Action, 
  setCloseFee2: setCloseFee2Action, 
  setCorrelation: setCorrelationAction, 
} = deal.actions;

export default deal.reducer;
