import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ICreateData, onClearListFilesDeleteLoopsCsvAction } from '.';
import { RootState } from '../../store';

export const postCreateChains = createAsyncThunk(
  'createData/postCreateChains',
  async (
    { authKey }: { authKey: string },
    { getState },
  ) => {
    const { createData } = getState() as RootState;

    const creatChainData = {
      data: createData.cheinData,
    };

    const { data } = await axios.post('/new-loop', { ...creatChainData }, {
      headers: {
        key: authKey,
      },
    });

    return data;
  },
);

export const postDownloadCSV = createAsyncThunk(
  'createData/postDownloadCSV',
  async (
    { authKey }: { authKey: string },
    { getState }: { getState: any },
  ) => {
    const state: ICreateData = getState().createData;

    const formData = new FormData();
    formData.append('file', state.fileCSV[0]);

    const { data } = await axios({
      method: 'post',
      url: '/add-courses',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        key: authKey,
      },
    });

    return data;
  },
);

export const postUpdateMetrics = createAsyncThunk(
  'createData/postUpdateMetrics',
  async (
    { authKey }: { authKey: string },
    { getState },
  ) => {
    const { createData } = getState() as RootState;
    const {
      updateLoopId,
    } = createData;

    const updateMetric = {
      loop_id: updateLoopId ? updateLoopId : 0,
    };

    const { data } = await axios.post('/update-metrics', { ...updateMetric }, {
      headers: {
        key: authKey,
      },
    });

    return data;
  },
);

export const getUpdateMetricsStatus = createAsyncThunk(
  'createData/getUpdateMetricsStatus',
  async (
    { authKey }: { authKey: string },
  ) => {
    const { data } = await axios.get('/check-metrics', {
      headers: {
        key: authKey,
      },
    });

    return data;
  },
);

export const getStateDeleteLoops = createAsyncThunk(
  'createData/getStateDeleteLoops',
  async (
    { authKey }: { authKey: string },
  ) => {
    const { data } = await axios.get('/check-delete-loops-status', {
      headers: {
        key: authKey,
      },
    });

    return data;
  },
);

export const setStateDeleteLoopsCsv = createAsyncThunk(
  'createData/setStateDeleteLoops',
  async (
    { authKey }: { authKey: string },
    {
      dispatch,
      getState,
    },
  ) => {
    const { createData } = getState() as RootState;

    const formData = new FormData();
    formData.append('file', createData.deleteLoopsCsv[0]);

    const { data } = await axios.post('/delete-loops-csv', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        key: authKey,
      },
    });

    dispatch(onClearListFilesDeleteLoopsCsvAction(0));

    return data;
  },
);

export const getStateDeleteLoopsAuto = createAsyncThunk(
  'createData/getStateDeleteLoopsAuto',
  async ({
    authKey,
    isNeedToChange,
  }: {
    authKey: string,
    isNeedToChange: boolean,
  },
  ) => {

    const requestData = {
      need_to_change: isNeedToChange,
    };

    const { data } = await axios.post('/delete-loops-auto', { ...requestData }, {
      headers: {
        key: authKey,
      },
    });

    return data;
  },
);