export interface MarksData {
  value: number;
  label: string;
}

export const marksRule: MarksData[] = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: '>10',
  },
];

export const marks: MarksData[] = [
  {
    value: 0,
    label: '<1 мин',
  },
  {
    value: 1,
    label: '1 мин',
  },
  {
    value: 2,
    label: '10 мин',
  },
  {
    value: 3,
    label: '1ч',
  },
  {
    value: 4,
    label: '3 ч',
  },
  {
    value: 5,
    label: '24ч',
  },
  {
    value: 6,
    label: '48ч',
  },
  {
    value: 7,
    label: '>48ч',
  },
];

export const countRule = {
  minCountRule: 1,
  maxCountRule: 10,
  stepRule: null,
  minTimeRange: 0,
  maxTimeRange: 7,
  minAvgProfitDay: -30,
  maxAvgProfitDay: 30,
  stepAvgDay: 0.001,
  minAvgProfitWeek: -30,
  maxAvgProfitWeek: 30,
  stepAvgWeek: 0.001,
  minTimeProfit: 0,
  maxTimeProfit: 120,
  minAvgProfitMonth: -30,
  maxAvgProfitMonth: 30,
  stepAvgMonth: 0.001,
  minPercentProfit: 0,
  maxPercentProfit: 100,

};
