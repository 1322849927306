import styled from 'styled-components';
import { Box, Text } from '../../../../../../../../components/styledComponents';

export const FilterGroup = styled(Box)`
  grid-column: 1 / 3;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.snowWhite};
  border-radius: 4px;

  @media (max-width: 510px) {
    grid-column: 1 / 2;
  }

  @media (max-width: 374px) {
    width: 100%;
  }
`;

export const FilterGroupHeader = styled(Box).attrs({
  py: 1,
  pl: 2,
  pr: 5,
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  @media (max-width: 374px) {
    padding: 4px;
  }
`;

export const FilterGroupTitle = styled(Text).attrs({
  variant: 'regular16',
  color: 'snowWhite',
})`
  @media (max-width: 374px) {
    overflow-wrap: break-word;
  }
`;

type FilterGroupSwitchProps = {
  isShow: boolean,
}

export const FilterGroupSwitch = styled(Box).attrs({
  position: 'relative',
  width: 24,
  height: 24,
})<FilterGroupSwitchProps>`
  rotate: ${({ isShow }) => isShow ? '180deg' : '0'};
  transition: .25s all;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-82%, -50%) rotate(45deg);
    width: 12px;
    height: 2px;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
  }

  &::after {
    transform: translate(-13%, -50%) rotate(-45deg);
  }
`;

type FilterGroupBodyProps = {
  isShow: boolean,
}

export const FilterGroupBody = styled(Box).attrs<FilterGroupBodyProps>(({ isShow }) => ({
  px: 2,
  pt: isShow ? 1 : 0,
  pb: isShow ? 3 : 0,
  width: '100%',
  height: isShow ? '100%' : 0,
}))<FilterGroupBodyProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

