import * as Styled from './CellWithDeviation.styled';
import { CellWithDeviationProps } from './CellWithDeviation.types';
import { CellContainer } from '../../ui/CellContainer';
import { setFormatString } from '../../utils/SetFormatString';

export function CellWithDeviation({
  value,
  subValue,
  options,
  color,
  size,
  background = '',
  isBorderBottom,
  colorBorder,
  onClick,
}: CellWithDeviationProps) {
  const [title, subTitle] = setFormatString({
    value,
    options,
    helpValue: subValue,
  });

  return (
    <>
      <CellContainer
        background={background}
        paddingVertical={1}
        isBorderBottom={isBorderBottom}
        colorBorder={colorBorder}
        onClick={onClick}
      >
        <Styled.CellText color={color} size={size}>
          {title}
        </Styled.CellText>
      </CellContainer>
      <CellContainer
        background={background}
        paddingVertical={1}
        isBorderBottom={isBorderBottom}
        colorBorder={colorBorder}
        onClick={onClick}
      >
        <Styled.CellText color={color} size={size}>
          {subTitle}
        </Styled.CellText>
      </CellContainer>
    </>
  );
}