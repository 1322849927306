import styled from 'styled-components';
import { Box, Text } from '../../../../../../components/styledComponents';
import { SCREEN } from '../../../../../../constants/constants';

export const Container = styled(Box).attrs({
  width: '100%',
  p: 3,
  bg: 'blackBlue',
  minHeight: 288,
})`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 4px;

  @media (max-width: ${SCREEN.desktops}) {
    order: 2;
  }
`;

export const Title = styled(Text).attrs({
  color: 'snowWhite',
  variant: 'light20',
  mb: 1,
})`
  text-align: center;
`;