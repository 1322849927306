import { PayloadAction } from '@reduxjs/toolkit';
import { ICreateData } from '.';
import { INPUT_MASKS } from '../../../constants/constants';
import { CreateChainData } from '../../types';

export const setChainsLength = (
  state: ICreateData,
  { payload }: PayloadAction<number>,
) => {
  state.chainsLength = payload;
};

export const setNewListChains = (
  state: ICreateData,
) => {
  const newArrChains: CreateChainData[] = [];

  for (let i = 0; i < state.chainsLength; i += 1) {
    const newObj: CreateChainData = {
      currency: '',
      platform: '',
      method: '',
      id: i,
    };

    newArrChains.push(newObj);
  }

  state.cheinData = newArrChains;
};

export const onUpdateItemPlatform = (
  state: ICreateData,
  { payload }: PayloadAction<{ propField: string, index: number }>,
) => {
  const newObj = state.cheinData.map((item) => {
    if (item.id === payload.index) {
      return {
        ...item,
        platform: payload.propField,
      };
    }
    return item;
  });

  state.cheinData = newObj;
};

export const onUpdateItemCurrency = (
  state: ICreateData,
  { payload }: PayloadAction<{ propField: string, index: number }>,
) => {
  const newObj = state.cheinData.map((item) => {
    if (item.id === payload.index) {
      return {
        ...item,
        currency: payload.propField,
      };
    }
    return item;
  });

  state.cheinData = newObj;
};

export const onUpdateItemMethod = (
  state: ICreateData,
  { payload }: PayloadAction<{ propField: string, index: number }>,
) => {
  const newObj = state.cheinData.map((item) => {
    if (item.id === payload.index) {
      return {
        ...item,
        method: payload.propField,
      };
    }
    return item;
  });

  state.cheinData = newObj;
};

export const checkForEmptyValues = (
  state: ICreateData,
) => {
  const temp = state.cheinData.find((item) => (
    !item.currency || !item.method || !item.platform
  ));

  if (temp === undefined) {
    state.validBtn = true;
  } else {
    state.validBtn = false;
  }
};

export const setNewListFiles = (
  state: ICreateData,
  { payload }: PayloadAction<File[]>,
) => {
  state.fileCSV = payload;
};

export const deleteListFiles = (
  state: ICreateData,
) => {
  state.fileCSV = [];
};

export const onClearListFilesDeleteLoopsCsv = (
  state: ICreateData,
  { payload }: PayloadAction<number>,
) => {
  state.deleteLoopsCsv = state.deleteLoopsCsv.filter((file, index) => index !== payload);
};

export const onDownloadFilesDeleteLoopsCsv = (
  state: ICreateData,
  { payload }: PayloadAction<File[]>,
) => {
  state.deleteLoopsCsv = payload;
};

export const updateLoopId = (
  state: ICreateData,
  { payload }: PayloadAction<string>,
) => {

  if (INPUT_MASKS.number.test(payload) || payload === '') {
    state.updateLoopId = payload;
  }
};