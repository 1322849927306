import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import * as Styled from './Advantages.styled';
import { Slide } from './components/Slide';
import { ADVANTAGES, SERVICES, SLIDERS } from './constsnts';
import arrow from '../../../../assets/icons/arrow-home.svg';
import checkbox from '../../../../assets/icons/checkbox-home.svg';
import { Button } from '../../../../components/Button';
import { ImageContainer } from '../../../../components/ImageContainer';
import { usePathLogged } from '../../../../hooks/usePathLogged';
import { ButtonContainer } from '../../../../ui/ButtonContainer';
import { Container } from '../../../../ui/Container';
import { SlidesPerView } from '../../../../ui/SlidesPerView';

export function Advantages() {
  const { t } = useTranslation();
  const {
    colors: {
      snowWhite,
      greenCobalt,
    },
  } = useTheme();
  const { setPathAuntification } = usePathLogged();

  function onClickSetPathSignUpHandler() {
    setPathAuntification('signUp');
  }

  return(
    <Styled.Wrapper>
      <Container>
        <Styled.AdvantagesContainer>
          <Styled.AdvantagesWrapper>
            {ADVANTAGES.map(({ strong, text }, index) => (
              <Styled.TitleWrapper key={`${index}w`}>
                <Styled.TitleStrong>{t(strong)}</Styled.TitleStrong>
                <Styled.TitleText>{t(text)}</Styled.TitleText>
              </Styled.TitleWrapper>
            ))}
          </Styled.AdvantagesWrapper>

          <Styled.ServicesWrapper>
            <Styled.ServicesContainer>
              <Styled.ServicesBlock>
                {SERVICES.map((service, index) => (
                  <Styled.ServiceWrapper key={`${index}e`}>
                    <ImageContainer
                      width={20}
                      height={20}
                      image={checkbox}
                      fill={greenCobalt}
                    />
                    <Styled.ServiceText>
                      {t(service)}
                    </Styled.ServiceText>
                  </Styled.ServiceWrapper>
                ))}
              </Styled.ServicesBlock>

              <ButtonContainer marginTop={13}>
                <Button
                  width={461}
                  height={50}
                  onClick={onClickSetPathSignUpHandler}
                  title={t('_advantages.advantages.button')}
                  borderRadius={6}
                  size='medium16'
                  image={arrow}
                  imageWidth={16}
                  imageHeight={8}
                  fill={snowWhite}
                  fillHover={snowWhite}
                  fillActive={snowWhite}
                  indent={45}
                />
              </ButtonContainer>
            </Styled.ServicesContainer>
          </Styled.ServicesWrapper>
        </Styled.AdvantagesContainer>

        <Styled.SliderContainer>
          <SlidesPerView
            slides={
              SLIDERS.map((slider, index) => (
                <Slide
                  key={index}
                  { ...slider }
                />
              ))
            }
          />
        </Styled.SliderContainer>
      </Container>
    </Styled.Wrapper>
  );
}