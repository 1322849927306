export const parseDataRangeTime = (value: number | undefined) => {
  switch (value) {
    case 1:
      return 60;
    case 2:
      return 600;
    case 3:
      return 3600;
    case 4:
      return 10800;
    case 5:
      return 86400;
    case 6:
      return 172800;
    default:
      return 0;
  }
};