import { PayloadAction } from '@reduxjs/toolkit';
import { TabelesState } from '.';
import {
  ActiveTypeTime,
  IChainSteps, TypeTime,
  UpdateStartCurrencyOrSetMarginalProfitChain,
} from './types';
import { createUpdatedStepValue } from './utils/CreateUpdatedStepValue';
import { mergeFavoriteChainsWithTypeTime } from '../../../utils/mergeFavoriteChainsWithTypeTime';
import { SelectedMetrics } from '../../types';
import { FiltersState } from '../filters';
import { DataChainsFull, DataMetric } from '../filters/types';

export const chainsPagination = (
  state: TabelesState,
  { payload }: PayloadAction<number>,
) => {
  state.currentPageChains = payload;
};

export const onMaxTimeJobScripts = (
  state: TabelesState,
  { payload }: PayloadAction<number>,
) => {
  state.maxTimeJobSctiprs = payload;
};

export const collapseTransitAndConvertStatusInText = (
  state: TabelesState,
) => {
  const { chainSteps } = state;

  const newArr: IChainSteps[] = [];

  for (let i = 0; i < chainSteps.length; i += 1) {
    if (chainSteps[i + 1] || i === chainSteps.length - 1) {
      if ((chainSteps[i].platform_to === '#TRANSIT') && (chainSteps[i + 1]?.platform_from === '#TRANSIT')) {
        const newObj = {
          ...chainSteps[i],
          platform_to: chainSteps[i + 1].platform_to,
        };
        i += 1;
        newArr.push(newObj);
      } else {
        newArr.push({
          ...chainSteps[i],
          sum_start: 0,
          sum_end: 0,
        });
      }
    }
  }

  state.chainSteps = newArr.map((item) => {
    switch (item.rate) {
      case -1:
        state.normalRate = false;
        return {
          ...item,
          rate: 'Нет предложений',
        };
      case -2:
        state.normalRate = false;
        return {
          ...item,
          rate: 'Площадка не работает',
        };
      case -3:
        state.normalRate = false;
        return {
          ...item,
          rate: 'Тех. проблемы',
        };
      default:
        return {
          ...item,
        };
    }
  });
};

export const updateStartCurrencyOrSetMarginalProfitChain = (
  state: TabelesState,
  {
    payload: {
      presentValue,
      indexCurrency,
    },
  }: PayloadAction<UpdateStartCurrencyOrSetMarginalProfitChain>,
) => {
  const { chainSteps } = state;
  const stepsChainOrStepsChainsWithNewStartCurrency: IChainSteps[] = indexCurrency
    ? (
      Array.prototype.concat(
        chainSteps?.slice(indexCurrency),
        chainSteps?.slice(0, indexCurrency),
      )
    )
    : chainSteps;

  const updatedSteps = stepsChainOrStepsChainsWithNewStartCurrency.reduce((
    acc: { steps: IChainSteps[], valueSteps: number, valueViewStep: number },
    step,
  ) => {
    const {
      updatedValue: nextValue,
      updatedViewValue: nextViewValue,
    } = createUpdatedStepValue({
      presentValue: acc.valueSteps,
      rate: +step.rate,
      tax: step.tax,
      constTax: step.tax_const,
      significant: step.significant,
    });

    const newStep = {
      ...step,
      sumStart: acc.valueViewStep,
      sumEnd: nextViewValue,
    };

    return {
      steps: [...acc.steps, newStep],
      valueSteps: nextValue,
      valueViewStep: nextViewValue,
    };
  }, {
    steps: [],
    valueSteps: presentValue,
    valueViewStep: presentValue,
  },
  );

  state.chainSteps = updatedSteps.steps;
  state.marginalProfit = updatedSteps.valueViewStep;
};

export const setActiveTypeTime = (
  state: TabelesState,
  { payload }: PayloadAction<ActiveTypeTime>,
) => {
  state.activeTypeTime = payload;
};

export const onSelectedCurrencyGraph = (
  state: TabelesState,
  { payload }: PayloadAction<SelectedMetrics>,
) => {
  const {
    selectedCurrencyGraph,
  } = state;

  if (!selectedCurrencyGraph.some((metric) => metric.name === payload.name)) {
    state.selectedCurrencyGraph.push(payload);
  } else {
    state.selectedCurrencyGraph = selectedCurrencyGraph.filter((metric) => metric.name !== payload.name);
  }
};

export const onDeleteSelectedCurrencyGraph = (
  state: TabelesState,
  { payload }: PayloadAction<DataMetric>,
) => {
  const { selectedCurrencyGraph } = state;

  state.selectedCurrencyGraph = selectedCurrencyGraph.filter((method) => method.name !== payload.name);
};

export const setIsEqualCurrencies = (
  state: TabelesState,
) => {
  state.isEqualCurrencies = !state.isEqualCurrencies;
  state.isCurrencyPairStrict = false;
};

export const setIsRelatedPlatforms = (
  state: TabelesState,
) => {
  state.isRelatedPlatforms = !state.isRelatedPlatforms;
};

export const setIsCurrencyPairStrict = (
  state: TabelesState,
) => {
  state.isCurrencyPairStrict = !state.isCurrencyPairStrict;
};

export const resetSelectedPageInFirst = (
  state: TabelesState,
) => {
  state.currentPageChains = 1;
};

// Соединяет данные статических полей избраных цепочек с данными динамических полей, в зависимости от выбранного типа времени
export const concatenateDataFavoriteChainWithDataTypeTime = (
  state: TabelesState,
  { payload }: PayloadAction<TypeTime>,
) => {
  state.favoriteChains = mergeFavoriteChainsWithTypeTime({
    typeTime: payload,
    dataDay: state.favoriteChainsDay,
    dataWeek: state.favoriteChainsWeek,
    dataMonth: state.favoriteChainsMonth,
    dataChains: state.favoriteChains,
  });
};
