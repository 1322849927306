import styled from 'styled-components';
import { layout } from 'styled-system';
import { Box, Text } from '../../../../components/styledComponents';

export const StoppingProcesses = styled(Box).attrs({
  display: 'flex',
  width: '100%',
  height: 'calc(100vh - 78px)',
})`
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StoppingProcessesContainer = styled(Box).attrs({
  display: 'flex',
  bg: 'blackBlue',
  px: '10px',
  py: '10px',
  my: '25px',
  mx: '25px',
})`
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
`;

export const StoppingProcessesContainerTextTitle = styled(Text).attrs({
  display: 'flex',
  color: 'snowWhite',
  variant: 'medium20',
  mb: 3,
  px: '10px',
})(layout);

export const StoppingProcessesContainerText = styled(Text).attrs({ 
  display: 'flex', 
  color: 'snowWhite', 
  variant: 'regular16', 
  mb: 3, 
  px: '10px', 
})(layout);

export const StoppingProcessesButton = styled(Box).attrs({
  as: 'button',
  color: 'snowWhite',
  bg: 'greenCobalt',
  py: 4,
  px: 6,
})`
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: .25s all;

  &:hover {
    background: ${({ theme }) => theme.colors.lightGrayBlue};
    transition: .25s all;
  }

  &:active {
    background: ${({ theme }) => theme.colors.greenBlue};
    transition: .25s all;
  }
`;