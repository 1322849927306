import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Styled from './404.styled';
import { Button } from '../../components/Button';
import { MetaTags } from '../../components/MetaTags';
import { PATH } from '../../constants/constants';
import { useAppSelector } from '../../hooks/storeHooks';
import { ButtonContainer } from '../../ui/ButtonContainer';


function Page404() {
  const {
    isLogged,
  } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  function onClickSetPathSingInHandler() {
    navigate(isLogged ? PATH.chains : PATH.signIn);
  }

  return (
    <Styled.NotFound>
      <MetaTags
        title="This page is not found"
        description="Arbitoring - the best currency arbitrage in the world"
        name="Arbitoring"
        type="article"
      />
      <Styled.NotFoundContainer>
        <Styled.NotFoundErrorText>404</Styled.NotFoundErrorText>
        <Styled.NotFoundErrorTitle>Это не та веб-страница, которую вы ищете</Styled.NotFoundErrorTitle>
        <ButtonContainer
          marginTop={7}
          width="75%"
        >
          <Button
            width="100%"
            height={40}
            onClick={onClickSetPathSingInHandler}
            title="Вернутся на основную"
            size="regular16"
          />
        </ButtonContainer>
      </Styled.NotFoundContainer>
    </Styled.NotFound>
  );
}

export default memo(Page404);
