import styled from 'styled-components';
import { color, compose, layout, position, space } from 'styled-system';
import { BoxProps } from './Box.types';

export const Box = styled('div')<BoxProps>(
  compose(
    color,
    layout,
    position,
    space,
  ),
);
