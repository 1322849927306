import styled, { css } from 'styled-components';
import { Box, Text } from '../../../../../../../../components/styledComponents';

export const MetricContainer = styled(Box)`
  grid-column: 1 / 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 510px) {
    grid-column: 1 / 2;
  }
`;

export const MetricTitle = styled(Text).attrs({
  variant: 'regular16',
  color: 'snowWhite',
})``;

type MetricListProps = {
  isScroll: boolean,
  isRightActions: boolean,
}

export const MetricList = styled(Box).attrs<MetricListProps>(({
  isScroll,
  isRightActions,
}) => ({
  height: isScroll && !isRightActions ? 80 : 'auto',
}))<MetricListProps>`
  display: flex;
  flex-wrap: wrap;
  overflow-y: ${({
    isScroll,
    isRightActions,
  }) => isScroll && !isRightActions ? 'scroll' : 'hidden'};
`;

type MetricItemProps = {
  isActive: boolean,
  isRightActions: boolean,
}

export const MetricItem = styled(Text).attrs<MetricItemProps>(({
  isActive,
  isRightActions,
}) => ({
  variant: 'regular14',
  color: isActive ? 'grayBlue' : 'lightGray',
  bg: isActive ? 'snowWhite' : 'grayBlue',
  height: 22,
  mb: isRightActions ? 0 : 1,
  mr: 1,
  py: 1,
  px: 3,
}))<MetricItemProps>`
  text-align: center;
  border-radius: 4px;
  cursor: pointer;

  @media (max-width: 510px) {
    flex-direction: column;
    margin-bottom: 4px;
  }
`;

type MetricWrapperProps = {
  isRightActions: boolean,
}

export const MetricWrapper = styled(Box).attrs({
  mt: 2,
})<MetricWrapperProps>`
  display: flex;
  flex-direction: ${({ isRightActions }) => isRightActions ? 'row' : 'column'};
  justify-content: ${({ isRightActions }) => isRightActions ? 'flex-start' : 'space-between'};

  @media (max-width: 510px) {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  ${({ isRightActions }) => {
    if (isRightActions) {
      return css`
        align-items: center;
      `;
    }
  }}
`;
