import { PayloadAction } from '@reduxjs/toolkit';
import { DealStore } from './types';

export const setDealNumber = (
  state: DealStore,
  { payload }: PayloadAction<string>,
) => {
  state.dealNumber = payload;
};

export const setDealValid = (
  state: DealStore,
  { payload }: PayloadAction<boolean>,
) => {
  state.isDealValid = payload;
};

export const setCloseDate = (
  state: DealStore,
  { payload }: PayloadAction<string>,
) => {
  state.closeDate = payload;
};

export const setDate = (
  state: DealStore,
  { payload }: PayloadAction<string>,
) => {
  state.date = payload;
};

export const setRatesArray = (
  state: DealStore,
  { payload }: PayloadAction<string[][]>,
) => {
  state.ratesArray = payload;
};

export const setSlidingWindowSize = (
  state: DealStore,
  { payload }: PayloadAction<string>,
) => {
  state.slidingWindowSize = payload;
};
export const setRate1 = (
  state: DealStore,
  { payload }: PayloadAction<number | null>,
) => {
  state.rate1 = payload;
};

export const setRate2 = (
  state: DealStore,
  { payload }: PayloadAction<number | null>,
) => {
  state.rate2 = payload;
};

export const setPlatform1 = (
  state: DealStore,
  { payload }: PayloadAction<string | null>,
) => {
  state.platform1 = payload;
};

export const setPlatform2 = (
  state: DealStore,
  { payload }: PayloadAction<string | null>,
) => {
  state.platform2 = payload;
};

export const setCurrencyFrom = (
  state: DealStore,
  { payload }: PayloadAction<string | null>,
) => {
  state.currencyFrom = payload;
};

export const setCurrencyTo = (
  state: DealStore,
  { payload }: PayloadAction<string | null>,
) => {
  state.currencyTo = payload;
};

export const setProfitVolume = (
  state: DealStore,
  { payload }: PayloadAction<number | null>,
) => {
  state.profitVolume = payload;
};

export const setCloseFee1 = (
  state: DealStore,
  { payload }: PayloadAction<number | null>,
) => {
  state.closeFee1 = payload;
};

export const setCloseFee2 = (
  state: DealStore,
  { payload }: PayloadAction<number | null>,
) => {
  state.closeFee2 = payload;
};

export const setCorrelation = (
  state: DealStore,
  { payload }: PayloadAction<number | null>,
) => {
  state.correlation = payload;
};
