import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import {
  ERROR_VALID_MESSAGE,
  INITIAL_VALUE,
  ServerValidMessage,
} from './constants';
import { useAuthenticationErrorProps } from './useAuthenticationError.types';
import { INPUT_MASKS } from '../../constants/constants';
import { setRegisterRequestValidMessageResetAction } from '../../redux/reducers/auth';
import { useAppDispatch, useAppSelector } from '../storeHooks';
import { usePrevious } from '../usePrevious';

export function useAuthenticationError({
  login,
  password,
  passwordRepeat = '',
  isPasswordRepeat = false,
}: useAuthenticationErrorProps) {
  const [error, setError] = useState(INITIAL_VALUE.error);

  const {
    registerRequestValidMessage,
  } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  // Ошибки приходят с сервера на одном языке, поэтому данный метод предусматривет мультиязычность
  // Отображает ошибку с сервера
  const serverError = useMemo(() => {
    switch (registerRequestValidMessage) {
      case ServerValidMessage.ServerIncorrectEmail:
        return ERROR_VALID_MESSAGE.serverIncorrectEmail;
      case ServerValidMessage.ServerEmailNoSuch:
        return ERROR_VALID_MESSAGE.serverEmailNoSuch;
      case ServerValidMessage.ServerNoSuchUser:
        return ERROR_VALID_MESSAGE.serverNoSuchUser;
      case ServerValidMessage.ServerPasswordBanSymbol:
        return ERROR_VALID_MESSAGE.serverPasswordBanSymbol;
      case ServerValidMessage.ServerPasswordShort:
        return ERROR_VALID_MESSAGE.serverPasswordShort;
      case ServerValidMessage.ServerCodesAreNotEqual:
        return ERROR_VALID_MESSAGE.serverCodesAreNotEqual;
      case ServerValidMessage.ServerPasswordDoesNotContainSpecialCharacters:
        return ERROR_VALID_MESSAGE.serverPasswordDoesNotContainSpecialCharacters;
      case ServerValidMessage.ServerPasswordDoesNotContainUpperLetters:
        return ERROR_VALID_MESSAGE.serverPasswordDoesNotContainUpperLetters;
      case ServerValidMessage.ServerPasswordDoesNotContainLowerLetters:
        return ERROR_VALID_MESSAGE.serverPasswordDoesNotContainLowerLetters;
      case ServerValidMessage.ServerPasswordDoesNotContainNumbers:
        return ERROR_VALID_MESSAGE.serverPasswordDoesNotContainNumbers;
      case ServerValidMessage.ServerUserAlreadyExists:
        return ERROR_VALID_MESSAGE.serverUserAlreadyExists;
      case ServerValidMessage.Okay:
        return ERROR_VALID_MESSAGE.okay;
      case ServerValidMessage.ServerIncorrectPassword:
        return ERROR_VALID_MESSAGE.password;
      default:
        return  ERROR_VALID_MESSAGE.okay;
    }
  }, [registerRequestValidMessage]);

  // Метод валидации на стороне клиента
  const isValid = useMemo(() => {
    switch (true) {
      case !INPUT_MASKS.email.test(login):
        setError(ERROR_VALID_MESSAGE.email);
        return false;
      case isPasswordRepeat && !INPUT_MASKS.leastOneUppercaseLetter.test(password):
        setError(ERROR_VALID_MESSAGE.serverPasswordDoesNotContainUpperLetters);
        return false;
      case isPasswordRepeat && !INPUT_MASKS.leastOneLowercaseLetter.test(password):
        setError(ERROR_VALID_MESSAGE.serverPasswordDoesNotContainLowerLetters);
        return false;
      case isPasswordRepeat && !INPUT_MASKS.leastOneNumber.test(password):
        setError(ERROR_VALID_MESSAGE.serverPasswordDoesNotContainNumbers);
        return false;
      case isPasswordRepeat && !INPUT_MASKS.leastOneSpecialCharacter.test(password):
        setError(ERROR_VALID_MESSAGE.serverPasswordDoesNotContainSpecialCharacters);
        return false;
      case isPasswordRepeat && password.length < 8:
        setError(ERROR_VALID_MESSAGE.passwordShort);
        return false;
      case isPasswordRepeat && passwordRepeat !== password:
        setError(ERROR_VALID_MESSAGE.passwordsDoNotMatch);
        return false;
      default:
        setError(INITIAL_VALUE.error);
        return true;
    }
  }, [login, password, passwordRepeat]);

  // Нужно отчищать сообщение об ошибке логина, когда пользователь только защел на сайт
  // Поэтому получаем перед педыдущее значение так как на самом первом рендере error === null
  const previousLogin = usePrevious(login);
  const previousPreviousLogin = usePrevious(previousLogin.current);

  useLayoutEffect(() => {
    if (previousPreviousLogin.current === null) setError('');
  }, [login]);

  useEffect(() => {
    if (registerRequestValidMessage !== null) {
      dispatch(setRegisterRequestValidMessageResetAction());
    }
  }, [isValid, login, password]);

  return {
    error,
    isValid,
    serverError,
  };
}