import styled, { css } from 'styled-components';
import { Box, Text } from '../../../styledComponents';

export const Container = styled(Box).attrs({
  width: '100%',
})``;

type PinCodeProps = {
  isCompleted: boolean,
}

export const PinCode = styled(Box).attrs({
  width: '100%',
})<PinCodeProps>`
  display: flex;
  justify-content: space-around;

  
  ${({
    isCompleted, theme: {
      colors: {
        blueAzure,
        snowWhite,
        lightGray,
        whiteAluminum,
      },
    },
  }) => css`
    .test {
      background-color: ${isCompleted ? whiteAluminum : snowWhite};
      border-radius: 4px;
      border: 2px solid ${isCompleted ? lightGray : blueAzure};
      text-align: center;
      width: 25px;
      height: 30px;
    }
  `}
`;

export const ErrorWrapper = styled(Box).attrs({
  width: '100%',
  height: 16,
  my: 1,
})`
  box-sizing: border-box;
`;

export const ErrorMessage = styled(Text).attrs({
  variant: 'regular14',
  color: 'carminePink',
})`
  text-align: center;
`;