import { useTheme } from 'styled-components';
import * as Styled from './RetractableSidePanel.styled';
import { RetractableSidePanelProps } from './RetractableSidePanel.types';
import arrow from '../../assets/icons/paginationArrow.svg';
import { ImageContainer } from '../../components/ImageContainer';

export function RetractableSidePanel({
  width,
  children,
  isLeft = false,
  title = '',
  marginTopSidePanel = 0,
  isActive,
  setIsActive,
}: RetractableSidePanelProps) {
  const {
    colors: {
      snowWhite,
    },
  } = useTheme();

  function onClickSetActiveSidePanelHandler() {
    setIsActive((prev) => !prev);
  }

  return(
    <Styled.Container
      isLeft={isLeft}
      width={isActive ? width : 0}
    >
      <Styled.ButtonActiveSidePanel
        onClick={onClickSetActiveSidePanelHandler}
      >
        <ImageContainer
          width={20}
          height={20}
          image={arrow}
          fill={snowWhite}
        />
        <Styled.TileSidePanel>
          {title}
        </Styled.TileSidePanel>
      </Styled.ButtonActiveSidePanel>

      <Styled.SidePanelContainer
        width={isActive ? width : 0}
        marginTop={marginTopSidePanel}
        isActive={isActive}
      >
        {children}
      </Styled.SidePanelContainer>
    </Styled.Container>
  );
}