import styled from 'styled-components';
import { Box, Text } from '../../../components/styledComponents';

export const PasswordRecoveryWrapper = styled(Box)`
  display: flex;
  justify-content: end;
`;

export const PasswordRecovery = styled(Text).attrs({
  variant: 'medium14',
  color: 'blueAzure',
  mt: 2,
})`
  text-align: end;
  cursor: pointer;
  user-select: none;
`;