import { createSlice } from '@reduxjs/toolkit';
import {
  setProfitChain,
  setAmountChain,
  setStartCurrencyChain,
  startOrStopOnlineParsingInLoop,
  setAmountOnlineParsing,
  setProfitOnlineParsing,
  setStateBtnStarted,
  setStatusOnlineParsing,
  setNewPointInGraphSecond,
} from './actions';
import * as asyncActs from './async';
import {
  IOnlineParsingDataChains,
  IOnlineParsingMap,
} from './types';

export interface IOnlineParsing {
  loadingState: 'loading' | 'failed' | 'success';
  error?: string;
  requestStatus?: number;

  loopID: number | null;
  startCurrencyId: string | null;
  profit: string | null;
  amount: string | null;
  onlineParsing: {
    [key: number]: IOnlineParsingMap;
  };

  resultAmount?: number;
  resultProfit?: number;
  onlineParsingDataChains: IOnlineParsingDataChains[];

  spreadOnlineParsing: number[];
  timeOnlineParsing: string[];
  stopParserRes: any;
  activeChainsOnlineParser: any;
}

const initialState: IOnlineParsing = {
  loadingState: 'success',
  loopID: null,
  startCurrencyId: null,
  profit: null,
  amount: null,
  // onlineParsing: new Map(),
  onlineParsing: {},

  resultAmount: 0,
  resultProfit: 0,
  onlineParsingDataChains: [],

  spreadOnlineParsing: [],
  timeOnlineParsing: [],
  stopParserRes: {},
  activeChainsOnlineParser: {},

};

export const parsing = createSlice({
  name: 'parsing',
  initialState,
  reducers: {
    setProfitChain,
    setAmountChain,
    setStartCurrencyChain,
    startOrStopOnlineParsingInLoop,
    setAmountOnlineParsing,
    setProfitOnlineParsing,
    setStateBtnStarted,
    setStatusOnlineParsing,
    setNewPointInGraphSecond,
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActs.getOnlineParsing.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.getOnlineParsing.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.message;
      })
      .addCase(asyncActs.getOnlineParsing.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';

        state.resultAmount = payload?.amount;
        state.resultProfit = payload?.profit;
        state.onlineParsingDataChains = payload.data;
      });

    builder
      .addCase(asyncActs.stopOnlineParser.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.stopOnlineParser.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.message;
      })
      .addCase(asyncActs.stopOnlineParser.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';

        state.stopParserRes = payload;
      });

  },
});

export const {
  setProfitChain: setProfitChainActions,
  setAmountChain: setAmountChainActions,
  setStartCurrencyChain: setStartCurrencyChainAct,
  startOrStopOnlineParsingInLoop: startOrStopOnlineParsingInLoopActions,
  setAmountOnlineParsing: setAmountOnlineParsingActions,
  setProfitOnlineParsing: setProfitOnlineParsingActions,
  setStateBtnStarted: setStateBtnStartedAct,
  setStatusOnlineParsing: setStatusOnlineParsingAct,
  setNewPointInGraphSecond: setNewPointInGraphSecondActions,
} = parsing.actions;

export default parsing.reducer;
