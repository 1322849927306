import styled from 'styled-components';
import { Box, Text } from '../../../../components/styledComponents';

export const Container = styled(Box).attrs({
  width: '100%',
  py: 10,
})`
  display: flex;
  justify-content: space-between;
`;

export const InfoContainer = styled(Box)`
  display: flex;
  column-gap: 180px;
`;

export const InfoNameContainer = styled(Box)`
  display: flex;
  column-gap: 20px;
`;

export const InfoNamePlatform = styled(Text).attrs({
  color: 'snowWhite',
  variant: 'medium20',
})``;

export const InfoPointerToNamePlatform = styled(Text).attrs({
  color: 'snowWhite',
  variant: 'light20',
})`
  user-select: none;
`;

export const ActionContainer = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;