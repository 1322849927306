import { useState } from 'react';
import { ActionMeta, MultiValue, OptionType, useMultiSelectReturn } from './useMultiSelect.types';

export function useMultiSelect(defaultValues: OptionType[] = []): useMultiSelectReturn {
  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>(defaultValues);

  const handleSelectChange = (options: MultiValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
    // здесь мы игнорируем actionMeta, но можешь использовать его в будущем, если потребуется
    setSelectedOptions(options || []);
  };

  return [selectedOptions, handleSelectChange];
}
