import styled from 'styled-components';
import { Box, Text } from '../../../../components/styledComponents';

export const Wrapper = styled(Box).attrs({
  minHeight: 764,
  height: 764,
  pt: 20,
  pb: 22,
  as: 'section',
})`
  box-sizing: border-box;
  background-image: url('./assets/img/main-bg-2.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-attachment: fixed;
`;

export const TitleStrong = styled(Text).attrs({
  variant: 'OpenSansBold28',
  color: 'greenCobalt',
  as: 'strong',
  mr: 2,
})`
  display: inline;
`;

export const TitleText = styled(Text).attrs({
  variant: 'OpenSansBold28',
  color: 'blackBlue',
})`
  display: inline;
`;

export const TitleWrapper = styled(Box).attrs({
  as: 'h2',
  m: 0,
})``;

export const AdvantagesWrapper = styled(Box).attrs({
  width: '50%',
})`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const ServicesWrapper = styled(Box).attrs({
  width: '50%',
})``;

export const ServiceWrapper = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

export const ServiceText = styled(Text).attrs({
  variant: 'medium16',
  color: 'blackBlue',
  as: 'h4',
  m: 0,
})`
  line-height: 24px;
`;

export const ServicesBlock = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const ServicesContainer = styled(Box).attrs({
  width: 461,
  ml: 'auto',
})`
  display: flex;
  flex-direction: column;
`;

export const AdvantagesContainer = styled(Box)`
  display: flex;
`;

export const SliderContainer = styled(Box).attrs({
  pt: 28,
})``;