import styled from 'styled-components';
import { Box } from '../../../../components/styledComponents';


export const DealNumberWrapper = styled(Box)`
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

export const DealNumberForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 40vw;
  height: 40vh;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #1b1c22;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-size: 23px;
  gap: 20px;
`;

export const Input = styled.input`
  text-align: center;
  border-radius: 10px;
  background-color: #1b1c22;
  border: 2px solid #262626;
  outline: none;
  color: white;
  padding: 10px;
`;

export const Button = styled.button`
  margin-top: 50px;
  padding: 10px;
  border-radius: 10px;
  background-color: #1994ff;
  color: white;
  border: 1px solid black;
  cursor: pointer;
  transition: background-color ease-in-out 0.1s;

  &:hover {
    background-color: lighten($color: #1994ff, $amount: 6);
  }
`;

export const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1%;
  height: 87vh;
  width: 100%;
  gap: 10px;
`;

export const GraphContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 100%;
  margin-left: 5px;
  margin-right: 10px;
  background-color: #1b1c22;
  p{
    color: white
  }
`;

export const GraphWrapper = styled(Box)`
  display: flex;
  width: 100%;
  height: 80%;
`;

export const GraphText = styled.p`
  color: white;
  font-size: 20px;
`;

export const GraphContainers = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const infoContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px;
  color: white;
  justify-content: center;
  bachground: red;
`;

export const infoItem = styled(Box)`
  display: flex;
  font-size: 10px;
  margin-left: 10px;
`;

interface TabButtonProps {
  isActive: boolean;
}

export const TabsContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const TabButton = styled.button<TabButtonProps>`
  flex: 1;
  padding: 5px;
  border: none;
  background: ${({ isActive, theme }) => (isActive ? '#1b1c22' : '#2c2d33')};
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 5px 5px 5px 5px;

  &:hover {
    background: ${({ theme }) => theme.colors.primaryHover};
  }
  
  &:focus {
    outline: none;
  }
`;

export const TabsContent = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 0 0 5px 5px;
  padding: 20px;
  background: ${({ theme }) => theme.colors.background};
`;

export const DropZones = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70vh;
`;

export const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 700px;
`;

export const FullWidthDropZone = styled(Box)`
  width: 100%;
`;

export const DragItemsWrapper = styled(Box)`
  width: 20%;
  margin-left: 10px;
  background-color: #1b1c22;
  height: 100%;

  p {
    margin: 0;
    margin-left: 5%;
    margin-top: 5%;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 25px;
  }
`;
