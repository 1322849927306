import React, { useEffect } from 'react';

import * as Styled from './Chains.styled';
import { TableChains } from './components/TableChains';
import { TableFeatures } from './components/TableFeatures';
import { MetaTags } from '../../components/MetaTags';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { setIsValidPinCodeInFalseAction } from '../../redux/reducers/auth';

export function Chains() {
  const dispatch = useAppDispatch();
  const {
    isValidPinCode,
  } = useAppSelector((state) => state.auth);

  useEffect(() =>{
    if(isValidPinCode){
      dispatch(setIsValidPinCodeInFalseAction());
    }
  }, []);

  return (
    <Styled.Container>
      <MetaTags
        title="Arbitoring - Chains"
        description="Arbitoring - the best currency arbitrage in the world"
        name="Arbitoring"
        type="article"
      />

      <TableFeatures/>

      <TableChains/>
    </Styled.Container>
  );
}
