import { Checkbox } from '@mui/material';
import { useTheme } from 'styled-components';
import * as Styled from './CellWithCheckbox.styled';
import { CellWithCheckboxProps } from './CellWithCheckbox.types';
import { CellContainer } from '../../ui/CellContainer';
import { setFormatString } from '../../utils/SetFormatString';

export function CellWithCheckbox({
  value,
  background,
  paddingVertical,
  isBorderBottom,
  colorBorder,
  size,
  color,
  options,
  onClick,
}: CellWithCheckboxProps) {
  const {
    colors: {
      greenCobalt,
    },
  } = useTheme();

  return(
    <CellContainer
      isRow
      background={background}
      paddingVertical={paddingVertical}
      isBorderBottom={isBorderBottom}
      colorBorder={colorBorder}
      onClick={onClick}
    >
      <Styled.Container>
        <Checkbox
          sx={{ color: greenCobalt }}
          color="default"
          onChange={() => {
            // Заглушка
          }}
          checked={value === 'Вкл'}
        />
        <Styled.CellText size={size} color={color}>
          {setFormatString({ value, options })}
        </Styled.CellText>
      </Styled.Container>
    </CellContainer>
  );
}