import { createSlice } from '@reduxjs/toolkit';
import {
  logOut,
  onEmailUser,
  setIsTransitionPinCodeInFalse,
  setRegisterRequestValidMessageReset,
  setIsValidPinCodeInFalse,
  setIsRegisterStata,
  setIsActiveScreenInTrue,
  setIsActiveScreenInFalse,
} from './actions';
import * as asyncActs from './async';

export interface AuthState {
  isLogged: boolean;
  loadingState: 'loading' | 'failed' | 'success';
  authKey?: string;
  userStatus?: number;
  error?: string;
  login?: string;
  emailUser: string;
  status: boolean;
  isTransitionPinCode: boolean;
  isTransitionPinCodeRecovery: boolean,
  isValidPinCode: boolean;
  registerRequestValidMessage: string | null;
  verificationCode: string; // надо ли это в редуксе?
  isRegister?: boolean,
  isActiveScreen: boolean,
}

const initialState: AuthState = {
  isLogged: false,
  authKey: '',
  userStatus: 0,
  loadingState: 'success',
  emailUser: '',
  status: true,
  isTransitionPinCode: false,
  isTransitionPinCodeRecovery: false,
  isValidPinCode: false,
  registerRequestValidMessage: '',
  verificationCode: '',
  isActiveScreen: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOut,
    onEmailUser,
    setIsTransitionPinCodeInFalse,
    setRegisterRequestValidMessageReset,
    setIsValidPinCodeInFalse,
    setIsRegisterStata,
    setIsActiveScreenInTrue,
    setIsActiveScreenInFalse,
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActs.logIn.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.logIn.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.code;
        state.isLogged = false;
        state.registerRequestValidMessage = error.message || '';
      })
      .addCase(asyncActs.logIn.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';
        state.isLogged = payload.ok;
        state.userStatus = payload.status;
        state.authKey = payload.key;
        state.registerRequestValidMessage = payload.message;
      });

    builder
      .addCase(asyncActs.signIn.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.signIn.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.code;
        state.isTransitionPinCode = false;
        state.registerRequestValidMessage = error.message || '';
      })
      .addCase(asyncActs.signIn.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';
        state.isTransitionPinCode = payload.ok;
        state.registerRequestValidMessage = payload.message;
      });

    builder
      .addCase(asyncActs.passwordRecovery.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.passwordRecovery.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.code;
        state.isTransitionPinCodeRecovery = false;
        state.registerRequestValidMessage = error.message || '';
      })
      .addCase(asyncActs.passwordRecovery.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';
        state.isTransitionPinCodeRecovery = payload.ok;
        state.registerRequestValidMessage = payload.message;
      });

    builder
      .addCase(asyncActs.verificationIn.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.verificationIn.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.code;
        // state.isLogged = false;
        state.isValidPinCode = false;
        // state.userStatus = 0;
        state.registerRequestValidMessage = error.message || '';
      })
      .addCase(asyncActs.verificationIn.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';
        // state.isLogged = payload.ok;
        state.isValidPinCode = payload.ok;
        // state.userStatus = payload.ok ? 1 : 0;
        state.registerRequestValidMessage = payload.message;
      });

    builder
      .addCase(asyncActs.verificationRecovery.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.verificationRecovery.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.code;
        // state.isLogged = false;
        state.isValidPinCode = false;
        // state.userStatus = 0;
        state.registerRequestValidMessage = error.message || '';
      })
      .addCase(asyncActs.verificationRecovery.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';
        // state.isLogged = payload.ok;
        state.isValidPinCode = payload.ok;
        // state.userStatus = payload.ok ? 1 : 0;
        state.registerRequestValidMessage = payload.message;
      });
  },
});

export const {
  logOut: logout,
  onEmailUser: onEmailUserAct,
  setIsTransitionPinCodeInFalse: setIsTransitionPinCodeInFalseAction,
  setRegisterRequestValidMessageReset: setRegisterRequestValidMessageResetAction,
  setIsValidPinCodeInFalse: setIsValidPinCodeInFalseAction,
  setIsRegisterStata: setIsRegisterStataAction,
  setIsActiveScreenInTrue: setIsActiveScreenInTrueAction,
  setIsActiveScreenInFalse: setIsActiveScreenInFalseAction,
} = authSlice.actions;

export default authSlice.reducer;
