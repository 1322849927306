import { useEffect, useState } from 'react';
import * as Styled from './DeleteLoopsAuto.styled';
import { Button } from '../../../../components/Button';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { getStateDeleteLoops, getStateDeleteLoopsAuto } from '../../../../redux/reducers/createData/async';
import { InformationWindow } from '../../ui/InformationWindow';

export function DeleteLoopsAuto() {
  const [isActiveRequest, setIsActiveRequest] = useState(false);

  const dispatch = useAppDispatch();
  const {
    authKey = '',
  } = useAppSelector((state) => state.auth);
  const {
    isDeleteLoopsAuto,
    deleteLoopsStatus,
  } = useAppSelector((state) => state.createData);

  function onClickCheckStateDeleteLoopsHandler() {
    dispatch(getStateDeleteLoops({ authKey }));
  }

  function onClickSetStateDeleteLoopsAutoHandler() {
    dispatch(getStateDeleteLoopsAuto({
      authKey,
      isNeedToChange: true,
    }));
    setIsActiveRequest((prev) => !prev);
  }

  useEffect(() => {
    if (isActiveRequest) {
      dispatch(getStateDeleteLoops({ authKey }));
      setIsActiveRequest((prev) => !prev);
    }
  }, [isDeleteLoopsAuto]);

  return (
    <Styled.Container>
      <Styled.ElementWrapper>
        <InformationWindow
          width="100%"
          title="Статус работы алгоритма"
          label={deleteLoopsStatus?.deletion_status || 'Информация не получена'}
          hint="Одновременно может работать только один алгоритм удаления"
        />
      </Styled.ElementWrapper>

      <Styled.ElementWrapper>
        <InformationWindow
          width="100%"
          title="Автоматическое удаление"
          label={isDeleteLoopsAuto ? 'Включен' : 'Выключен'}
          hint="Работает ли алгоритм автоматического удаления в данный момент"
        />
      </Styled.ElementWrapper>

      <Styled.ButtonContainer>
        <Button
          width="45%"
          height={50}
          onClick={onClickCheckStateDeleteLoopsHandler}
          title="Обновить (узнать) статус работы алгоритма"
          size="regular16"
        />

        <Button
          width="45%"
          height={50}
          onClick={onClickSetStateDeleteLoopsAutoHandler}
          title="Включить/выключить автоматическое удаление цепочек"
          size="regular16"
        />
      </Styled.ButtonContainer>
    </Styled.Container>
  );
}