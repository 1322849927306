export function setStatus(statusNumber: number): string {
  switch (statusNumber) {
    case 1:
      return 'Активна';
    case 2:
      return 'Онлайн';
    case 3:
      return 'Торговля';
    case 4:
      return 'Идентиф.';
    case 5:
      return 'Нет предл.';
    case 6:
      return 'Недоступ';
    case 7:
      return 'Тех. сбой';
    case 8:
      return 'Останов.';
    case 9:
      return 'Удалена';
    default:
      return '' + statusNumber;
  }
}