import React from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Line } from 'react-chartjs-2';
import { useTheme } from 'styled-components';
import { ChartProps } from './Chart.types';

ChartJS.register(...registerables);
ChartJS.register(zoomPlugin);

ChartJS.defaults.color = '#EAE7DC';

export function Chart({
  labels,
  data,
}: ChartProps) {
  const {
    colors: {
      greenCobalt,
    },
  } = useTheme();

  const canvas = document.getElementById('canvas') as HTMLCanvasElement | null;
  const ctx = canvas?.getContext('2d');
  let gradient1;
  let gradient2;
  if (ctx) {
    gradient1 = ctx.createLinearGradient(0, 200, 0, 500);
    gradient1.addColorStop(0.25, greenCobalt);
    gradient1.addColorStop(0.6, '#24252D');

    gradient2 = ctx.createLinearGradient(0, 240, 0, 150);
    gradient2.addColorStop(0.5, '#24252D');
    gradient2.addColorStop(0.2, '#5C2828');
  }

  const lineChartData = {
    labels,
    datasets: [
      {
        data,
        label: 'Доходность цепочки',
        borderColor: '#EAE7DC',
        pointBorderColor: '#828282',
        pointRadius: 4,
        pointHoverRadius: 5,
        fill: {
          target: 'origin',
          above: gradient1,
          below: gradient2,
        },
        pointBackgroundColor: function (context: any) {
          const index = context.dataIndex;
          const value = context.dataset.data[index];
          return value < 0 ? '#ff192d' : greenCobalt;
        },
        lineTension: 0.5,
      },
    ],
  };

  return (
    <Line
      id="canvas"
      width={160}
      height={80}
      data={lineChartData}
      options={{
        transitions: {
          zoom: {
            animation: {
              duration: 1000,
              easing: 'easeOutCubic',
            },
          },
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: 'Время',
            },
            min: data.length - 16,
            max: data.length,
          },
          y: {
            display: true,
            title: {
              display: true,
              text: 'Доход',
            },
          },
        },
        plugins: {
          zoom: {
            pan: {
              enabled: true,
              mode: 'x',
            },
            limits: {
              y: { min: -1, max: 1 },
              x: { minRange: 10 },
            },
            zoom: {
              wheel: {
                enabled: true,
                speed: 0.1,
              },
              drag: {
                enabled: true,
                modifierKey: 'shift',
              },
              pinch: {
                enabled: true,
              },
              mode: 'x',
            },
          },
        },
      }}
    />
  );
}
