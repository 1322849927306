export interface IDataMetrics {
  id: string,
  title: string,
  actions: (value: number) => {
    payload: number;
    type: string;
  },
  setNewMap?: () => {
    payload: undefined;
    type: string;
  },
  maxValue?: number,
  valueField: number,
}

export const createChainsHeaders = [
  {
    id: 'platform_from',
    title: 'Откуда',
    name: 'platrorm',
  },
  {
    id: 'currency_from',
    title: 'Валюта',
    name: 'currency',
  },
  {
    id: 'arrowOne',
    title: 'Перевод',
    name: 'arrow',
  },
  {
    id: 'method',
    title: 'Метод',
    name: 'method',
  },
  {
    id: 'arrowTwo',
    title: 'Перевод',
    name: 'arrow',
  },
  {
    id: 'currency_to',
    title: 'Валюта',
    name: 'currency',
  },
  {
    id: 'platform_to',
    title: 'Куда',
    name: 'platform',
  },
];
