import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RequestPlatform } from './types';
import { AuthKey } from '../../../types/AuthKey.types';
import { RootState } from '../../store';

export const getMethodsCurrent = createAsyncThunk(
  'tables/getMethodsCurrent',
  async ({
    authKey,
    numberPage,
  }: {
    authKey: string,
    numberPage: number,
  }) => {
    const { data } = await axios.get(`/methods?current_page=${numberPage}`, {
      headers: {
        key: authKey,
      },
    });
    return data;
  },
);

export const getCurrencies = createAsyncThunk(
  'tables/getCurrencies',
  async ({
    authKey,
    numberPage,
  }: {
    authKey: string,
    numberPage: number,
  }) => {
    const { data } = await axios.get(`/currencies?current_page=${numberPage}`, {
      headers: {
        key: authKey,
      },
    });
    return data;
  },
);

export const getPlatformsCurrent = createAsyncThunk(
  'tables/getPlatformsCurrent',
  async ({
    authKey,
    numberPage,
  }: {
    authKey: string,
    numberPage: number,
  }) => {
    const { data } = await axios.get(`/platforms?current_page=${numberPage}`, {
      headers: {
        key: authKey,
      },
    });
    return data;
  },
);

export const getCountries = createAsyncThunk(
  'tables/getCountries',
  async ({
    authKey,
    numberPage,
  }: {
    authKey: string,
    numberPage: number,
  }) => {
    const { data } = await axios.get(`/countries?current_page=${numberPage}`, {
      headers: {
        key: authKey,
      },
    });
    return data;
  },
);

export const getMetrics = createAsyncThunk(
  'table/getMetrics',
  async ({
    authKey,
  }: {
    authKey: string,
  }) => {
    const { data } = await axios.get('/get-metrics', {
      headers: {
        key: authKey,
      },
    });
    return data;
  },
);

export const getAllMetrics = createAsyncThunk(
  'table/getAllMetrics',
  async ({
    authKey,
  }: {
    authKey: string,
  }) => {
    const { data } = await axios.get('/get-all-metrics', {
      headers: {
        key: authKey,
      },
    });
    return data;
  },
);

export const postUserErrorMessage = createAsyncThunk(
  'tables/postUserErrorMessage',
  async ({
    authKey,
    dataObj,
  }: {
    authKey: string,
    dataObj: {
      error_message: string,
      id_chains: string | null,
    }
  }) => {
    const { data } = await axios.post('/send_mistake', { ...dataObj }, {
      headers: {
        key: authKey,
      },
    });
    return data;
  },
);

export const getChainFullInfo = createAsyncThunk(
  'tables/getChainFullInfo',
  async ({
    authKey,
    loopId,
  }: {
    authKey: string,
    loopId: string | null,
  }) => {
    const { data } = await axios.get(`/chain-full-info/${loopId}`, {
      headers: {
        key: authKey,
      },
    });
    return data;
  },
);

export const getStoppingProcesses = createAsyncThunk(
  'table/getStoppingProcesses',
  async (
    { authKey }: { authKey: string },
  ) => {
    const { data } = await axios.get('/stop-database', {
      headers: {
        key: authKey,
      },
    });

    return data;
  },
);

export const getPlatformGraph = createAsyncThunk(
  'tables/getPlatformGraph',
  async (
    { authKey }: { authKey: string },
    { getState },
  ) => {
    const { tabels } = getState() as RootState;
    const {
      selectedCurrencyGraph,
      isEqualCurrencies,
      isRelatedPlatforms,
      isCurrencyPairStrict,
    } = tabels;

    const platformGraphFilter = {
      currencies: selectedCurrencyGraph.map(({ id }) => id),
      is_equal_currencies: isEqualCurrencies,
      is_related_platforms: isRelatedPlatforms,
      is_currency_pair_strict: isCurrencyPairStrict,
    };

    const { data } = await axios.post('/platform-graph', { ...platformGraphFilter }, {
      headers: {
        key: authKey,
      },
    });

    return data;
  },
);

export const getDataPlatform = createAsyncThunk(
  'tables/getDataPlatform',
  async ({
    authKey,
    platformId,
  }: RequestPlatform) => {
    const { data } = await axios.get(`/chain-platform-info/${platformId}`, {
      headers: {
        key: authKey,
      },
    });

    return data;
  },
);

// Роут получения избранных цепочек текущего пользователя
export const postFavoriteChains = createAsyncThunk(
  'table/postFavoriteChains',
  async (
    { authKey }: { authKey: string },
  ) => {
    const { data } = await axios.post('/get-favorite-chains', {},{
      headers: {
        key: authKey,
      },
    });

    return data;
  },
);

// Роут на добавление цепочки у текущего пользователя
export const postAddFavoriteChain = createAsyncThunk(
  'table/postAddFavoriteChain',
  async (
    {
      authKey,
      idLoop,
    }: {
      authKey: string,
      idLoop: string
    },
  ) => {
    const requestData = {
      id_loop: idLoop,
    };

    const { data } = await axios.post('/add-chain-to-favorites', { ...requestData }, {
      headers: {
        key: authKey,
      },
    });

    return data;
  },
);

export const getChainsRobots = createAsyncThunk(
  'table/getChainsRobots',
  async (
    {
      authKey,
    } : AuthKey,
  ) => {
    const { data } = await axios.get('/futures-robots', {
      headers: {
        key: authKey,
      },
    });

    return data;
  },
);
