import styled, { css } from 'styled-components';
import { color, space } from 'styled-system';
import { Box, Text } from '../styledComponents';

type BackgroundProps = {
  isActive: boolean;
}

export const Background = styled(Box).attrs({
  bg: 'monochromaticBlue',
})<BackgroundProps>`
  ${({ isActive }) => {
    if (isActive) {
      return css`
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      display: block;
      width: 100vw;
      height: calc(100vh + 18px);
      `;
    }
  }}
`;

type BackgroundActiveProps = {
  isActive: boolean;
}

export const BackgroundActive = styled(Box).attrs({
  bg: 'slateGray',
  color:'snowWhite',
  width: 'fit-content',
  height: 'fit-content',
  maxHeight: '80vh',
  top: '50%',
  left: '50%',
  px: '30px',
  pt: '10px',
  pb: '30px',
})<BackgroundActiveProps>`
  display: flex;
  flex-direction: column;
  z-index: 3;
  position: fixed;
  overflow-y: auto;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  border: 1px solid ${({ theme }) => theme.colors.snowWhite};
  border-radius: 5px;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;

  ${({ isActive }) => {
    if (isActive) {
      return css`
      position: fixed;
      opacity: 1;
      pointer-events: auto;
      `;
    }
  }}
`;

export const CloseButtonModal = styled('img').attrs({
  height: 35,
  width: 35,
  ml: '95%',
  mt: 2,
})`
  cursor: pointer;
  ${space};
`;

export const Title = styled(Text).attrs({
  mb: '30px',
  mt: '0',
  mx: 'auto',
  color:'snowWhite',
  variant: 'medium24',
})`
  ${space};
`;

export const Step = styled(Box).attrs({
  mx:'auto',
  mt: 0,
  mb: '35px',
  width: '90%',
  color:'snowWhite',
  pb: 5,
})`
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};

`;

export const SelectedPlatforms = styled(Text).attrs({
  bg: 'white',
  px:'17px',
  py:'5px',
  color:'oliveGreen',
  variant: 'regular20',
})`
    display: flex;
    align-items: center;
    border-radius: 20px;
    ${space};
`;

export const SelectCurrency = styled(Text).attrs({
  color:'snowWhite',
  variant: 'medium20',
})``;

export const ArrowIcon = styled('img').attrs({
  height: 30,
  width: 75,
  my: 'auto',
  mx: 0,
})`
  ${space};
`;

export const LinesContainer = styled(Box).attrs({
  minWidth: 800,
})`
  display: flex;
  justify-content: space-around;
`;

export const LinesContent = styled(Box).attrs({
  width: '40%',
})`
  display: flex;
  justify-content: space-between;
`;

export const LinesText = styled(Text).attrs({
  width: '45%',
  mx: 0,
  my: '10px',
})``;

export const LinkSource = styled('a').attrs({
  width: '45%',
  mx: 0,
  my: '10px',
  color: 'greenCobalt',
})`
  ${color};

  display: flex;
  align-items: center;
  text-decoration: underline;
`;