import styled from 'styled-components';
import { compose, color, layout, space } from 'styled-system';
import { Box, Text } from '../../../../../../../../components/styledComponents';

export const FilterInput = styled(Box).attrs({
  width: '100%',
  height: '100%',
})`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FilterTitle = styled(Text).attrs({
  variant: 'regular16',
  color: 'snowWhite',
})``;

export const FilterInputInput = styled('input').attrs({
  width: '100%',
  bg: 'grayBlue',
  color: 'snowWhite',
  mt: 3,
  py: 2,
  px: 3,
})`
  ${compose(layout, color, space)};
  box-sizing: border-box;
  border-radius: 4px;
  border: none;

  &:focus {
    outline: ${({ theme }) => theme.colors.lightGray} solid 1px;
  }
`;


