import styled from 'styled-components';
import { Box } from '../../../../../../components/styledComponents';
import { SCREEN } from '../../../../../../constants/constants';

export const Container = styled(Box).attrs({
  ml: 4,
})`
  @media (max-width: ${SCREEN.desktops}) {
    display: grid;
    grid-template:  minmax(min-content, max-content) / repeat(4, 1fr);
    margin-left: 0;
    column-gap: 8px;
    width: 100%;

    button {
      width: 100%;
    }
  }
`;