import styled from 'styled-components';
import { Box, Text } from '../../../../../../../../components/styledComponents';
import { SCREEN } from '../../../../../../../../constants/constants';

export const Container = styled(Box).attrs({
  width: '100%',
  p: 1,
})`
  display: flex;
  align-items: center;
  column-gap: 12px;

  @media (max-width: ${SCREEN.tablets}) {
    justify-content: space-between;
  }
`;

export const LogLabel = styled(Text).attrs({
  color: 'snowWhite',
  variant: 'light16',
})``;

export const LogValueContainer = styled(Box).attrs({
  py: 1,
  px: 2,
  bg: 'grayBlue',
})`
  border-radius: 4px;
`;

export const LogValue = styled(Text).attrs({
  color: 'lightGray',
  variant: 'regular16',
})``;