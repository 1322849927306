import styled from 'styled-components';
import { Box, Text } from '../../../../components/styledComponents';

export const Container = styled(Box).attrs({
  as: 'section',
  width: 'calc(100% - 40px)',
  mx: 5,
})``;

export const ChartTitle = styled(Text).attrs({
  variant: 'bold24',
  color: 'snowWhite',
  as: 'h3',
})`
  text-align: center;
`;

export const ButtonsContainer = styled(Box).attrs({
  my: 12,
  ml: 16,
})`
  box-sizing: border-box;
  display: flex;
`;

export const ButtonContainer = styled(Box).attrs({
  mr: 1,
})``;