export enum DiscoveryMenu {
  Directory = 'directory',
  Editing = 'editing',
  Scripts = 'scripts',
  Currencies = 'currencies',
  ControlDataBase = 'controlDataBase',
  DeleteLoops = 'deleteLoops',
}

export enum ActiveElement {
  Method = 'method',
  Currencies = 'currencies',
  Platforms = 'platforms',
  Countries = 'countries',
  Adding = 'adding',
  CVS = 'csv',
  PlatformGraph = 'platformGraph',
  DeleteChain = 'deleteChain',
  UpdateMetrics = 'updateMetrics',
  StoppingProcesses = 'stoppingProcesses',
  DeleteLoopsByCsv = 'deleteByCsv',
  DeleteLoopsAuto = 'deleteLoopsAuto',
  SearchChains = 'searchCheins',
}
