import styled from 'styled-components';
import { Box } from '../../components/styledComponents';

export const Container = styled(Box).attrs({
  as: 'main',
  width: '98%',
  height: 'calc(100vh - 108px)',
  position: 'relative',
  mx: 'auto',
})`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;