import { createSlice } from '@reduxjs/toolkit';
import {
  setNumberPageCourses,
} from './actions';
import * as asyncActions from './async';
import { CoursesData } from './types';
import { LoadingState } from '../../../types/LoadingState';

export type CoursesStore = {
  loadingState: LoadingState,
  error?: string;

  activePageCourses: number,
  coursesDataRequest?: CoursesData,
}

const initialState: CoursesStore = {
  loadingState: 'success',

  activePageCourses: 1,
};

export const courses = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    setNumberPageCourses,
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getCourses.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActions.getCourses.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.code;
      })
      .addCase(asyncActions.getCourses.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';
        state.coursesDataRequest = payload.data;
      });
  },
});

export const {
  setNumberPageCourses: setNumberPageCoursesAction,
} = courses.actions;

export default courses.reducer;