import styled from 'styled-components';
import { Box } from '../../components/styledComponents';

type ContainerProps = {
  width: number | string,
  height: number | string,
  background: string,
  borderRadius: number,
  marginTop: number,
  element: string,
}

export const Container = styled(Box).attrs<ContainerProps>(({
  width,
  height,
  background,
  marginTop,
  element,
}) => ({
  width,
  height,
  as: element,
  bg: background,
  position: 'relative',
  mt: marginTop,
}))<ContainerProps>`
  border-radius: ${({ borderRadius }) => borderRadius}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;