import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { cardRates , cardRatesPremium } from './constants';
import * as Styled from './TariffCards.styled';
import { TariffCardsProps } from './TariffCards.types';
import arrow from '../../../../../../assets/icons/arrow-home.svg';
import { Button } from '../../../../../../components/Button';
import { ButtonContainer } from '../../../../../../ui/ButtonContainer';


export function TariffCards({
  isPremium,
}:TariffCardsProps) {
  const { t } = useTranslation();
  const {
    colors: {
      snowWhite,
    },
  } = useTheme();
  function onClickSetPathSignUpHandler() {
    //
  }

  return(
    <Styled.RatesContainer>
      <Styled.Card>
        <Styled.CardBox>
          {isPremium && (
            <Styled.CardTitle>
              PREMIUM
            </Styled.CardTitle>
          )}
          {!isPremium && (
            <Styled.CardTitle>
              START
            </Styled.CardTitle>
          )}
        </Styled.CardBox>
        <Styled.CardList>
          <Styled.CardItem>{cardRates.exchangeRates}</Styled.CardItem>
          <Styled.CardItem>{cardRates.bundles}</Styled.CardItem>
          <Styled.CardItem>{cardRates.interExchangeBundles}</Styled.CardItem>
          <Styled.CardItem>{cardRates.internationalConnections}</Styled.CardItem>
          <Styled.CardItem>{cardRates.filters}</Styled.CardItem>
          {!isPremium && (
            <>
              <Styled.CardItemGrayOpacity>{cardRates.helps}</Styled.CardItemGrayOpacity>
              <Styled.CardItemGray>{cardRates.contentInfo}</Styled.CardItemGray>
            </>
          )}
          {isPremium && (
            <Styled.CardItemBlackPremium>{cardRates.helps}</Styled.CardItemBlackPremium>
          )}
          {isPremium && (
            <>
              <Styled.CardBoxContentPremium>
                <Styled.CardContentPremium>
                  {cardRatesPremium.price}
                </Styled.CardContentPremium>
              </Styled.CardBoxContentPremium>
              <Styled.CardBoxContentButton>
                <ButtonContainer marginTop={5}>
                  <Button
                    width={320}
                    height={50}
                    onClick={onClickSetPathSignUpHandler}
                    title={t('_advantages.advantages.button2')}
                    borderRadius={6}
                    size='medium16'
                    image={arrow}
                    imageWidth={16}
                    imageHeight={8}
                    fill={snowWhite}
                    fillHover={snowWhite}
                    fillActive={snowWhite}
                    indent={10}
                  />
                </ButtonContainer>
              </Styled.CardBoxContentButton>
            </>
          )}
        </Styled.CardList>

        {!isPremium && (
          <Styled.CardBoxContent>
            <Styled.CardContent>
              {cardRates.content}
            </Styled.CardContent>
          </Styled.CardBoxContent>
        )}

      </Styled.Card>
    </Styled.RatesContainer>
  );
}