import styled from 'styled-components';
import { Box, Text } from '../../../../../styledComponents';

export const Container = styled(Box).attrs({
  py: 1,
  px: 2,
})`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HintText = styled(Text).attrs({
  color: 'snowWhite',
  variant: 'regular14',
})``;

export const HintTitle = styled(Text).attrs({
  color: 'snowWhite',
  variant: 'light14',
})``;

export const RowContainer = styled(Box).attrs({
  width: '100%',
})`
  display: grid;
  grid-template: 30px / 1fr minmax(min-content, max-content);
  align-items: center;
  column-gap: 8px;
`;