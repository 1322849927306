import * as Styled from './CellWithArrow.styled';
import { CellWithArrowProps } from './CellWithArrow.types';
import arrow from '../../../../../../assets/icons/arrowNew.svg';
import { ImageContainer } from '../../../../../ImageContainer';
import { CellContainer } from '../../ui/CellContainer';

export function CellWithArrow({
  text,
  size,
  color,
  background,
  paddingVertical,
  isBorderBottom,
  colorBorder,
  onClick,
}: CellWithArrowProps) {
  return (
    <CellContainer
      isRow
      background={background}
      paddingVertical={paddingVertical}
      isBorderBottom={isBorderBottom}
      colorBorder={colorBorder}
      onClick={onClick}
    >
      <Styled.CellText size={size} color={color}>
        {text}
      </Styled.CellText>
      <ImageContainer
        width="100%"
        height={10}
        image={arrow}
        fill={color}
      />
    </CellContainer>
  );
}