import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { TariffCards } from './components/TariffCards';
import { textRatesVariables } from './constants';
import * as Styled from './Rates.styled';
import arrow from '../../../../assets/icons/arrow-home.svg';
import { Button } from '../../../../components/Button';
import { usePathLogged } from '../../../../hooks/usePathLogged';
import { ButtonContainer } from '../../../../ui/ButtonContainer';
import { Container } from '../../../../ui/Container';

export function Rates() {
  const { t } = useTranslation();

  const {
    colors: {
      snowWhite,
    },
  } = useTheme();
  const { setPathAuntification } = usePathLogged();

  function onClickSetPathSignUpHandler() {
    setPathAuntification('signUp');
  }

  return(
    <Styled.Rates>
      <Container>
        <Styled.RatesContainer>
          <Styled.RatesRow>
            <Styled.RatesTitle>
              {textRatesVariables.title}
            </Styled.RatesTitle>
            <Styled.RatesText>
              {textRatesVariables.wideSelectionOfCurrencies}
              <Styled.RatesTextColor>
                {textRatesVariables.realTimeOperation}
              </Styled.RatesTextColor>
            </Styled.RatesText>

            <Styled.RatesTextContent>
              {textRatesVariables.regularlyUpdatedDatabases}
            </Styled.RatesTextContent>

            <Styled.RatesTextContent>
              <Styled.RatesTextContentColor>
                {textRatesVariables.tag}
              </Styled.RatesTextContentColor>
              {textRatesVariables.contentInfo}
            </Styled.RatesTextContent>

            <Styled.RatesTextContent>
              {textRatesVariables.content}
            </Styled.RatesTextContent>

            <ButtonContainer marginTop={13}>
              <Button
                width={300}
                height={50}
                onClick={onClickSetPathSignUpHandler}
                title={t('_advantages.advantages.button')}
                borderRadius={6}
                size='medium16'
                image={arrow}
                imageWidth={16}
                imageHeight={8}
                fill={snowWhite}
                fillHover={snowWhite}
                fillActive={snowWhite}
                indent={5}
              />
            </ButtonContainer>
          </Styled.RatesRow>

          <Styled.RatesRowCard>
            <TariffCards
              isPremium={false}
            />
            <TariffCards
              isPremium={true}
            />
          </Styled.RatesRowCard>
        </Styled.RatesContainer>
      </Container>
    </Styled.Rates>
  );
}