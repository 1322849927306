import styled from 'styled-components';
import { Box, Text } from '../../../../../../../../components/styledComponents';

export const ErrorContainer = styled(Box).attrs({
  width: '100%',
  height: '100%',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

export const ErrorMessage = styled(Text).attrs({
  color: 'snowWhite',
  variant: 'light16',
})`
  text-align: center;
`;