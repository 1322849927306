import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getDealRates = createAsyncThunk(
  'deal/getDealRates',
  async ({ authKey, dealNumber }: { authKey: string, dealNumber: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        '/get-deal-rates',
        {
          headers: {
            key: authKey,
          },
          params: {
            dealNumber,
          },
        },
      );
      // eslint-disable-next-line no-console
      console.log(data); 
      if (!data.ok) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data?.message || 'Произошла ошибка');
      } else {
        return rejectWithValue('Произошла ошибка');
      }
    }
  },
);
