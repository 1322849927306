import styled from 'styled-components';
import { Box, Text } from '../../../../../../components/styledComponents';

export const SlideTitle = styled(Text).attrs({
  variant: 'medium18',
  color: 'blackBlue',
  mt: 10,
})`
  font-weight: bold;
  transition: .25s all;
`;

export const SlideText = styled(Text).attrs({
  variant: 'medium14',
  color: 'grey3',
  mt: 5,
})`
  line-height: 20px;
  transition: .25s all;
`;

export const Container = styled(Box).attrs({
  width: 297,
  height: 266,
  p: 5,
  bg: 'white',
})`
  box-sizing: border-box;
  border-radius: 15px;
  transition: .25s all;

  &:hover {
    transition: .25s all;
    background-color: ${({ theme }) => theme.colors.greenCobalt};

    ${SlideTitle},
    ${SlideText} {
      transition: .25s all;
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;