import styled from 'styled-components';
import { Box } from '../../../../components/styledComponents';
import { SCREEN } from '../../../../constants/constants';

export const Container = styled(Box).attrs({
  width: '100%',
  height: '100%',
  p: 3,
})`
  box-sizing: border-box;
  overflow-y: auto;
`;

export const LogsAndAlgorithms = styled(Box)`
  display: flex;

  @media (max-width: ${SCREEN.desktops}) {
    flex-direction: column;
  }
`;

export const InputsGrid = styled(Box).attrs({
  width: '100%',
  mt: 1,
})`
  display: flex;
  flex-wrap: wrap;
  column-gap: 32px;
`;

export const InputContainer = styled(Box).attrs({
  width: 'calc(50% - 16px)',
  minWidth: 310,
})`
  @media (max-width: ${SCREEN.desktops}) {
    width: 100%;
  }
`;

export const SelectContainer = styled(Box).attrs({
  mt: 4,
})`
  display: grid;
  grid-template: repeat(2, minmax(min-content, max-content)) / repeat(2, 1fr);
  column-gap: 32px;
  row-gap: 20px;

  @media (max-width: ${SCREEN.desktops}) {
    grid-template: repeat(4, minmax(min-content, max-content)) / 1fr;
  }
`;