import styled from 'styled-components';
import { Box, Text } from '../../../../components/styledComponents';

export const Footer = styled(Box).attrs({
  minHeight: 72,
  height: 72,
  as: 'footer',
})`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
`;

export const Container = styled(Box).attrs({
  width: '100%',
})`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const NavigationList = styled(Text).attrs({
  as: 'a',
  p: 0,
  variant: 'medium16',
  color: 'lightGray',
  mr: 'calc(60px/2)',
  ml: 'calc(60px/2)',
})`
  border-bottom: 1px solid white;
  cursor: pointer;
`;