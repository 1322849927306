import styled, { css, keyframes } from 'styled-components';
import { Box } from '../styledComponents';

type ResultInfoModalProps = {
  isActive: boolean;
}

export const ResultInfoModal = styled(Box).attrs({
  width: 400,
  height: 100,
  bg: 'graniteGray',
  position: 'fixed',
  top: 'auto',
  left: '50%',
  right: 'auto',
  bottom: '0',
  zIndex: 1000,
})<ResultInfoModalProps>`
  box-sizing: border-box;
  visibility: hidden;
  opacity: 0;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  ${({ isActive }) => {
    if (isActive) {
      return css`
        visibility: visible;
        opacity: 1;
        left: 50%;
        top: 50%;
      `;
    }
  }}`;

export const ResultExecutionAlgorithm = styled(Box).attrs({
  position: 'absolute',
  top: '5px',
  right: '5px',
  width: 18,
  height: 18,
})`
  cursor: pointer;
`;

export const ResultInfoModalText = styled(Box).attrs({
  padding: '5px',
  color: 'snowWhite',
  variant: 'regular14',
})`
  box-sizing: border-box;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: calc(100% - 8px);
`;

export const ResultInfoModalBar = styled(Box).attrs({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  height: 10,
  bg: 'graniteGray',
})`
  border-radius: 0 0 5px 5px;
`;

const progress = keyframes`
  0% {
    width: 100%;
  }
  5% {
    border-radius: 0 0 0 5px;
  }
  100% {
    width: 0;
    border-radius: 0 0 0 5px;
  }
`;

export const ResultInfoModalBarActive = styled(Box).attrs({
  bottom: 0,
  width: '100%',
  height: 10,
  bg: 'yellowPink',
})`
  border-radius: 0 0 5px 5px;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-name: ${progress};
`;