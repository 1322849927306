import React, {
  useEffect,
  useCallback,
} from 'react';


import { DataModalDeleteChains } from './components/DataModalDeleteChains';
import styles from './DeleteChainsModal.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/storeHooks';
import { deleteChainsDeleteCount } from '../../../../../../redux/reducers/algorithms/async';
import { setOpenModalDeleteChainsAct } from '../../../../../../redux/reducers/navigation';

export function DeleteChainsModal() {
  const dispatch = useAppDispatch();
  const {
    responseDeleteChains,
    isResponseOK,
  } = useAppSelector((state) => state.algorithms);

  const {
    openModalDeleteChains,
  } = useAppSelector((state) => state.navigation);

  const {
    authKey,
  } = useAppSelector((state) => state.auth);

  const blockedScroll = () => {
    const getHTML = document.querySelector('html');

    if (openModalDeleteChains && getHTML !== null) {
      getHTML.style.overflowY = 'hidden';
    } else if (getHTML !== null) {
      getHTML.style.overflowY = '';
    }
  };

  function onDeleteChainsOkHandler() {
    dispatch(deleteChainsDeleteCount({ authKey: authKey || '' }));
    dispatch(setOpenModalDeleteChainsAct());
  }

  function onExitModalHandler() {
    dispatch(setOpenModalDeleteChainsAct());
  }

  const notErrorReqDeleteChains = useCallback(() => (
    `${responseDeleteChains?.loops_count}`
  ), [isResponseOK]);

  useEffect(() => {
    blockedScroll();
  }, [openModalDeleteChains]);

  return (
    <>
      <div
        className={openModalDeleteChains
          ? styles.course_modal__substrate
          : styles.course_modal}
        onClick={onExitModalHandler}
      />
      <div className={openModalDeleteChains
        ? styles.course_modal && styles.course_modal_active_modal_hidden 
        : styles.course_modal}
      >
        <h3 className={styles.course_modal_title}>
          Удаление цепочек
        </h3>
        <DataModalDeleteChains
          notErrorReqDeleteChains={notErrorReqDeleteChains}
        />
        <div className={styles.course_modal_container}>
          {isResponseOK
            ? (
              <>
                <button
                  type="submit"
                  className={styles.scripts_btnStart}
                  onClick={onDeleteChainsOkHandler}
                >
                  Да
                </button>
                <button
                  type="submit"
                  className={styles.scripts_btnStart}
                  onClick={onExitModalHandler}
                >
                  Нет
                </button>
              </>
            )
            : (
              <button
                type="submit"
                className={styles.scripts_btnStart}
                onClick={onExitModalHandler}
              >
                Окей
              </button>
            )}
        </div>
      </div>
    </>
  );
}
