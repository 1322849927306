import React, { useEffect, useState, ChangeEvent } from 'react';

import Slider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import { DeleteChainsModal } from './components/DeleleChainsModal';

import {
  marksRule,
  marks,
  countRule,
} from './constants';
import styles from './DeleteChains.module.scss';
import { Button } from '../../../../components/Button';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';

import {
  onChangeStrictModeDeleteAct,
  onSelectedCoursesDeleteAct,
  onSelectedCoursesDeleteScriptAct,
  onSelectedPlatformsChainsDeleteAct,
  onChangeRegulationAct,
  onChangeTimeRegulationAct,
  onChangeLoopSpeedAct,
  onChangeRuleAct,
  onChangeRuleWeekAct,
  onChangeRuleMonthAct,
  onChangeTimeProfitAct,
  onChangeTimeProfitPercentAct,
  onChangeTimeMonthProfitPercentAct,
  onCurrencyIdScriptsAct,
  onSelectedPlatformsDeleteAct,
} from '../../../../redux/reducers/algorithms';

import { getChainsDeleteCount } from '../../../../redux/reducers/algorithms/async';
import { setOpenModalDeleteChainsAct } from '../../../../redux/reducers/navigation';
import { FilterSwitch } from '../../../Chains/components/TableFeatures/components/AllFilterChains/components/FilterSwitch';

export function DeleteChains() {
  const dispatch = useAppDispatch();

  const {
    strictModeDelete,
    selectedCoursesDelete,
    valueRangeRegulation,
    valueRangeTime,
    valueRangeLoopSpeed,
    valueRangeRule,
    valueRangeRuleWeek,
    valueRangeRuleMonth,
    valueRangeTimeProfit,
    valueRangeTimeProfitPercent,
    valueRangeTimeMonthProfitPercent,
    selectedPlarformsDeleteChain,
    currencyIdSctiprs,
  } = useAppSelector((state) => state.algorithms);

  const {
    allCurrencies,
    allPlatforms,
  } = useAppSelector((state) => state.tabels);

  const {
    authKey,
  } = useAppSelector((state) => state.auth);

  const [isActiveAllCourses, setIsActiveAllCourses] = useState<boolean>(false);
  const [isActiveAllPlatforms, setIsActiveAllPlatforms] = useState<boolean>(false);

  const valuetext = (value: number) => `${value}m`;

  const onActive = () => {
    allCurrencies.forEach((item) => {
      dispatch(onSelectedCoursesDeleteScriptAct({
        id: item.id,
        active: isActiveAllCourses,
      }));
    });
  };

  const onSelectedAllPlatforms = () => {
    allPlatforms.forEach((item) => {
      dispatch(onSelectedPlatformsDeleteAct({
        id: item.id,
        active: isActiveAllPlatforms,
      }));
    });
  };

  const handleChangeRegulation = (event: Event, newValue: number | number[]) => {
    dispatch(onChangeRegulationAct(newValue as number[]));
  };

  const handleChangeTime = (event: Event, newValue: number | number[]) => {
    dispatch(onChangeTimeRegulationAct(newValue as number[]));
  };

  const handleChangeLoopSpeed = (event: Event, newValue: number | number[]) => {
    dispatch(onChangeLoopSpeedAct(newValue as number[]));
  };

  const handleChangeRuleDay = (event: Event, newValue: number | number[]) => {
    dispatch(onChangeRuleAct(newValue as number[]));
  };

  const handleChangeRuleWeek = (event: Event, newValue: number | number[]) => {
    dispatch(onChangeRuleWeekAct(newValue as number[]));
  };

  const handleChangeRuleMonth = (event: Event, newValue: number | number[]) => {
    dispatch(onChangeRuleMonthAct(newValue as number[]));
  };

  const handleChangeTimeProfit = (event: Event, newValue: number | number[]) => {
    dispatch(onChangeTimeProfitAct(newValue as number[]));
  };

  const handleChangeTimeProfitPrecent = (event: Event, newValue: number | number[]) => {
    dispatch(onChangeTimeProfitPercentAct(newValue as number[]));
  };

  const handleChangeTimeMonthProfitPrecent = (event: Event, newValue: number | number[]) => {
    dispatch(onChangeTimeMonthProfitPercentAct(newValue as number[]));
  };

  function onChangeListIdHandler(e: ChangeEvent<HTMLInputElement>) {
    const listId = e.target.value;
    const regExpNumberAndSpace = /^[\d\s]+$/;
    if (regExpNumberAndSpace.test(listId) || listId === '') {
      dispatch(onCurrencyIdScriptsAct(listId));
    }
  }

  function onOpenModalAndReqCountChains() {
    dispatch(setOpenModalDeleteChainsAct());
    dispatch(getChainsDeleteCount({ authKey: authKey || '' }));
  }

  function onSelectedCoursesDelete(coursesId: number) {
    dispatch(onSelectedCoursesDeleteAct(coursesId));
  }

  function onSelectedPlatformsChainsDelete(platformsId: number) {
    dispatch(onSelectedPlatformsChainsDeleteAct(platformsId));
  }

  useEffect(() => {
    onActive();
  }, [isActiveAllCourses]);

  useEffect(() => {
    onSelectedAllPlatforms();
  }, [isActiveAllPlatforms]);

  return (
    <section className={styles.root}>
      <div className={styles.modal_container}>
        <Tooltip title="Если более 1 цепочки - вводить ID через пробел">
          <div className={styles.admin_start_transaction}>
            <div className={styles.admin_start_text}>
            ID цепочек
            </div>
            <input
              type="text"
              className={styles.modal_id_input}
              placeholder="1"
              value={currencyIdSctiprs}
              onChange={onChangeListIdHandler}
            />
          </div>
        </Tooltip>

        <div className={styles.modal_container_components}>
          <FilterSwitch
            hint="Нестрогий режим - достаточно одной валюты/платформы из выбранного набора на всю цепочку, чтобы она была учтена фильтром.
            Строгий режим -- каждая валюта/платформа цепочки должна присутствовать в выбранном наборе"
            textSwitch="Строгий режим"
            isChecked={strictModeDelete}
            setStateAction={onChangeStrictModeDeleteAct}
          />
        </div>
      </div>

      <div className={styles.modal_container}>
        <div className={styles.modal_courses}>
          <div className={styles.modal_id_text}>
            Валюты
          </div>
          <div className={styles.modal_courses_wrapper}>
            {allCurrencies.map((item) => (
              <div
                key={item.id}
                className={`
                ${styles.modal_courses_item}  
                ${selectedCoursesDelete.includes(item.id) ? styles.modal_courses_item_active : ''}
                `}
                onClick={() => {
                  onSelectedCoursesDelete(item.id);
                }}
              >
                {item.name}
              </div>
            ))}
          </div>

          <div className={styles.modal_courses_all}>
            <div
              className={styles.modal_courses_all_selected}
              onClick={() => {
                setIsActiveAllCourses(!isActiveAllCourses);
              }}
            >
              Выбрать все
            </div>
          </div>
        </div>
      </div>

      <div className={styles.modal_container}>
        <div className={styles.modal_platforms}>
          <div className={styles.modal_id_text}>Платформы</div>
          <div className={`
          ${styles.modal_platforms_wrapper} 
          ${allPlatforms ? styles.modal_platforms_wrapper_active : ''}
          `}
          >
            {allPlatforms.map((item) => (
              <div
                key={item.id}
                className={`
                ${styles.modal_platforms_item}  
                ${selectedPlarformsDeleteChain.includes(item.id) ? styles.modal_platforms_item_active : ''}
                `}
                onClick={() => {
                  onSelectedPlatformsChainsDelete(item.id);
                }}
              >
                {item.name}
              </div>
            ))}
          </div>

          <div className={styles.modal_platforms_all}>
            <div
              className={styles.modal_platforms_all_selected}
              onClick={() => {
                setIsActiveAllPlatforms(!isActiveAllPlatforms);
              }}
            >
              Выбрать все
            </div>
          </div>
        </div>
      </div>

      <Tooltip title="Длина цепочки">
        <div className={styles.modal_range}>
          <div className={styles.range_container}>
            <div className={styles.modal_id_text}>
            Количество правил
            </div>
            <div className={styles.modal_range_wrapper700}>
              <Slider
                className={styles.modal_range_range}
                getAriaLabel={() => 'Temperature range'}
                value={valueRangeRegulation}
                onChange={handleChangeRegulation}
                size="small"
                min={countRule.minCountRule}
                step={countRule.stepRule}
                max={countRule.maxCountRule}
                marks={marksRule}
                valueLabelDisplay="off"
                getAriaValueText={valuetext}
                aria-labelledby="non-linear-slider"
              />
            </div>
          </div>
        </div>
      </Tooltip>

      <div className={styles.modal_range}>
        <div className={styles.range_container}>
          <div className={styles.modal_id_text}>Время выполнения</div>
          <div className={styles.modal_range_wrapper700}>
            <Slider
              className={styles.modal_range_range}
              getAriaLabel={() => 'Temperature range'}
              value={valueRangeTime}
              onChange={handleChangeTime}
              size="small"
              min={countRule.minTimeRange}
              step={countRule.stepRule}
              max={countRule.maxTimeRange}
              marks={marks}
              valueLabelDisplay="off"
              getAriaValueText={valuetext}
              aria-labelledby="non-linear-slider"
            />
          </div>
        </div>
      </div>

      <div className={styles.modal_range}>
        <div className={styles.range_container}>
          <div className={styles.modal_id_text}>Время существования цепочки</div>
          <div className={styles.modal_range_wrapper700}>
            <Slider
              className={styles.modal_range_range}
              getAriaLabel={() => 'Temperature range'}
              value={valueRangeLoopSpeed}
              onChange={handleChangeLoopSpeed}
              size="small"
              min={countRule.minTimeRange}
              step={countRule.stepRule}
              max={countRule.maxTimeRange}
              marks={marks}
              valueLabelDisplay="off"
              getAriaValueText={valuetext}
              aria-labelledby="non-linear-slider"
            />
          </div>
        </div>
      </div>
    
      
      <div className={styles.modal_container3}>
        <Tooltip title="Средний доход по цепочке за последний день в процентах">
          <div className={styles.modal_profit}>
            <div className={styles.modal_wrapper_ranges_text}>
            Средний доход за весь период (День)
            </div>
            <div className={styles.modal_profit_wrapper}>
              <Slider
                className={styles.modal_profit_range}
                getAriaLabel={() => 'Temperature range'}
                value={valueRangeRule}
                onChange={handleChangeRuleDay}
                size="small"
                min={countRule.minAvgProfitDay}
                max={countRule.maxAvgProfitDay}
                step={countRule.stepAvgDay}
                valueLabelDisplay="off"
                getAriaValueText={valuetext}
              />
            </div>
            <div className={styles.modal_profit_value}>
              <div className={styles.modal_profit_value_item}>
                <div className={styles.modal_profit_value_text}>от</div>
                <div className={styles.modal_profit_value_input}>
                  {valueRangeRule[0] === -30
                    ? `${valueRangeRule[0]}% и более`
                    : `${valueRangeRule[0]}%`}
                </div>
              </div>
              <div className={styles.modal_profit_value_item}>
                <div className={styles.modal_profit_value_text}>до</div>
                <div className={styles.modal_profit_value_input}>
                  {valueRangeRule[1] === 30
                    ? `+${valueRangeRule[1]}% и более`
                    : `${valueRangeRule[1]}%`}
                </div>
              </div>
            </div>
          </div>
        </Tooltip>
      
        <Tooltip title="Средний доход по цепочке за последнюю неделю в процентах">
          <div className={styles.modal_profit}>
            <div className={styles.modal_wrapper_ranges_text}>
            Средний доход за весь период (Неделя)
            </div>
            <div className={styles.modal_profit_wrapper}>
              <Slider
                className={styles.modal_profit_range}
                getAriaLabel={() => 'Temperature range'}
                value={valueRangeRuleWeek}
                onChange={handleChangeRuleWeek}
                size="small"
                min={countRule.minAvgProfitWeek}
                max={countRule.maxAvgProfitWeek}
                step={countRule.stepAvgWeek}
                valueLabelDisplay="off"
                getAriaValueText={valuetext}
              />
            </div>
            <div className={styles.modal_profit_value}>
              <div className={styles.modal_profit_value_item}>
                <div className={styles.modal_profit_value_text}>от</div>
                <div className={styles.modal_profit_value_input}>
                  {valueRangeRuleWeek[0] === -30
                    ? `${valueRangeRuleWeek[0]}% и более`
                    : `${valueRangeRuleWeek[0]}%`}
                </div>
              </div>
              <div className={styles.modal_profit_value_item}>
                <div className={styles.modal_profit_value_text}>до</div>
                <div className={styles.modal_profit_value_input}>
                  {valueRangeRuleWeek[1] === 30
                    ? `+${valueRangeRuleWeek[1]}% и более`
                    : `${valueRangeRuleWeek[1]}%`}
                </div>
              </div>
            </div>
          </div>
        </Tooltip>
      </div>

      <div className={styles.modal_container}>
        <Tooltip title="Средняя длительность прибыльности цепочки за последний день в минутах">
          <div className={styles.modal_profit}>
            <div className={styles.modal_wrapper_ranges_text}>
            Время прибыльности (День)
            </div>
            <div className={styles.modal_profit_wrapper}>
              <Slider
                className={styles.modal_profit_range}
                getAriaLabel={() => 'Temperature range'}
                value={valueRangeTimeProfit}
                onChange={handleChangeTimeProfit}
                size="small"
                min={countRule.minTimeProfit}
                max={countRule.maxTimeProfit}
                valueLabelDisplay="off"
                getAriaValueText={valuetext}
              />
            </div>

            <div className={styles.modal_profit_value}>
              <div className={styles.modal_profit_value_item}>
                <div className={styles.modal_profit_value_text}>от</div>
                <div className={styles.modal_profit_value_input}>
                  {valueRangeTimeProfit[0]}
                м
                </div>
              </div>
              <div className={styles.modal_profit_value_item}>
                <div className={styles.modal_profit_value_text}>до</div>
                <div className={styles.modal_profit_value_input}>
                  {valueRangeTimeProfit[1] === 120
                    ? `>${valueRangeTimeProfit[1]}м`
                    : `${valueRangeTimeProfit[1]}м`}
                </div>
              </div>
            </div>
          </div>
        </Tooltip>
      
        <Tooltip title="Средний доход по цепочке за последний месяц в процентах">
          <div className={styles.modal_profit}>
            <div className={styles.modal_wrapper_ranges_text}>
            Средний доход за весь период (Месяц)
            </div>
            <div className={styles.modal_profit_wrapper}>
              <Slider
                className={styles.modal_profit_range}
                getAriaLabel={() => 'Temperature range'}
                value={valueRangeRuleMonth}
                onChange={handleChangeRuleMonth}
                size="small"
                min={countRule.minAvgProfitMonth}
                max={countRule.maxAvgProfitMonth}
                step={countRule.stepAvgMonth}
                valueLabelDisplay="off"
                getAriaValueText={valuetext}
              />
            </div>

            <div className={styles.modal_profit_value}>
              <div className={styles.modal_profit_value_item}>
                <div className={styles.modal_profit_value_text}>от</div>
                <div className={styles.modal_profit_value_input}>
                  {valueRangeRuleMonth[0] === -30
                    ? `${valueRangeRuleMonth[0]}% и более`
                    : `${valueRangeRuleMonth[0]}%`}
                </div>
              </div>
              <div className={styles.modal_profit_value_item}>
                <div className={styles.modal_profit_value_text}>до</div>
                <div className={styles.modal_profit_value_input}>
                  {valueRangeRuleMonth[1] === 30
                    ? `+${valueRangeRuleMonth[1]}% и более`
                    : `${valueRangeRuleMonth[1]}%`}
                </div>
              </div>
            </div>
          </div>
        </Tooltip>
      </div>

      <div className={styles.modal_container}>
        <Tooltip title="Процентная доля времени от последней недели, в течение которой цепочка была прибыльной">
          <div className={styles.modal_profit}>
            <div className={styles.modal_wrapper_ranges_text}>
            % времени прибыльности (Неделя)
            </div>
            <div className={styles.modal_profit_wrapper}>
              <Slider
                className={styles.modal_profit_range}
                getAriaLabel={() => 'Temperature range'}
                value={valueRangeTimeProfitPercent}
                onChange={handleChangeTimeProfitPrecent}
                size="small"
                min={countRule.minPercentProfit}
                max={countRule.maxPercentProfit}
                valueLabelDisplay="off"
                getAriaValueText={valuetext}
              />
            </div>

            <div className={styles.modal_profit_value}>
              <div className={styles.modal_profit_value_item}>
                <div className={styles.modal_profit_value_text}>от</div>
                <div className={styles.modal_profit_value_input}>
                  {valueRangeTimeProfitPercent[0]}
                %
                </div>
              </div>
              <div className={styles.modal_profit_value_item}>
                <div className={styles.modal_profit_value_text}>до</div>
                <div className={styles.modal_profit_value_input}>
                  {valueRangeTimeProfitPercent[1]}
                %
                </div>
              </div>
            </div>
          </div>
        </Tooltip>
      
        <Tooltip title="Процентная доля времени от последнего месяца, в течение которой цепочка была прибыльной">
          <div className={styles.modal_profit}>
            <div className={styles.modal_wrapper_ranges_text}>
            % времени прибыльности (Месяц)
            </div>

            <div className={styles.modal_profit_wrapper}>
              <Slider
                className={styles.modal_profit_range}
                getAriaLabel={() => 'Temperature range'}
                value={valueRangeTimeMonthProfitPercent}
                onChange={handleChangeTimeMonthProfitPrecent}
                size="small"
                min={countRule.minPercentProfit}
                max={countRule.maxPercentProfit}
                valueLabelDisplay="off"
                getAriaValueText={valuetext}
              />
            </div>

            <div className={styles.modal_profit_value}>
              <div className={styles.modal_profit_value_item}>
                <div className={styles.modal_profit_value_text}>от</div>
                <div className={styles.modal_profit_value_input}>
                  {valueRangeTimeMonthProfitPercent[0]}
                %
                </div>
              </div>
              <div className={styles.modal_profit_value_item}>
                <div className={styles.modal_profit_value_text}>до</div>
                <div className={styles.modal_profit_value_input}>
                  {valueRangeTimeMonthProfitPercent[1]}
                %
                </div>
              </div>
            </div>
          </div>
        </Tooltip>
      </div>
      <div className={styles.modal_container2}>
        <Button
          width={350}
          height={70}
          onClick={onOpenModalAndReqCountChains}
          title="Запустить алгоритм"
          size="regular16"
        />
        <DeleteChainsModal/>
      </div>
    </section>
  );
}
