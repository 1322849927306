import styled from 'styled-components';
import { Box } from '../../../../../../components/styledComponents';

export const Container = styled(Box).attrs({
  width: '100%',
  height: '100%',
})`
  display: flex;
  justify-content: center;
  align-items: center;
`;