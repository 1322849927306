import styled from 'styled-components';
import { Box, Text } from '../../../../../../components/styledComponents';

export const ChainsButtonsList = styled(Box).attrs({
  display: 'flex',
})`
  align-items: center;

  @media (max-width: 1130px) {
    order: 1;
    margin-top: 20px;
  }

  @media (max-width: 430px) {
    display: grid;
    grid-template: repeat(2, 30px) / 3fr 5fr;
    justify-content: center;
    row-gap: 5px;
  }
`;

export const ChainsClearButton = styled(Box).attrs({
  ml: 2,
})``;

export const ChainsTotalText = styled(Text).attrs({
  ml: 3,
  color: 'snowWhite',
})`
  user-select: none;

  @media (max-width: 430px) {
    grid-column: 1 / 3;
    text-align: center;
  }
`;