import { useEffect, useState } from 'react';
import { VolumeProps } from '../../../../components/Table/components/TableBodyCell/components/CellVolumeParams/CellVolumeParams.types';
import { INPUT_MASKS } from '../../../../constants/constants';
import { useInput } from '../../../../hooks/useInput';
import { useModal } from '../../../../hooks/useModal';
import { INITIAL_VALUE } from '../../constants';

// Модуль, который отвечат за ячейку объема в таблице
export function useVolume() {
  // Помещаем данные ячейки, на которыю кликнул пользователь
  const [dataCellVolume, setDataCellVolume] = useState<VolumeProps>(INITIAL_VALUE.dataCellVolume);

  const modalVolume = useModal();

  // Контроллеры полей ввода
  const controllerFailMinQtyCase = useInput({ initialValue: dataCellVolume.failMinQtyCase, mask: INPUT_MASKS.number });
  const controllerFixVolume = useInput({ initialValue: dataCellVolume.fixVolume, mask: INPUT_MASKS.number });
  const controllerMaxVolume = useInput({ initialValue: dataCellVolume.maxVolume, mask: INPUT_MASKS.number });

  function onClickSetDataVolumeParamsHandler() {
    // Бэк пока не предоставил роут
  }

  // Метод закритыя модалки и сброс не сохраненных значений
  function onClickResetDataVolumeHandler() {
    modalVolume.onClick();

    controllerFailMinQtyCase.setValueInField(dataCellVolume.failMinQtyCase);
    controllerFixVolume.setValueInField(dataCellVolume.fixVolume);
    controllerMaxVolume.setValueInField(dataCellVolume.maxVolume);
  }

  // Обновляем данные в полях ввода
  useEffect(() => {
    controllerFailMinQtyCase.setValueInField(dataCellVolume.failMinQtyCase);
    controllerFixVolume.setValueInField(dataCellVolume.fixVolume);
    controllerMaxVolume.setValueInField(dataCellVolume.maxVolume);
  }, [dataCellVolume]);

  return {
    modalVolume,
    setDataCellVolume,
    onClickResetDataVolumeHandler,
    controllerFailMinQtyCase,
    controllerFixVolume,
    controllerMaxVolume,
    onClickSetDataVolumeParamsHandler,
  };
}