import styled from 'styled-components';
import { Box } from '../../../../components/styledComponents';

export const Container = styled(Box).attrs({
  as: 'section',
  width: '100%',
  height: 'calc(100% - 78px)',
  mt: 5,
})`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PaginateWrapper = styled(Box).attrs({
  width: '100%',
  minHeight: 57,
})`
  display: flex;
  justify-content: center;
  align-items: center;
`;