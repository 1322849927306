import React, { useEffect, useRef } from 'react';
import * as Styled from './PopupWindow.styled';
import { PopupWindowProp } from './PopupWindow.types';
import close from '../../assets/icons/сross.svg';
import { ImageContainer } from '../ImageContainer';

export function PopupWindow({
  message,
  isResultInfoModal,
  setIsResultInfoModal,
}: PopupWindowProp) {
  const timeoutModalRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  function startTimer() {
    if (timeoutModalRef.current) {
      return;
    }
    timeoutModalRef.current = setTimeout(() => {
      setIsResultInfoModal(false);
    }, 9960);
  }

  function stopTimer() {
    if (timeoutModalRef.current) {
      clearTimeout(timeoutModalRef.current);
      timeoutModalRef.current = null;
    }
  }

  function onClickCloseModalHandler() {
    setIsResultInfoModal(false);
    stopTimer();
  }

  useEffect(() => {
    if (isResultInfoModal) {
      startTimer();
    }
  }, [isResultInfoModal]);

  return (
    <Styled.ResultInfoModal
      isActive={isResultInfoModal}
    >
      <Styled.ResultExecutionAlgorithm
        onClick={onClickCloseModalHandler}
      >
        <ImageContainer
          width={15}
          height={15}
          image={close}
        />
      </Styled.ResultExecutionAlgorithm>
      <Styled.ResultInfoModalText>{message}</Styled.ResultInfoModalText>
      <Styled.ResultInfoModalBar>
        <Styled.ResultInfoModalBarActive/>
      </Styled.ResultInfoModalBar>
    </Styled.ResultInfoModal>
  );
}
