import { createSlice } from '@reduxjs/toolkit';
import {
  chainsPagination,
  collapseTransitAndConvertStatusInText,
  concatenateDataFavoriteChainWithDataTypeTime,
  onDeleteSelectedCurrencyGraph,
  onMaxTimeJobScripts,
  onSelectedCurrencyGraph,
  resetSelectedPageInFirst,
  setActiveTypeTime,
  setIsCurrencyPairStrict,
  setIsEqualCurrencies,
  setIsRelatedPlatforms,
  updateStartCurrencyOrSetMarginalProfitChain,
} from './actions';
import * as asyncActs from './async';
import { ActiveTypeTime, IChainSteps, PlatformData, PlatformEdge, TypeTime } from './types';
import { OptionType } from '../../../hooks/useMultiSelect/useMultiSelect.types';
import { convertSnakeToCamel } from '../../../utils/convertSnakeToCamel';
import { mergeFavoriteChainsWithTypeTime } from '../../../utils/mergeFavoriteChainsWithTypeTime';
import { SelectedMetrics } from '../../types';
import { DataChainsFull, IDataChainsTime } from '../filters/types';

export interface TabelesState {
  loadingState: 'loading' | 'failed' | 'success';
  loadingStatePlatform: 'loading' | 'failed' | 'success';
  coursesCurrent?: any;
  methodCurrent?: any;
  currencies?: any;
  platformsCurrent?: any;
  countries?: any;
  chains?: any;
  courses?: any;
  requestCompiled?: any;
  requestCompiledDelete?: any;
  chainFullInfo?: any;
  newLoopsCount: number;
  userErrorMessage?: any;
  requestStatus?: number;
  error?: string;
  courseDelete?: number;
  deleteMethodTransfer?: number;
  deleteOutputMethod?: number;
  deletePlatforms?: number;
  deleteCountries?: number;
  deleteChains?: number;
  sendCourses: string[];
  sendPlatform: string[];
  currentPageChains: number;
  maxTimeJobSctiprs: number;
  selectedPlarforms: number[];
  selectedCurrency: string[];
  selectedCourses: { id: number, name: string }[];
  selectedMethods: number[];
  startCourse: number | null;
  valueRangeMaxSearch: string;
  valueRangeNumberOfRules: number;
  alanisisGrahpTwo: any;
  mertics: any;

  allPlatforms: SelectedMetrics[];
  allCurrencies: SelectedMetrics[];
  allMethods: SelectedMetrics[];

  chainSteps: IChainSteps[];
  normalRate: boolean;
  marginalProfit: number;
  activeTypeTime: ActiveTypeTime;
  platformEdges: PlatformEdge[];
  platformsSharp: string[];
  platformsNonSharp: string[];
  isEqualCurrencies: boolean;
  isRelatedPlatforms: boolean;
  isCurrencyPairStrict: boolean;
  selectedCurrencyGraph: SelectedMetrics[];
  platformData?: PlatformData,

  favoriteChains: DataChainsFull[],
  favoriteChainsDay: IDataChainsTime[],
  favoriteChainsWeek: IDataChainsTime[],
  favoriteChainsMonth: IDataChainsTime[],

  metricPlatform: OptionType[],
  metricMethod: OptionType[],
  metricCurrency: OptionType[],
  chainsRobots: any[],
}

const initialState: TabelesState = {
  loadingState: 'success',
  loadingStatePlatform: 'success',
  coursesCurrent: {},
  methodCurrent: {},
  currencies: {},
  platformsCurrent: {},
  countries: {},
  chains: {},
  courses: {},
  requestCompiled: {},
  requestCompiledDelete: {},
  chainFullInfo: {},
  userErrorMessage: {},
  requestStatus: 0,
  newLoopsCount: 0,
  courseDelete: 0,
  deleteMethodTransfer: 0,
  deleteOutputMethod: 0,
  deletePlatforms: 0,
  deleteCountries: 0,
  deleteChains: 0,
  sendCourses: [],
  sendPlatform: [],
  currentPageChains: 1,
  maxTimeJobSctiprs: 0,
  selectedPlarforms: [],
  selectedCurrency: [],
  selectedCourses: [],
  selectedMethods: [],
  startCourse: null,
  valueRangeMaxSearch: '',
  valueRangeNumberOfRules: 12,
  alanisisGrahpTwo: {},
  mertics: {},

  allPlatforms: [],
  allCurrencies: [],
  allMethods: [],

  chainSteps: [],
  normalRate: true,
  marginalProfit: 0,
  activeTypeTime: '_day',

  platformEdges: [],
  platformsSharp: [],
  platformsNonSharp: [],
  isEqualCurrencies: true,
  isRelatedPlatforms: false,
  isCurrencyPairStrict: false,
  selectedCurrencyGraph: [],

  favoriteChains: [],
  favoriteChainsDay: [],
  favoriteChainsWeek: [],
  favoriteChainsMonth: [],

  metricPlatform: [],
  metricMethod: [],
  metricCurrency: [],
  chainsRobots: [],
};

export const tabelsSlise = createSlice({
  name: 'tabels',
  initialState,
  reducers: {
    chainsPagination,
    onMaxTimeJobScripts,
    collapseTransitAndConvertStatusInText,
    setActiveTypeTime,
    updateStartCurrencyOrSetMarginalProfitChain,
    onSelectedCurrencyGraph,
    onDeleteSelectedCurrencyGraph,
    setIsEqualCurrencies,
    setIsRelatedPlatforms,
    setIsCurrencyPairStrict,
    resetSelectedPageInFirst,
    concatenateDataFavoriteChainWithDataTypeTime,
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActs.getMethodsCurrent.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.getMethodsCurrent.rejected, (state, action) => {
        state.loadingState = 'failed';
        state.error = action.error.code;
      })
      .addCase(asyncActs.getMethodsCurrent.fulfilled, (state, action) => {
        state.loadingState = 'success';
        state.methodCurrent = action.payload;
        state.requestStatus = action.payload.status;
      });

    builder
      .addCase(asyncActs.getCurrencies.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.getCurrencies.rejected, (state, action) => {
        state.loadingState = 'failed';
        state.error = action.error.code;
      })
      .addCase(asyncActs.getCurrencies.fulfilled, (state, action) => {
        state.loadingState = 'success';
        state.currencies = action.payload;
        state.requestStatus = action.payload.status;
      });

    builder
      .addCase(asyncActs.getPlatformsCurrent.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.getPlatformsCurrent.rejected, (state, action) => {
        state.loadingState = 'failed';
        state.error = action.error.code;
      })
      .addCase(asyncActs.getPlatformsCurrent.fulfilled, (state, action) => {
        state.loadingState = 'success';
        state.platformsCurrent = action.payload.data;
        state.requestStatus = action.payload.status;
      });

    builder
      .addCase(asyncActs.getCountries.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.getCountries.rejected, (state, action) => {
        state.loadingState = 'failed';
        state.error = action.error.code;
      })
      .addCase(asyncActs.getCountries.fulfilled, (state, action) => {
        state.loadingState = 'success';
        state.countries = action.payload;
        state.requestStatus = action.payload.status;
      });

    builder
      .addCase(asyncActs.getMetrics.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.getMetrics.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.code;
      })
      .addCase(asyncActs.getMetrics.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';
        state.mertics = payload;

        state.allPlatforms = payload.data.platforms;
        state.allCurrencies = payload.data.currencies;
        state.allMethods = payload.data?.methods;

        state.requestStatus = payload.status;
      });

    builder
      .addCase(asyncActs.getAllMetrics.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.getAllMetrics.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.code;
      })
      .addCase(asyncActs.getAllMetrics.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';

        state.metricPlatform = payload.data.platforms;
        state.metricCurrency = payload.data.currencies;
        state.metricMethod = payload.data.methods;
      });

    builder
      .addCase(asyncActs.postUserErrorMessage.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.postUserErrorMessage.rejected, (state, action) => {
        state.loadingState = 'failed';
        state.error = action.error.code;
      })
      .addCase(asyncActs.postUserErrorMessage.fulfilled, (state, action) => {
        state.loadingState = 'success';
        state.userErrorMessage = action.payload;
        state.requestStatus = action.payload.status;
      });

    builder
      .addCase(asyncActs.getChainFullInfo.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.getChainFullInfo.rejected, (state, action) => {
        state.loadingState = 'failed';
        state.error = action.error.code;
      })
      .addCase(asyncActs.getChainFullInfo.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';

        state.chainFullInfo = payload.data;
        state.requestStatus = payload.status;
        state.chainSteps = payload.data.steps;
      });

    builder
      .addCase(asyncActs.getStoppingProcesses.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.getStoppingProcesses.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.code;
      })
      .addCase(asyncActs.getStoppingProcesses.fulfilled, (state) => {
        state.loadingState = 'success';
      });

    builder
      .addCase(asyncActs.getPlatformGraph.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.getPlatformGraph.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.code;
      })
      .addCase(asyncActs.getPlatformGraph.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';

        state.platformEdges = payload.data.edges;
        state.platformsSharp = payload.data.sharp_platforms;
        state.platformsNonSharp = payload.data.non_sharp_platforms;
      });

    builder
      .addCase(asyncActs.getDataPlatform.pending, (state) => {
        state.loadingStatePlatform = 'loading';
      })
      .addCase(asyncActs.getDataPlatform.rejected, (state, { error }) => {
        state.loadingStatePlatform = 'failed';
        state.error = error.code;
      })
      .addCase(asyncActs.getDataPlatform.fulfilled, (state, { payload }) => {
        state.loadingStatePlatform = 'success';

        state.platformData = convertSnakeToCamel(payload.data);
      });

    builder
      .addCase(asyncActs.postFavoriteChains.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.postFavoriteChains.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.code;
      })
      .addCase(asyncActs.postFavoriteChains.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';

        state.favoriteChains = mergeFavoriteChainsWithTypeTime({
          typeTime: TypeTime.Day,
          dataDay: payload.data?.day || [],
          dataWeek: payload.data?.week || [],
          dataMonth: payload.data?.month || [],
          dataChains: payload.data?.loops || [],
        });
        state.favoriteChainsDay = payload.data?.day || [];
        state.favoriteChainsWeek = payload.data?.week || [];
        state.favoriteChainsMonth = payload.data?.month || [];
      });

    builder
      .addCase(asyncActs.postAddFavoriteChain.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.postAddFavoriteChain.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.code;
      })
      .addCase(asyncActs.postAddFavoriteChain.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';
      });

    builder
      .addCase(asyncActs.getChainsRobots.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.getChainsRobots.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.code;
      })
      .addCase(asyncActs.getChainsRobots.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';

        state.chainsRobots = convertSnakeToCamel(payload.data.data);
      });

  },
});

// экспортишь все actions
export const {
  chainsPagination: chainsPaginationAct,
  collapseTransitAndConvertStatusInText: collapseTransitAndConvertStatusInTextAct,
  setActiveTypeTime: setActiveTypeTimeAction,
  updateStartCurrencyOrSetMarginalProfitChain: updateStartCurrencyOrSetMarginalProfitChainAction,
  onSelectedCurrencyGraph: onSelectedCurrencyGraphAction,
  onDeleteSelectedCurrencyGraph: onDeleteSelectedCurrencyGraphAction,
  setIsEqualCurrencies: setIsEqualCurrenciesAction,
  setIsRelatedPlatforms: setIsRelatedPlatformsAction,
  setIsCurrencyPairStrict: setIsCurrencyPairStrictAction,
  resetSelectedPageInFirst: resetSelectedPageInFirstAction,
  concatenateDataFavoriteChainWithDataTypeTime: concatenateDataFavoriteChainWithDataTypeTimeAction,
} = tabelsSlise.actions;

export default tabelsSlise.reducer;
