import * as Styled from './Error.styled';
import { ErrorProps } from './Error.types';

export function Error({
  error,
}: ErrorProps) {
  return(
    <Styled.ErrorContainer>
      <Styled.ErrorMessage>
        {error}
      </Styled.ErrorMessage>
    </Styled.ErrorContainer>
  );
}