import styled from 'styled-components';
import { Box, Text } from '../../components/styledComponents';

type ContainerProps = {
  marginTop: number,
}

export const Container = styled(Box).attrs<ContainerProps>(({ marginTop }) => ({
  width: '100%',
  mt: marginTop,
}))<ContainerProps>`
  box-sizing: border-box;
`;

export const Title = styled(Text).attrs({
  variant: 'light16',
  color: 'snowWhite',
})`
  text-align: center;
`;

export const SliderContainer = styled(Box).attrs({
  mt: 2,
  px: 2,
})``;