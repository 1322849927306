import { DataHeaders } from '../../components/Table/Table.types';

export const tableMetaData: DataHeaders[] = [
  {
    id: 1,
    header: 'id',
    name: 'ID',
  },
  {
    id: 2,
    header: 'name',
    name: 'Название',
  },
  {
    id: 3,
    header: 'strategyParams',
    name: 'Пар. Откр/Закр',
    options: {
      isStrategyParams: true,
    },
  },
  {
    id: 4,
    header: 'volumeParams',
    name: 'Пар. Объема',
    options: {
      isVolumeParams: true,
    },
  },
  {
    id: 5,
    header: 'makeWorking',
    name: 'Активация',
    options: {
      isCheckbox: true,
    },
  },
  {
    id: 6,
    header: 'isWorking',
    name: 'Статус',
  },
  {
    id: 7,
    header: 'version',
    name: 'Версия',
  },
  {
    id: 8,
    header: 'launchTime',
    name: 'Запущен',
  },
  {
    id: 9,
    header: 'launchLogin',
    name: 'Логин',
  },
  {
    id: 10,
    header: 'openPositionsNumber',
    name: 'Откр операций',
  },
  {
    id: 11,
    header: 'closePositionsNumber',
    name: 'Закр операций',
  },
  {
    id: 12,
    header: 'profitTotal',
    name: 'Доход',
  },
  // todo
  {
    id: 13,
    header: 'date',
    name: 'Посл ордер',
  },
];

export const INITIAL_VALUE = {
  dataCellVolume: {
    failMinQtyCase: '',
    fixVolume: '',
    maxVolume: '',
  },
  dataCellStrategy: {
    bidAskDiff: '',
    currencyList: [],
    dateDiff: '',
    diff1: '',
    diff2: '',
    lossLimit: '',
    profitLimit: '',
  },
};