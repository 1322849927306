import { GetRefRowProps } from './GetRefRow.types';

// Метод, пердназначенный для получения референса строки
export function getRefRow({
  refs,
  node,
  index,
}: GetRefRowProps) {
  if (node) {
    refs.current[index] = node;
  }
}