import { useState } from 'react';
import * as Styled from './FilterGroupWrapper.styled';
import { FilterGroupWrapperProps } from './FilterGroupWrapper.types';

export function FilterGroupWrapper({
  title,
  formElements,
}: FilterGroupWrapperProps) {
  const [isShow, setIsShow] = useState(false);

  function onClickSetStateIsShowHandler() {
    setIsShow((prev) => !prev);
  }

  return (
    <Styled.FilterGroup>
      <Styled.FilterGroupHeader
        onClick={onClickSetStateIsShowHandler}
      >
        <Styled.FilterGroupTitle>{title}:</Styled.FilterGroupTitle>
        <Styled.FilterGroupSwitch isShow={isShow}/>
      </Styled.FilterGroupHeader>
      <Styled.FilterGroupBody isShow={isShow}>
        {formElements}
      </Styled.FilterGroupBody>
    </Styled.FilterGroup>
  );
}