import styled from 'styled-components';
import { color } from 'styled-system';
import { Box, Text } from '../../../../../../components/styledComponents';

export const Container = styled(Box).attrs({
  height: '100%',
})`
  display: flex;
  align-items: center;
`;

export const InfoLabel = styled(Text).attrs({
  color: 'lightGray',
  variant: 'light16',
})``;

export const InfoValue = styled(Text).attrs({
  color: 'snowWhite',
  variant: 'medium20',
})``;

export const InfoValueLink = styled('a').attrs({
  color: 'snowWhite',
})`
  ${color};

  font-family: Roboto-Medium, sans-serif;
  font-size: 20px;
  line-height: 22px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
`;

type InfoValueWithColor = {
  isActive: boolean,
}

export const InfoValueWithColor = styled(Text).attrs<InfoValueWithColor>(({ isActive }) => ({
  color: isActive ? 'lime' : 'carminePink',
  variant: 'medium20',
}))<InfoValueWithColor>``;