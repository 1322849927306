import { Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import * as Styled from './SliderSwipperOneSlide.styled';
import { SliderSwipperOneSlideProps } from './SliderSwipperOneSlide.types';

export function SliderSwipperOneSlide({
  slides,
}: SliderSwipperOneSlideProps) {
  return(
    <Styled.Container>
      <Swiper
        pagination={true}
        className="mySwiper"
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        modules={[Pagination, Autoplay]}
      >
        {slides && slides.map((slide, index) => (
          <SwiperSlide key={`${index}zz`}>
            {slide}
          </SwiperSlide>
        ))}
      </Swiper>
    </Styled.Container>
  );
}