import { Error } from './components/Error';
import { Log } from './components/Log';
import * as Styled from './Logs.styled';
import { useAppSelector } from '../../../../../../hooks/storeHooks';
import { Skeleton } from '../../../../../../ui/Skeleton';
import { getTimeFormating } from '../../../../../../utils/getTimeFormating/getTimeFormating';

export function Logs() {
  const {
    emailLog,
    newLoopsCount,
    loops,
    time,
    begin,
    loadingState,
    isResponseOK,
    versionAlg,
    neglectedLoopsCount,
    error = '',
    errorMessage = '',
  } = useAppSelector((state) => state.algorithms);

  function getIdSearchChains() {
    if (!loops?.length) return 'Цепочек не было найдено';

    const firstId = loops[0];
    const LastId = loops[loops.length - 1];

    if (newLoopsCount === 1) {
      return `${firstId}`;
    }

    if (newLoopsCount === 2) {
      return `${firstId} и ${LastId}`;
    }

    return `${firstId} ... ${LastId}`;
  }

  const LOGS_DATA = [
    {
      key: 1,
      label: 'Версия алгоритма:',
      value: versionAlg || 'Неизвестна',
    },
    {
      key: 2,
      label: 'Пользователь:',
      value: emailLog || 'Неизвестен',
    },
    {
      key: 3,
      label: 'Последний запуск алгоритма:',
      value: begin || 'Данные не были получены',
    },
    {
      key: 4,
      label: 'Время работы алгоритма:',
      value: getTimeFormating({ date: time, compressionDate: true }),
    },
    {
      key: 5,
      label: 'Количество найденый цепочек:',
      value: newLoopsCount || 'Цепочек не было найдено',
    },
    {
      key: 6,
      label: 'ID найденый цепочек:',
      value: getIdSearchChains(),
    },
    {
      key: 7,
      label: 'Проигнорированных цепочек:',
      value: neglectedLoopsCount || 'Цепочек не было найдено',
    },
  ];

  return (
    <>
      {loadingState === 'loading'
        ? <Skeleton height={288}/>
        : (
          <Styled.Container>
            <Styled.Title>
              Информация по запуску алгоритмов
            </Styled.Title>

            {isResponseOK && loadingState !== 'failed' && LOGS_DATA.map((log) => (
              <Log { ...log }/>
            ))}

            {(!isResponseOK || loadingState === 'failed') && (
              <Error error={errorMessage || error}/>
            )}
          </Styled.Container>
        )}
    </>
  );
}