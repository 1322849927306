import * as Styled from './CellVolumeParams.styled';
import { CellVolumeParamsProps } from './CellVolumeParams.types';
import { CustomTooltip } from '../../../../../../ui/CustomTooltip';
import { CellContainer } from '../../ui/CellContainer';
import { CellText } from '../../ui/CellText';
import { Hint } from '../../ui/Hint';


export function CellVolumeParams({
  background,
  paddingVertical,
  isBorderBottom,
  colorBorder,
  size,
  color,
  onClick,
  value,
}: CellVolumeParamsProps) {
  const VOLUME_DATA = [
    {
      key: 1,
      text: 'Случаев с ошибкой:',
      title: value.failMinQtyCase,
    },
    {
      key: 2,
      text: 'Фикс. Объем:',
      title: value.fixVolume,
    },
    {
      key: 3,
      text: 'Макс. объем:',
      title: value.maxVolume,
    },
  ];

  return(
    <CellContainer
      background={background}
      paddingVertical={paddingVertical}
      isBorderBottom={isBorderBottom}
      colorBorder={colorBorder}
      onClick={onClick}
      isRow
    >
      <CustomTooltip
        title={<Hint data={VOLUME_DATA}/>}
        placement="right-start"
      >
        <Styled.Container>
          <CellText
            size={size}
            color={color}
            text="Редактировать"
          />
        </Styled.Container>
      </CustomTooltip>
    </CellContainer>
  );
}