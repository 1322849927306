import { CoursesSorting } from '../../../../../../redux/reducers/filters/types';

export const sortingType = [
  { value: CoursesSorting.Ascending, label: 'По убыванию' },
  { value: CoursesSorting.Descending, label: 'По возрастанию' },
];

export const sortingField = [
  { value: 'id', label: 'Id' },
  { value: 'rule_number', label: 'Правило' },
  { value: 'platform_from', label: 'Откуда' },
  { value: 'platform_to', label: 'Куда' },
  { value: 'method', label: 'Способ перевода' },
  { value: 'currency_from', label: 'Из валюты' },
  { value: 'currency_to', label: 'В валюту' },
  { value: 'rate', label: 'Курс' },
  { value: 'tax', label: 'Комиссия (%)' },
  { value: 'tax_const', label: 'Комиссия фикс.' },
  { value: 'tax_max', label: 'Макс размер комиссии' },
  { value: 'tax_min', label: 'Мин размер комиссии' },
  { value: 'tran_speed', label: 'Время транзакции (сек)' },
  { value: 'tran_max', label: 'Макс сумма транзакции' },
  { value: 'tran_min', label: 'Мин сумма транзакции' },
  { value: 'update_rate', label: 'Периодичность обновления курса' },
  { value: 'last_change', label: 'Дата курса' },
  { value: 'author', label: 'Автор' },
];