import { AuthKey } from '../../../types/AuthKey.types';

export interface IChainSteps {
  [key: string]: string | number | boolean | undefined,

  id: number,
  author: string,
  currency_from: string,
  currency_to: string,
  last_change: string,
  method: string,
  platform_from: string,
  platform_to: string,
  rate: number | string,
  rule_number: number,
  tax: number,
  tax_const: number,
  tax_max: number,
  tax_min: number,
  tran_max: number,
  tran_min: number,
  tran_speed: number,
  update_rate: number,
  significant: number,
  comment?: string,
  bot?: boolean,
  sumStart?: number,
  sumEnd?: number,
}

export type ActiveTypeTime = '_day' | '_week' | '_month';

export type CreateNewValue = {
  presentValue: number,
  rate: number,
  tax: number,
  constTax: number,
  significant: number,
}

export type UpdateStartCurrencyOrSetMarginalProfitChain = {
  presentValue: number,
  indexCurrency?: number,
}

export type PlatformEdge = {
  node1: string;
  node2: string;
  currencies: string;
}

export interface RequestPlatform extends AuthKey {
  platformId: string,
}

export type ParserData = {
  allCourses: string,
  currency: string,
  incomingCourses: string,
  internalCourses: string,
  lastChange: string,
  link: string | null,
  loops: string,
  outgoingCourses: string,
  parsRate: string,
  parser: string,
  parserActive: string,
  label?: string,
  value?: string,
}

export type PlatformData = {
  parserData: ParserData[],
  platformId: number,
  platformName: string,
  platformSynonyms: string[],
}

export enum TypeTime {
  Day = '_day',
  Week = '_week',
  Month = '_month',
}