import styled from 'styled-components';
import { Box, Text } from '../../components/styledComponents';

type ContainerProps = {
  marginTop: number;
}

export const Container = styled(Box).attrs<ContainerProps>(({ marginTop }) => ({
  width: '100%',
  mt: marginTop,
}))<ContainerProps>``;

export const Title = styled(Text).attrs({
  color: 'snowWhite',
  variant: 'light16',
})`
  text-align: center;
`;

export const SliderContainer = styled(Box).attrs({
  mt: 2,
  px: 2,
})``;

export const ActionsWrapper = styled(Box).attrs({
  mt: 3,
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ActionBlock = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 8px;
`;

export const ActionTitle = styled(Text).attrs({
  color: 'lightGray',
  variant: 'light14',
})``;

export const Action = styled(Box).attrs({
  bg: 'grayBlue',
  width: 100,
  height: 30,
})`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

export const ActionValue = styled(Text).attrs({
  color: 'snowWhite',
  variant: 'light16',
})``;