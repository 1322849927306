import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const SCROLL_SIZE = 10;

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  html {
    font-size: 100%;
  }

  body {
    margin: 0;
    font-family: 'Roboto', 'Ubuntu', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #13141A;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

  .container {
    position: relative;
    z-index: 1;
  }

  p, a,
  h1, h2, h3, h4, h5, h6,
  input, select, option, button,
  label {
    font-family: 'Ubuntu', sans-serif;
  }

  // html, body {
  //   min-width: 768px;
  //   overflow-y: hidden;
  //   word-wrap: break-word;
  // }
  //
  // body, a {
  //   color: ${({ theme }) => theme.colors.gray10};
  // }
  //
  // body {
  //   font-family: SBSansText, sans-serif;
  //   font-size: 14px;
  //   line-height: 22px;
  //   letter-spacing: 0.3px;
  //   background-color: ${({ theme }) => theme.colors.gray};
  //   margin: 0;
  //   padding: 0;
  // }
  //
  // a {
  //   text-decoration-line: none;
  // }
  //
  // ul {
  //   margin: 0;
  //   padding: 0;
  //   list-style-type: none;
  // }
  //
  // [type="checkbox"], [type="radio"] {
  //   outline: none;
  // }

  // ::-webkit-scrollbar {
  //   width: ${SCROLL_SIZE}px;
  //   height: ${SCROLL_SIZE}px;
  // }
  //
  // ::-webkit-scrollbar-thumb {
  //   background: ${({ theme }) => theme.colors.black20};
  //   border: 0.5px solid ${({ theme }) => theme.colors.gray};
  //   border-radius: 6px;
  // }

  @font-face {
    font-family: 'Roboto-Light';
    src: url('./assets/fonts/Roboto-Light.eot'),
    url('./assets/fonts/Roboto-Light.ttf') format('truetype'),
    url('./assets/fonts/Roboto-Light.woff') format('woff'),
    url('./assets/fonts/Roboto-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto-Regular';
    src: url('./assets/fonts/Roboto-Regular.eot'),
    url('./assets/fonts/Roboto-Regular.ttf') format('truetype'),
    url('./assets/fonts/Roboto-Regular.woff') format('woff'),
    url('./assets/fonts/Roboto-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto-Medium';
    src: url('./assets/fonts/Roboto-Medium.eot'),
    url('./assets/fonts/Roboto-Medium.ttf') format('truetype'),
    url('./assets/fonts/Roboto-Medium.woff') format('woff'),
    url('./assets/fonts/Roboto-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto-Bold';
    src: url('./assets/fonts/Roboto-Bold.eot'),
    url('./assets/fonts/Roboto-Bold.ttf') format('truetype'),
    url('./assets/fonts/Roboto-Bold.woff') format('woff'),
    url('./assets/fonts/Roboto-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url('./assets/fonts/OpenSans-Bold.eot'),
    url('./assets/fonts/OpenSans-Bold.ttf') format('truetype'),
    url('./assets/fonts/OpenSans-Bold.woff') format('woff'),
    url('./assets/fonts/OpenSans-Bold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
  }
`;
