import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { color, compose, space } from 'styled-system';
import { Box } from '../../../../components/styledComponents';

export const Header = styled(Box).attrs({
  as: 'header',
  py: 8,
})`
  position: absolute;
  top: 0;
  left: 0;
`;

export const Wrapper = styled(Box).attrs({
  as: 'section',
  width: '100%',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  column-gap: 16px;
`;

export const NavigationWrapper = styled(Box).attrs({
  as: 'nav',
  width: '100%',
  ml: 25,
})`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
`;

export const NavigationList = styled(Box).attrs({
  as: 'ul',
  m: 0,
  p: 0,
})`
  display: flex;
`;

export const NavigationLink = styled(Link).attrs({
  as: 'span',
  color: 'snowWhite',
})`
  ${compose(color, space)};

  font-family: Roboto-Medium, sans-serif;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  text-decoration: none;
  transition: 0.35s all;
`;

export const NavigationItem = styled(Box).attrs({
  as: 'li',
  px: 5,
  py: 3,
})`
  list-style-type: none;
  cursor: pointer;
  transition: 0.35s all;

  &:hover {
    ${NavigationLink} {
      color: ${({ theme }) => theme.colors.greenCobalt};
      transition: 0.35s all;
    }
  }
`;