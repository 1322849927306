import { ActiveFilterChains, CoursesActiveFilters, CoursesSorting } from './types';

export const coursesFiltersInitialState: CoursesActiveFilters = {
  coursesRuleNumber: 0,
  coursesPlatform: '',
  coursesCurrency: '',
  coursesRateFirst: 0,
  coursesRateSecond: 0,
  coursesMethod: '',
  coursesSortField: 'id',
  coursesSortDirection: CoursesSorting.Ascending,
};

export const chainsFiltersInitialState: ActiveFilterChains = {
  loopId: '',
  selectedPlatforms: [],
  selectedCourses: [],
  selectedCurrency: [],
  selectedMethods: [],
  ruleValue: 10,
  valueRangeRegulation: [2, 10],
  valueRangeRule: [-30, 30],
  valueRangeRuleWeek: [-30, 30],
  valueRangeRuleMonth: [-30, 30],
  valueRangeTimeProfit: [0, 120],
  valueRangeTimeProfitWeek: [0, 120],
  valueRangeTimeProfitMonth: [0, 120],
  valueRangeTimeProfitPercent: [0, 100],
  valueRangeTimeProfitPercentWeek: [0, 100],
  valueRangeTimeProfitPercentMonth: [0, 100],
  valueRangeTime: [0, 7],
  valueRangeLoopSpeed: [0, 7],
  order: ['', false],
  isModeFilter: true,
  modeChainsStatus: [1, 2, 3],
  currentProfitability: [-10, 30],
  averageIncomeDuringProfitability: [-10, 30],
  averageIncomeDuringProfitabilityWeek: [-10, 30],
  averageIncomeDuringProfitabilityMonth: [-10, 30],
};