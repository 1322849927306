import { MergeFavoriteChainsWithTypeTimeProps } from './MergeFavoriteChainsWithTypeTime.types';
import { DataChainsFull } from '../../redux/reducers/filters/types';
import { TypeTime } from '../../redux/reducers/tabels/types';

export function mergeFavoriteChainsWithTypeTime({
  typeTime,
  dataDay,
  dataWeek,
  dataMonth,
  dataChains = [],
}: MergeFavoriteChainsWithTypeTimeProps) {
  const dynamicFieldsFromTypeTime = (() => {
    switch (typeTime) {
      case TypeTime.Day:
        return dataDay;
      case TypeTime.Week:
        return dataWeek;
      case TypeTime.Month:
        return dataMonth;
      default:
        return [];
    }
  })();

  function mergeWithTime(item: DataChainsFull, index: number): DataChainsFull {
    return {
      ...item,
      ...dynamicFieldsFromTypeTime[index],
    };
  }

  if (dataChains && dataChains.length > 0) {
    return dataChains.map(mergeWithTime);
  } else {
    return [];
  }
}