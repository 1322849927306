import { BtnDataSearch } from './TableTypeTimeBtn.types';

export const btnDataSearch: BtnDataSearch[] = [
  {
    id: 1,
    title: 'День',
    selected: true,
    time: '_day',
  },
  {
    id: 2,
    title: 'Нед',
    selected: false,
    time: '_week',
  },
  {
    id: 3,
    title: 'Мес',
    selected: false,
    time: '_month',
  },
];
