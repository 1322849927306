import * as Styled from './CellStrategyParams.styled';
import { CellStrategyParamsProps } from './CellStrategyParams.types';
import { CustomTooltip } from '../../../../../../ui/CustomTooltip';
import { getTimeFormating } from '../../../../../../utils/getTimeFormating';
import { CellContainer } from '../../ui/CellContainer';
import { CellText } from '../../ui/CellText';
import { Hint } from '../../ui/Hint';

export function CellStrategyParams({
  background,
  paddingVertical,
  isBorderBottom,
  colorBorder,
  size,
  color,
  onClick,
  value,
}: CellStrategyParamsProps) {
  const STRATEGY_DATA = [
    {
      key: 1,
      text: 'Bid Ask Diff:',
      title: value.bidAskDiff || 'Н/Д',
    },
    {
      key: 2,
      text: 'Валюты:',
      title: value.currencyList.length ? value.currencyList : 'Н/Д',
    },
    {
      key: 3,
      text: 'Дата разница:',
      title: value.dateDiff ? getTimeFormating({ date: Number(value.dateDiff), compressionDate: true }) : 'Н/Д',
    },
    {
      key: 4,
      text: 'Разница 1:',
      title: value.diff1 || 'Н/Д',
    },
    {
      key: 5,
      text: 'Разница 2:',
      title: value.diff2 || 'Н/Д',
    },
    {
      key: 6,
      text: 'Лимит потерь:',
      title: value.lossLimit || 'Н/Д',
    },
    {
      key: 7,
      text: 'Лимит прибыли:',
      title: value.profitLimit || 'Н/Д',
    },
  ];

  return(
    <CellContainer
      background={background}
      paddingVertical={paddingVertical}
      isBorderBottom={isBorderBottom}
      colorBorder={colorBorder}
      onClick={onClick}
      isRow
    >
      <CustomTooltip
        title={<Hint data={STRATEGY_DATA}/>}
        placement="right-start"
      >
        <Styled.Container>
          <CellText
            size={size}
            color={color}
            text="Редактировать"
          />
        </Styled.Container>
      </CustomTooltip>
    </CellContainer>
  );
}