import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { OnlineParserValue } from './types';
import { _onlineParserURL } from '../../../default.evn';
import { deleteFieldWithNull } from '../../../utils/deleteFieldWithNull/deleteFieldWithNull';
import { parsingNumber } from '../../../utils/parsingNumber';

export const getOnlineParsing = createAsyncThunk(
  'parsing/getOnlineParsing',
  async (
    {
      authKey,
      activeParser,
      amount,
      profit,
      idChains = 0,
      startCurrency,
    }: OnlineParserValue,
  ) => {
    const listKeyOnlineParser = deleteFieldWithNull({
      profit: parsingNumber(profit || ''),
      amount: parsingNumber(amount || ''),
      key: authKey,
      loop_id: idChains,
      currency_name: startCurrency,
      is_active: activeParser === 2,
    });

    const { data } = await axios.get(_onlineParserURL, {
      headers: {
        'Content-Type': 'application/json',
        ...listKeyOnlineParser,
      },
    });

    return data;
  },
);

export const stopOnlineParser = createAsyncThunk(
  'parsing/stopOnlineParser',
  async (
    {
      authKey,
      idChains,
      activeParser,
    }: OnlineParserValue,
  ) => {
    const { data } = await axios.get(_onlineParserURL, {
      headers: {
        'Content-Type': 'application/json',
        key: authKey,
        loop_id: idChains,
        is_active: activeParser,
      },
    });

    return data;
  },
);
