import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { SERVICES } from './constants';
import * as Styled from './Services.styled';
import arrow from '../../../../../../assets/icons/arrow-2.svg';
import { ImageContainer } from '../../../../../../components/ImageContainer';

export function Services() {
  const { t } = useTranslation();
  const {
    colors: {
      greenCobalt,
    },
  } = useTheme();

  return(
    <Styled.ServiceList>
      {SERVICES.map((service, index) => (
        <Styled.ServiceItem key={`${index}r`}>
          <ImageContainer
            width={28}
            height={28}
            image={arrow}
            fill={greenCobalt}
          />
          <Styled.ServiceText>
            {t(service)}
          </Styled.ServiceText>
        </Styled.ServiceItem>
      ))}
    </Styled.ServiceList>
  );
}