import styled from 'styled-components';
import { compose, color, layout, space } from 'styled-system';
import { Box, Text } from '../styledComponents';

export const DropDown = styled(Box)``;

export const Container = styled(Box).attrs({
  position: 'relative',
  width: '100%',
})`
  display: flex;
  flex-direction: column;
`;

type DropDownSubstrateProps = {
  isShow: boolean,
}

export const DropDownSubstrate = styled(Box).attrs({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 0,
  width: '100%',
  height: '100%',
})<DropDownSubstrateProps>`
  display: ${({ isShow }) => isShow ? 'block' : 'none'};
`;

export const DropDownInputWrapper = styled(Box).attrs({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1,
  width: '100%',
})``;

export const DropDownInput = styled('input').attrs({
  bg: 'grayBlue',
  color: 'snowWhite',
  width: '100%',
  maxHeight: 42,
  py: 2,
  px: 3,
})`
  ${compose(layout, color, space)};
  box-sizing: border-box;
  border-radius: 0;
  border: none;

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    outline: none;
  }
`;

type DropDownListWrapperProps = {
  isShow: boolean,
}

export const DropDownListWrapper = styled(Box).attrs<DropDownListWrapperProps>(({ isShow }) => ({
  position: 'absolute',
  top: '42px',
  left: 0,
  zIndex: 1,
  maxHeight: isShow ? 168 : 0,
  width: '100%',
}))<DropDownListWrapperProps>`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  transition: .25s all;
  opacity: ${({ isShow }) => isShow ? 1 : 0};
  visibility: ${({ isShow }) => isShow ? 'visible' : 'hidden'};
`;

export const DropDownItemWrapper = styled(Box).attrs({
  bg: 'grayBlue',
  px: 2,
  py: 3,
})`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.graniteGray};
  }
`;

export const DropDownItem = styled(Text).attrs({
  variant: 'regular16',
  color: 'snowWhite',
})``;