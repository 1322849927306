export function setFormatTime(timeInSecond: number | string): string {
  if (timeInSecond < 0) return 'Н/Д';

  const days = Math.floor(+timeInSecond / (60 * 60 * 24));
  const hours = Math.floor(((+timeInSecond / (60 * 60)) % 24));
  const minutes = Math.floor((+timeInSecond / 60) % 60);
  const seconds = Math.floor(+timeInSecond % 60);

  if (days >= 20) {
    return `${days}д`;
  }

  if (days > 0) {
    return `${days}д ${hours}ч`;
  }

  if (hours > 0) {
    return `${hours}ч ${minutes}m`;
  }

  if (minutes > 0) {
    return `${minutes}м ${seconds}с`;
  }

  if (seconds > 0) {
    return `${seconds}с`;
  }

  return 'Н/Д';
}