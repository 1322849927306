import { FormControl, FormGroup } from '@mui/material';
import * as Styled from './FilterCheckboxWrapper.styled';
import { FilterCheckboxWrapperProps } from './FilterCheckboxWrapper.types';

export function FilterCheckboxWrapper({
  title,
  formElements,
}: FilterCheckboxWrapperProps) {
  return (
    <Styled.CheckboxWrapper>
      {title && (
        <Styled.CheckboxWrapperTitle>
          {title}:
        </Styled.CheckboxWrapperTitle>
      )}
      <Styled.CheckboxWrapperBody>
        <FormControl component="fieldset">
          <FormGroup>
            {formElements}
          </FormGroup>
        </FormControl>
      </Styled.CheckboxWrapperBody>
    </Styled.CheckboxWrapper>
  );
}