import { ChangeEvent, DragEvent, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import * as Styled from './DragAndDrop.styled';
import { DragAndDropProps } from './DragAndDrop.types';

import close from '../../../../assets/icons/admin-close.svg';
import file from '../../../../assets/icons/file.svg';
import folder from '../../../../assets/icons/folder.svg';

import { ImageContainer } from '../../../../components/ImageContainer';
import { useAppDispatch } from '../../../../hooks/storeHooks';
import { sliceString } from '../../../../utils/SliceString';

export function DragAndDrop({
  width,
  height,
  fileList,
  setValueFilesAction,
  clearValueFilesAction,
  setIsErrorState,
  fileTypes = [],
  size = 'regular20',
  imageFolderFill = '',
  imageFileFill = '',
  colorFileText = '',
  isOneFile = false,
}: DragAndDropProps) {
  const dispatch = useAppDispatch();
  const { colors } = useTheme();

  const [isDrag, setIsDrag] = useState(false);
  const typesFileSet = new Set(fileTypes);

  const inputFileRef = useRef<HTMLInputElement | null>(null);

  function onDragStartHandler(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    setIsDrag(true);
  }

  function onDragLeaveHandler(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    setIsDrag(false);
  }

  function onDropHandler(e: DragEvent<HTMLDivElement>, fullFiles: FileList) {
    e.preventDefault();

    if (isDrag) {
      const fileOrFiles = isOneFile
        ? [fullFiles[0]]
        : [...fullFiles];

      dispatch(setValueFilesAction(fileOrFiles));

      setIsDrag(false);
    }
  }

  function onChangeInputHandler(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();

    if (inputFileRef.current && inputFileRef.current.files) {
      const fileOrFiles = isOneFile
        ? [inputFileRef.current.files[0]]
        : [...inputFileRef.current.files];

      dispatch(setValueFilesAction(fileOrFiles));

      inputFileRef.current.value = '';
    }
  }

  function onClickDeleteFileHandler(index: number) {
    dispatch(clearValueFilesAction(index));
  }

  useEffect(() => {
    setIsErrorState(
      !fileList.length || (!!fileTypes.length && !!fileList.filter(({ type }) => !typesFileSet.has(type)).length),
    );
  }, [fileList]);

  return (
    <Styled.Container
      width={width}
      height={height}
    >
      <Styled.DragAndDropMain
        onDragStart={onDragStartHandler}
        onDragLeave={onDragLeaveHandler}
        onDragOver={onDragStartHandler}
        onDrop={(e) => onDropHandler(e, e.dataTransfer.files)}
      >
        {fileList && (
          <Styled.DragAndDropFileOrFilesContainer
            listFileLength={fileList.length}
            isVisible={!!fileList.length}
          >
            {fileList.map(({
              name,
              type,
            }, index) => (
              <Styled.DragAndDropFileContainer
                listFileLength={fileList.length}
                key={index}
                width={90}
                height={140}
              >
                <ImageContainer
                  width="100%"
                  height="100%"
                  image={file}
                  fill={imageFileFill}
                />

                <Styled.DragAndDropFileText color={colorFileText}>
                  {sliceString({
                    string: name,
                    maxSymbols: 26,
                    sliceBySymbols: 23,
                  })}
                </Styled.DragAndDropFileText>

                <Styled.DragAndDropFileType>
                  {sliceString({
                    string: type.split('/')[1],
                    maxSymbols: 7,
                    sliceBySymbols: 5,
                  })}
                </Styled.DragAndDropFileType>

                <Styled.DeleteFileContainer
                  onClick={() => onClickDeleteFileHandler(index)}
                >
                  <ImageContainer
                    width="65%"
                    height="65%"
                    image={close}
                    fill={colors.oliveGreen}
                  />
                </Styled.DeleteFileContainer>
              </Styled.DragAndDropFileContainer>
            ))}
          </Styled.DragAndDropFileOrFilesContainer>
        )}

        <Styled.DragAndDropImageContainer isVisible={!!fileList.length}>
          <ImageContainer
            width="100%"
            height="100%"
            image={folder}
            fill={imageFolderFill}
          />
        </Styled.DragAndDropImageContainer>

        <Styled.DragAndDropMainText
          size={size}
          isVisible={isDrag || !!fileList.length}
        >
          Перетащите или
        </Styled.DragAndDropMainText>

        <Styled.SelectedFilesLabel
          htmlFor="files"
          isVisible={isDrag || !!fileList.length}
        >
          <Styled.SelectedFilesLabelText size={size}>
            выберите
          </Styled.SelectedFilesLabelText>
          <Styled.SelectedFilesInput
            type="file"
            id="files"
            ref={inputFileRef}
            onChange={onChangeInputHandler}
          />
        </Styled.SelectedFilesLabel>

        <Styled.DragAndDropMainText
          size={size}
          isVisible={isDrag || !!fileList.length}
        >
          файл
        </Styled.DragAndDropMainText>
      </Styled.DragAndDropMain>
    </Styled.Container>
  );
}