import { useEffect, useRef, useState } from 'react';
import { UseActiveProps } from './useActive.types';

export const useActive = (ref: UseActiveProps) => {
  const [isActive, setIsActive] = useState(false);
  const mouseDown = useRef(false);

  const onMouseDown = () => {
    setIsActive(true);
    mouseDown.current = true;
  };

  const onMouseUp = () => {
    setIsActive(false);
    mouseDown.current = false;
  };

  const onMouseLeave = () => {
    if (mouseDown.current) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  useEffect(() => {
    const node = ref.current;

    if (node) {
      node.addEventListener('mousedown', onMouseDown);
      node.addEventListener('mouseleave', onMouseLeave);
      document.addEventListener('mouseup', onMouseUp);

      return () => {
        node.removeEventListener('mousedown', onMouseDown);
        node.removeEventListener('mouseleave', onMouseLeave);
        document.removeEventListener('mouseup', onMouseUp);
      };
    }
  }, []);

  return isActive;
};