import { PayloadAction } from '@reduxjs/toolkit';
import {
  InitStateAlgorithms,
} from '.';
import {
  SelectedAllMetrics,
  SelectedMetrics,
  SelectedMetricsExtended,
} from '../../types';

export const onChangeStrictModeDelete = (
  state: InitStateAlgorithms,
) => {
  state.strictModeDelete = !state.strictModeDelete;
};

export const clearFiltresChains = (state: InitStateAlgorithms) => {
  state.valueRangeRegulation = [1, 10];
  state.valueRangeTime = [0, 7];
  state.valueRangeLoopSpeed = [0, 7];
  state.valueRangeRule = [-30, 30];
};

export const onSelectedPlatforms = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number>,
) => {
  if (!state.selectedPlarforms.includes(payload)) {
    state.selectedPlarforms.push(payload);
  } else {
    state.selectedPlarforms = state.selectedPlarforms.filter((item) => item !== payload);
  }
};

export const onSelectedPlatformsChainsDelete = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number>,
) => {
  if (!state.selectedPlarformsDeleteChain.includes(payload)) {
    state.selectedPlarformsDeleteChain.push(payload);
  } else {
    state.selectedPlarformsDeleteChain = state.selectedPlarformsDeleteChain
      .filter((item) => item !== payload);
  }
};

export const onSelectedPlatformsScripts = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<SelectedAllMetrics>,
) => {
  if (!state.selectedPlarforms.includes(payload.id) && payload.active) {
    state.selectedPlarforms.push(payload.id);
  } else if (!payload.active) {
    state.selectedPlarforms = [];
  }
};

export const onSelectedPlatformsAlgThree = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number>,
) => {
  if (!state.selectedPlarformsAlgThree.includes(payload)) {
    state.selectedPlarformsAlgThree.push(payload);
  } else {
    state.selectedPlarformsAlgThree = state.selectedPlarformsAlgThree.filter((item) => item !== payload);
  }
};

export const onSelectedPlatformsScriptsAlgThree = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<SelectedMetricsExtended>,
) => {
  if (!state.selectedPlarformsAlgThree.includes(payload.id || -1) && payload.active) {
    state.selectedPlarformsAlgThree.push(payload.id || -1);
  } else if (!payload.active) {
    state.selectedPlarformsAlgThree = [];
  }
};

export const onSelectedPlatformsDelete = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<{id: number, active: boolean}>,
) => {
  if (!state.selectedPlarformsDeleteChain.includes(payload.id) && payload.active === true) {
    state.selectedPlarformsDeleteChain.push(payload.id);
  } else if (payload.active === false) {
    state.selectedPlarformsDeleteChain = [];
  }
};

export const onSelectedCourses = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<SelectedMetrics>,
) => {
  const existCurrency = state.selectedCourses.find((item) => item.id === payload.id);

  if (!existCurrency) {
    state.selectedCourses.push(payload);
  } else {
    state.selectedCourses = state.selectedCourses.filter((item) => item.id !== payload.id);

    if (state.startCourse === payload.id) {
      state.startCourse = null;
    }
  }
};

export const onSelectedCoursesScripts = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<SelectedMetricsExtended>,
) => {
  const existCurrency = state.selectedCourses.find((item) => item.id === payload.metric?.id);

  if (!existCurrency && payload.active && payload.metric) {
    state.selectedCourses.push(payload.metric);
  } else if (!payload.active) {
    state.selectedCourses = [];

    if (state.startCourse === payload.metric?.id) {
      state.startCourse = null;
    }
  }
};

export const onSelectedCoursesAlgThree = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<SelectedMetrics>,
) => {
  const existCurrency = state.selectedCoursesAlgThree.find((item) => item.id === payload.id);

  if (!existCurrency) {
    state.selectedCoursesAlgThree.push(payload);
  } else {
    state.selectedCoursesAlgThree = state.selectedCoursesAlgThree.filter((item) => item.id !== payload.id);

    if (state.startCourseAlgThree === payload.id) {
      state.startCourseAlgThree = null;
    }
  }
};

export const onSelectedCoursesScriptsAlgThree = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<SelectedMetricsExtended>,
) => {
  const existCurrency = state.selectedCoursesAlgThree.find((item) => item.id === payload.metric?.id);

  if (!existCurrency && payload.active && payload.metric) {
    state.selectedCoursesAlgThree.push(payload.metric);
  } else if (!payload.active) {
    state.selectedCoursesAlgThree = [];

    if (state.startCourseAlgThree === payload.metric?.id) {
      state.startCourseAlgThree = null;
    }
  }
};

export const onSelectedMethods = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number>,
) => {
  if (!state.selectedMethods.includes(payload)) {
    state.selectedMethods.push(payload);
  } else {
    state.selectedMethods = state.selectedMethods.filter((item) => item !== payload);
  }
};

export const onSelectedMethodsScripts = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<SelectedAllMetrics>,
) => {
  if (!state.selectedMethods.includes(payload.id) && payload.active) {
    state.selectedMethods.push(payload.id);
  } else if (!payload.active) {
    state.selectedMethods = [];
  }
};

export const onSelectedMethodsAlgThree = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number>,
) => {
  if (!state.selectedMethodsAlgThree.includes(payload)) {
    state.selectedMethodsAlgThree.push(payload);
  } else {
    state.selectedMethodsAlgThree = state.selectedMethodsAlgThree.filter((item) => item !== payload);
  }
};

export const onSelectedMethodsScriptsAlgThree = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<SelectedMetricsExtended>,
) => {
  if (!state.selectedMethodsAlgThree.includes(payload.id || -1) && payload.active) {
    state.selectedMethodsAlgThree.push(payload.id || -1);
  } else if (!payload.active) {
    state.selectedMethodsAlgThree = [];
  }
};

export const onMaxTimeJobScripts = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number>,
) => {
  state.maxTimeJobSctiprs = payload;
};

export const onMaxTimeJobScriptsAlgThree = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number>,
) => {
  state.maxTimeJobSctiprsAlgThree = payload;
};

export const handleChangeMaxSearch = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<string>,
) => {
  state.valueRangeMaxSearch = payload;
};

export const handleChangeMaxSearchAlgThree = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<string>,
) => {
  state.valueRangeMaxSearchAlgThree = payload;
};

export const handleChangeNumberOfRules = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number>,
) => {
  state.valueRangeNumberOfRules = payload as number;
};

export const handleChangeStepOfPlatforms = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number>,
) => {
  state.valueRangeStepsOfPlatforms = payload as number;
};

export const handleChangeMaximumNumberChain = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number>,
) => {
  state.valueRangeMaximumNumberChain = payload;
};

export const handleChangeMaximumNumberChainAlgThree = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number>,
) => {
  state.valueRangeMaximumNumberChainAlgorithmThree = payload;
};

export const handleChangeStepsOfPlatformsAlgorithmThree = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number>,
) => {
  state.valueRangeStepsOfPlatformsAlgorithmThree = payload as number;
};

export const handleChangeNumberOfRulesAlgThree = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number>,
) => {
  state.valueRangeNumberOfRulesAlgThree = payload;
};

export const setDefaultSearchAlgorithmStatusInUndefined = (
  state: InitStateAlgorithms,
) => {
  state.searchAlgorithmStatus = undefined;
};

export const onStartCourses = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number>,
) => {
  if (state.startCourse === payload) {
    state.startCourse = null;
  } else {
    state.startCourse = payload;
  }
};

export const onStartCoursesAlgThree = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number>,
) => {
  const { startCourseAlgThree } = state;

  state.startCourseAlgThree = (
    startCourseAlgThree === payload
      ? null
      : payload
  );
};

export const onNewDateActiveBtnUser = (
  state: InitStateAlgorithms,
) => {
  const date = new Date();
  const DDMMYYYY = `${(`0${date.getDate()}`).slice(-2)}/${(`0${date.getMonth() + 1}`).slice(-2)}/${date.getFullYear()}`;
  const HHMMSS = date.toTimeString().split(' ')[0];

  state.timeActionBtn = `${DDMMYYYY} ${HHMMSS}`;
  state.begin = `${DDMMYYYY} ${HHMMSS}`;
};

export const receivingEmail = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<string>,
) => {
  state.email = payload;
  state.emailLog = payload;
};

export const choiseAlg = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number>,
) => {
  state.versionAlg = payload;
};

export const onSelectedCoursesDelete = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number>,
) => {
  if (!state.selectedCoursesDelete.includes(payload)) {
    state.selectedCoursesDelete.push(payload);
  } else {
    state.selectedCoursesDelete = state.selectedCoursesDelete.filter((item) => item !== payload);
  }
};

export const onSelectedCoursesDeleteScript = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<{id: number, active: boolean}>,
) => {
  if (!state.selectedCoursesDelete.includes(payload.id) && payload.active === true) {
    state.selectedCoursesDelete.push(payload.id);
  } else if (payload.active === false) {
    state.selectedCoursesDelete = [];
  }
};

export const onChangeRegulation = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number[]>,
) => {
  state.valueRangeRegulation = payload;
};

export const onChangeTimeRegulation = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number[]>,
) => {
  state.valueRangeTime = payload;
};

export const onChangeLoopSpeed = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number[]>,
) => {
  state.valueRangeLoopSpeed = payload;
};

export const onChangeRule = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number[]>,
) => {
  state.valueRangeRule = payload;
};

export const onChangeRuleWeek = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number[]>,
) => {
  state.valueRangeRuleWeek = payload;
};

export const onChangeRuleMonth = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number[]>,
) => {
  state.valueRangeRuleMonth = payload;
};

export const onChangeTimeProfit = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number[]>,
) => {
  state.valueRangeTimeProfit = payload;
};

export const onChangeTimeProfitPercent = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number[]>,
) => {
  state.valueRangeTimeProfitPercent = payload;
};

export const onChangeTimeMonthProfitPercent = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number[]>,
) => {
  state.valueRangeTimeMonthProfitPercent = payload;
};

export const onChangeMarginalityChain = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number[]>,
) => {
  state.valueRangeMarginalityChain = payload;
};

export const onChangeMarginalityChainAlgorithmThree = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number[]>,
) => {
  state.valueRangeMarginalityChainAlgorithmThree = payload;
};

export const onCurrencyIdScripts = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<string>,
) => {
  const deleteSpace = payload.replace(/ +/g, ' ');

  state.currencyIdSctiprs = deleteSpace;
};

export const setActiveAlgorithmVersion = (
  state: InitStateAlgorithms,
  { payload }: PayloadAction<number>,
) => {
  state.activeAlgorithmVersion = payload;
};
