import React, { Dispatch, SetStateAction, useState } from 'react';
import { useDrag } from 'react-dnd';
import { DragItem } from './DragItem.styled';

interface AvgRateBidItemProps {
  data: number[];
  setIsDragging: Dispatch<SetStateAction<boolean>>;
  label: string;
}

function ProfitActualShort({ data, setIsDragging, label }: AvgRateBidItemProps) {
  const [isDraggingLocal, setIsDraggingLocal] = useState(false);

  const [{ isDragging }, drag] = useDrag({
    type: 'profit-short',
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => {
      setIsDragging(true);
      setIsDraggingLocal(true);
      return { type: 'profit-short', data };
    },
    end: () => {
      setIsDragging(false); 
      setIsDraggingLocal(false);
    },
  });

  const opacity = isDraggingLocal ? 0.4 : 1;

  return (
    <DragItem ref={drag} style={{ opacity, cursor: 'grab' }}>
      {label}
    </DragItem>
  );
}

export { ProfitActualShort };
