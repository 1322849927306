import Slider from '@mui/material/Slider';
import * as Styled from './FilterRangeSlider.styled';
import { FilterRangeSliderProps } from './FilterRangeSlider.types';
import { useAppDispatch } from '../../../../../../../../hooks/storeHooks';

export function FilterRangeSlider({
  title,
  minValue,
  maxValue,
  value,
  setStateValueAction,
  step = 1,
  signatureValueStartLeft = '',
  signatureValueEndLeft = '',
  signatureValueStartRight = '',
  signatureValueEndRight = '',
  signatureValueStartLeftBorder = '',
  signatureValueEndLeftBorder = '',
  signatureValueStartRightBorder = '',
  signatureValueEndRightBorder = '',
}: FilterRangeSliderProps) {
  const dispatch = useAppDispatch();

  function onChangeSetStateValueHandler(event: Event, newValue: number | number[]) {
    if (typeof newValue !== 'number') {
      dispatch(setStateValueAction(newValue));
    }
  }

  const valueLeftBorder = value[0];
  const valueRightBorder = value[1];

  return (
    <Styled.Filter>
      <Styled.FilterTitle>
        {title}:
      </Styled.FilterTitle>
      <Styled.FilterSliderWrapper>
        <Slider
          getAriaLabel={() => 'Temperature range'}
          value={value}
          onChange={onChangeSetStateValueHandler}
          size="small"
          min={minValue}
          max={maxValue}
          step={step}
          valueLabelDisplay="off"
        />
      </Styled.FilterSliderWrapper>
      <Styled.FilterInputsWrapper>
        <Styled.FilterInputWrapper>
          <Styled.FilterInputText>от</Styled.FilterInputText>
          <Styled.FilterValue>
            {valueLeftBorder === minValue
              ? `${signatureValueStartLeftBorder}${valueLeftBorder}${signatureValueEndLeftBorder || signatureValueEndLeft}`
              : `${signatureValueStartLeft}${valueLeftBorder}${signatureValueEndLeft}`}
          </Styled.FilterValue>
        </Styled.FilterInputWrapper>
        <Styled.FilterInputWrapper>
          <Styled.FilterInputText>до</Styled.FilterInputText>
          <Styled.FilterValue>
            {valueRightBorder === maxValue
              ? `${signatureValueStartRightBorder}${valueRightBorder}${signatureValueEndRightBorder || signatureValueEndRight}`
              : `${signatureValueStartRight}${valueRightBorder}${signatureValueEndRight}`}
          </Styled.FilterValue>
        </Styled.FilterInputWrapper>
      </Styled.FilterInputsWrapper>
    </Styled.Filter>
  );
}