import { Features } from './components/Features';
import { ModalStrategy } from './components/ModalStrategy';
import { ModalVolume } from './components/ModalVolume';
import { tableMetaData } from './constants';
import { useInitialValue } from './hooks/useInitialValue';
import { useStrategy } from './hooks/useStrategy';
import { useVolume } from './hooks/useVolume';
import * as Styled from './OnlineOperation.styled';
import { MetaTags } from '../../components/MetaTags';
import { Table } from '../../components/Table';
import { DataBody } from '../../components/Table/Table.types';
import { ModalContainer } from '../../ui/ModalContainer';
import { Skeleton } from '../../ui/Skeleton';

export function OnlineOperation() {
  const {
    modalVolume,
    setDataCellVolume,
    onClickResetDataVolumeHandler,
    controllerFailMinQtyCase,
    controllerFixVolume,
    controllerMaxVolume,
    onClickSetDataVolumeParamsHandler,
  } = useVolume();

  const {
    modalStrategy,
    setDataCellStrategy,
    onClickResetDataStrategyHandler,
    controllerBidAskDiff,
    controllerDateDiff,
    controllerDiff1,
    controllerDiff2,
    controllerLossLimit,
    controllerProfitLimit,
    listCurrency,
    onClickSetDataStrategyParamsHandler,
  } = useStrategy();

  const {
    colors,
    chainsRobots,
    loadingState,
    metricCurrency,
  } = useInitialValue();

  // Метод, который устанавливает данные в модалках редактирования параметров откр/закр и объема
  function setActionByCell(rowData: DataBody, i: number, j: number) {
    if (j === 2) {
      modalStrategy.onClick();
      //@ts-ignore todo убрать временный костыль
      setDataCellStrategy(rowData.strategyParams);
    }

    if (j === 3) {
      modalVolume.onClick();
      //@ts-ignore todo убрать временный костыль
      setDataCellVolume(rowData.volumeParams);
    }
  }

  return (
    <Styled.Container>
      <MetaTags
        title="Arbitoring - Online Operation"
        description="Arbitoring - the best currency arbitrage in the world"
        name="Arbitoring"
        type="article"
      />

      <Features/>

      <Styled.TableContainer>
        {loadingState === 'loading'
          ? (
            <Skeleton
              width="100%"
              height="100%"
              background={colors.blackBlue}
              borderRadius={4}
            />
          )
          : (
            <Table
              width="100%"
              height={500}
              dataHeaders={tableMetaData}
              dataBody={chainsRobots}
              subName="_deviation"
              colorBodyColumnHover={colors.graniteGray}
              colorBodyColumn={colors.blackBlue}
              colorBodyColumnEven={colors.oliveGreen}
              colorSubstrate={colors.blackBlue}
              isBorderHeader
              sizeHeaderCell='regular14'
              setActionByCell={setActionByCell}
            />
          )}
      </Styled.TableContainer>

      {modalStrategy.isActive && (
        <ModalContainer
          onClose={onClickResetDataStrategyHandler}
          onActive={onClickSetDataStrategyParamsHandler}
          title="Редактировать параметры откр/закр"
          helpHint='Временно недоступно'
          isDisabled
        >
          <ModalStrategy
            controllerBidAskDiff={controllerBidAskDiff}
            controllerDateDiff={controllerDateDiff}
            controllerDiff1={controllerDiff1}
            controllerDiff2={controllerDiff2}
            controllerLossLimit={controllerLossLimit}
            controllerProfitLimit={controllerProfitLimit}
            listCurrency={listCurrency}
            metricCurrency={metricCurrency}
          />
        </ModalContainer>
      )}

      {modalVolume.isActive && (
        <ModalContainer
          onClose={onClickResetDataVolumeHandler}
          onActive={onClickSetDataVolumeParamsHandler}
          title="Редактировать параметры объема"
          helpHint='Временно недоступно'
          isDisabled
        >
          <ModalVolume
            controllerFailMinQtyCase={controllerFailMinQtyCase}
            controllerFixVolume={controllerFixVolume}
            controllerMaxVolume={controllerMaxVolume}
          />
        </ModalContainer>
      )}
    </Styled.Container>
  );
}