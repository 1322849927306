import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Features } from './components/Features';
import { TABLE_TYPE_TIME, tableMetaData } from './components/Features/constants';
import * as Styled from './FavoriteChains.styled';

import { ActiveTimeType } from './FavoriteChains.types';
import { MetaTags } from '../../components/MetaTags';
import { Table } from '../../components/Table';
import { DataBody } from '../../components/Table/Table.types';
import { PATH } from '../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { postFavoriteChains } from '../../redux/reducers/tabels/async';
import { Skeleton } from '../../ui/Skeleton';

export function FavoriteChains() {
  const {
    colors: {
      blackBlue,
      oliveGreen,
      graniteGray,
    },
  } = useTheme();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    authKey = '',
  } = useAppSelector((state) => state.auth);

  const [activeTypeTime,setActiveTypeTime] = useState<ActiveTimeType>(TABLE_TYPE_TIME.activeTypeTime);
  const {
    loadingState,
    favoriteChains,
  } = useAppSelector((state) => state.tabels);

  function onClickNavigateInLoopPageHandler(loopChain?: DataBody) {
    navigate(PATH.loop + loopChain?.id);
  }

  useEffect(() => {
    dispatch(postFavoriteChains({
      authKey,
    }));
  }, []);

  return (
    <Styled.Container>
      <MetaTags
        title="Arbitoring - My chains"
        description="Arbitoring - the best currency arbitrage in the world"
        name="Arbitoring"
        type="article"
      />

      <Features
        activeTypeTime={activeTypeTime}
        setActiveTypeTime={setActiveTypeTime}
      />

      {loadingState === 'loading'
        ? (
          <Skeleton
            width="100%"
            height="100%"
            background={blackBlue}
            borderRadius={4}
          />
        )
        : (
          <Table
            width="100%"
            height="100%"
            dataHeaders={tableMetaData}
            dataBody={favoriteChains}
            subName="_deviation"
            colorBodyColumnHover={graniteGray}
            colorBodyColumn={blackBlue}
            colorBodyColumnEven={oliveGreen}
            activeTypeTime={activeTypeTime.typeTime}
            setActionByRow={onClickNavigateInLoopPageHandler}
            colorSubstrate={blackBlue}
            isBorderHeader
          />
        )}
    </Styled.Container>
  );
}