import styled from 'styled-components';
import { compose, color, layout, space } from 'styled-system';
import { Box, Text } from '../../../../../../../../components/styledComponents';

export const Filter = styled(Box)`
  grid-column: 1 / 3;
  display: flex;
  flex-direction: column;

  @media (max-width: 510px) {
    grid-column: 1 / 2;
  }
`;

export const FilterTitle = styled(Text).attrs({
  variant: 'regular16',
  color: 'snowWhite',
})``;

export const FilterSliderWrapper = styled(Box).attrs({
  mt: 4,
  px: 4,
})``;

export const FilterInputsWrapper = styled(Box).attrs({
  px: 3,
})`
  display: flex;
  justify-content: space-between;
`;

export const FilterInputWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FilterInputText = styled(Text).attrs({
  variant: 'regular10',
  color: 'lightGray',
})``;

export const FilterValue = styled(Text).attrs({
  variant: 'light12',
  width: 110,
  height: 22,
  bg: 'grayBlue',
  color: 'snowWhite',
  ml: 1,
  py: 1,
  px: 3,
})`
  ${compose(layout)};
  box-sizing: border-box;
  text-align: center;
  border-radius: 4px;

  @media (max-width: 374px) {
    width: 90px;
    padding: 4px;
  }
`;