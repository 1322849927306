export const colors: { [key: string]: string } = {
  oliveGreen: '#13141A',
  grayBlue: '#24252D',
  blackBlue: '#1B1C22',
  arsenic: '#2C2D33',
  lightGray: '#949494',
  greenCobalt: '#038AFF',
  lightGrayBlue: '#1d97ff',
  greenBlue: '#0279e7',
  white: '#FFFFFF',
  snowWhite: '#F9F4F5',
  whiteLight: 'rgb(247, 250, 252)',
  monochromaticBlue: 'rgba(19, 20, 26, 0.3)',
  slateGray: '#3B3C41',
  graniteGray: '#323444',
  carminePink: '#EB5757',
  carminePinkLight: '#FF7070',
  carminePinkDark: '#D45252',
  yellowPink: '#EF3F3F',
  black: '#000000',
  blackShadow: 'rgba(0, 0, 0, 0.25)',
  whiteAluminum: '#AAA69D',
  purpleBlue: '#3A55B7',
  blueAzure: '#3F5ABF',
  cornflowerBlue: '#667CCC',
  blueGray: '#7087DB',
  darkWhite: '#E2E2E2',
  lightGrayGhostly: 'rgba(94, 94, 94, 0.1)',
  grayAzure: 'rgb(44, 45, 51)',
  heavenly: '#EBF4FF',
  red: '#FF0000',
  darkGray: 'rgba(113,128,150,var(--text-opacity))',
  lightGreen: '#557A46',
  lime: '#00FF0C',
  lightYellow: '#FBD85D',
  grey3: '#585858',
  grey4: '#F0F0F0',
  grey5: '#757575',
  opacityGray: '#E0E0E0',
  nightBlue: '#1b1b23',
  mainBlue: '#1994FF',
};
