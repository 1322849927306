import { PATH } from '../constants/constants';
import { UserStatus } from '../types/CourseItem';

export const NON_LOGGED_PATHS = [
  PATH.signIn,
  PATH.signUp,
  PATH.passwordRecovery,
];

export const USER_STATUS = [
  UserStatus.adminUser,
  UserStatus.premiumUser,
  UserStatus.freeUser,
];