import React from 'react';
import { useTheme } from 'styled-components';
import { btnDataSearch, TABLE_TYPE_TIME } from './constants';
import * as Styled from './Features.styled';
import { FeaturesProps } from './Features.types';
import refresh from '../../../../assets/icons/refresh.svg';
import { Button } from '../../../../components/Button';
import { ButtonType } from '../../../../components/Button/Button.types';
import { TooltipWithElement } from '../../../../components/TooltipWithElement';
import { TooltipPlacement } from '../../../../components/TooltipWithElement/TooltipWithElement.types';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { concatenateDataFavoriteChainWithDataTypeTimeAction } from '../../../../redux/reducers/tabels';
import { postFavoriteChains } from '../../../../redux/reducers/tabels/async';
import { TypeTime } from '../../../../redux/reducers/tabels/types';

export function Features({
  activeTypeTime,
  setActiveTypeTime,
}: FeaturesProps) {
  const {
    colors: {
      snowWhite,
    },
  } = useTheme();

  const {
    authKey = '',
  } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();
  
  function onClickUpdateChainsHandler() {
    dispatch(postFavoriteChains({
      authKey,
    }));
    setActiveTypeTime(TABLE_TYPE_TIME.activeTypeTime);
  }

  function onClickActiveIdBtnProfitTimeHandler(id: number, typeTime: TypeTime) {
    setActiveTypeTime({
      id,
      typeTime,
    });
    dispatch(concatenateDataFavoriteChainWithDataTypeTimeAction(typeTime));
  }

  return (
    <Styled.Container>
      <Button
        width={120}
        height={32}
        onClick={onClickUpdateChainsHandler}
        image={refresh}
        imageWidth={18}
        imageHeight={18}
        fill={snowWhite}
        fillHover={snowWhite}
        fillActive={snowWhite}
        indent={1}
        isImageLeft
        title='Обновить'
        size='regular14'
      />
      <Styled.TypeTimeButtonContainer>
        <TooltipWithElement
          hint="Прибыльность за период"
          placement={TooltipPlacement.left}
          element={
            <Styled.ButtonListContainer>
              {btnDataSearch.map(({ id, title, time }) => (
                <Styled.ButtonContainer key={id}>
                  <Button
                    width={110}
                    height={32}
                    onClick={() => onClickActiveIdBtnProfitTimeHandler(id, time)}
                    title={title}
                    isSelected={id === activeTypeTime.id}
                    type={ButtonType.TypeWhite}
                    size="regular14"
                  />
                </Styled.ButtonContainer>
              ))}
            </Styled.ButtonListContainer>
          }
        />
      </Styled.TypeTimeButtonContainer>
    </Styled.Container>
  );
}