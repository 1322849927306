import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Styled from './Navigation.styled';
import { NavigationProps } from './Navigation.types';
import adminMenu from '../../../../assets/icons/admin-menu.png';
import { useHover } from '../../../../hooks/useHover';

export function Navigation({
  navigationListUser,
}:NavigationProps) {

  const { t } = useTranslation();
  const navigationRef = useRef<HTMLDivElement | null>(null);
  const navigationHoverRef = useHover(navigationRef);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  function onClickSetPathHandler(path: string) {
    navigate(path);
  }

  return (
    <Styled.NavigationContainer ref={navigationRef}>
      <Styled.NavigationMenuImage
        src={adminMenu}
      />
      <Styled.Navigation isView={navigationHoverRef}>
        {navigationListUser.map(({
          id,
          title,
          path,
        }) => (
          <Styled.NavigationLink
            key={id}
            to={path}
          >
            <Styled.NavigationLinkText
              isActive={path === pathname}
              onClick={() => onClickSetPathHandler(path)}
            >
              {t(title)}
            </Styled.NavigationLinkText>
          </Styled.NavigationLink>
        ))}
      </Styled.Navigation>
    </Styled.NavigationContainer>
  );
}