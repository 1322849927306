import styled, { css } from 'styled-components';
import { Box, Text } from '../../components/styledComponents';

type ContainerProps = {
  height: number | string,
  width: number | string,
  paddingY: number
  isBorder: boolean,
}

export const Container = styled(Box).attrs<ContainerProps>(({
  height,
  width,
  paddingY,
}) =>({
  width,
  height,
  py: paddingY,
}))<ContainerProps>`
  box-sizing: border-box;
  
  ${({ theme: { colors: { grayBlue, lightGray, snowWhite, graniteGray } }, isBorder }) => css`
    .select__control {
      background-color: ${grayBlue};
      border: none;
      border-radius: 4px;
      cursor: pointer;
      white-space: nowrap;
      box-shadow: ${isBorder ? `0 0 0 1px ${lightGray}`: 'none'};
    }
  
    .select__control--is-focused {
      border-color: ${lightGray} !important;
      box-shadow: 0 0 0 1px ${lightGray};
    }
    
    .select__indicator-separator,
    .select__option--is-focused {
      background-color: ${lightGray}
    }
    
    .select__indicator {
      svg {
        fill: ${lightGray};
      }
    }
    
    .select__menu,
    .select__option--is-selected {
      background-color: ${grayBlue};
    }
    
    .select__option {
      font-size: 16px;
      line-height: 18px;
      padding: 10px;
      color: ${snowWhite};
    }
    
    .select__option--is-focused {
      background-color: ${graniteGray};
    }
    
    .select__single-value,
    .select__input-container {
      color: ${snowWhite};
    }
  `}
  
`;

export const SelectTitle = styled(Text).attrs({
  variant: 'regular16',
  color: 'snowWhite',
  pb: 2,
})`
  white-space: nowrap;
  cursor: auto;
`;