import styled from 'styled-components';
import { SIZE } from './constnsts';
import { Box } from '../../components/styledComponents';

export const Wrapper = styled(Box).attrs({
  width: '100vw',
})`
  display: flex;
  justify-content: center;
`;

export const Container = styled(Box).attrs({
  maxWidth: SIZE.laptopL,
  width: SIZE.laptopL,
})``;