import React from 'react';

import { useTheme } from 'styled-components';
import * as Styled from './TableFilters.styled';
import filters from '../../../../../../assets/icons/filterIcon.svg';
import { ImageContainer } from '../../../../../../components/ImageContainer';
import { useAppDispatch } from '../../../../../../hooks/storeHooks';
import { setIsOpenModalFiltersAction } from '../../../../../../redux/reducers/navigation';


export function TableFilters() {
  const dispatch = useAppDispatch();
  const {
    colors: {
      snowWhite,
    },
  } = useTheme();

  function onClickOpenModalAllFiltersHandler() {
    dispatch(setIsOpenModalFiltersAction());
  }

  return (
    <Styled.FiltersAllWrapper onClick={onClickOpenModalAllFiltersHandler}>
      <ImageContainer
        image={filters}
        width={16}
        height={14}
        fill={snowWhite}
      />
      <Styled.FiltersAllText>Все фильтры</Styled.FiltersAllText>
    </Styled.FiltersAllWrapper>
  );
}
