import styled from 'styled-components';
import { Box, Text } from '../../../../../../components/styledComponents';

export const Substrate = styled(Box).attrs({
  width: '100%',
  height: '100dvh',
  bg: 'monochromaticBlue',
})`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`;

export const Container = styled(Box).attrs({
  p: 3,
  bg: 'arsenic',
  width: 450,
  maxWidth: 450,
})`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  border-radius: 4px;
  box-sizing: border-box;
`;

export const ModalText = styled(Text).attrs({
  color: 'snowWhite',
  variant: 'light20',
  mt: 4,
})`
  text-align: center;
`;

export const Title = styled(Text).attrs({
  color: 'snowWhite',
  variant: 'light16',
})`
  text-align: center;
`;