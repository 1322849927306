import { FC } from 'react';
import { useDrop, DropTargetMonitor } from 'react-dnd';
import * as Styled from './DropZoneBottom.styled';

interface DropZoneProps {
  onDrop: (item: { type: string; data: any }) => void;
}

interface DropItem {
  type: string;
  data: any;
}

const DropZoneBottom: FC<DropZoneProps> = ({ onDrop }) => {
  const [{ isOver }, drop] = useDrop<DropItem, void, { isOver: boolean }>({
    accept: ['graph-data', 'avg-rate-bid-data', 'avg-rate-ask-data', 'rate-diff', 'profit-long', 'profit-short'],
    drop: (item) => onDrop(item),
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <Styled.DropContainerBottom ref={drop}>
      <p>Создать график процентных величин</p>
    </Styled.DropContainerBottom>
  );
};

export default DropZoneBottom;
