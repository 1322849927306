import { useEffect } from 'react';
import { useTheme } from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { getAllMetrics, getChainsRobots } from '../../../../redux/reducers/tabels/async';

// Модуль, отвечающий за начальную загрузку необходимых данных для страницы
export function useInitialValue() {
  const dispatch = useAppDispatch();

  const {
    loadingState,
    chainsRobots,
    metricCurrency,
  } = useAppSelector((state) => state.tabels);
  const {
    authKey = '',
  } = useAppSelector((state) => state.auth);
  
  const {
    colors: {
      blackBlue,
      oliveGreen,
      graniteGray,
    },
  } = useTheme();

  useEffect(() => {
    if (!metricCurrency.length) {
      dispatch(getAllMetrics({ authKey }));
    }

    dispatch(getChainsRobots({ authKey: authKey }));
  }, []);

  const colors = {
    blackBlue,
    oliveGreen,
    graniteGray,
  };

  return{
    colors,
    chainsRobots,
    loadingState,
    metricCurrency,
  };
}