import { ChangeEventHandler } from 'react';

export type InputValue = string | number | readonly string[] | undefined;

export enum InputType {
  Text = 'text',
  Email = 'email',
  Password = 'password',
  File = 'file',
}

export type FieldInputProps = {
  value: InputValue,
  onChange: ChangeEventHandler<HTMLInputElement>,
  title?: string,
  backgroundColor?: string,
  borderColor?: string,
  outlineColor?: string,
  color?: string,
  placeholder?: string,
  borderRadius?: number,
  type?: InputType
  marginTop?: number,
  marginLeft?: number,
  tabIndex?: number,
  isReadOnly?: boolean,
  isNotVisible?: boolean,
  isDisabled?: boolean,
  width?: number | string,
  height?: number | string,
  isActionButton?: boolean,
  setValueInField?: (value: string | number) => void;
}