import { DataHeaders } from '../../../../components/Table/Table.types';
import { TypeTime } from '../../../../redux/reducers/tabels/types';

type BtnDataSearch = {
  id: number,
  title: string,
  time: TypeTime,
}

export const btnDataSearch: BtnDataSearch[] = [
  {
    id: 0,
    title: 'День',
    time: TypeTime.Day,
  },
  {
    id: 1,
    title: 'Нед',
    time: TypeTime.Week,
  },
  {
    id: 2,
    title: 'Мес',
    time: TypeTime.Month,
  },
];

export const TABLE_TYPE_TIME = {
  activeTypeTime: {
    id: 0,
    typeTime: TypeTime.Day,
  },
};

export const tableMetaData: DataHeaders[] = [
  {
    id: 1,
    header: 'id',
    name: 'ID',
    hint: 'ID цепочки',
  },
  {
    id: 2,
    header: 'added',
    name: 'Была найдена',
    hint: 'Период времени, начиная с момента как Arbitoring нашел данную цепочку и начали по ней собирать статистику',
    options: {
      isTime: true,
    },
  },
  {
    id: 3,
    header: 'length',
    name: 'Кол-во правил',
    hint: 'Количество обменов или переводов в цепочке',
  },
  {
    id: 4,
    header: 'platforms',
    name: 'Платформы',
    hint: 'Используемые платформы',
    options: {
      isHintCellBody: true,
    },
  },
  {
    id: 5,
    header: 'last_speed',
    name: 'Время выполнения',
    hint: 'Приблизительное время, которое потребуется для выполнения всех обменов по данной цепочке',
    options: {
      isTime: true,
    },
  },
  {
    id: 6,
    header: 'fiat',
    name: 'Вид валюты',
    hint: 'Какие виды валют есть в цепочке: Ф - Фиат безналичные / К - Крипто / Н - Фиат наличные',
  },
  {
    id: 7,
    header: 'currencies',
    name: 'Валюты',
    hint: 'Используемые валюты',
    options: {
      isHintCellBody: true,
    },
  },
  {
    id: 8,
    header: 'profit_time',
    name: 'Время прибыльности',
    hint: '% времени, в течении которого данная цепочка была прибыльна для заданного периода День/Неделя/Месяц',
    options: {
      isPercent: true,
      isTypeTime: true,
    },
  },
  {
    id: 9,
    header: 'profit_duration',
    name: 'Время прибыльности',
    hint: 'Средняя продолжительность интервала прибыльности для заданного периода День/Неделя/Месяц, и на сколько средняя продолжительность интервала прибыльности колеблется во времени',
    options: {
      isFieldWithDeviation: true,
      isTime: true,
      isCalculatePercent: true,
      isDeviation: true,
      colspan: 2,
    },
  },
  {
    id: 10,
    header: 'mean_profit_spread',
    name: 'Во время прибыльности',
    hint: 'Средний % дохода, который получается по данной цепочке в интервалы ее прибыльности, и на сколько % среднего дохода колеблется во времени',
    options: {
      isFieldWithDeviation: true,
      isPercent: true,
      isDeviation: true,
      colspan: 2,
    },
  },
  {
    id: 11,
    header: 'mean_spread',
    name: 'За весь период',
    hint: 'Средний % дохода, который получается по данной цепочке за весь заданный период, и на сколько % среднего дохода колеблется во времени',
    options: {
      isFieldWithDeviation: true,
      isPercent: true,
      isDeviation: true,
      colspan: 2,
    },
  },
  {
    id: 12,
    header: 'last_spread',
    name: 'Спред',
    hint: 'Текущий % прибыльности, который рассчитали на основании самых последних данных с платформ (1-3 минуты давности)',
    options: {
      isPercent: true,
    },
  },
  {
    id: 13,
    header: 'status_id',
    name: 'Статус',
    hint: 'Текущий статус цепочки',
    options: {
      isStatus: true,
    },
  },
];