import { getActiveSortingProps } from './GetActiveSorting.types';

// Метод, предназначенный для получения состояния сортировки на колонки
export function getActiveSorting({
  header,
  fillSorting,
  isActive,
  activeTypeTime,
  isFieldWithTypeTime,
}: getActiveSortingProps) {
  // Сортировка не активна
  if (!fillSorting) return undefined;

  // Сортировка по возрастанию/убыванию
  // activeTypeTime - (_day, _month, _week) - исполльзуется для применения сортировки стоблцов
  // на разных временных промежутках
  // eslint-disable-next-line no-console
  if (fillSorting === header + (isFieldWithTypeTime ? activeTypeTime : '')) {
    return isActive;
  }
}