import { useState } from 'react';

export const useModal = () => {
  const [isActive, setIsActive] = useState(false);

  const onClick = () => {
    setIsActive((prev) => !prev);
  };

  return {
    isActive,
    onClick,
  };
};
