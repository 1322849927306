import * as Styled from './CellText.styled';
import { CellTextProps } from './CellText.types';

export function CellText({
  size,
  color,
  text,
}: CellTextProps) {
  return(
    <Styled.CellText
      size={size}
      color={color}
    >
      {text}
    </Styled.CellText>
  );
}