import { DataHeaders } from '../../components/Table/Table.types';
import { CoursesData } from '../../redux/reducers/courses/types';

export const initialDataCourses: CoursesData = {
  currency: [],
  data: [],
  headers: [],
  id_max: 0,
  in_min: 0,
  methods: [],
  platforms: [],
  total_pages: 0,
};

export const tableMetaData: DataHeaders[] = [
  {
    id: 1,
    header: 'id',
    name: 'ID',
  },
  {
    id: 2,
    header: 'rule_number',
    name: 'Правило',
  },
  {
    id: 3,
    header: 'platform_from',
    name: 'Откуда',
  },
  {
    id: 4,
    header: 'currency_from',
    name: 'Из валюты',
  },
  {
    id: 5,
    header: 'method',
    name: 'Способ перевода',
  },
  {
    id: 6,
    header: 'platform_to',
    name: 'Куда',
  },
  {
    id: 7,
    header: 'currency_to',
    name: 'В валюту',
  },
  {
    id: 8,
    header: 'rate',
    name: 'Курс',
  },
  {
    id: 9,
    header: 'tax',
    name: 'Комиссия (%)',
  },
  {
    id: 10,
    header: 'last_change',
    name: 'Дата курса',
  },
  {
    id: 11,
    header: 'author',
    name: 'Автор',
  },
];