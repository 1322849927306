import { createSlice } from '@reduxjs/toolkit';
import {
  onChangeStrictModeDelete,
  onSelectedPlatforms,
  onSelectedPlatformsScripts,
  onSelectedCourses,
  onSelectedCoursesScripts,
  onSelectedMethods,
  onSelectedMethodsScripts,
  onMaxTimeJobScripts,
  handleChangeMaxSearch,
  handleChangeNumberOfRules,
  handleChangeStepOfPlatforms,
  handleChangeMaximumNumberChain,
  handleChangeMaximumNumberChainAlgThree,
  onChangeMarginalityChain,
  onStartCourses,
  onNewDateActiveBtnUser,
  receivingEmail,
  choiseAlg,
  onSelectedPlatformsAlgThree,
  onSelectedPlatformsScriptsAlgThree,
  onSelectedCoursesAlgThree,
  onSelectedCoursesScriptsAlgThree,
  onStartCoursesAlgThree,
  handleChangeMaxSearchAlgThree,
  handleChangeNumberOfRulesAlgThree,
  onSelectedMethodsAlgThree,
  onSelectedMethodsScriptsAlgThree,
  onMaxTimeJobScriptsAlgThree,
  onSelectedCoursesDelete,
  onSelectedCoursesDeleteScript,
  onChangeRegulation,
  onChangeTimeRegulation,
  onChangeLoopSpeed,
  onChangeRule,
  onChangeRuleWeek,
  onChangeRuleMonth,
  onChangeTimeProfit,
  onChangeTimeProfitPercent,
  onChangeTimeMonthProfitPercent,
  onChangeMarginalityChainAlgorithmThree,
  onCurrencyIdScripts,
  onSelectedPlatformsChainsDelete,
  onSelectedPlatformsDelete,
  handleChangeStepsOfPlatformsAlgorithmThree,
  setDefaultSearchAlgorithmStatusInUndefined,
  setActiveAlgorithmVersion,
} from './actions';
import * as asyncActs from './async';
import {
  ResAlgSearch,
  SelectedMetrics,
} from '../../types';


export enum TitleBtnParsing {
  start = 'Запустить',
  stop = 'Остановить',
  continue = 'Продолжить',
}

export enum StateBtnParsing {
  startBtn = 1,
  stopBtn = 2,
  countinueBtn = 3,
}

export interface IOnlineParsingMap {
  state?: boolean,
  loopId?: number,
  stateBtn?: number,
  title?: string,
  currencyStart?: string | null,
  amount?: number | null,
  profit?: number | null,
}

export interface resDeleteChains {
  loops_count: number;
  id_list: number[];
  status: number;
  ok: boolean;
}

export interface InitStateAlgorithms {
  loadingState: 'loading' | 'failed' | 'success';
  error?: string;
  requestStatus?: number;
  isResponseOK?: boolean;
  errorMessageOK?: string;
  neglectedLoopsCount?: number;

  selectedPlarforms: number[];
  selectedCourses: { id: number, name: string }[];
  selectedMethods: number[];
  maxTimeJobSctiprs: number;
  currencyIdSctiprs: string;
  valueRangeMaxSearch: string;
  valueRangeNumberOfRules: number;
  valueRangeStepsOfPlatforms: number;
  valueRangeStepsOfPlatformsAlgorithmThree: number;
  valueRangeMaximumNumberChain: number;
  valueRangeMaximumNumberChainAlgorithmThree: number;
  startCourse: number | null;
  timeActionBtn: string;
  email: string;

  alanisisGrahpTwo: any;
  newLoopsCount: number;
  loops: number[];
  time: number;
  emailLog: string;
  begin: string;
  versionAlg?: number | undefined;
  errorMessage?: string;

  alanisisGrahpOne: any;
  newLoopsCountOne: number;

  strictModeDelete: boolean;

  searchAlgorithmStatus?: boolean;
  maxTimeJobSctiprsAlgThree: number;
  selectedPlarformsAlgThree: number[],
  selectedCoursesAlgThree: SelectedMetrics[],
  selectedMethodsAlgThree: number[],
  startCourseAlgThree: number | null,
  valueRangeMaxSearchAlgThree: string,
  valueRangeNumberOfRulesAlgThree: number,
  alanisisGrahpThree?: ResAlgSearch,

  selectedCoursesDelete: number[];
  valueRangeRegulation: number[];
  valueRangeTime: number[];
  valueRangeLoopSpeed: number[];
  valueRangeRule: number[];
  valueRangeRuleWeek: number[];
  valueRangeRuleMonth: number[];
  valueRangeTimeProfit: number[];
  valueRangeTimeProfitPercent: number[];
  valueRangeTimeMonthProfitPercent: number[];
  valueRangeMarginalityChain: number[];
  valueRangeMarginalityChainAlgorithmThree: number[];
  selectedPlarformsDeleteChain: number[];
  responseDeleteChains?: resDeleteChains;

  emailUser: string,
  activeAlgorithmVersion: number;
}

const initialState: InitStateAlgorithms = {
  loadingState: 'success',
  requestStatus: 0,

  strictModeDelete: false,

  selectedPlarforms: [],
  selectedCourses: [],
  selectedMethods: [],
  maxTimeJobSctiprs: 0,
  currencyIdSctiprs: '',
  valueRangeMaxSearch: '',
  valueRangeNumberOfRules: 3,
  valueRangeNumberOfRulesAlgThree: 3,
  valueRangeStepsOfPlatforms: 5,
  valueRangeStepsOfPlatformsAlgorithmThree: 5,
  valueRangeMaximumNumberChain: 500,
  valueRangeMaximumNumberChainAlgorithmThree: 500,
  startCourse: null,
  timeActionBtn: '',
  email: '',
  emailLog: '',

  alanisisGrahpTwo: {},
  newLoopsCount: 0,
  loops: [],
  neglectedLoopsCount: 0,
  time: 0,
  begin: '',
  versionAlg: undefined,

  alanisisGrahpOne: {},
  newLoopsCountOne: 0,

  maxTimeJobSctiprsAlgThree: 0,
  selectedPlarformsAlgThree: [],
  selectedCoursesAlgThree: [],
  selectedMethodsAlgThree: [],
  startCourseAlgThree: null,
  valueRangeMaxSearchAlgThree: '',

  selectedCoursesDelete: [],
  valueRangeRegulation: [1, 10],
  valueRangeTime: [0, 7],
  valueRangeLoopSpeed: [0, 7],
  valueRangeRule: [-30, 30],
  valueRangeRuleWeek: [-30, 30],
  valueRangeRuleMonth: [-30, 30],
  valueRangeTimeProfit: [0, 120],
  valueRangeTimeProfitPercent: [0, 100],
  valueRangeTimeMonthProfitPercent: [0, 100],
  valueRangeMarginalityChain:[2.0, 100.0],
  valueRangeMarginalityChainAlgorithmThree: [2.0, 100.0],
  selectedPlarformsDeleteChain: [],

  emailUser: '',
  activeAlgorithmVersion: 2,
};  

export const algoritm = createSlice({
  name: 'algorithms',
  initialState,
  reducers: {
    onChangeStrictModeDelete,
    onSelectedPlatforms,
    onSelectedPlatformsScripts,
    onSelectedCourses,
    onSelectedCoursesScripts,
    onSelectedMethods,
    onSelectedMethodsScripts,
    onMaxTimeJobScripts,
    handleChangeMaxSearch,
    handleChangeNumberOfRules,
    handleChangeStepOfPlatforms,
    handleChangeMaximumNumberChain,
    handleChangeMaximumNumberChainAlgThree,
    handleChangeStepsOfPlatformsAlgorithmThree,
    onStartCourses,
    onNewDateActiveBtnUser,
    receivingEmail,
    choiseAlg,
    onSelectedPlatformsAlgThree,
    onSelectedPlatformsScriptsAlgThree,
    onSelectedCoursesAlgThree,
    onSelectedCoursesScriptsAlgThree,
    onStartCoursesAlgThree,
    handleChangeMaxSearchAlgThree,
    handleChangeNumberOfRulesAlgThree,
    onSelectedMethodsAlgThree,
    onSelectedMethodsScriptsAlgThree,
    onMaxTimeJobScriptsAlgThree,
    onSelectedCoursesDelete,
    onSelectedCoursesDeleteScript,
    onChangeRegulation,
    onChangeTimeRegulation,
    onChangeLoopSpeed,
    onChangeRule,
    onChangeRuleWeek,
    onChangeRuleMonth,
    onChangeTimeProfit,
    onChangeTimeProfitPercent,
    onChangeTimeMonthProfitPercent,
    onChangeMarginalityChain,
    onChangeMarginalityChainAlgorithmThree,
    onCurrencyIdScripts,
    onSelectedPlatformsChainsDelete,
    onSelectedPlatformsDelete,
    setDefaultSearchAlgorithmStatusInUndefined,
    setActiveAlgorithmVersion,
  },
  extraReducers: (builder) => {
    // Основной метод для запроса всех алгоритмов поска цепочек
    builder
      .addCase(asyncActs.postStartSrearAlgorithm.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.postStartSrearAlgorithm.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.message;
      })
      .addCase(asyncActs.postStartSrearAlgorithm.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';

        state.newLoopsCount = payload.new_loops_count;
        state.loops = payload.loops;
        state.time = payload.time;
        state.requestStatus = payload.status;
        state.isResponseOK = payload.ok;
      });

    builder
      .addCase(asyncActs.getAnalisisiGrachLog.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.getAnalisisiGrachLog.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.message;
        state.isResponseOK = false;
      })
      .addCase(asyncActs.getAnalisisiGrachLog.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';

        state.newLoopsCount = payload.new_loops_count;
        state.loops = payload.loops;
        state.time = payload.time;
        state.begin = payload.begin;
        state.emailLog = payload.email;
        state.isResponseOK = payload.ok;
        state.versionAlg = payload?.ver;
        state.errorMessage = payload?.error_message;
        state.neglectedLoopsCount = payload?.neglected_loops_count;
      });

    builder
      .addCase(asyncActs.getChainsDeleteCount.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.getChainsDeleteCount.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.message;
        state.isResponseOK = false;
        state.errorMessageOK = error.message;
      })
      .addCase(asyncActs.getChainsDeleteCount.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';

        state.responseDeleteChains = payload;
        state.requestStatus = payload.status;
        state.isResponseOK = payload.ok;
        state.errorMessageOK = payload?.error_message;
      });
      
    // Метод, для проверки статуса алгоритма
    builder
      .addCase(asyncActs.getStatusAlgorithmLog.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.getStatusAlgorithmLog.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.message;
      })
      .addCase(asyncActs.getStatusAlgorithmLog.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';
        state.searchAlgorithmStatus = payload.active;
      });

    builder
      .addCase(asyncActs.deleteChainsDeleteCount.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.deleteChainsDeleteCount.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.message;
        state.isResponseOK = false;
        state.errorMessageOK = error.message;
      })
      .addCase(asyncActs.deleteChainsDeleteCount.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';

        state.isResponseOK = payload.ok;
        state.errorMessageOK = payload?.error_message;
        state.requestStatus = payload.status;
      });

    builder
      .addCase(asyncActs.getEmail.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.getEmail.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.message;
      })
      .addCase(asyncActs.getEmail.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';
        state.emailUser = payload.data.email;
      });
  },
});

export const {
  onChangeStrictModeDelete: onChangeStrictModeDeleteAct,
  onSelectedPlatforms: onSelectedPlatformsAct,
  onSelectedPlatformsScripts: onSelectedPlatformsScriptsAct,
  onSelectedCourses: onSelectedCoursesAct,
  onSelectedCoursesScripts: onSelectedCoursesScriptsAct,
  onSelectedMethods: onSelectedMethodsAct,
  onSelectedMethodsScripts: onSelectedMethodsScriptsAct,
  onMaxTimeJobScripts: onMaxTimeJobScriptsAct,
  handleChangeMaxSearch: handleChangeMaxSearchAct,
  handleChangeNumberOfRules: handleChangeNumberOfRulesAct,
  handleChangeStepOfPlatforms: handleChangeStepOfPlatformsAct,
  handleChangeMaximumNumberChain: handleChangeMaximumNumberChainAct,
  handleChangeMaximumNumberChainAlgThree: handleChangeMaximumNumberChainAlgThreeAct,
  onStartCourses: onStartCoursesAct,
  onNewDateActiveBtnUser: onNewDateActiveBtnUserAct,
  receivingEmail: receivingEmailAct,
  choiseAlg: choiseAlgAct,
  onSelectedPlatformsAlgThree: onSelectedPlatformsAlgThreeAct,
  onSelectedPlatformsScriptsAlgThree: onSelectedPlatformsScriptsAlgThreeAct,
  onSelectedCoursesAlgThree: onSelectedCoursesAlgThreeAct,
  onSelectedCoursesScriptsAlgThree: onSelectedCoursesScriptsAlgThreeAct,
  onStartCoursesAlgThree: onStartCoursesAlgThreeAct,
  handleChangeMaxSearchAlgThree: handleChangeMaxSearchAlgThreeAct,
  handleChangeNumberOfRulesAlgThree: handleChangeNumberOfRulesAlgThreeAct,
  onSelectedMethodsAlgThree: onSelectedMethodsAlgThreeAct,
  onSelectedMethodsScriptsAlgThree: onSelectedMethodsScriptsAlgThreeAct,
  onMaxTimeJobScriptsAlgThree: onMaxTimeJobScriptsAlgThreeAct,
  onSelectedCoursesDelete: onSelectedCoursesDeleteAct,
  onSelectedCoursesDeleteScript: onSelectedCoursesDeleteScriptAct,
  onChangeRegulation: onChangeRegulationAct,
  onChangeTimeRegulation: onChangeTimeRegulationAct,
  onChangeLoopSpeed: onChangeLoopSpeedAct,
  onChangeRule: onChangeRuleAct,
  onChangeRuleWeek: onChangeRuleWeekAct,
  onChangeRuleMonth: onChangeRuleMonthAct,
  onChangeTimeProfit: onChangeTimeProfitAct,
  onChangeTimeProfitPercent: onChangeTimeProfitPercentAct,
  onChangeTimeMonthProfitPercent: onChangeTimeMonthProfitPercentAct,
  onCurrencyIdScripts: onCurrencyIdScriptsAct,
  onSelectedPlatformsChainsDelete: onSelectedPlatformsChainsDeleteAct,
  onSelectedPlatformsDelete: onSelectedPlatformsDeleteAct,
  onChangeMarginalityChain: onChangeMarginalityChainAct,
  handleChangeStepsOfPlatformsAlgorithmThree: handleChangeStepsOfPlatformsAlgorithmThreeAct,
  onChangeMarginalityChainAlgorithmThree: onChangeMarginalityChainAlgorithmThreeAct,
  setDefaultSearchAlgorithmStatusInUndefined: setDefaultSearchAlgorithmStatusInUndefinedAction,
  setActiveAlgorithmVersion: setActiveAlgorithmVersionAction,
} = algoritm.actions;

export default algoritm.reducer;
