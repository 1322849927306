import { PayloadAction } from '@reduxjs/toolkit';
import { FiltersState } from '.';
import { coursesFiltersInitialState } from './constants';
import {
  CoursesActiveFilters,
  DataChainsFull,
  DataMetric,
} from './types';
import { INPUT_MASKS } from '../../../constants/constants';

// Метод, который сбрасывает фильтры в начальное состояние
export const coursesClearFilter = (
  state: FiltersState,
) => {
  state.coursesActiveFilters = coursesFiltersInitialState;
};

// Метод, который устанавливает активные фильтры в таблице курсов
export const setFilterCourse = (
  state: FiltersState,
  { payload }: PayloadAction<CoursesActiveFilters>,
) => {
  state.coursesActiveFilters = payload;
};

export const valueLoopId = (
  state: FiltersState,
  { payload }: PayloadAction<string>,
) => {
  if (INPUT_MASKS.number.test(payload) || payload === '') {
    state.loopId = payload;
  }
};

export const onSelectCourse = (
  state: FiltersState,
  { payload }: PayloadAction<DataMetric>,
) => {
  const {
    selectedCourses,
  } = state;

  if (!selectedCourses.some((metric) => metric.name === payload.name)) {
    state.selectedCourses.push(payload);
  } else {
    state.selectedCourses = selectedCourses.filter((metric) => metric.name !== payload.name);
  }
};

export const onSelectAllCourse = (
  state: FiltersState,
  { payload }: PayloadAction<{ data: DataMetric[], isActive: boolean }>,
) => {
  state.selectedCourses = payload.isActive
    ? payload.data
    : [];
};

export const onSelectPlatform = (
  state: FiltersState,
  { payload }: PayloadAction<DataMetric>,
) => {
  const {
    selectedPlatforms,
  } = state;

  if (!selectedPlatforms.some((metric) => metric.name === payload.name)) {
    state.selectedPlatforms.push(payload);
  } else {
    state.selectedPlatforms = selectedPlatforms.filter((metric) => metric.name !== payload.name);
  }
};

export const onSelectAllPlatform = (
  state: FiltersState,
  { payload }: PayloadAction<{ data: DataMetric[], isActive: boolean }>,
) => {
  state.selectedPlatforms = payload.isActive
    ? payload.data
    : [];
};

export const onSetSelectedMethods = (
  state: FiltersState,
  { payload }: PayloadAction<DataMetric>,
) => {
  const { selectedMethods } = state;

  if (!selectedMethods.some((method) => method.name === payload.name)) {
    state.selectedMethods.push(payload);
  }
};

export const onDeleteSelectedMethods = (
  state: FiltersState,
  { payload }: PayloadAction<DataMetric>,
) => {
  const { selectedMethods } = state;

  state.selectedMethods = selectedMethods.filter((method) => method.name !== payload.name);
};

export const onSelectCurrency = (
  state: FiltersState,
  { payload }: PayloadAction<DataMetric>,
) => {
  const { selectedCurrency } = state;

  if (!selectedCurrency.some((metric) => metric.name === payload.name)) {
    state.selectedCurrency.push(payload);
  } else {
    state.selectedCurrency = selectedCurrency.filter((metric) => metric.name !== payload.name);
  }
};

export const onSelectAllCurrency = (
  state: FiltersState,
  { payload }: PayloadAction<{ data: DataMetric[], isActive: boolean }>,
) => {
  state.selectedCurrency = payload.isActive
    ? payload.data
    : [];
};

export const onRuleValue = (
  state: FiltersState,
  { payload }: PayloadAction<number>,
) => {
  state.ruleValue = payload;
};

export const onChangeRegulation = (
  state: FiltersState,
  { payload }: PayloadAction<number[]>,
) => {
  state.valueRangeRegulation = payload;
};

export const onChangeRule = (
  state: FiltersState,
  { payload }: PayloadAction<number[]>,
) => {
  state.valueRangeRule = payload;
};

export const onChangeRuleWeek = (
  state: FiltersState,
  { payload }: PayloadAction<number[]>,
) => {
  state.valueRangeRuleWeek = payload;
};

export const onChangeRuleMonth = (
  state: FiltersState,
  { payload }: PayloadAction<number[]>,
) => {
  state.valueRangeRuleMonth = payload;
};

export const onChangeTimeProfit = (
  state: FiltersState,
  { payload }: PayloadAction<number[]>,
) => {
  state.valueRangeTimeProfit = payload;
};

export const onChangeTimeProfitWeek = (
  state: FiltersState,
  { payload }: PayloadAction<number[]>,
) => {
  state.valueRangeTimeProfitWeek = payload;
};

export const onChangeTimeProfitMonth = (
  state: FiltersState,
  { payload }: PayloadAction<number[]>,
) => {
  state.valueRangeTimeProfitMonth = payload;
};

export const onChangeTimeProfitPercent = (
  state: FiltersState,
  { payload }: PayloadAction<number[]>,
) => {
  state.valueRangeTimeProfitPercent = payload;
};

export const onChangeTimeProfitPercentWeek = (
  state: FiltersState,
  { payload }: PayloadAction<number[]>,
) => {
  state.valueRangeTimeProfitPercentWeek = payload;
};

export const onChangeTimeProfitPercentMonth = (
  state: FiltersState,
  { payload }: PayloadAction<number[]>,
) => {
  state.valueRangeTimeProfitPercentMonth = payload;
};

export const onChangeTime = (
  state: FiltersState,
  { payload }: PayloadAction<number[]>,
) => {
  state.valueRangeTime = payload;
};

export const onChangeLoopSpeed = (
  state: FiltersState,
  { payload }: PayloadAction<number[]>,
) => {
  state.valueRangeLoopSpeed = payload;
};

export const setAverageIncomeDuringProfitability = (
  state: FiltersState,
  { payload }: PayloadAction<number[]>,
) => {
  state.averageIncomeDuringProfitability = payload;
};

export const setAverageIncomeDuringProfitabilityWeek = (
  state: FiltersState,
  { payload }: PayloadAction<number[]>,
) => {
  state.averageIncomeDuringProfitabilityWeek = payload;
};

export const setAverageIncomeDuringProfitabilityMonth = (
  state: FiltersState,
  { payload }: PayloadAction<number[]>,
) => {
  state.averageIncomeDuringProfitabilityMonth = payload;
};

export const setCurrentProfitability = (
  state: FiltersState,
  { payload }: PayloadAction<number[]>,
) => {
  state.currentProfitability = payload;
};

export const valueOrder = (
  state: FiltersState,
  { payload }: PayloadAction<string>,
) => {
  const {
    order,
  } = state;

  const isSameProperties = payload === order[0];

  state.order = [
    isSameProperties && order[1] ? '' : payload,
    isSameProperties && !order[1],
  ];
};

export const loopWithTimeInterval = (
  state: FiltersState,
  { payload }: PayloadAction<number>,
) => {
  const choiseDataTime = (activeTimeInterval: number) => {
    switch (activeTimeInterval) {
      case 1:
        return state.dataDay;
      case 2:
        return state.dataWeek;
      case 3:
        return state.dataMonth;
      default:
        return state.dataDay;
    }
  };

  const dataTime = choiseDataTime(payload);

  state.loopChains = state.loopChains?.map((item: DataChainsFull, index: number) => ({
    ...item,
    ...dataTime[index],
  })) || [];
};

export const loopBadStatus = (
  state: FiltersState,
) => {
  state.loopChains = [];
  state.dataDay = [];
  state.dataWeek = [];
  state.dataMonth = [];
  state.totalPages = 0;
};

export const setIsModeFilter = (
  state: FiltersState,
) => {
  state.isModeFilter = !state.isModeFilter;
};

export const setModeChainsStatus = (
  state: FiltersState,
  { payload }: PayloadAction<number[]>,
) => {
  const { modeChainsStatus } = state;
  const curredNumbersArray = new Set(payload);

  state.modeChainsStatus = !modeChainsStatus.includes(payload[0])
    ? modeChainsStatus.concat(payload)
    : modeChainsStatus.filter((item) => !curredNumbersArray.has(item));
};

export const setModeChainStatusForFreeUser = (
  state: FiltersState,
  { payload }: PayloadAction<number[]>,
) => {
  const curredNumbersArray = new Set(state.modeChainsStatus.concat(payload));

  state.modeChainsStatus = Array.from(curredNumbersArray);
};

export const setActiveFieldFilters = (
  state: FiltersState,
) => {
  const {
    loopId,
    selectedPlatforms,
    selectedCourses,
    selectedCurrency,
    selectedMethods,
    ruleValue,
    valueRangeRegulation,
    valueRangeRule,
    valueRangeRuleWeek,
    valueRangeRuleMonth,
    valueRangeTimeProfit,
    valueRangeTimeProfitWeek,
    valueRangeTimeProfitMonth,
    valueRangeTimeProfitPercent,
    valueRangeTimeProfitPercentWeek,
    valueRangeTimeProfitPercentMonth,
    valueRangeTime,
    valueRangeLoopSpeed,
    order,
    isModeFilter,
    modeChainsStatus,
    currentProfitability,
    averageIncomeDuringProfitability,
    averageIncomeDuringProfitabilityWeek,
    averageIncomeDuringProfitabilityMonth,
  } = state;

  state.activeFiltersChains = {
    loopId,
    selectedPlatforms,
    selectedCourses,
    selectedCurrency,
    selectedMethods,
    ruleValue,
    valueRangeRegulation,
    valueRangeRule,
    valueRangeRuleWeek,
    valueRangeRuleMonth,
    valueRangeTimeProfit,
    valueRangeTimeProfitWeek,
    valueRangeTimeProfitMonth,
    valueRangeTimeProfitPercent,
    valueRangeTimeProfitPercentWeek,
    valueRangeTimeProfitPercentMonth,
    valueRangeTime,
    valueRangeLoopSpeed,
    order,
    isModeFilter,
    modeChainsStatus,
    currentProfitability,
    averageIncomeDuringProfitability,
    averageIncomeDuringProfitabilityWeek,
    averageIncomeDuringProfitabilityMonth,
  };
};

export const resetIndispensableFilters = (
  state: FiltersState,
) => {
  const {
    activeFiltersChains,
  } = state;

  state.loopId = activeFiltersChains?.loopId || '';
  state.selectedPlatforms = activeFiltersChains?.selectedPlatforms || [];
  state.selectedCourses = activeFiltersChains?.selectedCourses || [];
  state.selectedCurrency = activeFiltersChains?.selectedCurrency || [];
  state.selectedMethods = activeFiltersChains?.selectedMethods || [];
  state.ruleValue = activeFiltersChains?.ruleValue || 10;
  state.valueRangeRegulation = activeFiltersChains?.valueRangeRegulation || [2, 10];
  state.valueRangeRule = activeFiltersChains?.valueRangeRule || [-30, 30];
  state.valueRangeRuleWeek = activeFiltersChains?.valueRangeRuleWeek || [-30, 30];
  state.valueRangeRuleMonth = activeFiltersChains?.valueRangeRuleMonth || [-30, 30];
  state.valueRangeTimeProfit = activeFiltersChains?.valueRangeTimeProfit || [0, 120];
  state.valueRangeTimeProfitWeek = activeFiltersChains?.valueRangeTimeProfitWeek || [0, 120];
  state.valueRangeTimeProfitMonth = activeFiltersChains?.valueRangeTimeProfitMonth || [0, 120];
  state.valueRangeTimeProfitPercent = activeFiltersChains?.valueRangeTimeProfitPercent || [0, 100];
  state.valueRangeTimeProfitPercentWeek = activeFiltersChains?.valueRangeTimeProfitPercentWeek || [0, 100];
  state.valueRangeTimeProfitPercentMonth = activeFiltersChains?.valueRangeTimeProfitPercentMonth || [0, 100];
  state.averageIncomeDuringProfitability = activeFiltersChains?.averageIncomeDuringProfitability || [-10, 30];
  state.averageIncomeDuringProfitabilityWeek = activeFiltersChains?.averageIncomeDuringProfitabilityWeek || [-10, 30];
  state.averageIncomeDuringProfitabilityMonth = activeFiltersChains?.averageIncomeDuringProfitabilityMonth || [-10, 30];
  state.currentProfitability = activeFiltersChains?.currentProfitability || [-10, 30];
  state.valueRangeTime = activeFiltersChains?.valueRangeTime || [0, 7];
  state.valueRangeLoopSpeed = activeFiltersChains?.valueRangeLoopSpeed || [0, 7];
  state.order = activeFiltersChains?.order || ['', false];
  state.isModeFilter = activeFiltersChains?.isModeFilter || true;
  state.modeChainsStatus = activeFiltersChains?.modeChainsStatus || [1, 2, 3];
};
