export enum TitleButtonParsing {
  Start = 'Запустить',
  Stop = 'Остановить',
  Continue = 'Продолжить',
}

export enum StateButtonParsing {
  Start = 1,
  Stop = 2,
}

export type OnlineParserValue = {
  authKey: string,
  idChains: number,
  profit: string | null,
  amount: string | null,
  startCurrency: string,
  activeParser: number,
}

export type IOnlineParsingMap = {
  isActive: boolean,
  loopId: number,
  stateButton: number,
  title: string,
  currencyStart: string,
  amount: string | null,
  profit: string | null,
}

export interface IOnlineParsingDataChains {
  [key: string]: string | number | boolean | undefined,

  platform_from: string;
  platform_to: string;
  currency_from: string;
  currency_to: string;
  method: string;
  rate: number;
  tax: number;
  rule_number: number;
  sumStart?: number,
  sumEnd?: number,
}
