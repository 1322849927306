import styled from 'styled-components';
import { Box, Text } from '../../../../../../../../components/styledComponents';

export const RangeSliderMarks = styled(Box)`
  grid-column: 1 / 3;
  display: flex;
  flex-direction: column;

  @media (max-width: 510px) {
    grid-column: 1 / 2;
  }
`;

export const RangeSliderMarksTitle = styled(Text).attrs({
  variant: 'regular16',
  color: 'snowWhite',
})``;

export const RangeSliderMarksWrapper = styled(Box).attrs({
  mt: 4,
  px: 4,
})``;