export type GraphChain = {
  spread: number;
  time: string;
}

// Названия в енаме соответствуют названию свойст в объекте с данными по графику
export enum ActiveTimeGraph {
  Second = 'graphs',
  Minute = 'graphm',
  Hour = 'graphh',
  Month = 'graphd',
}

export type GraphTime = {
  id: ActiveTimeGraph;
  title: string;
  isSelected: boolean;
  isDisabled: boolean;
}
